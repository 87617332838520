<template>
    <div class="container-analyticDeveloper">
      <SideHeader/>
      <div class="container-right-analyticDeveloper">
        <TopHeader
         :pathPage="path"
         :pathSubPage="subPath"
         :pathSubSubPage="subSubPath"
        />
        <div class="main-analyticDeveloper">
          <div class="box-header-analyticDeveloper">
          <div class="box-navigation">
            <div class="navigation" :class="[{ active: page ==='login' }]" @click="page = 'login', subSubPath = 'Login Activity', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">Login Activity</div>
            <div class="navigation" :class="[{ active: page ==='save' }]" @click="page = 'save', subSubPath = 'Save Activity', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">Save Activity</div>
            <div class="navigation" :class="[{ active: page ==='classroom' }]" @click="page = 'classroom', subSubPath = 'Classroom Activity', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">Classroom Activity</div>
            <div class="navigation" :class="[{ active: page ==='assets' }]" @click="page = 'assets', subSubPath = 'Assets Usages', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">Assets Usages</div>
          </div>
          <div class="box-header-right">
            <!-- <div class="update-timer">
              <p>Last updated was {{timer}} seconds ago</p>
            </div> -->
            <div class="btn-refresh" @click="refresh()"><img src="../../assets/refresh-cw.svg" alt=""></div>
            <div v-if="isDetail == false" @click="export_to_csv()" class="btn-export">
              <img src="../../assets/log-in.svg" alt="">
              <p>Export</p>
            </div>
          </div>
        </div>
        <div class="box-main-analyticDeveloper">
          <div class="box-page" v-if="page === 'login' && isDetail == false">
            <div class="box-chart">
              <div class="box-chart-header">
                <p>Login Activity</p>
                <div class="chart-header-right">
                  <div class="box-dropdown">
                    <ul>
                      <li class="dropdown">
                        <div class="filter">
                          <img src="../../assets/filter.svg" alt="">
                          <p>Filter</p>
                        </div>
                        <ul>
                          <li>
                            <div class="box-submenu" @click="filterFunction('oldest')"><p>Date: Oldest</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('newest')"><p>Date: Newest</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('hightolow')"><p>Login: High to Low</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('lowtohigh')"><p>Login: Low to High</p></div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="card-date">
                    <div class="date-title" @click="openDate()">
                      <img src="../../assets/calendar.svg" alt="">
                      <p>Date</p>
                    </div>
                    <div id="date" class="dropdown-date">
                      <div class="card-start-end">
                        <input v-model="startDateRange" @change="dateFilterFunction()" type="date">
                        <p>to</p>
                        <input v-model="endDateRange" @change="dateFilterFunction()" type="date">
                      </div>
                      <div class="separator"></div>
                      <div class="box-date-filter">
                        <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chart-detail">
                <apexchart width="95%" height="400" type="bar" :options="options" :series="series"></apexchart>
              </div>
              <div class="paginate-left">
                <p>Showing {{start_chart_devLogin + 1}} to {{end_chart_devLogin}} of {{total_chart_item_devLogin}} entries</p>
                <paginate
                v-model="paginate_devLogin"
                :page-count="total_chart_page_devLogin"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changeChartDeveloperLogin"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
            </div>
            <div class="box-search">
              <div class="card-searching">
                <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingLogin === false" id="loginDevTable">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Time</th>
                  <th>Login Count</th>
                </tr>
                <tr class="hover-row" @click="fetchLoginDetail(id)" v-for="(dataDeveloperLogin,id) in allDataDeveloperLogin" :key="dataDeveloperLogin.id">
                  <td v-if="id >= start_page_developerLogin && id < end_page_developerLogin">{{id + 1}}</td>
                  <td v-if="id >= start_page_developerLogin && id < end_page_developerLogin">{{dataDeveloperLogin.login_date}}</td>
                  <td v-if="id >= start_page_developerLogin && id < end_page_developerLogin">{{dataDeveloperLogin.name}}</td>
                  <td v-if="id >= start_page_developerLogin && id < end_page_developerLogin">{{dataDeveloperLogin.email}}</td>
                  <td v-if="id >= start_page_developerLogin && id < end_page_developerLogin">{{dataDeveloperLogin.login_time}}</td>
                  <td v-if="id >= start_page_developerLogin && id < end_page_developerLogin">{{dataDeveloperLogin.login_counts}}</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingLogin === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_developerLogin + 1}} to {{end_page_developerLogin}} of {{total_item_developerLogin}} entries</p>
                <paginate
                v-model="paginate_developerLogin"
                :page-count="total_page_developerLogin"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageDeveloperLogin"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page === 'login' && isDetail == true">
            <div class="box-back" @click="isDetail = false">
              <img src="../../assets/back.svg" alt="">
              <p>Back to User Login</p>
            </div>
            <div class="box-details">
              <div class="detail-name">
                <p>{{loginDetail.name}}</p>
              </div>
              <div class="detail-email-count">
                <div class="email">
                  <p>Email : {{loginDetail.email}}</p>
                </div>
                <div class="count">
                  <p>Login counts: {{loginDetail.login_counts}}</p>
                </div>
              </div>
            </div>
            <div class="box-table-padding">
              <table v-if="isLoadingLoginDetail === false">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Time</th>
                </tr>
                <tr v-for="(dataDetailLogin,id) in allDataLoginDetail" :key="dataDetailLogin.id">
                  <td v-if="id >= start_page_developerLoginDetail && id < end_page_developerLoginDetail">{{id + 1}}</td>
                  <td v-if="id >= start_page_developerLoginDetail && id < end_page_developerLoginDetail">{{dataDetailLogin.login_date}}</td>
                  <td v-if="id >= start_page_developerLoginDetail && id < end_page_developerLoginDetail">{{dataDetailLogin.login_time}}</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingLoginDetail === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left-padding">
              <p>Showing {{start_page_developerLoginDetail + 1}} to {{end_page_developerLoginDetail}} of {{total_item_developerLoginDetail}} entries</p>
                <paginate
                  v-model="paginate_developerLoginDetail"
                  :page-count="total_page_developerLoginDetail"
                  :page-range="3"
                  :margin-pages="1"
                  :click-handler="changePageDeveloperLoginDetail"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'pagination'"
                  :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page === 'save'">
            <div class="box-chart">
              <div class="box-chart-header">
                <p>Save Activity</p>
                <div class="chart-header-right">
                  <div class="box-dropdown">
                    <ul>
                      <li class="dropdown">
                        <div class="filter">
                          <img src="../../assets/filter.svg" alt="">
                          <p>Filter</p>
                        </div>
                        <ul>
                          <li>
                            <div class="box-submenu" @click="filterFunction('oldest')"><p>Date: Oldest</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('newest')"><p>Date: Newest</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('atoz')"><p>Scene Name: A-Z</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('ztoa')"><p>Scene Name: Z-A</p></div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="card-date">
                    <div class="date-title" @click="openDate()">
                      <img src="../../assets/calendar.svg" alt="">
                      <p>Date</p>
                    </div>
                    <div id="date" class="dropdown-date">
                      <div class="card-start-end">
                        <input v-model="startDateRange" @change="dateFilterFunction()" type="date">
                        <p>to</p>
                        <input v-model="endDateRange" @change="dateFilterFunction()" type="date">
                      </div>
                      <div class="separator"></div>
                      <div class="box-date-filter">
                        <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chart-detail">
                <apexchart width="95%" height="400" type="bar" :options="optionsSave" :series="seriesSave"></apexchart>
              </div>
              <div class="paginate-left">
                <p>Showing {{start_chart_devSave + 1}} to {{end_chart_devSave}} of {{total_chart_item_devSave}} entries</p>
                <paginate
                v-model="paginate_devSave"
                :page-count="total_chart_page_devSave"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changeChartDeveloperSave"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
              </div>
            </div>
            <div class="box-search">
              <div class="card-searching">
                <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingSave === false" id="saveDevTable">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Scene Name</th>
                  <th>Subject</th>
                  <th>School Level</th>
                  <th>Class</th>
                  <th>Description</th>
                </tr>
                <tr v-for="(dataDeveloperSave,id) in allDataDeveloperSave" :key="dataDeveloperSave.id">
                  <td v-if="id >= start_page_developerSave && id < end_page_developerSave">{{id + 1}}</td>
                  <td v-if="id >= start_page_developerSave && id < end_page_developerSave">{{dataDeveloperSave.last_modified_date}}</td>
                  <td v-if="id >= start_page_developerSave && id < end_page_developerSave">{{dataDeveloperSave.scene_name}}</td>
                  <td v-if="id >= start_page_developerSave && id < end_page_developerSave">{{dataDeveloperSave.subject}}</td>
                  <td v-if="id >= start_page_developerSave && id < end_page_developerSave">{{dataDeveloperSave.school_level}}</td>
                  <td v-if="id >= start_page_developerSave && id < end_page_developerSave">{{dataDeveloperSave.class}}</td>
                  <td v-if="id >= start_page_developerSave && id < end_page_developerSave">{{dataDeveloperSave.description}}</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingSave === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_developerSave + 1}} to {{end_page_developerSave}} of {{total_item_developerSave}} entries</p>
                <paginate
                v-model="paginate_developerSave"
                :page-count="total_page_developerSave"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageDeveloperSave"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page === 'classroom'">
            <div class="box-chart">
              <div class="box-chart-header">
                <p>Classroom Activity</p>
                <div class="chart-header-right">
                  <div class="box-dropdown">
                    <ul>
                      <li class="dropdown">
                        <div class="filter">
                          <img src="../../assets/filter.svg" alt="">
                          <p>Filter</p>
                        </div>
                        <ul>
                          <li>
                            <div class="box-submenu" @click="filterFunction('oldest')"><p>Date: Oldest</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('newest')"><p>Date: Newest</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('atoz')"><p>Classroom Name: A-Z</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('ztoa')"><p>Classroom Name: Z-A</p></div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="card-date">
                    <div class="date-title" @click="openDate()">
                      <img src="../../assets/calendar.svg" alt="">
                      <p>Date</p>
                    </div>
                    <div id="date" class="dropdown-date">
                      <div class="card-start-end">
                        <input v-model="startDateRange" @change="dateFilterFunction()" type="date">
                        <p>to</p>
                        <input v-model="endDateRange" @change="dateFilterFunction()" type="date">
                      </div>
                      <div class="separator"></div>
                      <div class="box-date-filter">
                        <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chart-detail">
                <apexchart width="95%" height="400" type="bar" :options="optionsClass" :series="seriesClass"></apexchart>
              </div>
              <div class="paginate-left">
                <p>Showing {{start_chart_devClass + 1}} to {{end_chart_devClass}} of {{total_chart_item_devClass}} entries</p>
                <paginate
                v-model="paginate_devClass"
                :page-count="total_chart_page_devClass"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changeChartDeveloperClass"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
              </div>
            </div>
            <div class="box-search">
              <div class="card-searching">
                <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingClass === false" id="classDevTable">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Classroom Name</th>
                  <th>Name</th>
                  <th>Students</th>
                </tr>
                <tr v-for="(dataDeveloperClass,id) in allDataDeveloperClass" :key="dataDeveloperClass.id">
                  <td v-if="id >= start_page_developerClass && id < end_page_developerClass">{{id + 1}}</td>
                  <td v-if="id >= start_page_developerClass && id < end_page_developerClass">{{dataDeveloperClass.created_date}}</td>
                  <td v-if="id >= start_page_developerClass && id < end_page_developerClass">{{dataDeveloperClass.classroom_name}}</td>
                  <td v-if="id >= start_page_developerClass && id < end_page_developerClass">{{dataDeveloperClass.classroom_owner}}</td>
                  <td v-if="id >= start_page_developerClass && id < end_page_developerClass">{{dataDeveloperClass.student_count}}</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingClass === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_developerClass + 1}} to {{end_page_developerClass}} of {{total_item_developerClass}} entries</p>
                <paginate
                v-model="paginate_developerClass"
                :page-count="total_page_developerClass"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageDeveloperClass"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page === 'assets'">
            <div class="box-chart">
              <div class="box-chart-header">
                <p>Assets Usages</p>
                <div class="chart-header-right">
                  <div class="box-dropdown">
                    <ul>
                      <li class="dropdown">
                        <div class="filter">
                          <img src="../../assets/filter.svg" alt="">
                          <p>Filter</p>
                        </div>
                        <ul>
                          <li>
                            <div class="box-submenu" @click="filterFunction('oldest')"><p>Date: Oldest</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('newest')"><p>Date: Newest</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('hightolow')"><p>Assets Usages: High to Low</p></div>
                          </li>
                          <li>
                            <div class="box-submenu" @click="filterFunction('lowtohigh')"><p>Assets Usages: Low to High</p></div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="card-date">
                    <div class="date-title" @click="openDate()">
                      <img src="../../assets/calendar.svg" alt="">
                      <p>Date</p>
                    </div>
                    <div id="date" class="dropdown-date">
                      <div class="card-start-end">
                        <input v-model="startDateRange" @change="dateFilterFunction()" type="date">
                        <p>to</p>
                        <input v-model="endDateRange" @change="dateFilterFunction()" type="date">
                      </div>
                      <div class="separator"></div>
                      <div class="box-date-filter">
                        <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chart-detail">
                <apexchart width="95%" height="400" type="bar" :options="optionsAssets" :series="seriesAssets"></apexchart>
              </div>
              <div class="paginate-left">
                <p>Showing {{start_chart_devAssets + 1}} to {{end_chart_devAssets}} of {{total_chart_item_devAssets}} entries</p>
                <paginate
                v-model="paginate_devAssets"
                :page-count="total_chart_page_devAssets"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changeChartDeveloperAssets"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
              </div>
            </div>
            <div class="box-search">
              <div class="card-searching">
                <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingAssets === false" id="assetsDevTable">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>3D Assets</th>
                  <th>Category</th>
                  <th>Assets Usages</th>
                </tr>
                <tr v-for="(dataDeveloperAssets,id) in allDataDeveloperAssets" :key="dataDeveloperAssets.id">
                  <td v-if="id >= start_page_developerAssets && id < end_page_developerAssets">{{id + 1}}</td>
                  <td v-if="id >= start_page_developerAssets && id < end_page_developerAssets">{{dataDeveloperAssets.date}}</td>
                  <td v-if="id >= start_page_developerAssets && id < end_page_developerAssets">{{dataDeveloperAssets.name}}</td>
                  <td v-if="id >= start_page_developerAssets && id < end_page_developerAssets">{{dataDeveloperAssets.category ?? '-'}}</td>
                  <td v-if="id >= start_page_developerAssets && id < end_page_developerAssets">{{dataDeveloperAssets.usages}}</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingAssets === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_developerAssets + 1}} to {{end_page_developerAssets}} of {{total_item_developerAssets}} entries</p>
                <paginate
                v-model="paginate_developerAssets"
                :page-count="total_page_developerAssets"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageDeveloperAssets"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SideHeader from "../../components/Header/SideHeader.vue"
  import TopHeader from "../../components/Header/TopHeader.vue"
  import {onBeforeMount, ref, watch} from 'vue'
  import Paginate from 'vuejs-paginate-next';
  import { useStore } from "vuex";
  import Axios from 'axios'
  
  export default {
    name: "analyticDeveloper",
    components: {
      SideHeader,
      TopHeader,
      Paginate,
    },
    setup() {
      const store = useStore()
      let path = ref('Analytics')
      let subPath = ref('Developer')
      let subSubPath = ref('Login Activity')
      let page = ref('login')
      let isDetail = ref(false)
      let isLoadingAssets = ref(true)
      let isLoadingLogin = ref(true)
      let isLoadingLoginDetail = ref(true)
      let isLoadingSave = ref(true)
      let isLoadingClass = ref(true)
      let timer = ref(0)
      let datefilter = ref('')

      let allDataDeveloperLogin = ref(null)
      let defaultDataDevLogin = ref(null)
      let allDataDeveloperSave = ref(null)
      let defaultDataDevSave = ref(null)
      let allDataDeveloperClass = ref(null)
      let defaultDataDevClass = ref(null)
      let allDataDeveloperAssets = ref(null)
      let defaultDataDevAssets = ref(null)
      let allDataLoginDetail = ref(null)
      let loginDetail = ref(null)

      const openDate=()=>{
      document.getElementById("date").classList.toggle("show");
      }

      const refresh=()=>{
        if (page.value === 'login' && isDetail.value == false){
          fetchDeveloperLogin()
        }else if(page.value === 'login' && isDetail.value == true){
          isDetail = false
          fetchDeveloperLogin()
        }else if(page.value === 'save'){
          fetchDeveloperSave()
        }else if(page.value === 'classroom'){
          fetchDeveloperClass()
        }else if(page.value === 'assets'){
          fetchDeveloperAssets()
        }
      }

      let inputSearch = ref(null)

      watch(async()=>allDataDeveloperLogin.value,(()=>{
        findDataChartDevLogin()
      }))
      watch(async()=>allDataDeveloperSave.value,(()=>{
        findDataChartSave()
      }))
      watch(async()=>allDataDeveloperClass.value,(()=>{
        findDataChartClass()
      }))
      watch(async()=>allDataDeveloperAssets.value,(()=>{
        findDataChartAssets()
      }))

      watch(async()=>page.value,(()=>{
      if(page.value === 'login'){
        allDataDeveloperLogin.value = defaultDataDevLogin.value
        filterData.value = allDataDeveloperLogin.value
        changePageDeveloperLogin(1)
        if(allDataDeveloperLogin.value != null){
          total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
          total_item_developerLogin.value = allDataDeveloperLogin.value.length
          paginate_developerLogin.value = 1
        }

      }
      else if(page.value === 'save'){
        allDataDeveloperSave.value = defaultDataDevSave.value
        filterData.value = allDataDeveloperSave.value
        changePageDeveloperSave(1)
        if(allDataDeveloperSave.value != null){
          total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
          total_item_developerSave.value = allDataDeveloperSave.value.length
          paginate_developerSave.value = 1 
        }
          
      }
      else if(page.value === 'classroom'){
        allDataDeveloperClass.value = defaultDataDevClass.value
        filterData.value = allDataDeveloperClass.value
        changePageDeveloperClass(1)
        if(allDataDeveloperClass.value != null){
          total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
          total_item_developerClass.value = allDataDeveloperClass.value.length
          paginate_developerClass.value = 1 
        }
        
      }
      else if(page.value === 'assets'){
        allDataDeveloperAssets.value = defaultDataDevAssets.value
        filterData.value = allDataDeveloperAssets.value
        changePageDeveloperAssets(1)
        if(allDataDeveloperAssets.value != null){
          total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
          total_item_developerAssets.value = allDataDeveloperAssets.value.length
          paginate_developerAssets.value = 1 
        }
        
      }
    }))


      const searchList=(input)=>{
        if(page.value === 'login'){
          if(filterData.value == null || filterData.value === ''){
            filterData.value = defaultDataDevLogin.value
          }
          if(inputSearch.value !== ''){
            allDataDeveloperLogin.value = filterData.value
            let filter = allDataDeveloperLogin.value.filter((val)=>{
              if(val.name.toUpperCase().includes(input.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataDeveloperLogin.value = filter
            }else{
              allDataDeveloperLogin.value = filterData.value
            }
            // findDataChartDevLogin()
            changePageDeveloperLogin(1)
          }else{
            allDataDeveloperLogin.value = filterData.value
            // findDataChartDevLogin()
          }
          total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
          total_item_developerLogin.value = allDataDeveloperLogin.value.length
          paginate_developerLogin.value = 1    
        }
        if(page.value === 'save'){
          if(filterData.value == null || filterData.value === ''){
            filterData.value = defaultDataDevSave.value
          }
          if(inputSearch.value !== ''){
            allDataDeveloperSave.value = filterData.value
            let filter = allDataDeveloperSave.value.filter((val)=>{
              if(val.scene_name.toUpperCase().includes(input.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataDeveloperSave.value = filter
            }else{
              allDataDeveloperSave.value = filterData.value
            }
            // findDataChartSave()
            changePageDeveloperSave(1)
          }else{
            allDataDeveloperSave.value = filterData.value
            // findDataChartSave()
          }
          total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
          total_item_developerSave.value = allDataDeveloperSave.value.length
          paginate_developerSave.value = 1    
        }
        if(page.value === 'classroom'){
          if(filterData.value == null || filterData.value === ''){
            filterData.value = defaultDataDevClass.value
          }
          if(inputSearch.value !== ''){
            allDataDeveloperClass.value = filterData.value
            let filter = allDataDeveloperClass.value.filter((val)=>{
              if(val.classroom_name.toUpperCase().includes(input.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataDeveloperClass.value = filter
            }else{
              allDataDeveloperClass.value = filterData.value
            }
            // findDataChartClass()
            changePageDeveloperClass(1)
          }else{
            allDataDeveloperClass.value = filterData.value
            // findDataChartClass()
          }
          total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
          total_item_developerClass.value = allDataDeveloperClass.value.length
          paginate_developerClass.value = 1    
        }
        if(page.value === 'assets'){
          if(filterData.value == null || filterData.value === ''){
            filterData.value = defaultDataDevAssets.value
          }
          if(inputSearch.value !== ''){
            // console.log('if')
            // console.log(inputSearch)
            allDataDeveloperAssets.value = filterData.value
            let filter = allDataDeveloperAssets.value.filter((val)=>{
              if(val.name.toUpperCase().includes(input.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataDeveloperAssets.value = filter
            }else{
              allDataDeveloperAssets.value = filterData.value
            }
            // findDataChartAssets()
            changePageDeveloperAssets(1)
          }else{
            allDataDeveloperAssets.value = filterData.value
            // findDataChartAssets()
            // console.log('else')
          }
          total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
          total_item_developerAssets.value = allDataDeveloperAssets.value.length
          paginate_developerAssets.value = 1    
        }
      }

      let startDateRange = ref('')
      let endDateRange = ref('')
      let filterData = ref(null)
      const dateFilterFunction=(params)=>{
      if(page.value == 'login'){
        // login_date
        // console.log(allDataDeveloperLogin)
        if((startDateRange.value != '' && endDateRange.value != '')){
          datefilter.value = ''
          var formatStart =  startDateRange.value.split('-')
          var startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          var formatEnd =  endDateRange.value.split('-')
          var endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
        
          let filter = allDataDeveloperLogin.value.filter((val)=>{
            var formatDate = val.login_date.split(/\//)
            var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            return (date >= startDate && date <= endDate)
          })

          if(filter){
            allDataDeveloperLogin.value = filter
          }else{
            allDataDeveloperLogin.value = defaultDataDevLogin.value
          }
          filterData.value = allDataDeveloperLogin.value
          changePageDeveloperLogin(1)
          total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
          total_item_developerLogin.value = allDataDeveloperLogin.value.length
          paginate_developerLogin.value = 1 
          openDate()
        }
        if(params == 'today'){
          if(datefilter.value == 'today'){
            datefilter.value = ''
            allDataDeveloperLogin.value = defaultDataDevLogin.value
            filterData.value = allDataDeveloperLogin.value
            changePageDeveloperLogin(1)
            total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
            total_item_developerLogin.value = allDataDeveloperLogin.value.length
            paginate_developerLogin.value = 1 
          }else{
            datefilter.value = 'today'
            allDataDeveloperLogin.value = defaultDataDevLogin.value
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            let filter = allDataDeveloperLogin.value.filter((val)=>{
              if(val.login_date.toUpperCase().includes(today.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataDeveloperLogin.value = filter
            }else{
              allDataDeveloperLogin.value = defaultDataDevLogin.value
            }
            filterData.value = allDataDeveloperLogin.value
            changePageDeveloperLogin(1)
            total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
            total_item_developerLogin.value = allDataDeveloperLogin.value.length
            paginate_developerLogin.value = 1 
          }
          openDate()
        }
        if(params == 'yesterday'){
          if(datefilter.value == 'yesterday'){
            datefilter.value = ''
            allDataDeveloperLogin.value = defaultDataDevLogin.value
            filterData.value = allDataDeveloperLogin.value
            changePageDeveloperLogin(1)
            total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
            total_item_developerLogin.value = allDataDeveloperLogin.value.length
            paginate_developerLogin.value = 1 
          }else{
            datefilter.value = 'yesterday'
            allDataDeveloperLogin.value = defaultDataDevLogin.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperLogin.value.filter((val)=>{
              var formatDate = val.login_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataDeveloperLogin.value = filter
            }else{
              allDataDeveloperLogin.value = defaultDataDevLogin.value
            }
            filterData.value = allDataDeveloperLogin.value
            changePageDeveloperLogin(1)
            total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
            total_item_developerLogin.value = allDataDeveloperLogin.value.length
            paginate_developerLogin.value = 1 
          }
          openDate()
        }
        if(params == 'week'){
          if(datefilter.value == 'week'){
            datefilter.value = ''
            allDataDeveloperLogin.value = defaultDataDevLogin.value
            filterData.value = allDataDeveloperLogin.value
            changePageDeveloperLogin(1)
            total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
            total_item_developerLogin.value = allDataDeveloperLogin.value.length
            paginate_developerLogin.value = 1 
          }else{
            datefilter.value = 'week'
            allDataDeveloperLogin.value = defaultDataDevLogin.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperLogin.value.filter((val)=>{
              var formatDate = val.login_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataDeveloperLogin.value = filter
            }else{
              allDataDeveloperLogin.value = defaultDataDevLogin.value
            }
            filterData.value = allDataDeveloperLogin.value
            changePageDeveloperLogin(1)
            total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
            total_item_developerLogin.value = allDataDeveloperLogin.value.length
            paginate_developerLogin.value = 1 
          }
          openDate()
        }
        if(params == 'month'){
          if(datefilter.value == 'month'){
            datefilter.value = ''
            allDataDeveloperLogin.value = defaultDataDevLogin.value
            filterData.value = allDataDeveloperLogin.value
            changePageDeveloperLogin(1)
            total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
            total_item_developerLogin.value = allDataDeveloperLogin.value.length
            paginate_developerLogin.value = 1 
          }else{
            datefilter.value = 'month'
            allDataDeveloperLogin.value = defaultDataDevLogin.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperLogin.value.filter((val)=>{
              var formatDate = val.login_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataDeveloperLogin.value = filter
            }else{
              allDataDeveloperLogin.value = defaultDataDevLogin.value
            }
            filterData.value = allDataDeveloperLogin.value
            changePageDeveloperLogin(1)
            total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
            total_item_developerLogin.value = allDataDeveloperLogin.value.length
            paginate_developerLogin.value = 1 
          }
          openDate()
        }
      }
      else if(page.value == 'save'){
        // last_modified_date
        // console.log(allDataDeveloperSave)
        if((startDateRange.value != '' && endDateRange.value != '')){
          formatStart =  startDateRange.value.split('-')
          startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          formatEnd =  endDateRange.value.split('-')
          endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
        
          let filter = allDataDeveloperSave.value.filter((val)=>{
            var formatDate = val.last_modified_date.split(/\//)
            var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            return (date >= startDate && date <= endDate)
          })

          if(filter){
            allDataDeveloperSave.value = filter
          }else{
            allDataDeveloperSave.value = defaultDataDevSave.value
          }
          filterData.value = allDataDeveloperSave.value
          changePageDeveloperSave(1)
          total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
          total_item_developerSave.value = allDataDeveloperSave.value.length
          paginate_developerSave.value = 1 
          openDate()
        }
        if(params == 'today'){
          if(datefilter.value == 'today'){
            datefilter.value = ''
            allDataDeveloperSave.value = defaultDataDevSave.value
            filterData.value = allDataDeveloperSave.value
            changePageDeveloperSave(1)
            total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
            total_item_developerSave.value = allDataDeveloperSave.value.length
            paginate_developerSave.value = 1 
          }else{
            datefilter.value = 'today'
            allDataDeveloperSave.value = defaultDataDevSave.value
            today = new Date();
            dd = String(today.getDate()).padStart(2, '0');
            mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            let filter = allDataDeveloperSave.value.filter((val)=>{
              if(val.last_modified_date.toUpperCase().includes(today.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataDeveloperSave.value = filter
            }else{
              allDataDeveloperSave.value = defaultDataDevSave.value
            }
            filterData.value = allDataDeveloperSave.value
            changePageDeveloperSave(1)
            total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
            total_item_developerSave.value = allDataDeveloperSave.value.length
            paginate_developerSave.value = 1 
          }
          openDate()
        }
        if(params == 'yesterday'){
          if(datefilter.value == 'yesterday'){
            datefilter.value = ''
            allDataDeveloperSave.value = defaultDataDevSave.value
            filterData.value = allDataDeveloperSave.value
            changePageDeveloperSave(1)
            total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
            total_item_developerSave.value = allDataDeveloperSave.value.length
            paginate_developerSave.value = 1 
          }else{
            datefilter.value = 'yesterday'
            allDataDeveloperSave.value = defaultDataDevSave.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperSave.value.filter((val)=>{
              var formatDate = val.last_modified_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataDeveloperSave.value = filter
            }else{
              allDataDeveloperSave.value = defaultDataDevSave.value
            }
            filterData.value = allDataDeveloperSave.value
            changePageDeveloperSave(1)
            total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
            total_item_developerSave.value = allDataDeveloperSave.value.length
            paginate_developerSave.value = 1 
          }
          openDate()
        }
        if(params == 'week'){
          if(datefilter.value == 'week'){
            datefilter.value = ''
            allDataDeveloperSave.value = defaultDataDevSave.value
            filterData.value = allDataDeveloperSave.value
            changePageDeveloperSave(1)
            total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
            total_item_developerSave.value = allDataDeveloperSave.value.length
            paginate_developerSave.value = 1 
          }else{
            datefilter.value = 'week'
            allDataDeveloperSave.value = defaultDataDevSave.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperSave.value.filter((val)=>{
              var formatDate = val.last_modified_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataDeveloperSave.value = filter
            }else{
              allDataDeveloperSave.value = defaultDataDevSave.value
            }
            filterData.value = allDataDeveloperSave.value
            changePageDeveloperSave(1)
            total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
            total_item_developerSave.value = allDataDeveloperSave.value.length
            paginate_developerSave.value = 1 
          }
          openDate()
        }
        if(params == 'month'){
          if(datefilter.value == 'month'){
            datefilter.value = ''
            allDataDeveloperSave.value = defaultDataDevSave.value
            filterData.value = allDataDeveloperSave.value
            changePageDeveloperSave(1)
            total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
            total_item_developerSave.value = allDataDeveloperSave.value.length
            paginate_developerSave.value = 1 
          }else{
            datefilter.value = 'month'
            allDataDeveloperSave.value = defaultDataDevSave.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperSave.value.filter((val)=>{
              var formatDate = val.last_modified_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataDeveloperSave.value = filter
            }else{
              allDataDeveloperSave.value = defaultDataDevSave.value
            }
            filterData.value = allDataDeveloperSave.value
            changePageDeveloperSave(1)
            total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
            total_item_developerSave.value = allDataDeveloperSave.value.length
            paginate_developerSave.value = 1 
          }
          openDate()
        }
      }
      else if(page.value == 'classroom'){
        // created_date
        // console.log(allDataDeveloperClass)
        if((startDateRange.value != '' && endDateRange.value != '')){
          formatStart =  startDateRange.value.split('-')
          startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          formatEnd =  endDateRange.value.split('-')
          endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
        
          let filter = allDataDeveloperClass.value.filter((val)=>{
            if(val.created_date != null){
              var formatDate = val.created_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            }
          })

          if(filter){
            allDataDeveloperClass.value = filter
          }else{
            allDataDeveloperClass.value = defaultDataDevClass.value
          }
          filterData.value = allDataDeveloperClass.value
          changePageDeveloperClass(1)
          total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
          total_item_developerClass.value = allDataDeveloperClass.value.length
          paginate_developerClass.value = 1 
          openDate()
        }
        if(params == 'today'){
          if(datefilter.value == 'today'){
            datefilter.value = ''
            allDataDeveloperClass.value = defaultDataDevClass.value
            filterData.value = allDataDeveloperClass.value
            changePageDeveloperClass(1)
            total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
            total_item_developerClass.value = allDataDeveloperClass.value.length
            paginate_developerClass.value = 1
          }else{
            datefilter.value = 'today'
            allDataDeveloperClass.value = defaultDataDevClass.value
            today = new Date();
            dd = String(today.getDate()).padStart(2, '0');
            mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            let filter = allDataDeveloperClass.value.filter((val)=>{
              if(val.created_date != null){
                if(val.created_date.toUpperCase().includes(today.toUpperCase())){
                  return val
                }
              }
            })
            if(filter){
              allDataDeveloperClass.value = filter
            }else{
              allDataDeveloperClass.value = defaultDataDevClass.value
            }
            filterData.value = allDataDeveloperClass.value
            changePageDeveloperClass(1)
            total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
            total_item_developerClass.value = allDataDeveloperClass.value.length
            paginate_developerClass.value = 1 
          }
          openDate()
        }
        if(params == 'yesterday'){
          if(datefilter.value == 'yesterday'){
            datefilter.value = ''
            allDataDeveloperClass.value = defaultDataDevClass.value
            filterData.value = allDataDeveloperClass.value
            changePageDeveloperClass(1)
            total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
            total_item_developerClass.value = allDataDeveloperClass.value.length
            paginate_developerClass.value = 1
          }else{
            datefilter.value = 'yesterday'
            allDataDeveloperClass.value = defaultDataDevClass.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperClass.value.filter((val)=>{
              if(val.created_date != null){
                var formatDate = val.created_date.split(/\//)
                var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
                return (date >= startDate && date <= endDate)
              }
            })

            if(filter){
              allDataDeveloperClass.value = filter
            }else{
              allDataDeveloperClass.value = defaultDataDevClass.value
            }
            filterData.value = allDataDeveloperClass.value
            changePageDeveloperClass(1)
            total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
            total_item_developerClass.value = allDataDeveloperClass.value.length
            paginate_developerClass.value = 1
          }
          openDate()
        }
        if(params == 'week'){
          if(datefilter.value == 'week'){
            datefilter.value = ''
            allDataDeveloperClass.value = defaultDataDevClass.value
            filterData.value = allDataDeveloperClass.value
            changePageDeveloperClass(1)
            total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
            total_item_developerClass.value = allDataDeveloperClass.value.length
            paginate_developerClass.value = 1
          }else{
            datefilter.value = 'week'
            allDataDeveloperClass.value = defaultDataDevClass.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperClass.value.filter((val)=>{
              if(val.created_date != null){
                var formatDate = val.created_date.split(/\//)
                var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
                return (date >= startDate && date <= endDate)
              }
            })

            if(filter){
              allDataDeveloperClass.value = filter
            }else{
              allDataDeveloperClass.value = defaultDataDevClass.value
            }
            filterData.value = allDataDeveloperClass.value
            changePageDeveloperClass(1)
            total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
            total_item_developerClass.value = allDataDeveloperClass.value.length
            paginate_developerClass.value = 1
          }
          openDate()
        }
        if(params == 'month'){
          if(datefilter.value == 'month'){
            datefilter.value = ''
            allDataDeveloperClass.value = defaultDataDevClass.value
            filterData.value = allDataDeveloperClass.value
            changePageDeveloperClass(1)
            total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
            total_item_developerClass.value = allDataDeveloperClass.value.length
            paginate_developerClass.value = 1
          }else{
            datefilter.value = 'month'
            allDataDeveloperClass.value = defaultDataDevClass.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperClass.value.filter((val)=>{
              if(val.created_date != null){
                var formatDate = val.created_date.split(/\//)
                var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
                return (date >= startDate && date <= endDate)
              }
            })

            if(filter){
              allDataDeveloperClass.value = filter
            }else{
              allDataDeveloperClass.value = defaultDataDevClass.value
            }
            filterData.value = allDataDeveloperClass.value
            changePageDeveloperClass(1)
            total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
            total_item_developerClass.value = allDataDeveloperClass.value.length
            paginate_developerClass.value = 1
          }
          openDate()
        }
      }
      else if(page.value == 'assets'){
        // date
        // console.log(allDataDeveloperAssets)
        if((startDateRange.value != '' && endDateRange.value != '')){
          formatStart =  startDateRange.value.split('-')
          startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          formatEnd =  endDateRange.value.split('-')
          endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
        
          let filter = allDataDeveloperAssets.value.filter((val)=>{
            var formatDate = val.date.split(/\//)
            var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            return (date >= startDate && date <= endDate)
          })

          if(filter){
            allDataDeveloperAssets.value = filter
          }else{
            allDataDeveloperAssets.value = defaultDataDevAssets.value
          }
          filterData.value = allDataDeveloperAssets.value
          changePageDeveloperAssets(1)
          total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
          total_item_developerAssets.value = allDataDeveloperAssets.value.length
          paginate_developerAssets.value = 1 
          openDate()
        }
        else if(params == 'today'){
          if(datefilter.value == 'today'){
            datefilter.value = ''
            allDataDeveloperAssets.value = defaultDataDevAssets.value
            filterData.value = allDataDeveloperAssets.value
            changePageDeveloperAssets(1)
            total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
            total_item_developerAssets.value = allDataDeveloperAssets.value.length
            paginate_developerAssets.value = 1 
          }else{
            datefilter.value = 'today'
            allDataDeveloperAssets.value = defaultDataDevAssets.value
            today = new Date();
            dd = String(today.getDate()).padStart(2, '0');
            mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            let filter = allDataDeveloperAssets.value.filter((val)=>{
              if(val.date.toUpperCase().includes(today.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataDeveloperAssets.value = filter
            }else{
              allDataDeveloperAssets.value = defaultDataDevAssets.value
            }
            filterData.value = allDataDeveloperAssets.value
            changePageDeveloperAssets(1)
            total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
            total_item_developerAssets.value = allDataDeveloperAssets.value.length
            paginate_developerAssets.value = 1 
          }
          openDate()
        }
        else if(params == 'yesterday'){
          if(datefilter.value == 'yesterday'){
            datefilter.value = ''
            allDataDeveloperAssets.value = defaultDataDevAssets.value
            filterData.value = allDataDeveloperAssets.value
            changePageDeveloperAssets(1)
            total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
            total_item_developerAssets.value = allDataDeveloperAssets.value.length
            paginate_developerAssets.value = 1 
          }else{
            datefilter.value = 'yesterday'
            allDataDeveloperAssets.value = defaultDataDevAssets.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperAssets.value.filter((val)=>{
              var formatDate = val.date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataDeveloperAssets.value = filter
            }else{
              allDataDeveloperAssets.value = defaultDataDevAssets.value
            }
            filterData.value = allDataDeveloperAssets.value
            changePageDeveloperAssets(1)
            total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
            total_item_developerAssets.value = allDataDeveloperAssets.value.length
            paginate_developerAssets.value = 1 
          }
          openDate()
        }
        else if(params == 'week'){
          if(datefilter.value == 'week'){
            datefilter.value = ''
            allDataDeveloperAssets.value = defaultDataDevAssets.value
            filterData.value = allDataDeveloperAssets.value
            changePageDeveloperAssets(1)
            total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
            total_item_developerAssets.value = allDataDeveloperAssets.value.length
            paginate_developerAssets.value = 1 
          }else{
            datefilter.value = 'week'
            allDataDeveloperAssets.value = defaultDataDevAssets.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperAssets.value.filter((val)=>{
              var formatDate = val.date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataDeveloperAssets.value = filter
            }else{
              allDataDeveloperAssets.value = defaultDataDevAssets.value
            }
            filterData.value = allDataDeveloperAssets.value
            changePageDeveloperAssets(1)
            total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
            total_item_developerAssets.value = allDataDeveloperAssets.value.length
            paginate_developerAssets.value = 1 
          }
          openDate()
        }
        else if(params == 'month'){
          if(datefilter.value == 'month'){
            datefilter.value = ''
            allDataDeveloperAssets.value = defaultDataDevAssets.value
            filterData.value = allDataDeveloperAssets.value
            changePageDeveloperAssets(1)
            total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
            total_item_developerAssets.value = allDataDeveloperAssets.value.length
            paginate_developerAssets.value = 1 
          }else{
            datefilter.value = 'month'
            allDataDeveloperAssets.value = defaultDataDevAssets.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataDeveloperAssets.value.filter((val)=>{
              var formatDate = val.date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataDeveloperAssets.value = filter
            }else{
              allDataDeveloperAssets.value = defaultDataDevAssets.value
            }
            filterData.value = allDataDeveloperAssets.value
            changePageDeveloperAssets(1)
            total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
            total_item_developerAssets.value = allDataDeveloperAssets.value.length
            paginate_developerAssets.value = 1 
          }
          openDate()
        }
        else{
          allDataDeveloperAssets.value = defaultDataDevAssets.value
          filterData.value = allDataDeveloperAssets.value
          changePageDeveloperAssets(1)
          total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
          total_item_developerAssets.value = allDataDeveloperAssets.value.length
          paginate_developerAssets.value = 1 
        }
      }
      }

      const filterFunction=(params)=>{
        if(page.value === 'login'){
          // allDataDeveloperLogin.value = defaultDataDevLogin.value
          // console.log(allDataDeveloperLogin)
          if(params === 'oldest'){
            // console.log('masuk old')
            allDataDeveloperLogin.value.sort((a,b)=>a.login_date_unix - b.login_date_unix)
            findDataChartDevLogin()
          }
          else if(params === 'newest'){
            // console.log('masuk new')
            allDataDeveloperLogin.value.sort((a,b)=>b.login_date_unix - a.login_date_unix)
            findDataChartDevLogin()
          }
          else if(params === 'hightolow'){
            allDataDeveloperLogin.value.sort((a,b)=>b.login_counts - a.login_counts)
            findDataChartDevLogin()
          }
          else if(params === 'lowtohigh'){
            allDataDeveloperLogin.value.sort((a,b)=>a.login_counts - b.login_counts)
            findDataChartDevLogin()
          }
        }
        else if(page.value === 'assets'){
          // allDataDeveloperAssets.value = defaultDataDevAssets.value
          if(params === 'oldest'){
            allDataDeveloperAssets.value.sort((a,b)=>a.date_unix - b.date_unix)
            findDataChartAssets()
          }
          else if(params === 'newest'){
            allDataDeveloperAssets.value.sort((a,b)=>b.date_unix - a.date_unix)
            findDataChartAssets()
          }
          else if(params === 'hightolow'){
            allDataDeveloperAssets.value.sort((a,b)=>b.usages - a.usages)
            findDataChartAssets()
          }
          else if(params === 'lowtohigh'){
            allDataDeveloperAssets.value.sort((a,b)=>a.usages - b.usages)
            findDataChartAssets()
          }
        }
        else if(page.value === 'save'){
          // allDataDeveloperSave.value = defaultDataDevSave.value
          // console.log(allDataDeveloperSave)
          if(params === 'oldest'){
            allDataDeveloperSave.value.sort((a,b)=>a.last_modified_date_unix - b.last_modified_date_unix)
            findDataChartSave()
          }
          else if(params === 'newest'){
            allDataDeveloperSave.value.sort((a,b)=>b.last_modified_date_unix - a.last_modified_date_unix)
            findDataChartSave()
          }
          else if(params === 'atoz'){
            allDataDeveloperSave.value.sort(function(a,b){
              return a.scene_name.localeCompare(b.scene_name)})
              findDataChartSave()
          }
          else if(params === 'ztoa'){
            allDataDeveloperSave.value.sort(function(a,b){
              return b.scene_name.localeCompare(a.scene_name)})
              findDataChartSave()
          }
        }
        else if(page.value === 'classroom'){
          // allDataDeveloperClass.value = defaultDataDevClass.value
          // console.log(allDataDeveloperClass)
          if(params === 'oldest'){
            allDataDeveloperClass.value.sort((a,b)=>a.created_date_unix - b.created_date_unix)
            findDataChartClass()
          }
          else if(params === 'newest'){
            allDataDeveloperClass.value.sort((a,b)=>b.created_date_unix - a.created_date_unix)
            findDataChartClass()
          }
          else if(params === 'atoz'){
            allDataDeveloperClass.value.sort(function(a,b){
              return a.classroom_name.localeCompare(b.classroom_name)})
              findDataChartClass()
          }
          else if(params === 'ztoa'){
            allDataDeveloperClass.value.sort(function(a,b){
              return b.classroom_name.localeCompare(a.classroom_name)})
              findDataChartClass()
          }
        }
      }

      const export_to_csv=()=>{
      if(page.value === 'login'){
        let arrayToPdf = []
        var rows = document.querySelectorAll('#loginDevTable tr')
        let row = []

        rows[0].querySelectorAll('td,th').forEach((val)=>{
          // console.log(val.innerText)
          row.push(val.innerText)
        })
        // .replaceAll(',', '')
        arrayToPdf.push(row.join(','))
        allDataDeveloperLogin.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.login_date},${val.name.replaceAll(',', '')},${val.email},${val.login_time},${val.login_counts}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if(page.value === 'save'){
        let arrayToPdf = []
        rows = document.querySelectorAll('#saveDevTable tr')
        let row = []
        rows[0].querySelectorAll('td,th').forEach((val)=>{
          // console.log(val.innerText)
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataDeveloperSave.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.last_modified_date},${val.scene_name.replaceAll(',', '')},${val.subject},${val.school_level},${val.class},${val.description.replaceAll(',', '')}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if(page.value === 'classroom'){
        let arrayToPdf = []
        rows = document.querySelectorAll('#classDevTable tr')
        let row = []
        rows[0].querySelectorAll('td,th').forEach((val)=>{
          // console.log(val.innerText)
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataDeveloperClass.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.created_date},${val.classroom_name.replaceAll(',', '')},${val.classroom_owner.replaceAll(',', '')},${val.student_count}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if(page.value === 'assets'){
        let arrayToPdf = []
        rows = document.querySelectorAll('#assetsDevTable tr')
        let row = []
        rows[0].querySelectorAll('td,th').forEach((val)=>{
          // console.log(val.innerText)
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataDeveloperAssets.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.date},${val.name.replaceAll(',', '')},${val.category.replaceAll(',', '')},${val.usages}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
    }

    function download_csv(csv, filename){
      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], {type: "text/csv"});
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }

    let series = ref([])

    let options=ref(
                  {
                      width:'50%',
                      chart:{
                      type:'bar',
                          },plotOptions: {
                      bar: {
                          horizontal: false,
                          columnWidth: '70%',
                          endingShape: 'rounded'
                      },
                      },dataLabels: {
                          enabled: false
                      },
                      stroke: {
                          show: true,
                          width: 2,
                          colors: ['transparent']
                      },
                      xaxis: {
                          categories: [],
                          title: {
                            text: 'Date'
                          }
                      },
                      yaxis: {
                          title: {
                              text: 'Login'
                          }
                      },
                      fill: {
                          opacity: 1 ,
                      }, 
                      // tooltip: {
                      //     y: {
                      //         formatter: function (val) {
                      //         return "" + val + " second"
                      //         }
                      //     }
                      // }
                  }
    )
    
    let seriesSave = ref([])
    let optionsSave = ref(
          {
              width:'50%',
              chart:{
              type:'bar',
                  },plotOptions: {
              bar: {
                  horizontal: false,
                  columnWidth: '70%',
                  endingShape: 'rounded'
              },
              },dataLabels: {
                  enabled: false
              },
              stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
              },
              xaxis: {
                  categories: [],
                  title: {
                    text: 'Date'
                  },
                  labels: {
                  format: 'dd/MM',
                },
              },
              yaxis: {
                  title: {
                      text: 'Login Counts'
                  }
              },
              fill: {
                  opacity: 1 ,
              }, 
              // tooltip: {
              //     y: {
              //         formatter: function (val) {
              //         return "" + val + " second"
              //         }
              //     }
              // }
          }
    )

    let seriesClass = ref([])
    let optionsClass = ref(
          {
              width:'50%',
              chart:{
              type:'bar',
                  },plotOptions: {
              bar: {
                  horizontal: false,
                  columnWidth: '70%',
                  endingShape: 'rounded'
              },
              },dataLabels: {
                  enabled: false
              },
              stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
              },
              xaxis: {
                  categories: [],
                  title: {
                    text: 'Date'
                  },
                  labels: {
                  format: 'dd/MM',
                },
              },
              yaxis: {
                  title: {
                      text: 'Login Counts'
                  }
              },
              fill: {
                  opacity: 1 ,
              }, 
              // tooltip: {
              //     y: {
              //         formatter: function (val) {
              //         return "" + val + " second"
              //         }
              //     }
              // }
          }
    )

    let seriesAssets = ref([])
    let optionsAssets=ref(
            {
                width:'50%',
                chart:{
                type:'bar',
                    },plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '70%',
                    endingShape: 'rounded'
                },
                },dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                    title: {
                      text: 'Date'
                    },
                    labels: {
                    format: 'dd/MM',
                  },
                },
                yaxis: {
                    title: {
                        text: 'Login Counts'
                    }
                },
                fill: {
                    opacity: 1 ,
                }, 
                // tooltip: {
                //     y: {
                //         formatter: function (val) {
                //         return "" + val + " second"
                //         }
                //     }
                // }
            }
      )
    
    let idChartDevLogin = ref([])
    let dataChartDevLogin = ref([])  
    let idChartSave = ref([])
    let dataChartSave= ref()  
    let idChartClass = ref([])
    let dataChartClass = ref()   
    let idChartAssets = ref([])
    let dataChartAssets = ref()

    const isDateInArray=(needle, haystack)=>{
      for (var i = 0; i < haystack.length; i++) {
        if (needle === haystack[i]) {
          return true;
        }
      }
      return false;
    }
    const findDataChartDevLogin=async()=>{
      // console.log('start push data')
      let dates = ref(null)
      let uid = ref(null)
      let uniqueDates = ref(null)
      uniqueDates.value = []
      dates.value = []
      uid.value = []
      const rawDates = []

      for(let i = 0; i < allDataDeveloperLogin.value.length; i++){
        uid.value.push(allDataDeveloperLogin.value[i].user_uid)
      }
      // console.log(uid)

      const promise = []
      let token = localStorage.getItem('idToken')
      for(let i = 0; i < uid.value.length; i++){
        promise.push(
          Axios.post('https://us-central1-millea-lab.cloudfunctions.net/web/api/getDeveloperLoginActivity',{
            "user_uid":uid.value[i]
          },{
        headers:{
          Authorization: 'Bearer ' + token,
        }}).catch((err)=>{
          // console.log(err)
        if(err.message === 'Request failed with status code 401'){
            localStorage.removeItem('idStorage')
            window.location.href="/login"
        }
      })
      )}

      const resolve = await Promise.all(promise)
      for(let i = 0; i < resolve.length; i++){
        for(let j = 0; j < resolve[i].data.details.length; j++){
          
          if(resolve[i] != undefined){
            // console.log(i)
            rawDates.push({
              login_date: resolve[i].data.details[j].login_date,
              login_datetime_unix: resolve[i].data.details[j].login_datetime_unix,
            })
          }
        }
      }

      // rawDates.sort((a,b)=>b.login_datetime_unix - a.login_datetime_unix)

      dates.value = rawDates.map((date)=>date.login_date)

      for (var j = 0; j < dates.value.length; j++) {
        if(dates.value[j] == null || dates.value[j] == ''){
          break
        }else if(!isDateInArray(dates.value[j], uniqueDates.value)){
          uniqueDates.value.push(dates.value[j]);
        }
      }

      idChartDevLogin.value = uniqueDates.value
      dataChartDevLogin.value = []
      var count = 0

      for (var k = 0; k < uniqueDates.value.length; k++){
        count = 0
        for(var l = 0; l < dates.value.length; l++){
          if(uniqueDates.value[k] === dates.value[l]){
            count = count + 1
            dataChartDevLogin.value[k] = count
          }
        }
      }
      pushChartDataLogin()
      // console.log('end push data')
    }
    const pushChartDataLogin=()=>{
      let tempId = ref([])
      let tempData = ref([])
      let add = 0
      for(let i = start_item_chart_devLogin.value; i < max_item_chart_devLogin.value; i++){
        if(idChartDevLogin.value[i] == null || idChartDevLogin.value[i] == ''){
          break
        }else{
          tempId.value[add] = idChartDevLogin.value[i]
          tempData.value[add] = dataChartDevLogin.value[i]
          add++
        }
      }
      total_chart_page_devLogin.value = Math.ceil((idChartDevLogin.value.length / 10))
      total_chart_item_devLogin.value = idChartDevLogin.value.length
      // console.log(idChartDevLogin)
      // console.log(dataChartDevLogin)
      series.value = [{
        name: 'Date',
        data: tempData,
        color:'#004F98'
      }]
      options.value = {
        xaxis: {
          labels: {
            format: 'dd/MM',
          },
          categories: tempId,
          title:{
            text: 'Date'
          }
        },
        yaxis: {
          title: {
            text: 'Activity Count'
          }
        }
      }
    }

    const findDataChartSave=()=>{
      let dates = ref(null)
      let uniqueDates = ref(null)
      dates.value = []
      uniqueDates.value = []

      for(var i = 0; i < allDataDeveloperSave.value.length; i++){
        dates.value.push(allDataDeveloperSave.value[i].last_modified_date)
      }

      for (var j = 0; j < dates.value.length; j++) {
        if(!isDateInArray(dates.value[j], uniqueDates.value)){
          uniqueDates.value.push(dates.value[j]);
        }
      }

      idChartSave.value = uniqueDates.value
      dataChartSave.value = []

      var count = 0

      for (var k = 0; k < uniqueDates.value.length; k++){
        count = 0
        for(var l = 0; l < dates.value.length; l++){
          if(uniqueDates.value[k] === dates.value[l]){
            count = count + 1
            dataChartSave.value[k] = count
          }
        }
      }
      pushChartDataSave()
    }

    const pushChartDataSave=()=>{
      let tempId = ref([])
      let tempData = ref([])
      let add = 0
      for(let i = start_item_chart_devSave.value; i < max_item_chart_devSave.value; i++){
        if(idChartSave.value[i] == null || idChartSave.value[i] == ''){
          break
        }else{
          tempId.value[add] = idChartSave.value[i]
          tempData.value[add] = dataChartSave.value[i]
          add++
        }
      }
      total_chart_page_devSave.value = Math.ceil((idChartSave.value.length / 10))
      total_chart_item_devSave.value = idChartSave.value.length

      seriesSave.value = [{
        name: 'Date',
        data: tempData,
        color:'#004F98'
      }]
      optionsSave.value = {
        xaxis: {
          labels: {
            format: 'dd/MM',
          },
          categories: tempId,
          title:{
            text: 'Date'
          }
        },
        yaxis: {
          title: {
            text: 'Activity Count'
          }
        }
      }
    }

    const findDataChartClass=()=>{
      let dates = ref(null)
      let uniqueDates = ref(null)
      dates.value = []
      uniqueDates.value = []

      for(var i = 0; i < allDataDeveloperClass.value.length; i++){
        dates.value.push(allDataDeveloperClass.value[i].created_date)
      }

      for (var j = 0; j < dates.value.length; j++) {
        if(!isDateInArray(dates.value[j], uniqueDates.value)){
          uniqueDates.value.push(dates.value[j]);
        }
      }

      idChartClass.value = uniqueDates.value
      dataChartClass.value = []

      var count = 0

      for (var k = 0; k < uniqueDates.value.length; k++){
        count = 0
        for(var l = 0; l < dates.value.length; l++){
          if(uniqueDates.value[k] === dates.value[l]){
            count = count + 1
            dataChartClass.value[k] = count
          }
        }
      }
      pushChartDataClass()
    }

    const pushChartDataClass=()=>{
      let tempId = ref([])
      let tempData = ref([])
      let add = 0
      for(let i = start_item_chart_devClass.value; i < max_item_chart_devClass.value; i++){
        if(idChartClass.value[i] == null || idChartClass.value[i] == ''){
          break
        }else{
          tempId.value[add] = idChartClass.value[i]
          tempData.value[add] = dataChartClass.value[i]
          add++
        }
      }
      total_chart_page_devClass.value = Math.ceil((idChartClass.value.length / 10))
      total_chart_item_devClass.value = idChartClass.value.length

      seriesClass.value = [{
        name: 'Date',
        data: tempData,
        color:'#004F98'
      }]
      optionsClass.value = {
        xaxis: {
          labels: {
            format: 'dd/MM',
          },
          categories: tempId,
          title:{
            text: 'Date'
          }
        },
        yaxis: {
          title: {
            text: 'Activity Count'
          }
        }
      }
    }

    const findDataChartAssets=()=>{
      let dates = ref(null)
      let uniqueDates = ref(null)
      dates.value = []
      uniqueDates.value = []

      for(var i = 0; i < allDataDeveloperAssets.value.length; i++){
        dates.value.push(allDataDeveloperAssets.value[i].date)
      }

      for (var j = 0; j < dates.value.length; j++) {
        if(!isDateInArray(dates.value[j], uniqueDates.value)){
          uniqueDates.value.push(dates.value[j]);
        }
      }

      idChartAssets.value = uniqueDates.value
      dataChartAssets.value = []

      var count = 0

      for (var k = 0; k < uniqueDates.value.length; k++){
        count = 0
        for(var l = 0; l < dates.value.length; l++){
          if(uniqueDates.value[k] === dates.value[l]){
            count = count + allDataDeveloperAssets.value[l].usages
            dataChartAssets.value[k] = count
          }
        }
      }
      pushChartDataAssets()
    }

    const pushChartDataAssets=()=>{
      let tempId = ref([])
      let tempData = ref([])
      let add = 0
      for(let i = start_item_chart_devAssets.value; i < max_item_chart_devAssets.value; i++){
        if(idChartAssets.value[i] == null || idChartAssets.value[i] == ''){
          break
        }else{
          tempId.value[add] = idChartAssets.value[i]
          tempData.value[add] = dataChartAssets.value[i]
          add++
        }
      }
      total_chart_page_devAssets.value = Math.ceil((idChartAssets.value.length / 10))
      total_chart_item_devAssets.value = idChartAssets.value.length

      seriesAssets.value = [{
        name: 'Date',
        data: tempData,
        color:'#004F98'
      }]
      optionsAssets.value = {
        xaxis: {
          labels: {
            format: 'dd/MM',
          },
          categories: tempId,
          title:{
            text: 'Date'
          }
        },
        yaxis: {
          title: {
            text: 'Activity Count'
          }
        }
      }
    }

    //Developer Login State
    let start_page_developerLogin = ref(0)
    let end_page_developerLogin = ref(10)
    let total_item_developerLogin = ref(0)
    let total_page_developerLogin = ref(0)
    let paginate_developerLogin = ref(1)

    const changePageDeveloperLogin=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_developerLogin.value =  new_start
      end_page_developerLogin.value = new_end
    }

    let start_chart_devLogin = ref(0)
    let end_chart_devLogin = ref(10)
    let total_chart_item_devLogin = ref(0)
    let total_chart_page_devLogin = ref(0)
    let paginate_devLogin = ref(1)
    let max_item_chart_devLogin= ref(10)
    let start_item_chart_devLogin = ref(0)

    const changeChartDeveloperLogin=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_chart_devLogin.value =  new_start
      end_chart_devLogin.value = new_end
      total_chart_page_devLogin.value = Math.ceil((idChartDevLogin.value.length / 10))
      total_chart_item_devLogin.value = idChartDevLogin.value.length
      max_item_chart_devLogin.value = pageNum * 10
      start_item_chart_devLogin.value = max_item_chart_devLogin.value - 10
      pushChartDataLogin()
    }

    //Developer Login Detail State
    let start_page_developerLoginDetail = ref(0)
    let end_page_developerLoginDetail = ref(10)
    let total_item_developerLoginDetail = ref (0)
    let total_page_developerLoginDetail = ref(0)
    let paginate_developerLoginDetail = ref(1)

    const changePageDeveloperLoginDetail=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_developerLoginDetail.value =  new_start
      end_page_developerLoginDetail.value = new_end
    }

    //Developer Save State
    let start_page_developerSave = ref(0)
    let end_page_developerSave = ref(10)
    let total_item_developerSave= ref (0)
    let total_page_developerSave = ref(0)
    let paginate_developerSave= ref(1)
    let max_item_per_page_save= ref(10)
    let start_item_save = ref(0)

    const changePageDeveloperSave=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_developerSave.value =  new_start
      end_page_developerSave.value = new_end
      max_item_per_page_save.value = pageNum * 10
      start_item_save.value = max_item_per_page_save.value - 10
    }

    let start_chart_devSave = ref(0)
    let end_chart_devSave = ref(10)
    let total_chart_item_devSave = ref(0)
    let total_chart_page_devSave = ref(0)
    let paginate_devSave= ref(1)
    let max_item_chart_devSave = ref(10)
    let start_item_chart_devSave = ref(0)

    const changeChartDeveloperSave=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_chart_devSave.value =  new_start
      end_chart_devSave.value = new_end
      total_chart_page_devSave.value = Math.ceil((idChartSave.value.length / 10))
      total_chart_item_devSave.value = idChartSave.value.length
      max_item_chart_devSave.value = pageNum * 10
      start_item_chart_devSave.value = max_item_chart_devSave.value - 10
      pushChartDataSave()
    }

    //Developer Class State
    let start_page_developerClass = ref(0)
    let end_page_developerClass = ref(10)
    let total_item_developerClass= ref (0)
    let total_page_developerClass = ref(0)
    let paginate_developerClass= ref(1)
    let max_item_per_page_class= ref(10)
    let start_item_class = ref(0)

    const changePageDeveloperClass=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_developerClass.value =  new_start
      end_page_developerClass.value = new_end
      max_item_per_page_class.value = pageNum * 10
      start_item_class.value = max_item_per_page_class.value - 10
    }

    let start_chart_devClass = ref(0)
    let end_chart_devClass = ref(10)
    let total_chart_item_devClass = ref(0)
    let total_chart_page_devClass = ref(0)
    let paginate_devClass = ref(1)
    let max_item_chart_devClass = ref(10)
    let start_item_chart_devClass = ref(0)

    const changeChartDeveloperClass=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_chart_devClass.value =  new_start
      end_chart_devClass.value = new_end
      total_chart_page_devClass.value = Math.ceil((idChartClass.value.length / 10))
      total_chart_item_devClass.value = idChartClass.value.length
      max_item_chart_devClass.value = pageNum * 10
      start_item_chart_devClass.value = max_item_chart_devClass.value - 10
      pushChartDataClass()
    }

    //Developer Class Assets
    let start_page_developerAssets = ref(0)
    let end_page_developerAssets = ref(10)
    let total_item_developerAssets= ref (0)
    let total_page_developerAssets = ref(0)
    let paginate_developerAssets= ref(1)
    let max_item_per_page_assets= ref(10)
    let start_item_assets = ref(0)

    const changePageDeveloperAssets=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_developerAssets.value =  new_start
      end_page_developerAssets.value = new_end
      max_item_per_page_assets.value = pageNum * 10
      start_item_assets.value = max_item_per_page_assets.value - 10
    }

    let start_chart_devAssets = ref(0)
    let end_chart_devAssets = ref(10)
    let total_chart_item_devAssets = ref(0)
    let total_chart_page_devAssets = ref(0)
    let paginate_devAssets= ref(1)
    let max_item_chart_devAssets = ref(10)
    let start_item_chart_devAssets = ref(0)

    const changeChartDeveloperAssets=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_chart_devAssets.value =  new_start
      end_chart_devAssets.value = new_end
      total_chart_page_devAssets.value = Math.ceil((idChartAssets.value.length / 10))
      total_chart_item_devAssets.value = idChartAssets.value.length
      max_item_chart_devAssets.value = pageNum * 10
      start_item_chart_devAssets.value = max_item_chart_devAssets.value - 10
      pushChartDataAssets()
    }

    const fetchDeveloperLogin=async()=>{
      isLoadingLogin.value = true
      await store.dispatch('getValueDeveloperLogin')
      let temp = await store.getters.getAllDeveloperLogin
      allDataDeveloperLogin.value = temp
      defaultDataDevLogin.value = temp
      total_page_developerLogin.value = Math.ceil((allDataDeveloperLogin.value.length / 10))
      total_item_developerLogin.value = allDataDeveloperLogin.value.length
      // findDataChartDevLogin()
      isLoadingLogin.value = false
    }

    const fetchDeveloperSave=async()=>{
      isLoadingSave.value = true
      await store.dispatch('getValueDeveloperSave')
      let temp = await store.getters.getAllDeveloperSave
      allDataDeveloperSave.value = await temp
      defaultDataDevSave.value = temp
      total_page_developerSave.value = Math.ceil((allDataDeveloperSave.value.length / 10))
      total_item_developerSave.value = allDataDeveloperSave.value.length
      // findDataChartSave()
      isLoadingSave.value = false
    }

    const fetchDeveloperClass=async()=>{
      isLoadingClass.value = true
      await store.dispatch('getValueDeveloperClass')
      let temp = await store.getters.getAllDeveloperClass
      allDataDeveloperClass.value = temp
      defaultDataDevClass.value = temp
      total_page_developerClass.value = Math.ceil((allDataDeveloperClass.value.length / 10))
      total_item_developerClass.value = allDataDeveloperClass.value.length
      // findDataChartClass()
      isLoadingClass.value = false
    }

    const fetchDeveloperAssets=async()=>{
      isLoadingAssets.value = true
      await store.dispatch('getValueDeveloperAssets')
      let temp = await store.getters.getAllDeveloperAssets
      allDataDeveloperAssets.value = temp
      defaultDataDevAssets.value = temp
      total_page_developerAssets.value = Math.ceil((allDataDeveloperAssets.value.length / 10))
      total_item_developerAssets.value = allDataDeveloperAssets.value.length
      // findDataChartAssets()
      isLoadingAssets.value = false
    }

    const fetchLoginDetail=(id)=>{
      isLoadingLoginDetail.value = true
      isDetail.value = true
      loginDetail.value = allDataDeveloperLogin.value[id]

      let token = localStorage.getItem('idToken')
      let uid = loginDetail.value.user_uid
      Axios.post('https://us-central1-millea-lab.cloudfunctions.net/web/api/getDeveloperLoginActivity',{
        "user_uid":uid
      },{
      headers:{
            Authorization: 'Bearer ' + token,
      }}
      ).then((res)=>{
        allDataLoginDetail.value = res.data
        allDataLoginDetail.value = allDataLoginDetail.value.details
        total_page_developerLoginDetail.value = Math.ceil((allDataLoginDetail.value.length / 10))
        total_item_developerLoginDetail.value = allDataLoginDetail.value.length
      }).catch((err)=>{
        allDataLoginDetail.value = []
        if(err.message === 'Request failed with status code 401'){
            localStorage.removeItem('idStorage')
            window.location.href="/login"
        }
      })
      isLoadingLoginDetail.value = false
    }

    onBeforeMount(()=>{
      fetchDeveloperLogin()
      fetchDeveloperSave()
      fetchDeveloperClass()
      fetchDeveloperAssets()
    })

      return {
        path,
        subPath,
        subSubPath,
        page,
        isDetail,
        isLoadingAssets,
        isLoadingLogin,
        isLoadingLoginDetail,
        isLoadingSave,
        isLoadingClass,
        timer,
        datefilter,
        allDataDeveloperLogin,
        defaultDataDevLogin,
        allDataDeveloperSave,
        defaultDataDevSave,
        allDataDeveloperClass,
        defaultDataDevClass,
        allDataDeveloperAssets,
        defaultDataDevAssets,
        allDataLoginDetail,
        loginDetail,
        openDate,
        refresh,
        filterFunction,
        export_to_csv,
        download_csv,
        inputSearch,
        searchList,
        startDateRange,
        endDateRange,
        filterData,
        dateFilterFunction,
        series,
        options,
        seriesSave,
        optionsSave,
        seriesClass,
        optionsClass,
        seriesAssets,
        optionsAssets,
        idChartDevLogin,
        dataChartDevLogin,
        idChartSave,
        dataChartSave,
        idChartClass,
        dataChartClass,
        idChartAssets,
        dataChartAssets,
        isDateInArray,
        findDataChartDevLogin,
        findDataChartSave,
        findDataChartClass,
        findDataChartAssets,
        start_page_developerLogin,
        end_page_developerLogin,
        total_item_developerLogin,
        total_page_developerLogin,
        paginate_developerLogin,
        changePageDeveloperLogin,
        start_chart_devLogin,
        end_chart_devLogin,
        total_chart_item_devLogin,
        total_chart_page_devLogin,
        paginate_devLogin,
        max_item_chart_devLogin,
        start_item_chart_devLogin,
        changeChartDeveloperLogin,
        start_page_developerLoginDetail,
        end_page_developerLoginDetail,
        total_item_developerLoginDetail,
        total_page_developerLoginDetail,
        paginate_developerLoginDetail,
        changePageDeveloperLoginDetail,
        start_page_developerSave,
        end_page_developerSave,
        total_item_developerSave,
        total_page_developerSave,
        paginate_developerSave,
        max_item_per_page_save,
        start_item_save,
        changePageDeveloperSave,
        start_chart_devSave,
        end_chart_devSave,
        total_chart_item_devSave,
        total_chart_page_devSave,
        paginate_devSave,
        max_item_chart_devSave,
        start_item_chart_devSave,
        changeChartDeveloperSave,
        start_page_developerClass,
        end_page_developerClass,
        total_item_developerClass,
        total_page_developerClass,
        paginate_developerClass,
        max_item_per_page_class,
        start_item_class,
        changePageDeveloperClass,
        start_chart_devClass,
        end_chart_devClass,
        total_chart_item_devClass,
        total_chart_page_devClass,
        paginate_devClass,
        max_item_chart_devClass,
        start_item_chart_devClass,
        changeChartDeveloperClass,
        start_page_developerAssets,
        end_page_developerAssets,
        total_item_developerAssets,
        total_page_developerAssets,
        paginate_developerAssets,
        max_item_per_page_assets,
        start_item_assets,
        changePageDeveloperAssets,
        start_chart_devAssets,
        end_chart_devAssets,
        total_chart_item_devAssets,
        total_chart_page_devAssets,
        paginate_devAssets,
        max_item_chart_devAssets,
        start_item_chart_devAssets,
        changeChartDeveloperAssets,
        fetchLoginDetail,
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "../../scss/Analytic/Developer.scss";
  </style>
  