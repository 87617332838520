<template>
    <div class="container-userList">
      <SideHeader/>
      <div class="container-right-userList">
        <TopHeader
         :pathPage="path"
         :pathSubPage="subPath"
         :pathSubSubPage="subSubPath"
        />
        <div class="main-userList">
          <div class="">Ini Halaman User List</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SideHeader from "../../components/Header/SideHeader.vue"
  import TopHeader from "../../components/Header/TopHeader.vue"
  import {ref} from 'vue'
  
  export default {
    name: "userList",
    components: {
      SideHeader,
      TopHeader,
    },
    setup() {
      let path = ref('Training')
      let subPath = ref('User List')
      let subSubPath = ref(null)
      return {
        path,
        subPath,
        subSubPath
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "../../scss/Training/UserList.scss";
  </style>
  