<template>
  <!-- Popup -->
  <div id="popup" class="box-popup">
    <div class="popup-content">
      <div class="popup-left">
        <img src="../../assets/succeed-popup.svg" alt="" />
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <div id="popupFailed" class="box-popup">
    <div class="popup-failed">
      <div class="popup-left">
        <!-- <img src="../../assets/succeed-popup.svg" alt=""> -->
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>

  <!-- Modal Action Blog -->
  <b-modal id="modalPopUpBlog" title="View Blog">
    <div class="box-detail">
      <div class="title">Title:</div>
      <div class="description">{{ allDataBlogDetail.blog_title }}</div>
    </div>
    <div class="box-detail">
      <div class="title">Description:</div>
      <!-- <div class="description" v-if="allDataBlogDetail.blog_content.length > 1000">{{ allDataBlogDetail.blog_content.substring(0, 1000) + " ..." }}</div>
      <div class="description" v-else>{{ allDataBlogDetail.blog_content }}</div> -->
      <div class="description">{{ allDataBlogDetail.blog_content }}</div>
    </div>
    <div class="box-detail">
      <div class="title">Post Name:</div>
      <div class="description">{{ allDataBlogDetail.user_name }}</div>
    </div>
    <div class="box-detail">
      <div class="title">Post Date:</div>
      <div class="description">{{ allDataBlogDetail.blog_date }}</div>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal" @click="rejectBlog()">
        <p>REJECT</p>
      </div>
      <div class="btn-submit" data-bs-dismiss="modal" @click="approveBlog()">
        <p>APPROVE</p>
      </div>
    </div>
  </b-modal>

  <b-modal id="modalDeleteBlog" title="Delete Blog">
    <div class="box-delete">
      <p>Are you sure you want to delete</p>
      <p>"{{ deleteBlogName }}"</p>
      <p>Blog ?</p>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal"><p>CANCEL</p></div>
      <div class="btn-submit" data-bs-dismiss="modal" @click="deleteBlog()">
        <p>DELETE</p>
      </div>
    </div>
  </b-modal>

  <div class="container-blog">
    <SideHeader />
    <div class="container-right-blog">
      <TopHeader
        :pathPage="path"
        :pathSubPage="subPath"
        :pathSubSubPage="subSubPath"
      />
      <div class="main-blog">
        <div class="box-header-blog">
          <div class="box-navigation">
            <div class="navigation active">Blog</div>
          </div>
          <div class="box-header-right">
            <div class="btn-export">
              <img src="../../assets/log-in.svg" alt="" />
              <p>Export</p>
            </div>
          </div>
        </div>
        <div class="box-main-blog">
          <div v-if="isDetail == false" class="box-page">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="card-date">
                  <div class="date-title" @click="openDate()">
                    <img src="../../assets/calendar.svg" alt="" />
                    <p>Date</p>
                  </div>
                  <div id="date" class="dropdown-date">
                    <div class="card-start-end">
                      <input
                        v-model="startDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                      <p>to</p>
                      <input
                        v-model="endDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                    </div>
                    <div class="separator"></div>
                    <div class="box-date-filter">
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'today' }]"
                        @click="dateFilterFunction('today')"
                      >
                        Today
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'yesterday' }]"
                        @click="dateFilterFunction('yesterday')"
                      >
                        Yesterday
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'week' }]"
                        @click="dateFilterFunction('week')"
                      >
                        Last Week
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'month' }]"
                        @click="dateFilterFunction('month')"
                      >
                        Last Month
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="box-table">
              <table id="blogTable" v-if="isLoadingBlog == false">
                <tr>
                  <th>No</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Post Name</th>
                  <th>Post Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                <tr
                  class="hover-row"
                  v-for="(dataBlog, id) in newDataBlog"
                  :key="dataBlog.id"
                >
                  <td
                    @click="getBlogDetail(id)"
                    v-if="id >= start_page_blog && id < end_page_blog"
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    @click="getBlogDetail(id)"
                    v-if="id >= start_page_blog && id < end_page_blog"
                  >
                    {{ dataBlog.blog_title }}
                  </td>
                  <td
                    @click="getBlogDetail(id)"
                    v-if="
                      dataBlog.blog_content.length > 100 &&
                      id >= start_page_blog &&
                      id < end_page_blog
                    "
                  >
                    {{ dataBlog.blog_content.substring(0, 100) + " ..." }}
                  </td>
                  <td
                    @click="getBlogDetail(id)"
                    v-else-if="id >= start_page_blog && id < end_page_blog"
                  >
                    {{ dataBlog.blog_content }}
                  </td>
                  <td
                    @click="getBlogDetail(id)"
                    v-if="id >= start_page_blog && id < end_page_blog"
                  >
                    {{ dataBlog.user_name }}
                  </td>
                  <td
                    @click="getBlogDetail(id)"
                    v-if="id >= start_page_blog && id < end_page_blog"
                  >
                    {{ dataBlog.blog_date }}
                  </td>
                  <td
                    @click="getBlogDetail(id)"
                    v-if="
                      id >= start_page_blog &&
                      id < end_page_blog &&
                      dataBlog.blog_approval_status_id ===
                        'kNfgoZLh7vFjHhDJl5vF'
                    "
                  >
                    <div class="approved">
                      <p>Approved</p>
                    </div>
                  </td>
                  <td
                    @click="getBlogDetail(id)"
                    v-else-if="
                      id >= start_page_blog &&
                      id < end_page_blog &&
                      dataBlog.blog_approval_status_id ===
                        'fViLuZnhNXVcJgLQ8BYT'
                    "
                  >
                    <div class="pending">
                      <p>Pending</p>
                    </div>
                  </td>
                  <td
                    @click="getBlogDetail(id)"
                    v-else-if="
                      id >= start_page_blog &&
                      id < end_page_blog &&
                      dataBlog.blog_approval_status_id ===
                        'jsh3Uvjj8yePgnV1EmQU'
                    "
                  >
                    <div class="rejected">
                      <p>Rejected</p>
                    </div>
                  </td>
                  <td v-if="id >= start_page_blog && id < end_page_blog">
                    <div class="box-action">
                      <b-button
                        v-b-modal.modalPopUpBlog
                        cancel-disabled="true"
                        class="btn-view"
                        @click="viewBlogDetail(id)"
                      >
                        <p>View</p>
                      </b-button>
                      <b-button
                        v-b-modal.modalDeleteBlog
                        cancel-disabled="true"
                        class="btn-delete"
                        @click="getIdDeleteBlogs(id)"
                      >
                        <p>Delete</p>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="empty-card" v-else>
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_blog + 1 }} to {{ end_page_blog }} of
                {{ total_item_blog }} entries
              </p>
              <paginate
                v-model="paginate_blog"
                :page-count="total_page_blog"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageBlog"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
          <div v-else class="box-page">
            <div class="box-back" @click="isDetail = false">
              <img src="../../assets/back.svg" alt="" />
              <p>Back to Blog List</p>
            </div>
            <div class="box-blog">
              <div class="title">
                <p>{{ allDataBlogDetail.blog_title }}</p>
              </div>
              <div class="date-author">
                <p>{{ allDataBlogDetail.blog_date }} by</p>
                <p class="blue">{{ allDataBlogDetail.user_name }}</p>
              </div>
              <div class="picture">
                <img :src="allDataBlogDetail.blog_attachment_file_url" alt="" />
              </div>
              <div class="description">
                <p>{{ allDataBlogDetail.blog_content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue";
import TopHeader from "../../components/Header/TopHeader.vue";
import { onBeforeMount, ref } from "vue";
import Paginate from "vuejs-paginate-next";
import Axios from "axios";
import { useStore } from "vuex";

export default {
  name: "PendekarBlog",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    const store = useStore();
    let path = ref("PendekarVR");
    let subPath = ref("Blog");
    let subSubPath = ref();
    let isDetail = ref(false);
    let datefilter = ref("");

    let isLoadingBlog = ref(true);

    let allDataBlog = ref(null);
    let defaultDataBlog = ref(null);

    const openDate = () => {
      document.getElementById("date").classList.toggle("show");
    };

    let startDateRange = ref("");
    let endDateRange = ref("");
    const dateFilterFunction = (params) => {
      if (startDateRange.value != "" && endDateRange.value != "") {
        datefilter.value = "";
        openDate();
      } else if (params == "today") {
        if (datefilter.value == "today") {
          datefilter.value = "";
        } else {
          datefilter.value = "today";
        }
        openDate();
      } else if (params == "yesterday") {
        if (datefilter.value == "yesterday") {
          datefilter.value = "";
        } else {
          datefilter.value = "yesterday";
        }
        openDate();
      } else if (params == "week") {
        if (datefilter.value == "week") {
          datefilter.value = "";
        } else {
          datefilter.value = "week";
        }
        openDate();
      } else if (params == "month") {
        if (datefilter.value == "month") {
          datefilter.value = "";
        } else {
          datefilter.value = "month";
        }
        openDate();
      }
      // else {
      // }
    };

    let popUpText = ref("");
    const popUp = (status) => {
      if (status == "SuccessRejectBlog") {
        popUpText.value = "Reject Blog Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedRejectBlog") {
        popUpText.value = "Reject Blog Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessApproveBlog") {
        popUpText.value = "Approve Blog Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedApproveBlog") {
        popUpText.value = "Approve Blog Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessDeleteBlog") {
        popUpText.value = "Delete Blog Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedDeleteBlog") {
        popUpText.value = "Delete Blog Failed";
        document.getElementById("popupFailed").style.display = "flex";
      }
    };

    const closepopUp = () => {
      document.getElementById("popup").style.display = "none";
      document.getElementById("popupFailed").style.display = "none";
    };

    let inputSearch = ref("");

    const searchList = () => {
      if (inputSearch.value !== "") {
        newDataBlog.value = defaultDataBlog.value;
        // console.log(newDataBlog)
        let filter = newDataBlog.value.filter((val) => {
          if (
            val.blog_title
              .toUpperCase()
              .includes(inputSearch.value.toUpperCase())
          ) {
            return val;
          }
        });
        if (filter) {
          newDataBlog.value = filter;
        } else {
          newDataBlog.value = defaultDataBlog.value;
        }
        changePageBlog(1);
      } else {
        newDataBlog.value = defaultDataBlog.value;
      }
      total_page_blog.value = Math.ceil(newDataBlog.value.length / 10);
      total_item_blog.value = newDataBlog.value.length;
      paginate_blog.value = 1;
    };

    //Blog State
    let start_page_blog = ref(0);
    let end_page_blog = ref(10);
    let total_item_blog = ref(0);
    let total_page_blog = ref(0);
    let paginate_blog = ref(1);

    const changePageBlog = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_page_blog.value = new_start;
      end_page_blog.value = new_end;
    };

    let newDataBlog = ref([]);

    const fetchBlog = async () => {
      isLoadingBlog.value = true;
      await store.dispatch("getValueBlog");
      allDataBlog.value = await store.getters.getAllBlog;
      allDataBlog.value = allDataBlog.value.result.blogs;
      // console.log(allDataBlog);

      newDataBlog.value = [];
      for (let i = 0; i < allDataBlog.value.length; i++) {
        let newDate = new Date(allDataBlog.value[i].blog_date);
        let dateStr =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear();
        newDataBlog.value.push({
          blog_approval_status_id: allDataBlog.value[i].blog_approval_status_id,
          blog_attachment_file_url:
            allDataBlog.value[i].blog_attachmenst_file_url,
          blog_category: allDataBlog.value[i].blog_category,
          blog_content: allDataBlog.value[i].blog_content,
          blog_title: allDataBlog.value[i].blog_title,
          id: allDataBlog.value[i].id,
          user_name: allDataBlog.value[i].user_name,
          blog_date: dateStr,
        });
      }

      // console.log(newDataBlog);
      defaultDataBlog.value = newDataBlog.value;
      total_page_blog.value = Math.ceil(newDataBlog.value.length / 10);
      total_item_blog.value = newDataBlog.value.length;
      isLoadingBlog.value = false;
    };

    let allDataBlogDetail = ref([]);
    const getBlogDetail = (id) => {
      // console.log(id);
      isDetail.value = true;
      allDataBlogDetail.value = newDataBlog.value[id];
      // console.log(allDataBlogDetail);
    };

    let blogUID = ref();
    const viewBlogDetail = (id) => {
      // console.log(id);
      let cutContent = ref();
      blogUID.value = allDataBlog.value[id].id;
      if (allDataBlog.value[id].blog_content.length > 1000) {
        cutContent.value =
          allDataBlog.value[id].blog_content.substring(0, 1000) + "...";
      } else {
        cutContent.value = allDataBlog.value[id].blog_content;
      }
      // console.log(cutContent);
      let newDate = new Date(allDataBlog.value[id].blog_date);
      let dateStr =
        newDate.getDate() +
        "/" +
        (newDate.getMonth() + 1) +
        "/" +
        newDate.getFullYear();
      allDataBlogDetail.value = [];
      allDataBlogDetail.value.push({
        blog_approval_status_id: allDataBlog.value[id].blog_approval_status_id,
        blog_attachment_file_url:
          allDataBlog.value[id].blog_attachment_file_url,
        blog_category: allDataBlog.value[id].blog_category,
        blog_content: cutContent.value,
        blog_title: allDataBlog.value[id].blog_title,
        id: allDataBlog.value[id].id,
        user_name: allDataBlog.value[id].user_name,
        blog_date: dateStr,
      });
      allDataBlogDetail.value = allDataBlogDetail.value[0];
      // console.log(allDataBlogDetail);
    };

    let deleteBlogName = ref(null);
    let deleteBlogId = ref(null);
    const getIdDeleteBlogs = (id) => {
      // console.log(newDataBlog.value[id]);
      deleteBlogId.value = newDataBlog.value[id].id;
      deleteBlogName.value = newDataBlog.value[id].blog_title;
    };

    const deleteBlog = () => {
      isLoadingBlog.value = true;
      let token = localStorage.getItem("idToken");
      Axios.delete(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blogs/" +
          deleteBlogId.value +
          "/admin",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          // console.log("success");
          popUp("SuccessDeleteBlog");
          fetchBlog();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedDeleteBlog");
            fetchBlog();
          }
        });
    };

    const rejectBlog = () => {
      isLoadingBlog.value = true;
      // console.log(blogUID);
      let token = localStorage.getItem("idToken");
      Axios.patch(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blogs/" +
          blogUID.value +
          "/admin/post-approval",
        {
          blog_approval_status_id: "jsh3Uvjj8yePgnV1EmQU",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          // console.log("success");
          popUp("SuccessRejectBlog");
          fetchBlog();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedRejectBlog");
            fetchBlog();
          }
        });
    };

    const approveBlog = () => {
      isLoadingBlog.value = true;
      // console.log(blogUID);
      let token = localStorage.getItem("idToken");
      Axios.patch(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blogs/" +
          blogUID.value +
          "/admin/post-approval",
        {
          blog_approval_status_id: "kNfgoZLh7vFjHhDJl5vF",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          console.log("success");
          popUp("SuccessApproveBlog");
          fetchBlog();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedApproveBlog");
            fetchBlog();
          }
        });
    };

    onBeforeMount(() => {
      fetchBlog();
    });

    return {
      path,
      subPath,
      subSubPath,
      isDetail,
      isLoadingBlog,
      allDataBlog,
      defaultDataBlog,
      datefilter,
      openDate,
      startDateRange,
      endDateRange,
      dateFilterFunction,
      searchList,
      inputSearch,
      start_page_blog,
      end_page_blog,
      total_item_blog,
      total_page_blog,
      paginate_blog,
      changePageBlog,
      newDataBlog,
      getBlogDetail,
      blogUID,
      viewBlogDetail,
      allDataBlogDetail,
      rejectBlog,
      approveBlog,
      popUpText,
      popUp,
      closepopUp,
      deleteBlogId,
      deleteBlogName,
      getIdDeleteBlogs,
      deleteBlog,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/PendekarVR/Blog.scss";
</style>
