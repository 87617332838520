<template>
  <!-- Popup -->
  <div id="popup" class="box-popup">
    <div class="popup-content">
      <div class="popup-left">
        <img src="../../assets/succeed-popup.svg" alt="" />
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <div id="popupFailed" class="box-popup">
    <div class="popup-failed">
      <div class="popup-left">
        <!-- <img src="../../assets/succeed-popup.svg" alt=""> -->
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>

  <!-- Modal Action Regist Approval -->
  <b-modal id="modalPopUpRegistration" title="View Registration">
    <div class="box-detail">
      <div class="title">Name:</div>
      <div class="description">
        {{ allDataRegistrationApprovalDetail.user_uid }}
      </div>
    </div>
    <div class="box-detail">
      <div class="title">Date:</div>
      <div class="description">
        {{ allDataRegistrationApprovalDetail.registration_date }}
      </div>
    </div>
    <div class="box-detail">
      <div class="title">Email:</div>
      <div class="description">
        {{ allDataRegistrationApprovalDetail.user_email }}
      </div>
    </div>
    <div class="box-detail">
      <div class="title">Phone:</div>
      <div class="description">-</div>
    </div>
    <div class="new-footer">
      <div
        class="btn-cancel"
        data-bs-dismiss="modal"
        @click="rejectRegistration()"
      >
        <p>REJECT</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        @click="approveRegistration()"
      >
        <p>APPROVE</p>
      </div>
    </div>
  </b-modal>

  <div class="container-registrationApproval">
    <SideHeader />
    <div class="container-right-registrationApproval">
      <TopHeader
        :pathPage="path"
        :pathSubPage="subPath"
        :pathSubSubPage="subSubPath"
      />
      <div class="main-registrationApproval">
        <div class="box-header-registrationApproval">
          <div class="box-navigation">
            <div class="navigation active">Registration Approval</div>
          </div>
          <div class="box-header-right">
            <div class="btn-export" @click="export_to_csv()">
              <img src="../../assets/log-in.svg" alt="" />
              <p>Export</p>
            </div>
          </div>
        </div>
        <div class="box-main-registrationApproval">
          <div class="box-page">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="card-date">
                  <div class="date-title" @click="openDate()">
                    <img src="../../assets/calendar.svg" alt="" />
                    <p>Date</p>
                  </div>
                  <div id="date" class="dropdown-date">
                    <div class="card-start-end">
                      <input
                        v-model="startDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                      <p>to</p>
                      <input
                        v-model="endDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                    </div>
                    <div class="separator"></div>
                    <div class="box-date-filter">
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'today' }]"
                        @click="dateFilterFunction('today')"
                      >
                        Today
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'yesterday' }]"
                        @click="dateFilterFunction('yesterday')"
                      >
                        Yesterday
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'week' }]"
                        @click="dateFilterFunction('week')"
                      >
                        Last Week
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'month' }]"
                        @click="dateFilterFunction('month')"
                      >
                        Last Month
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="box-table">
              <table
                v-if="isLoadingRegistration == false"
                id="registrationApprovalTable"
              >
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                <tr
                  v-for="(dataRegistration, id) in newDataRegistrationApproval"
                  :key="dataRegistration.id"
                >
                  <td
                    v-if="
                      id >= start_page_registrationApproval &&
                      id < end_page_registrationApproval
                    "
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_registrationApproval &&
                      id < end_page_registrationApproval
                    "
                  >
                    {{ dataRegistration.user_uid }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_registrationApproval &&
                      id < end_page_registrationApproval
                    "
                  >
                    {{ dataRegistration.registration_date }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_registrationApproval &&
                      id < end_page_registrationApproval
                    "
                  >
                    {{ dataRegistration.user_email }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_registrationApproval &&
                      id < end_page_registrationApproval
                    "
                  >
                    -
                  </td>
                  <td
                    v-if="
                      id >= start_page_registrationApproval &&
                      id < end_page_registrationApproval
                    "
                  >
                    <div
                      v-if="
                        dataRegistration.pendekar_vr_registration_approval_status ===
                        'APPROVED'
                      "
                      class="approved"
                    >
                      <p>
                        {{
                          dataRegistration.pendekar_vr_registration_approval_status
                        }}
                      </p>
                    </div>
                    <div
                      v-if="
                        dataRegistration.pendekar_vr_registration_approval_status ===
                        'PENDING'
                      "
                      class="pending"
                    >
                      <p>
                        {{
                          dataRegistration.pendekar_vr_registration_approval_status
                        }}
                      </p>
                    </div>
                    <div
                      v-if="
                        dataRegistration.pendekar_vr_registration_approval_status ===
                        'REJECTED'
                      "
                      class="rejected"
                    >
                      <p>
                        {{
                          dataRegistration.pendekar_vr_registration_approval_status
                        }}
                      </p>
                    </div>
                  </td>
                  <td
                    v-if="
                      id >= start_page_registrationApproval &&
                      id < end_page_registrationApproval
                    "
                  >
                    <b-button
                      v-b-modal.modalPopUpRegistration
                      cancel-disabled="true"
                      class="btn-view"
                      @click="getRegistrationDetail(id)"
                    >
                      <p>View</p>
                    </b-button>
                  </td>
                </tr>
              </table>
              <div class="empty-card" v-else>
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_registrationApproval + 1 }} to
                {{ end_page_registrationApproval }} of
                {{ total_item_registrationApproval }} entries
              </p>
              <paginate
                v-model="paginate_registrationApproval"
                :page-count="total_page_registrationApproval"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageRegistrationApproval"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue";
import TopHeader from "../../components/Header/TopHeader.vue";
import { onBeforeMount, ref } from "vue";
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";
import Axios from "axios";

export default {
  name: "RegistrationApproval",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    const store = useStore();
    let path = ref("PendekarVR");
    let subPath = ref("Registration Approval");
    let subSubPath = ref();
    let datefilter = ref("");

    let isLoadingRegistration = ref(true);

    let allDataRegistrationApproval = ref(null);
    let defaultDataRegistrattionApproval = ref(null);

    const openDate = () => {
      document.getElementById("date").classList.toggle("show");
    };

    let startDateRange = ref("");
    let endDateRange = ref("");
    // let filterData = ref(null)
    const dateFilterFunction = (params) => {
      if (startDateRange.value != "" && endDateRange.value != "") {
        datefilter.value = "";
        openDate();
      } else if (params == "today") {
        if (datefilter.value == "today") {
          datefilter.value = "";
        } else {
          datefilter.value = "today";
        }
        openDate();
      } else if (params == "yesterday") {
        if (datefilter.value == "yesterday") {
          datefilter.value = "";
        } else {
          datefilter.value = "yesterday";
        }
        openDate();
      } else if (params == "week") {
        if (datefilter.value == "week") {
          datefilter.value = "";
        } else {
          datefilter.value = "week";
        }
        openDate();
      } else if (params == "month") {
        if (datefilter.value == "month") {
          datefilter.value = "";
        } else {
          datefilter.value = "month";
        }
        openDate();
      }
    };

    let popUpText = ref("");
    const popUp = (status) => {
      if (status == "SuccessRejectRegistration") {
        popUpText.value = "Reject Registration Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedRejectRegistration") {
        popUpText.value = "Reject Registration Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessApproveRegistration") {
        popUpText.value = "Approve Registration Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedApproveRegistration") {
        popUpText.value = "Approve Registration Failed";
        document.getElementById("popupFailed").style.display = "flex";
      }
    };

    const closepopUp = () => {
      document.getElementById("popup").style.display = "none";
      document.getElementById("popupFailed").style.display = "none";
    };

    let inputSearch = ref("");

    const searchList = () => {
      if (inputSearch.value !== "") {
        newDataRegistrationApproval.value =
          defaultDataRegistrattionApproval.value;
        // console.log(newDataRegistrationApproval);
        let filter = newDataRegistrationApproval.value.filter((val) => {
          if (
            val.user_email
              .toUpperCase()
              .includes(inputSearch.value.toUpperCase())
          ) {
            return val;
          }
        });
        if (filter) {
          newDataRegistrationApproval.value = filter;
        } else {
          newDataRegistrationApproval.value =
            defaultDataRegistrattionApproval.value;
        }
        changePageRegistrationApproval(1);
      } else {
        newDataRegistrationApproval.value =
          defaultDataRegistrattionApproval.value;
      }
      total_page_registrationApproval.value = Math.ceil(
        newDataRegistrationApproval.value.length / 10
      );
      total_item_registrationApproval.value =
        newDataRegistrationApproval.value.length;
      paginate_registrationApproval.value = 1;
    };

    const export_to_csv = () => {
      let arrayToPdf = [];
      var rows = document.querySelectorAll("#registrationApprovalTable tr");
      let row = [];
      rows[0].querySelectorAll("td,th").forEach((val) => {
        row.push(val.innerText);
      });
      row.pop();
      arrayToPdf.push(row.join(","));
      allDataRegistrationApproval.value.forEach((val, index) => {
        arrayToPdf.push(
          `${index + 1},${val.user_uid},${val.registration_date},${
            val.user_email
          },-,${val.pendekar_vr_registration_approval_status}`
        );
      });
      download_csv(arrayToPdf.join("\n"), subPath.value);
    };
    function download_csv(csv, filename) {
      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], { type: "text/csv" });
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }

    //Rgistration State
    let start_page_registrationApproval = ref(0);
    let end_page_registrationApproval = ref(10);
    let total_item_registrationApproval = ref(0);
    let total_page_registrationApproval = ref(0);
    let paginate_registrationApproval = ref(1);

    const changePageRegistrationApproval = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_page_registrationApproval.value = new_start;
      end_page_registrationApproval.value = new_end;
    };

    let newDataRegistrationApproval = ref([]);

    const fetchRegistration = async () => {
      isLoadingRegistration.value = true;
      await store.dispatch("getValueRegistrationApproval");
      allDataRegistrationApproval.value = await store.getters
        .getAllRegistrationApproval;
      allDataRegistrationApproval.value =
        allDataRegistrationApproval.value.result.registrations;

      // console.log(allDataRegistrationApproval.value.length);

      newDataRegistrationApproval.value = [];
      for (let i = 0; i < allDataRegistrationApproval.value.length; i++) {
        let newDate = new Date(
          allDataRegistrationApproval.value[i].registration_date
        );
        let dateStr =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear();
        newDataRegistrationApproval.value.push({
          user_uid: allDataRegistrationApproval.value[i].user_uid,
          user_email: allDataRegistrationApproval.value[i].user_email,
          pendekar_vr_registration_approval_status:
            allDataRegistrationApproval.value[i]
              .pendekar_vr_registration_approval_status,
          pendekar_vr_registration_approval_status_id:
            allDataRegistrationApproval.value[i]
              .pendekar_vr_registration_approval_status_id,
          registration_date: dateStr,
        });
      }
      defaultDataRegistrattionApproval.value =
        newDataRegistrationApproval.value;
      // console.log(newDataRegistrationApproval);

      total_page_registrationApproval.value = Math.ceil(
        newDataRegistrationApproval.value.length / 10
      );
      total_item_registrationApproval.value =
        newDataRegistrationApproval.value.length;
      isLoadingRegistration.value = false;
    };

    let allDataRegistrationApprovalDetail = ref([]);
    let userUID = ref();
    let userEmail = ref();
    const getRegistrationDetail = (id) => {
      // console.log(id);
      userUID.value = newDataRegistrationApproval.value[id].user_uid;
      userEmail.value = newDataRegistrationApproval.value[id].user_email;
      // console.log(userUID, userEmail);
      allDataRegistrationApprovalDetail.value =
        newDataRegistrationApproval.value[id];
      // console.log(allDataRegistrationApprovalDetail);
    };

    const rejectRegistration = () => {
      isLoadingRegistration.value = true;
      let token = localStorage.getItem("idToken");
      Axios.patch(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/users/" +
          userUID.value +
          "/pendekar-vr-registration/admin",
        {
          user_email: userEmail.value,
          pendekar_vr_registration_approval_status_id: "GlV9fvG63LKLsj8TCYCI",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          console.log("success");
          popUp("SuccessRejectRegistration");
          fetchRegistration();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedRejectRegistration");
            fetchRegistration();
          }
        });
    };

    const approveRegistration = () => {
      isLoadingRegistration.value = true;
      let token = localStorage.getItem("idToken");
      Axios.patch(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/users/" +
          userUID.value +
          "/pendekar-vr-registration/admin",
        {
          user_email: userEmail.value,
          pendekar_vr_registration_approval_status_id: "oWcrZ9T5sR43cM8nDHiO",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          console.log("success");
          popUp("SuccessApproveRegistration");
          fetchRegistration();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedApproveRegistration");
            fetchRegistration();
          }
        });
    };

    onBeforeMount(() => {
      fetchRegistration();
    });

    return {
      path,
      subPath,
      subSubPath,
      isLoadingRegistration,
      allDataRegistrationApproval,
      defaultDataRegistrattionApproval,
      datefilter,
      openDate,
      startDateRange,
      endDateRange,
      dateFilterFunction,
      popUpText,
      popUp,
      closepopUp,
      inputSearch,
      searchList,
      start_page_registrationApproval,
      end_page_registrationApproval,
      total_item_registrationApproval,
      total_page_registrationApproval,
      paginate_registrationApproval,
      changePageRegistrationApproval,
      newDataRegistrationApproval,
      allDataRegistrationApprovalDetail,
      getRegistrationDetail,
      userUID,
      userEmail,
      rejectRegistration,
      approveRegistration,
      export_to_csv,
      download_csv,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/PendekarVR/RegistrationApproval.scss";
</style>
