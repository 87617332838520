import { createRouter, createWebHistory } from "vue-router";
import HistoryActivityView from "../views/IndividualLicense/HistoryActivityView.vue";
import ActiveUserListView from "../views/IndividualLicense/ActiveUserListView.vue";
import ReviewRequestView from "../views/IndividualLicense/ReviewRequestView.vue";
import TrainingListView from "../views/Training/TrainingListView.vue";
import TrainerListView from "../views/Training/TrainerListView.vue";
import UserListView from "../views/Training/UserListView.vue";
import PurchaseHistoryView from "../views/Training/PurchaseHistoryView.vue";
import UserView from "../views/Analytic/UserView.vue";
import DeveloperView from "../views/Analytic/DeveloperView.vue";
import RegisterUserView from "../views/Tools/RegisterUserView.vue";
import CategoryManagerView from "../views/Tools/CategoryManagerView.vue";
import TranslateObjectView from "../views/Tools/TranslateObjectView.vue";
import LeaderboardView from "../views/PendekarVR/LeaderboardView.vue";
import RegistrationApprovalView from "../views/PendekarVR/RegistrationApprovalView.vue";
import TaskApprovalView from "../views/PendekarVR/TaskApprovalView.vue";
import ContentView from "../views/PendekarVR/ContentView.vue";
import EventsView from "../views/PendekarVR/EventsView.vue";
import BlogView from "../views/PendekarVR/BlogView.vue";
import LoginView from "../views/LoginView.vue";
import LeaderboardV2 from "../views/PendekarVR/Leaderboard-v2.vue";
import store from "@/store/index";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const checkUserLogin = async (to, from, next) => {
  await store.dispatch("getValueLoading", { value: true });
  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // console.log(user)
      next();
      await store.dispatch("getValueLoading", { value: false }); // stop loading page
    } else {
      localStorage.removeItem("idStorage");
      localStorage.removeItem("idToken");
      await store.dispatch("getValueLoading", { value: false }); // stop loading
      await store.dispatch("getValueIsLogin", { value: false });
      window.location.href = "/login";
    }
  });
};

const routes = [
  {
    path: "/",
    name: "HistoryActivity",
    component: HistoryActivityView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/active-user-list",
    name: "ActiveUserList",
    component: ActiveUserListView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/review-request",
    name: "ReviewRequest",
    component: ReviewRequestView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/training-list",
    name: "TrainingList",
    component: TrainingListView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/trainer-list",
    name: "TrainerList",
    component: TrainerListView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/purchase-history",
    name: "PurchaseHistory",
    component: PurchaseHistoryView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/user-list",
    name: "UserList",
    component: UserListView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/user",
    name: "User",
    component: UserView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/developer",
    name: "Developer",
    component: DeveloperView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/register-user",
    name: "RegisterUser",
    component: RegisterUserView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/category-manager",
    name: "CategoryManager",
    component: CategoryManagerView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/translate-object",
    name: "TranslateObject",
    component: TranslateObjectView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    component: LeaderboardView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/leaderboard-filter",
    name: "LeaderboardFilter",
    component: LeaderboardV2,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/task-approval",
    name: "TaskApproval",
    component: TaskApprovalView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/registration-approval",
    name: "RegistrationApproval",
    component: RegistrationApprovalView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/content",
    name: "PendekarContent",
    component: ContentView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/events",
    name: "PendekarEvents",
    component: EventsView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/blog",
    name: "PendekarBlog",
    component: BlogView,
    beforeEnter(to, from, next) {
      checkUserLogin(to, from, next);
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
