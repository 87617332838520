<template>
    <div class="container-topHeader">
        <div class="container-top">
            <div class="page-box">
                <h1>{{pathPage}}</h1>
            </div>
            <div class="profile-box">
                <!-- <div class="notification"><img src="../../assets/Header/bell.svg" alt=""></div> -->
                <!-- <div class="profile-picture"></div> -->
                <img class="profile-picture" :src="profilePicture"  alt="">
            </div>
        </div>
        <div class="container-bottom">
            <div class="sub-page">
                <p>{{pathPage}}</p>
            </div>
            <p v-if="pathSubPage != null" class="separator">/</p>
            <div class="sub-page">
                <p>{{pathSubPage}}</p>
            </div>
            <p v-if="pathSubSubPage != null" class="separator">/</p>
            <div class="sub-page-bold">
                <p>{{pathSubSubPage}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import Axios from 'axios'
    import {onBeforeMount, ref} from 'vue'
    export default {
        name:'topHeader',
         props:{
            pathPage:String,
            pathSubPage:String,
            pathSubSubPage:String
        },
        setup(){
            let userData = ref(null)
            let profilePicture = ref(null)
            const fetch=async()=>{
                // let token = localStorage.getItem('idToken')
                let idStorage  = localStorage.getItem('idStorage')
                Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getUserData`,{
                    uid:idStorage
                }).then ((res)=>{
                    userData.value = res.data
                    profilePicture.value = userData.value[0].profile_picture
                })
            }
            onBeforeMount(()=>{
                fetch()
            })
            return {
                userData,
                profilePicture,
            }
        }
       
    
    }
    </script>

<style lang="scss">
@import "../../scss/TopHeader.scss";
</style>
