<template>
  <div class="container-leaderboard">
    <SideHeader />
    <div class="container-right-leaderboard">
      <TopHeader
        :pathPage="path"
        :pathSubPage="subPath"
        :pathSubSubPage="subSubPath"
      />
      <div class="main-leaderboard">
        <div class="box-header-leaderboard">
          <div class="box-navigation">
            <div class="navigation active">Leaderboard Filter</div>
          </div>
          <div class="box-header-right">
            <div class="btn-export" @click.prevent="export_to_csv()">
              <img src="../../assets/log-in.svg" alt="" />
              <p>Export</p>
            </div>
          </div>
        </div>
        <div class="box-main-leaderboard">
          <div class="box-page">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="card-date">
                  <v-date-picker
                    v-model.range.number="newDateFilter"
                    id="date"
                    :max-date="today"
                  >
                    <template #default="{ togglePopover }">
                      <div class="date-title" @click="togglePopover">
                        <img src="../../assets/calendar.svg" alt="" />
                        <p>Date</p>
                      </div>
                    </template>
                  </v-date-picker>
                  <!-- </div> -->
                </div>
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingLeaderboard == false" id="leaderboardTable">
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Point</th>
                  <th>Level</th>
                </tr>
                <tr
                  v-for="(dataLeaderboard, id) in allDataLeaderboard"
                  :key="dataLeaderboard.id"
                >
                  <td
                    v-if="
                      id >= start_page_leaderboard && id < end_page_leaderboard
                    "
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_leaderboard && id < end_page_leaderboard
                    "
                  >
                    {{ dataLeaderboard.user_uid }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_leaderboard && id < end_page_leaderboard
                    "
                  >
                    {{ dataLeaderboard.user_email }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_leaderboard && id < end_page_leaderboard
                    "
                  >
                    {{ dataLeaderboard.current_earned_points }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_leaderboard && id < end_page_leaderboard
                    "
                  >
                    {{ dataLeaderboard.pendekar_tier }}
                  </td>
                  <!-- <td
                    v-if="
                      id >= start_page_leaderboard && id < end_page_leaderboard
                    "
                  >
                    <p v-if="dataLeaderboard.points < 200">
                      Tidak Berperingkat
                    </p>
                    <p v-else-if="dataLeaderboard.points < 500">
                      Pendekar VR Muda
                    </p>
                    <p v-else-if="dataLeaderboard.points < 750">
                      Pendekar VR Sakti
                    </p>
                    <p v-else>Pendekar VR Legenda</p>
                  </td> -->
                </tr>
              </table>
              <div class="empty-card" v-else>
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_leaderboard + 1 }} to
                {{
                  end_page_leaderboard > total_item_leaderboard
                    ? total_item_leaderboard
                    : end_page_leaderboard
                }}
                of {{ total_item_leaderboard }} entries
              </p>
              <paginate
                v-model="paginate_leaderboard"
                :page-count="total_page_leaderboard"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageLeaderboard"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// components
import SideHeader from "../../components/Header/SideHeader.vue";
import TopHeader from "../../components/Header/TopHeader.vue";
import Paginate from "vuejs-paginate-next";

// composables
import { onMounted, ref, watch } from "vue";
import moment from "moment";

// store
import { useStore } from "vuex";
const store = useStore();

// data
const path = ref("PendekarVR");
const subPath = ref("Leaderboard-Filter");
const subSubPath = ref();
const isLoadingLeaderboard = ref(true);
const allDataLeaderboard = ref(null);
const inputSearch = ref("");
const defaultDataLeaderboard = ref(null);
const today = moment().valueOf();
const newDateFilter = ref({
  start: new Date(),
  end: new Date(),
});
const start_page_leaderboard = ref(0);
const end_page_leaderboard = ref(10);
const total_item_leaderboard = ref(0);
const total_page_leaderboard = ref(0);
const paginate_leaderboard = ref(1);

const changePageLeaderboard = (pageNum) => {
  let new_end = pageNum * 10;
  let new_start = new_end - 10;

  start_page_leaderboard.value = new_start;
  end_page_leaderboard.value = new_end;
};

const fetchLeaderboardFilter = async (page, limit, start, end) => {
  isLoadingLeaderboard.value = true;
  await store.dispatch("getValueLeaderboardFilter", {
    page: page,
    limit: limit,
    startDate: start,
    endDate: end,
  });
  allDataLeaderboard.value = await store.getters.getAllLeaderboardFilter;
  // console.log(await store.getters.getAllLeaderboardFilter);
  defaultDataLeaderboard.value = allDataLeaderboard.value?.result.leaderboard;
  allDataLeaderboard.value = allDataLeaderboard.value?.result.leaderboard;
  total_page_leaderboard.value = Math.ceil(
    allDataLeaderboard.value?.length / 10
  );
  total_item_leaderboard.value = allDataLeaderboard.value?.length;
  // console.log(allDataLeaderboard.value);
  isLoadingLeaderboard.value = false;
};

onMounted(() => {
  fetchLeaderboardFilter(
    paginate_leaderboard.value,
    end_page_leaderboard.value,
    null,
    null
  );
});

watch(
  newDateFilter,
  () => {
    start_page_leaderboard.value = 0;
    paginate_leaderboard.value = 1;
    fetchLeaderboardFilter(
      paginate_leaderboard,
      end_page_leaderboard,
      newDateFilter.value.start,
      newDateFilter.value.end
    );
  },
  { deep: true }
);

const download_csv = (csv, filename) => {
  let csvFile;
  let downloadLink;
  csvFile = new Blob([csv], { type: "text/csv" });
  downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

const export_to_csv = () => {
  if (!allDataLeaderboard.value) return;
  let arrayToPdf = [];
  var rows = document.querySelectorAll("#leaderboardTable tr");
  let row = [];
  rows[0].querySelectorAll("td,th").forEach((val) => {
    row.push(val.innerText);
  });
  arrayToPdf.push(row.join(";"));
  allDataLeaderboard.value.forEach((val, index) => {
    arrayToPdf.push(
      `${index + 1};${val.user_uid};${val.user_email};${
        val.current_earned_points
      };${val.pendekar_tier}`
    );
  });
  download_csv(arrayToPdf.join("\n"), "Leaderboard");
};
</script>

<style
  lang="scss"
  scoped
  src="../../scss/PendekarVR/Leaderboard-v2.scss"
></style>
