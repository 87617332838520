<template>
  <!-- Popup -->
  <div id="popup" class="box-popup">
    <div class="popup-content">
      <div class="popup-left">
        <img src="../../assets/succeed-popup.svg" alt="" />
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <div id="popupFailed" class="box-popup">
    <div class="popup-failed">
      <div class="popup-left">
        <!-- <img src="../../assets/succeed-popup.svg" alt=""> -->
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>

  <!-- Modal Create Content -->
  <b-modal id="modalCreateContent" title="Create Content">
    <div class="box-input-text">
      <p>Title</p>
      <input v-model="addContentTitle" type="text" name="" id="" />
    </div>
    <div class="box-input-textarea">
      <p>Description</p>
      <textarea v-model="addContentDescription" name="" id=""></textarea>
    </div>
    <div class="box-input-text">
      <p>Link Embed</p>
      <input v-model="addContentLink" type="url" name="" id="" />
    </div>
    <div class="box-input-text">
      <p>Channel Name</p>
      <input v-model="addContentChannel" type="text" name="" id="" />
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal"><p>CANCEL</p></div>
      <div @click="addNewContent()" class="btn-submit" data-bs-dismiss="modal">
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>

  <!-- Modal Edit Content -->
  <b-modal id="modalEditContent" title="Edit Content">
    <div class="box-input-text">
      <p>Title</p>
      <input v-model="editContentTitle" type="text" name="" id="" />
    </div>
    <div class="box-input-textarea">
      <p>Description</p>
      <textarea v-model="editContentDescription" name="" id=""></textarea>
    </div>
    <div class="box-input-text">
      <p>Link Embed</p>
      <input v-model="editContentLink" type="text" name="" id="" />
    </div>
    <div class="box-input-text">
      <p>Channel Name</p>
      <input v-model="editContentChannel" type="text" name="" id="" />
    </div>
    <div class="box-checkbox">
      <p>Set to Highlight</p>
      <input v-model="isHighlight" type="checkbox" />
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal"><p>CANCEL</p></div>
      <div class="btn-submit" data-bs-dismiss="modal" @click="editContent()">
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>

  <!-- Modal Delete Content -->
  <b-modal id="modalDeleteContent" title="Delete Content">
    <div class="box-delete">
      <p>Are you sure you want to delete</p>
      <p>"{{ deleteContentTitle }}"</p>
      <p>Content ?</p>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal"><p>CANCEL</p></div>
      <div class="btn-submit" data-bs-dismiss="modal" @click="deleteContent()">
        <p>DELETE</p>
      </div>
    </div>
  </b-modal>

  <div class="container-content">
    <SideHeader />
    <div class="container-right-content">
      <TopHeader
        :pathPage="path"
        :pathSubPage="subPath"
        :pathSubSubPage="subSubPath"
      />
      <div class="main-content">
        <div class="box-header-content">
          <div class="box-navigation">
            <div class="navigation active">Content List</div>
          </div>
        </div>
        <div class="box-main-content">
          <div class="box-page" v-if="isDetail == false">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="card-date">
                  <div class="date-title" @click="openDate()">
                    <img src="../../assets/calendar.svg" alt="" />
                    <p>Date</p>
                  </div>
                  <div id="date" class="dropdown-date">
                    <div class="card-start-end">
                      <input
                        v-model="startDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                      <p>to</p>
                      <input
                        v-model="endDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                    </div>
                    <div class="separator"></div>
                    <div class="box-date-filter">
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'today' }]"
                        @click="dateFilterFunction('today')"
                      >
                        Today
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'yesterday' }]"
                        @click="dateFilterFunction('yesterday')"
                      >
                        Yesterday
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'week' }]"
                        @click="dateFilterFunction('week')"
                      >
                        Last Week
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'month' }]"
                        @click="dateFilterFunction('month')"
                      >
                        Last Month
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
              <div class="date-searching-right">
                <b-button
                  v-b-modal.modalCreateContent
                  cancel-disabled="true"
                  class="btn-add"
                >
                  <p>+ Create Content</p>
                </b-button>
              </div>
            </div>
            <div class="box-table">
              <table id="contentTable" v-if="isLoadingContent == false">
                <tr>
                  <th>Content Video</th>
                  <th>Channel Name</th>
                  <th>Upload Date</th>
                  <th>Action</th>
                </tr>
                <tr
                  class="hover-row"
                  v-for="(dataContent, id) in newDataContent"
                  :key="dataContent.id"
                >
                  <td
                    v-if="id >= start_page_content && id < end_page_content"
                    @click="getContentDetail(id)"
                  >
                    <div class="box-content">
                      <div class="content-left">
                        <iframe
                          width="296"
                          height="164"
                          :src="dataContent.embed_video_url"
                        >
                        </iframe>
                      </div>
                      <div class="content-right">
                        <p class="title">{{ dataContent.video_title }}</p>
                        <p
                          class="description"
                          v-if="dataContent.video_description.length > 200"
                        >
                          {{
                            dataContent.video_description.substring(0, 200) +
                            " ..."
                          }}
                        </p>
                        <p class="description" v-else>
                          {{ dataContent.video_description }}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    v-if="id >= start_page_content && id < end_page_content"
                    @click="getContentDetail(id)"
                  >
                    {{ dataContent.channel_name }}
                  </td>
                  <td
                    v-if="id >= start_page_content && id < end_page_content"
                    @click="getContentDetail(id)"
                  >
                    {{ dataContent.date }}
                  </td>
                  <td>
                    <div class="box-action">
                      <b-button
                        v-b-modal.modalEditContent
                        cancel-disabled="true"
                        class="btn-edit"
                        @click="getIdEditContent(id)"
                      >
                        <p>Edit</p>
                      </b-button>
                      <b-button
                        v-if="dataContent.is_highlight != true"
                        v-b-modal.modalDeleteContent
                        cancel-disabled="true"
                        class="btn-delete"
                        @click="getIdDeleteContent(id)"
                      >
                        <p>Delete</p>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="empty-card" v-else>
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_content + 1 }} to
                {{ end_page_content }} of {{ total_item_content }} entries
              </p>
              <paginate
                v-model="paginate_content"
                :page-count="total_page_content"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageContent"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
          <div class="box-page" v-else>
            <div class="box-back" @click="isDetail = false">
              <img src="../../assets/back.svg" alt="" />
              <p>Back to Content List</p>
            </div>
            <div class="box-blog">
              <div class="title">
                <p>{{ allDataContentDetail.video_title }}</p>
              </div>
              <div class="date-author">
                <p>{{ allDataContentDetail.date }} by</p>
                <p class="blue">{{ allDataContentDetail.channel_name }}</p>
              </div>
              <div class="picture">
                <iframe
                  width="296"
                  height="164"
                  :src="allDataContentDetail.embed_video_url"
                >
                </iframe>
              </div>
              <div class="description">
                <p>{{ allDataContentDetail.video_description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue";
import TopHeader from "../../components/Header/TopHeader.vue";
import { onBeforeMount, ref } from "vue";
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";
import Axios from "axios";

export default {
  name: "PendekarContent",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    const store = useStore();
    let path = ref("PendekarVR");
    let subPath = ref("Content");
    let subSubPath = ref();
    let datefilter = ref("");
    let isDetail = ref(false);

    let isLoadingContent = ref(true);

    let allDataContent = ref(null);
    let defaultDataContent = ref(null);

    const openDate = () => {
      document.getElementById("date").classList.toggle("show");
    };

    let startDateRange = ref("");
    let endDateRange = ref("");
    const dateFilterFunction = (params) => {
      if (startDateRange.value != "" && endDateRange.value != "") {
        datefilter.value = "";
        openDate();
      } else if (params == "today") {
        if (datefilter.value == "today") {
          datefilter.value = "";
        } else {
          datefilter.value = "today";
        }
        openDate();
      } else if (params == "yesterday") {
        if (datefilter.value == "yesterday") {
          datefilter.value = "";
        } else {
          datefilter.value = "yesterday";
        }
        openDate();
      } else if (params == "week") {
        if (datefilter.value == "week") {
          datefilter.value = "";
        } else {
          datefilter.value = "week";
        }
        openDate();
      } else if (params == "month") {
        if (datefilter.value == "month") {
          datefilter.value = "";
        } else {
          datefilter.value = "month";
        }
        openDate();
      }
    };

    let popUpText = ref("");
    const popUp = (status) => {
      if (status == "SuccessAddContent") {
        popUpText.value = "Add Content Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedAddContent") {
        popUpText.value = "Add Content Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessEditContent") {
        popUpText.value = "Edit Content Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedEditContent") {
        popUpText.value = "Edit Content Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessDeleteContent") {
        popUpText.value = "Delete Content Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedDeleteContent") {
        popUpText.value = "Delete Content Failed";
        document.getElementById("popupFailed").style.display = "flex";
      }
    };

    const closepopUp = () => {
      document.getElementById("popup").style.display = "none";
      document.getElementById("popupFailed").style.display = "none";
    };

    let inputSearch = ref("");

    const searchList = () => {
      if (inputSearch.value !== "") {
        newDataContent.value = defaultDataContent.value;
        // console.log(newDataContent)
        let filter = newDataContent.value.filter((val) => {
          if (
            val.video_title
              .toUpperCase()
              .includes(inputSearch.value.toUpperCase())
          ) {
            return val;
          }
        });
        if (filter) {
          newDataContent.value = filter;
        } else {
          newDataContent.value = defaultDataContent.value;
        }
        changePageContent(1);
      } else {
        newDataContent.value = defaultDataContent.value;
      }
      total_page_content.value = Math.ceil(newDataContent.value.length / 10);
      total_item_content.value = newDataContent.value.length;
      paginate_content.value = 1;
    };

    //Add New Content
    let addContentTitle = ref(null);
    let addContentDescription = ref(null);
    let addContentLink = ref(null);
    let addContentChannel = ref(null);

    const addNewContent = () => {
      isLoadingContent.value = true;
      let token = localStorage.getItem("idToken");
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/contents",
        {
          embed_video_url: addContentLink.value,
          channel_name: addContentChannel.value,
          video_description: addContentDescription.value,
          video_title: addContentTitle.value,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          console.log("success");
          popUp("SuccessAddContent");
          fetchContent();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedAddContent");
          }
        });
    };

    //Edit Content
    let editContentId = ref(null);
    let editContentTitle = ref(null);
    let editContentDescription = ref(null);
    let editContentLink = ref(null);
    let editContentChannel = ref(null);
    let isHighlight = ref(false);

    const getIdEditContent = (id) => {
      isHighlight.value = false;
      editContentId.value = newDataContent.value[id].id;
      editContentTitle.value = newDataContent.value[id].video_title;
      editContentDescription.value = newDataContent.value[id].video_description;
      editContentLink.value = newDataContent.value[id].embed_video_url;
      editContentChannel.value = newDataContent.value[id].channel_name;
    };

    const editContent = () => {
      isLoadingContent.value = true;
      // console.log(
      //   "Id:",
      //   editContentId.value,
      //   "Title:",
      //   editContentTitle.value,
      //   "Desc:",
      //   editContentDescription.value,
      //   "Link:",
      //   editContentLink.value,
      //   "Channel:",
      //   editContentChannel.value,
      //   "isHighlight:",
      //   isHighlight.value
      // );
      if (isHighlight.value == false) {
        isHighlight.value = null;
      }
      let token = localStorage.getItem("idToken");
      Axios.patch(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/contents/" +
          editContentId.value,
        {
          embed_video_url: editContentLink.value,
          channel_name: editContentChannel.value,
          video_description: editContentDescription.value,
          video_title: editContentTitle.value,
          is_highlight: isHighlight.value,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          console.log("success");
          popUp("SuccessEditContent");
          fetchContent();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedEditContent");
            fetchContent();
          }
        });
    };

    //Delete Content
    let deleteContentId = ref(null);
    let deleteContentTitle = ref(null);
    const getIdDeleteContent = (id) => {
      deleteContentId.value = newDataContent.value[id].id;
      deleteContentTitle.value = newDataContent.value[id].video_title;
    };
    const deleteContent = () => {
      isLoadingContent.value = true;
      let token = localStorage.getItem("idToken");
      Axios.delete(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/contents/" +
          deleteContentId.value,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          console.log("success");
          popUp("SuccessDeleteContent");
          fetchContent();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedDeleteContent");
            fetchContent();
          }
        });
    };

    //Content State
    let start_page_content = ref(0);
    let end_page_content = ref(10);
    let total_item_content = ref(0);
    let total_page_content = ref(0);
    let paginate_content = ref(1);

    const changePageContent = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_page_content.value = new_start;
      end_page_content.value = new_end;
    };

    let newDataContent = ref([]);

    const fetchContent = async () => {
      isLoadingContent.value = true;
      await store.dispatch("getValueContent");
      allDataContent.value = await store.getters.getAllContent;
      allDataContent.value = allDataContent.value.result;

      newDataContent.value = [];

      for (let i = 0; i < allDataContent.value.length; i++) {
        let newDate = new Date(allDataContent.value[i].date);
        let dateStr =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear();
        newDataContent.value.push({
          channel_name: allDataContent.value[i].channel_name,
          embed_video_url: allDataContent.value[i].embed_video_url,
          id: allDataContent.value[i].id,
          is_highlight: allDataContent.value[i].is_highlight,
          video_description: allDataContent.value[i].video_description,
          video_title: allDataContent.value[i].video_title,
          date: dateStr,
        });
      }

      // console.log(newDataContent);
      defaultDataContent.value = newDataContent.value;

      total_page_content.value = Math.ceil(newDataContent.value.length / 10);
      total_item_content.value = newDataContent.value.length;
      isLoadingContent.value = false;
    };

    let allDataContentDetail = ref([]);
    const getContentDetail = (id) => {
      // console.log(id);
      isDetail.value = true;
      allDataContentDetail.value = newDataContent.value[id];
      // console.log(allDataContentDetail);
    };

    onBeforeMount(() => {
      fetchContent();
    });

    return {
      path,
      subPath,
      subSubPath,
      datefilter,
      isDetail,
      isLoadingContent,
      allDataContent,
      defaultDataContent,
      openDate,
      startDateRange,
      endDateRange,
      dateFilterFunction,
      searchList,
      inputSearch,
      addContentTitle,
      addContentDescription,
      addContentLink,
      addContentChannel,
      addNewContent,
      editContentId,
      editContentTitle,
      editContentDescription,
      editContentLink,
      editContentChannel,
      isHighlight,
      getIdEditContent,
      editContent,
      deleteContentId,
      deleteContentTitle,
      getIdDeleteContent,
      deleteContent,
      start_page_content,
      end_page_content,
      total_item_content,
      total_page_content,
      paginate_content,
      changePageContent,
      newDataContent,
      allDataContentDetail,
      getContentDetail,
      popUpText,
      popUp,
      closepopUp,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/PendekarVR/Content.scss";
</style>
