<template>
  <div id="popup" class="box-popup">
    <div class="popup-content">
      <div class="popup-left">
        <img src="../../assets/succeed-popup.svg" alt="" />
        <p>Succeed</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <div class="container-reviewRequest">
    <SideHeader />
    <div class="container-right-reviewRequest">
      <TopHeader
        :pathPage="path"
        :pathSubPage="subPath"
        :pathSubSubPage="subSubPath"
      />
      <div class="main-reviewRequest">
        <div class="box-header-history">
          <div class="box-navigation">
            <div class="navigation active">Review Request</div>
          </div>
          <div class="box-header-right">
            <div
              v-if="isDetail == false"
              @click="export_to_csv()"
              class="btn-export"
            >
              <img src="../../assets/log-in.svg" alt="" />
              <p>Export</p>
            </div>
          </div>
        </div>
        <div class="box-main-request-review">
          <div class="box-page" v-if="isDetail == false">
            <div class="box-date-searching">
              <div class="card-date">
                <div class="date-title" @click="openDate()">
                  <img src="../../assets/calendar.svg" alt="" />
                  <p>Date</p>
                </div>
                <div id="date" class="dropdown-date">
                  <div class="card-start-end">
                    <input
                      v-model="startDateRange"
                      @change="dateFilterFunction()"
                      type="date"
                    />
                    <p>to</p>
                    <input
                      v-model="endDateRange"
                      @change="dateFilterFunction()"
                      type="date"
                    />
                  </div>
                  <div class="separator"></div>
                  <div class="box-date-filter">
                    <div
                      class="date-filter"
                      :class="[{ active: datefilter === 'today' }]"
                      @click="dateFilterFunction('today')"
                    >
                      Today
                    </div>
                    <div
                      class="date-filter"
                      :class="[{ active: datefilter === 'yesterday' }]"
                      @click="dateFilterFunction('yesterday')"
                    >
                      Yesterday
                    </div>
                    <div
                      class="date-filter"
                      :class="[{ active: datefilter === 'week' }]"
                      @click="dateFilterFunction('week')"
                    >
                      Last Week
                    </div>
                    <div
                      class="date-filter"
                      :class="[{ active: datefilter === 'month' }]"
                      @click="dateFilterFunction('month')"
                    >
                      Last Month
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-searching">
                <input
                  v-model="inputSearch"
                  type="text"
                  placeholder="Search"
                  @change="(e) => searchList(e.target.value)"
                />
                <img src="../../assets/searching.svg" alt="" />
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingReview === false" id="reviewTable">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Review</th>
                </tr>
                <tr
                  class="hover-row"
                  @click="fetchReviewDetail(id)"
                  v-for="(dataReview, id) in allDataReview"
                  :key="dataReview.id"
                >
                  <td v-if="id >= start_page_review && id < end_page_review">
                    {{ id + 1 }}
                  </td>
                  <td v-if="id >= start_page_review && id < end_page_review">
                    {{ dataReview.created_date }}
                  </td>
                  <td v-if="id >= start_page_review && id < end_page_review">
                    {{ dataReview.name }}
                  </td>
                  <td v-if="id >= start_page_review && id < end_page_review">
                    {{ dataReview.email }}
                  </td>
                  <td v-if="id >= start_page_review && id < end_page_review">
                    {{ dataReview.review }}
                  </td>
                </tr>
              </table>
              <div class="empty-card" v-else-if="isLoadingReview === true">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_review + 1 }} to {{ end_page_review }} of
                {{ total_item_review }} entries
              </p>
              <paginate
                v-model="paginate_review"
                :page-count="total_page_review"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageReview"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
          <div class="box-page" v-if="isDetail == true">
            <div class="box-back" @click="isDetail = false">
              <img src="../../assets/back.svg" alt="" />
              <p>Back to Review Request</p>
            </div>
            <div class="box-details">
              <div class="detail-name">
                <p>{{ reviewDetailName }}</p>
              </div>
            </div>
            <div class="box-email-review">
              <div class="box-email">
                <div class="title"><p>Email:</p></div>
                <div class="description">
                  <p>{{ reviewDetailEmail }}</p>
                </div>
              </div>
              <div class="box-review">
                <div class="title"><p>Review:</p></div>
                <div class="description">
                  {{ reviewDetailReview }}
                </div>
              </div>
            </div>
            <div class="box-input-dropdown">
              <p>Review Request Approval Status</p>
              <select v-model="valueApproval" name="" id="">
                <option value="approve"><p>Approve</p></option>
                <option value="reject"><p>Reject</p></option>
              </select>
            </div>
            <div class="box-edit-review">
              <p>Modified</p>
              <div class="box-input">
                <textarea
                  type="text"
                  class="input-ta"
                  v-model="modifiedReview"
                  autofocus
                ></textarea>
              </div>
              <div class="btn-submit" @click="submitStatus()">
                <p>Submit</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue";
import TopHeader from "../../components/Header/TopHeader.vue";
import { onBeforeMount, ref } from "vue";
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";
import Axios from "axios";

export default {
  name: "ReviewRequest",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    const store = useStore();
    let path = ref("Individual License");
    let subPath = ref("Review Request");
    let subSubPath = ref(null);
    let datefilter = ref("");
    let allDataReview = ref(null);
    let defaultDataReview = ref(null);
    let reviewDetail = ref(null);
    let isDetail = ref(false);
    let isLoadingReview = ref(true);

    const popUp = () => {
      document.getElementById("popup").style.display = "flex";
    };

    const closepopUp = () => {
      document.getElementById("popup").style.display = "none";
    };

    const openDate = () => {
      document.getElementById("date").classList.toggle("show");
    };

    let inputSearch = ref("");

    // watch(async()=>inputSearch.value,(()=>{
    //   searchList(inputSearch.value)
    // }))

    const searchList = (input) => {
      if (filterData.value == null || filterData.value === "") {
        filterData.value = defaultDataReview.value;
      }
      if (inputSearch.value !== "") {
        allDataReview.value = defaultDataReview.value;
        let filter = allDataReview.value.filter((val) => {
          if (val.name.toUpperCase().includes(input.toUpperCase())) {
            return val;
          }
        });
        if (filter) {
          allDataReview.value = filter;
        } else {
          allDataReview.value = filterData.value;
        }
        changePageReview(1);
      } else {
        allDataReview.value = filterData.value;
      }
      total_page_review.value = Math.ceil(allDataReview.value.length / 10);
      total_item_review.value = allDataReview.value.length;
      paginate_review.value = 1;
    };

    let startDateRange = ref("");
    let endDateRange = ref("");
    let filterData = ref(null);
    const dateFilterFunction = (params) => {
      // created_date
      if (startDateRange.value != "" && endDateRange.value != "") {
        var formatStart = startDateRange.value.split("-");
        var startDate = new Date(
          [formatStart[1], formatStart[2], formatStart[0]].join("/")
        );
        var formatEnd = endDateRange.value.split("-");
        var endDate = new Date(
          [formatEnd[1], formatEnd[2], formatEnd[0]].join("/")
        );

        let filter = allDataReview.value.filter((val) => {
          var formatDate = val.created_date.split(/\//);
          var date = new Date(
            [formatDate[1], formatDate[0], formatDate[2]].join("/")
          );
          return date >= startDate && date <= endDate;
        });

        if (filter) {
          allDataReview.value = filter;
        } else {
          allDataReview.value = defaultDataReview.value;
        }
        filterData.value = allDataReview.value;
        changePageReview(1);
        total_page_review.value = Math.ceil(allDataReview.value.length / 10);
        total_item_review.value = allDataReview.value.length;
        paginate_review.value = 1;
        openDate();
      } else if (params == "today") {
        if (datefilter.value == "today") {
          datefilter.value = "";
          allDataReview.value = defaultDataReview.value;
          filterData.value = allDataReview.value;
          changePageReview(1);
          total_page_review.value = Math.ceil(allDataReview.value.length / 10);
          total_item_review.value = allDataReview.value.length;
          paginate_review.value = 1;
        } else {
          datefilter.value = "today";
          allDataReview.value = defaultDataReview.value;
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();

          today = dd + "/" + mm + "/" + yyyy;

          let filter = allDataReview.value.filter((val) => {
            if (val.created_date.toUpperCase().includes(today.toUpperCase())) {
              return val;
            }
          });
          if (filter) {
            allDataReview.value = filter;
          } else {
            allDataReview.value = defaultDataReview.value;
          }
          filterData.value = allDataReview.value;
          changePageReview(1);
          total_page_review.value = Math.ceil(allDataReview.value.length / 10);
          total_item_review.value = allDataReview.value.length;
          paginate_review.value = 1;
        }
        openDate();
      } else if (params == "yesterday") {
        if (datefilter.value == "yesterday") {
          datefilter.value = "";
          allDataReview.value = defaultDataReview.value;
          filterData.value = allDataReview.value;
          changePageReview(1);
          total_page_review.value = Math.ceil(allDataReview.value.length / 10);
          total_item_review.value = allDataReview.value.length;
          paginate_review.value = 1;
        } else {
          datefilter.value = "yesterday";
          allDataReview.value = defaultDataReview.value;

          today = new Date();
          startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 1
          );
          endDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
          );

          let filter = allDataReview.value.filter((val) => {
            var formatDate = val.created_date.split(/\//);
            var date = new Date(
              [formatDate[1], formatDate[0], formatDate[2]].join("/")
            );
            return date >= startDate && date <= endDate;
          });

          if (filter) {
            allDataReview.value = filter;
          } else {
            allDataReview.value = defaultDataReview.value;
          }
          filterData.value = allDataReview.value;
          changePageReview(1);
          total_page_review.value = Math.ceil(allDataReview.value.length / 10);
          total_item_review.value = allDataReview.value.length;
          paginate_review.value = 1;
        }
        openDate();
      } else if (params == "week") {
        if (datefilter.value == "week") {
          datefilter.value = "";
          allDataReview.value = defaultDataReview.value;
          filterData.value = allDataReview.value;
          changePageReview(1);
          total_page_review.value = Math.ceil(allDataReview.value.length / 10);
          total_item_review.value = allDataReview.value.length;
          paginate_review.value = 1;
        } else {
          datefilter.value = "week";
          allDataReview.value = defaultDataReview.value;

          today = new Date();
          startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 7
          );
          endDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
          );

          let filter = allDataReview.value.filter((val) => {
            var formatDate = val.created_date.split(/\//);
            var date = new Date(
              [formatDate[1], formatDate[0], formatDate[2]].join("/")
            );
            return date >= startDate && date <= endDate;
          });

          if (filter) {
            allDataReview.value = filter;
          } else {
            allDataReview.value = defaultDataReview.value;
          }
          filterData.value = allDataReview.value;
          changePageReview(1);
          total_page_review.value = Math.ceil(allDataReview.value.length / 10);
          total_item_review.value = allDataReview.value.length;
          paginate_review.value = 1;
        }
        openDate();
      } else if (params == "month") {
        if (datefilter.value == "month") {
          datefilter.value = "";
          allDataReview.value = defaultDataReview.value;
          filterData.value = allDataReview.value;
          changePageReview(1);
          total_page_review.value = Math.ceil(allDataReview.value.length / 10);
          total_item_review.value = allDataReview.value.length;
          paginate_review.value = 1;
        } else {
          datefilter.value = "month";
          allDataReview.value = defaultDataReview.value;

          today = new Date();
          startDate = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            today.getDate()
          );
          endDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
          );

          let filter = allDataReview.value.filter((val) => {
            var formatDate = val.created_date.split(/\//);
            var date = new Date(
              [formatDate[1], formatDate[0], formatDate[2]].join("/")
            );
            return date >= startDate && date <= endDate;
          });

          if (filter) {
            allDataReview.value = filter;
          } else {
            allDataReview.value = defaultDataReview.value;
          }
          filterData.value = allDataReview.value;
          changePageReview(1);
          total_page_review.value = Math.ceil(allDataReview.value.length / 10);
          total_item_review.value = allDataReview.value.length;
          paginate_review.value = 1;
        }
        openDate();
      } else {
        allDataReview.value = defaultDataReview.value;
        filterData.value = allDataReview.value;
        changePageReview(1);
        total_page_review.value = Math.ceil(allDataReview.value.length / 10);
        total_item_review.value = allDataReview.value.length;
        paginate_review.value = 1;
      }
    };

    const export_to_csv = () => {
      let arrayToPdf = [];
      var rows = document.querySelectorAll("#reviewTable tr");
      let row = [];
      rows[0].querySelectorAll("td,th").forEach((val) => {
        row.push(val.innerText);
      });
      arrayToPdf.push(row.join(","));
      allDataReview.value.forEach((val, index) => {
        arrayToPdf.push(
          `${index + 1},${val.created_date},${val.name.replaceAll(",", "")},${
            val.email
          },${val.review.replaceAll(",", "")}`
        );
      });
      download_csv(arrayToPdf.join("\n"), subPath.value);
    };
    function download_csv(csv, filename) {
      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], { type: "text/csv" });
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }

    //Review State
    let start_page_review = ref(0);
    let end_page_review = ref(10);
    let total_item_review = ref(0);
    let total_page_review = ref(0);
    let paginate_review = ref(1);

    const changePageReview = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_page_review.value = new_start;
      end_page_review.value = new_end;
    };

    //Review Detail State
    let reviewDetailName = ref(null);
    let reviewDetailEmail = ref(null);
    let reviewDetailReview = ref(null);
    let reviewDetailUid = ref(null);
    let modifiedReview = ref(null);
    let valueApproval = ref(null);
    let approvalStatus = ref(false);

    const submitStatus = () => {
      let token = localStorage.getItem("idToken");
      if (valueApproval.value === "approve") {
        approvalStatus.value = true;
      } else {
        approvalStatus.value = false;
      }

      if (modifiedReview.value == null || modifiedReview.value == "") {
        Axios.post(
          "https://us-central1-millea-lab.cloudfunctions.net/web/api/approveReview",
          {
            review_id: reviewDetailUid.value,
            status: approvalStatus.value,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
          .then(() => {
            modifiedReview.value = null;
            valueApproval.value = null;
            approvalStatus.value = false;
            popUp();
            location.reload();
          })
          .catch((err) => {
            if (err.message === "Request failed with status code 401") {
              localStorage.removeItem("idStorage");
              window.location.href = "/login";
            }
          });
      } else {
        Axios.post(
          "https://us-central1-millea-lab.cloudfunctions.net/web/api/approveReview",
          {
            review_id: reviewDetailUid.value,
            status: approvalStatus.value,
            review: modifiedReview.value,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
          .then(() => {
            modifiedReview.value = null;
            valueApproval.value = null;
            approvalStatus.value = false;
            location.reload();
          })
          .catch((err) => {
            if (err.message === "Request failed with status code 401") {
              localStorage.removeItem("idStorage");
              window.location.href = "/login";
            }
          });
      }
    };

    const fetchReview = async () => {
      isLoadingReview.value = true;
      await store.dispatch("getValueReview");
      let temp = await store.getters.getAllReview;
      allDataReview.value = temp;
      defaultDataReview.value = temp;
      total_page_review.value = Math.ceil(allDataReview.value.length / 10);
      total_item_review.value = allDataReview.value.length;
      isLoadingReview.value = false;
    };

    const fetchReviewDetail = (id) => {
      isDetail.value = true;
      reviewDetail.value = allDataReview.value[id];
      reviewDetailName.value = reviewDetail.value.name;
      reviewDetailEmail.value = reviewDetail.value.email;
      reviewDetailReview.value = reviewDetail.value.review;
      reviewDetailUid.value = reviewDetail.value.review_uid;
      // let token = localStorage.getItem('idToken')
    };

    onBeforeMount(() => {
      fetchReview();
    });

    return {
      path,
      subPath,
      subSubPath,
      datefilter,
      allDataReview,
      defaultDataReview,
      isDetail,
      isLoadingReview,
      popUp,
      closepopUp,
      openDate,
      inputSearch,
      searchList,
      startDateRange,
      endDateRange,
      filterData,
      dateFilterFunction,
      export_to_csv,
      download_csv,
      start_page_review,
      end_page_review,
      total_item_review,
      total_page_review,
      paginate_review,
      changePageReview,
      reviewDetailName,
      reviewDetailEmail,
      reviewDetailReview,
      reviewDetailUid,
      modifiedReview,
      valueApproval,
      approvalStatus,
      submitStatus,
      fetchReviewDetail,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/IndividualLicense/ReviewRequest.scss";
</style>

