<template>
  <div class="container-leaderboard">
    <SideHeader />
    <div class="container-right-leaderboard">
      <TopHeader
        :pathPage="path"
        :pathSubPage="subPath"
        :pathSubSubPage="subSubPath"
      />
      <div class="main-leaderboard">
        <div class="box-header-leaderboard">
          <div class="box-navigation">
            <div class="navigation active">Leaderboard</div>
          </div>
          <div class="box-header-right">
            <div class="btn-export">
              <img src="../../assets/log-in.svg" alt="" />
              <p>Export</p>
            </div>
          </div>
        </div>
        <div class="box-main-leaderboard">
          <div class="box-page">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="card-date">
                  <div class="date-title" @click="openDate()">
                    <img src="../../assets/calendar.svg" alt="" />
                    <p>Date</p>
                  </div>
                  <div id="date" class="dropdown-date">
                    <div class="card-start-end">
                      <input
                        v-model="startDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                      <p>to</p>
                      <input
                        v-model="endDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                    </div>
                    <div class="separator"></div>
                    <div class="box-date-filter">
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'today' }]"
                        @click="dateFilterFunction('today')"
                      >
                        Today
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'yesterday' }]"
                        @click="dateFilterFunction('yesterday')"
                      >
                        Yesterday
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'week' }]"
                        @click="dateFilterFunction('week')"
                      >
                        Last Week
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'month' }]"
                        @click="dateFilterFunction('month')"
                      >
                        Last Month
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingLeaderboard == false" id="leaderboardTable">
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Point</th>
                  <th>Level</th>
                </tr>
                <tr
                  v-for="(dataLeaderboard, id) in allDataLeaderboard"
                  :key="dataLeaderboard.id"
                >
                  <td
                    v-if="
                      id >= start_page_leaderboard && id < end_page_leaderboard
                    "
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_leaderboard && id < end_page_leaderboard
                    "
                  >
                    {{ dataLeaderboard.user_uid }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_leaderboard && id < end_page_leaderboard
                    "
                  >
                    {{ dataLeaderboard.user_email }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_leaderboard && id < end_page_leaderboard
                    "
                  >
                    {{ dataLeaderboard.points }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_leaderboard && id < end_page_leaderboard
                    "
                  >
                    <p v-if="dataLeaderboard.points < 200">
                      Tidak Berperingkat
                    </p>
                    <p v-else-if="dataLeaderboard.points < 500">
                      Pendekar VR Muda
                    </p>
                    <p v-else-if="dataLeaderboard.points < 750">
                      Pendekar VR Sakti
                    </p>
                    <p v-else>Pendekar VR Legenda</p>
                  </td>
                </tr>
              </table>
              <div class="empty-card" v-else>
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>

            <div class="paginate-left">
              <p>
                Showing {{ start_page_leaderboard + 1 }} to
                {{ end_page_leaderboard }} of
                {{ total_item_leaderboard }} entries
              </p>
              <paginate
                v-model="paginate_leaderboard"
                :page-count="total_page_leaderboard"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageLeaderboard"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue";
import TopHeader from "../../components/Header/TopHeader.vue";
import { onBeforeMount, ref } from "vue";
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";

export default {
  name: "PendekarLeaderboard",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    const store = useStore();
    let path = ref("PendekarVR");
    let subPath = ref("Leaderboard");
    let subSubPath = ref();
    let datefilter = ref("");

    let isLoadingLeaderboard = ref(true);

    let allDataLeaderboard = ref(null);
    let defaultDataLeaderboard = ref(null);

    const openDate = () => {
      document.getElementById("date").classList.toggle("show");
    };

    let startDateRange = ref("");
    let endDateRange = ref("");
    const dateFilterFunction = (params) => {
      if (startDateRange.value != "" && endDateRange.value != "") {
        datefilter.value = "";
        openDate();
      } else if (params == "today") {
        if (datefilter.value == "today") {
          datefilter.value = "";
        } else {
          datefilter.value = "today";
        }
        openDate();
      } else if (params == "yesterday") {
        if (datefilter.value == "yesterday") {
          datefilter.value = "";
        } else {
          datefilter.value = "yesterday";
        }
        openDate();
      } else if (params == "week") {
        if (datefilter.value == "week") {
          datefilter.value = "";
        } else {
          datefilter.value = "week";
        }
        openDate();
      } else if (params == "month") {
        if (datefilter.value == "month") {
          datefilter.value = "";
        } else {
          datefilter.value = "month";
        }
        openDate();
      }
      // else{
      // }
    };

    let inputSearch = ref("");

    const searchList = () => {
      if (inputSearch.value !== "") {
        allDataLeaderboard.value = defaultDataLeaderboard.value;
        // console.log(allDataLeaderboard)
        let filter = allDataLeaderboard.value.filter((val) => {
          if (
            val.user_email
              .toUpperCase()
              .includes(inputSearch.value.toUpperCase())
          ) {
            return val;
          }
        });
        if (filter) {
          allDataLeaderboard.value = filter;
        } else {
          allDataLeaderboard.value = defaultDataLeaderboard.value;
        }
        changePageLeaderboard(1);
      } else {
        allDataLeaderboard.value = defaultDataLeaderboard.value;
      }
      total_page_leaderboard.value = Math.ceil(
        allDataLeaderboard.value.length / 10
      );
      total_item_leaderboard.value = allDataLeaderboard.value.length;
      paginate_leaderboard.value = 1;
    };

    //Leaderboard State
    let start_page_leaderboard = ref(0);
    let end_page_leaderboard = ref(10);
    let total_item_leaderboard = ref(0);
    let total_page_leaderboard = ref(0);
    let paginate_leaderboard = ref(1);

    const changePageLeaderboard = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_page_leaderboard.value = new_start;
      end_page_leaderboard.value = new_end;
    };

    const fetchLeaderboard = async () => {
      isLoadingLeaderboard.value = true;
      await store.dispatch("getValueLeaderboard");
      allDataLeaderboard.value = await store.getters.getAllLeaderboard;
      defaultDataLeaderboard.value =
        allDataLeaderboard.value.result.leaderboard;
      allDataLeaderboard.value = allDataLeaderboard.value.result.leaderboard;
      total_page_leaderboard.value = Math.ceil(
        allDataLeaderboard.value.length / 10
      );
      total_item_leaderboard.value = allDataLeaderboard.value.length;
      // console.log(allDataLeaderboard);
      isLoadingLeaderboard.value = false;
    };

    onBeforeMount(() => {
      fetchLeaderboard();
    });

    return {
      path,
      subPath,
      subSubPath,
      datefilter,
      allDataLeaderboard,
      defaultDataLeaderboard,
      isLoadingLeaderboard,
      openDate,
      startDateRange,
      endDateRange,
      dateFilterFunction,
      searchList,
      inputSearch,
      changePageLeaderboard,
      start_page_leaderboard,
      end_page_leaderboard,
      total_page_leaderboard,
      total_item_leaderboard,
      paginate_leaderboard,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/PendekarVR/Leaderboard.scss";
</style>
