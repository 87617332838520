<template>
    <div class="container-purchaseHistory">
      <SideHeader/>
      <div class="container-right-purchaseHistory">
        <TopHeader
         :pathPage="path"
         :pathSubPage="subPath"
         :pathSubSubPage="subSubPath"
        />
        <div class="main-purchaseHistory">
          <div class="">Ini Halaman Purchase History</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SideHeader from "../../components/Header/SideHeader.vue"
  import TopHeader from "../../components/Header/TopHeader.vue"
  import {ref} from 'vue'
  
  export default {
    name: "purchaseHistory",
    components: {
      SideHeader,
      TopHeader,
    },
    setup() {
      let path = ref('Training')
      let subPath = ref('Purchase History')
      let subSubPath = ref(null)
      return {
        path,
        subPath,
        subSubPath
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "../../scss/Training/PurchaseHistory.scss";
  </style>
  