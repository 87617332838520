<template>
  <div class="container-historyActivity">
    <SideHeader/>
    <div class="container-right-historyActivity">
      <TopHeader
       :pathPage="path"
       :pathSubPage="subPath"
       :pathSubSubPage="subSubPath"
      />
      <div class="main-historyActivity">
        <div class="box-header-history">
          <div class="box-navigation">
            <div class="navigation" :class="[{ active: page ==='purchase' }]" @click="page = 'purchase', subSubPath = 'Purchase', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">Purchase</div>
            <div class="navigation" :class="[{ active: page ==='task' }]" @click="page = 'task', subSubPath = 'Task History', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">Task History</div>
            <div class="navigation" :class="[{ active: page ==='redeem' }]" @click="page = 'redeem', subSubPath = 'Redeem Points', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">Redeem Points</div>
            <div class="navigation" :class="[{ active: page ==='login' }]" @click="page = 'login', subSubPath = 'User Login', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">User Login</div>
            <div class="navigation" :class="[{ active: page ==='data' }]" @click="page = 'data', subSubPath = 'User Data', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">User Data</div>
          </div>
          <div class="box-header-right">
            <div v-if="isDetail == false" @click="export_to_csv()" class="btn-export">
              <img src="../../assets/log-in.svg" alt="">
              <p>Export</p>
            </div>
          </div>
          
        </div>
        <div class="box-main-history">
          <div class="box-page" v-if="page === 'purchase'">
            <div class="box-date-searching">
              <div class="card-date">
                <div class="date-title" @click="openDate()">
                  <img src="../../assets/calendar.svg" alt="">
                  <p>Date</p>
                </div>
                <div id="date" class="dropdown-date">
                  <div class="card-start-end">
                    <input v-model="startDateRange" @change="dateFilterFunction()" type="date">
                    <p>to</p>
                    <input v-model="endDateRange" @change="dateFilterFunction()" type="date">
                  </div>
                  <div class="separator"></div>
                  <div class="box-date-filter">
                    <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                    <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                    <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                    <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                  </div>
                </div>
              </div>
              <div class="card-searching">
                <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingPurchase === false" id="purchaseTable">
                <tr>
                  <th>No</th>
                  <th>Purchased Date</th>
                  <th>Expired Date</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Referral Code</th>
                </tr>
                <tr v-for="(dataPurchase,id) in allDataPurchaseHistory" :key="dataPurchase.id">
                  <td v-if="id >= start_page_purchase && id < end_page_purchase">{{id + 1}}</td>
                  <td v-if="id >= start_page_purchase && id < end_page_purchase">{{dataPurchase.purchased_date}}</td>
                  <td v-if="id >= start_page_purchase && id < end_page_purchase">{{dataPurchase.expired_date}}</td>
                  <td v-if="id >= start_page_purchase && id < end_page_purchase">{{dataPurchase.name}}</td>
                  <td v-if="id >= start_page_purchase && id < end_page_purchase">{{dataPurchase.email}}</td>
                  <td v-if="id >= start_page_purchase && id < end_page_purchase">{{"0"+dataPurchase.phone}}</td>
                  <td v-if="id >= start_page_purchase && id < end_page_purchase && dataPurchase.referral_code !== null">{{dataPurchase.referral_code}}</td>
                  <td v-else-if="id >= start_page_purchase && id < end_page_purchase">-</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingPurchase === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_purchase + 1}} to {{end_page_purchase}} of {{total_item_purchase}} entries</p>
                <paginate
                v-model="paginate_purchase"
                :page-count="total_page_purchase"
                :page-range="3"
                :margin-pages="2"
                :click-handler="changePagePurchase"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>

          <div class="box-page" v-if="page === 'task'">
            <div class="box-date-searching">
              <div class="card-date">
                <div class="date-title" @click="openDate()">
                  <img src="../../assets/calendar.svg" alt="">
                  <p>Date</p>
                </div>
                <div id="date" class="dropdown-date">
                  <div class="card-start-end">
                    <input v-model="startDateRange" @change="dateFilterFunction()" type="date">
                    <p>to</p>
                    <input v-model="endDateRange" @change="dateFilterFunction()" type="date">
                  </div>
                  <div class="separator"></div>
                  <div class="box-date-filter">
                    <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                    <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                    <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                    <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                  </div>
                </div>
              </div>
              <div class="card-searching">
                <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingTask === false" id="taskTable">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Task</th>
                  <th>Point</th>
                </tr>
                <tr v-for="(dataTask,id) in allDataTaskHistory" :key="dataTask.id">
                  <td v-if="id >= start_page_task && id < end_page_task">{{id + 1}}</td>
                  <td v-if="id >= start_page_task && id < end_page_task">{{dataTask.completed_at_date}}</td>
                  <td v-if="id >= start_page_task && id < end_page_task">{{dataTask.name}}</td>
                  <td v-if="id >= start_page_task && id < end_page_task">{{dataTask.task}}</td>
                  <td v-if="id >= start_page_task && id < end_page_task">{{dataTask.point}}</td>
                </tr>
                <!-- <tr>
                  <td>odd test</td>
                  <td>odd test</td>
                  <td>odd test</td>
                  <td>odd test</td>
                  <td>odd test</td>
                </tr> -->
              </table>
              <div class="empty-card"  v-else-if="isLoadingTask === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_task + 1}} to {{end_page_task}} of {{total_item_task}} entries</p>
                <paginate
                v-model="paginate_task"
                :page-count="total_page_task"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageTask"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>

          <div class="box-page" v-if="page === 'redeem'">
            <div class="box-date-searching">
              <div class="card-date">
                <div class="date-title" @click="openDate()">
                  <img src="../../assets/calendar.svg" alt="">
                  <p>Date</p>
                </div>
                <div id="date" class="dropdown-date">
                  <div class="card-start-end">
                    <input v-model="startDateRange" @change="dateFilterFunction()" type="date">
                    <p>to</p>
                    <input v-model="endDateRange" @change="dateFilterFunction()" type="date">
                  </div>
                  <div class="separator"></div>
                  <div class="box-date-filter">
                    <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                    <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                    <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                    <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                  </div>
                </div>
              </div>
              <div class="card-searching">
                <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingRedeem === false" id="redeemTable">
                <tr>
                  <th>No</th>
                  <th>Redeem Date</th>
                  <th>Name</th>
                  <th>Voucher</th>
                  <th>Point</th>
                </tr>
                <tr v-for="(dataPoints,id) in allDataRedeemPoints" :key="dataPoints.id">
                  <td v-if="id >= start_page_points && id < end_page_points">{{id + 1}}</td>
                  <td v-if="id >= start_page_points && id < end_page_points">{{dataPoints.redeem_date}}</td>
                  <td v-if="id >= start_page_points && id < end_page_points">{{dataPoints.name}}</td>
                  <td v-if="id >= start_page_points && id < end_page_points">{{dataPoints.voucher}}</td>
                  <td v-if="id >= start_page_points && id < end_page_points">{{dataPoints.point}}</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingRedeem === true">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_points + 1}} to {{end_page_points}} of {{total_item_points}} entries</p>
                <paginate
                v-model="paginate_points"
                :page-count="total_page_points"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePagePoints"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>

          <div class="box-page" v-if="page === 'login' && isDetail == false">
            <div class="box-chart">
              <div class="box-chart-header">
                <p>User Login Activity</p>
                <div class="chart-header-right">
                  <div class="card-date">
                    <div class="date-title" @click="openDate()">
                      <img src="../../assets/calendar.svg" alt="">
                      <p>Date</p>
                    </div>
                    <div id="date" class="dropdown-date">
                      <div class="card-start-end">
                        <input v-model="startDateRange" @change="dateFilterFunction()" type="date">
                        <p>to</p>
                        <input v-model="endDateRange" @change="dateFilterFunction()" type="date">
                      </div>
                      <div class="separator"></div>
                      <div class="box-date-filter">
                        <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                        <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chart-detail">
                <apexchart width="95%" height="330" type="bar" :options="optionsLogin" :series="seriesLogin"></apexchart>
              </div>
              <div class="paginate-left">
                <p>Showing {{start_chart_login + 1}} to {{end_chart_login}} of {{total_chart_item_login}} entries</p>
                <paginate
                v-model="paginate_chart_login"
                :page-count="total_chart_page_login"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changeChartLogin"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
            </div>
            <div class="box-search">
              <div class="card-searching">
                <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingLogin === false" id="loginTable">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Login Counts</th>
                </tr>
                <tr class="hover-row" v-for="(dataLogin,id) in allDataWebsiteLogin" :key="dataLogin.id" @click="fetchWebsiteLoginDetail(id)">
                  <td v-if="id >= start_page_login && id < end_page_login">{{id + 1}}</td>
                  <td v-if="id >= start_page_login && id < end_page_login">{{dataLogin.last_login_date}}</td>
                  <td v-if="id >= start_page_login && id < end_page_login">{{dataLogin.last_login_time}}</td>
                  <td v-if="id >= start_page_login && id < end_page_login">{{dataLogin.name}}</td>
                  <td v-if="id >= start_page_login && id < end_page_login">{{dataLogin.email}}</td>
                  <td v-if="id >= start_page_login && id < end_page_login">{{dataLogin.login_counts}}</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingLogin === true">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_login + 1}} to {{end_page_login}} of {{total_item_login}} entries</p>
                <paginate
                v-model="paginate_login"
                :page-count="total_page_login"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageLogin"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page === 'login' && isDetail == true">
            <div class="box-back" @click="isDetail = false">
              <img src="../../assets/back.svg" alt="">
              <p>Back to User Login</p>
            </div>
            <div class="box-details">
              <div class="detail-name">
                <p>{{loginDetail.name}}</p>
              </div>
              <div class="detail-email-count">
                <div class="email">
                  <p>Email : {{loginDetail.email}}</p>
                </div>
                <div class="count">
                  <p>Login counts: {{loginDetail.login_counts}}</p>
                </div>
              </div>
            </div>
            <div class="box-table-padding">
              <table v-if="isLoadingLoginDetail === false">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Time</th>
                </tr>
                <tr v-for="(dataDetailLogin,id) in allDataLoginDetail" :key="dataDetailLogin.id">
                  <td v-if="id >= start_page_loginDetail && id < end_page_loginDetail">{{id + 1}}</td>
                  <td v-if="id >= start_page_loginDetail && id < end_page_loginDetail">{{dataDetailLogin.login_date}}</td>
                  <td v-if="id >= start_page_loginDetail && id < end_page_loginDetail">{{dataDetailLogin.login_time}}</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingLoginDetail === true">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left-padding">
              <p>Showing {{start_page_loginDetail + 1}} to {{end_page_loginDetail}} of {{total_item_loginDetail}} entries</p>
                <paginate
                  v-model="paginate_loginDetail"
                  :page-count="total_page_loginDetail"
                  :page-range="3"
                  :margin-pages="1"
                  :click-handler="changePageLoginDetail"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'pagination'"
                  :page-class="'page-item'">>
                </paginate>
            </div>
          </div>

          <div class="box-page" v-if="page === 'data'">
            <div class="box-date-searching">
              <div class="card-searching">
                <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingData === false" id="userTable">
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Loyalti Program</th>
                  <th>Points</th>
                  <th>Referral Code</th>
                </tr>
                <tr v-for="(dataUser,id) in allDataUserData" :key="dataUser.id">
                  <td v-if="id >= start_page_data && id < end_page_data">{{id + 1}}</td>
                  <td v-if="id >= start_page_data && id < end_page_data">{{dataUser.name}}</td>
                  <td v-if="id >= start_page_data && id < end_page_data">{{dataUser.email}}</td>
                  <td v-if="id >= start_page_data && id < end_page_data">{{dataUser.loyalty_program}}</td>
                  <td v-if="id >= start_page_data && id < end_page_data">{{dataUser.points}}</td>
                  <td v-if="id >= start_page_data && id < end_page_data && dataUser.referral_code !== null">{{dataUser.referral_code}}</td>
                  <td v-else-if="id >= start_page_data && id < end_page_data">-</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingData === true">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_data + 1}} to {{end_page_data}} of {{total_item_data}} entries</p>
                <paginate
                v-model="paginate_data"
                :page-count="total_page_data"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageData"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue"
import TopHeader from "../../components/Header/TopHeader.vue"
import {onBeforeMount, ref, watch} from 'vue'
import Paginate from 'vuejs-paginate-next';
import { useStore } from "vuex";
import Axios from 'axios'

export default {
  name: "HistoryActivity",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    const store = useStore()
    let path = ref('Individual License')
    let subPath = ref('History Activity')
    let subSubPath = ref('Purchase')
    let page = ref('purchase')
    let datefilter = ref('')
    let isDetail = ref(false)
    let isLoadingLoginDetail = ref(true)
    let isLoadingPurchase = ref(true)
    let isLoadingTask = ref(true)
    let isLoadingRedeem = ref(true)
    let isLoadingLogin = ref(true)
    let isLoadingData = ref(true)

    let allDataPurchaseHistory = ref(null)
    let defaultDataPurchase = ref(null)
    let allDataTaskHistory = ref(null)
    let defaultDataTask = ref(null)
    let allDataRedeemPoints = ref(null)
    let defaultDataRedeem = ref(null)
    let allDataWebsiteLogin = ref(null)
    let defaultDataLogin = ref(null)
    let allDataWebsiteLoginDetail = ref(null)
    let allDataUserData = ref(null)
    let defaultDataUser = ref(null)
    let allDataLoginDetail = ref(null)
    let loginDetail = ref(null)

    const openDate=()=>{
      document.getElementById("date").classList.toggle("show");
    }

    let inputSearch = ref('')

    watch(async()=>allDataWebsiteLogin.value,(()=>{
      findDataChartLogin()
    }))

    watch(async()=>page.value,(()=>{
      if(page.value === 'purchase'){
        allDataPurchaseHistory.value = defaultDataPurchase.value
        filterData.value = allDataPurchaseHistory.value
        changePagePurchase(1)
        if(allDataPurchaseHistory.value != null){
          total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
          total_item_purchase.value = allDataPurchaseHistory.value.length
          paginate_purchase.value = 1 
        }

      }
      else if(page.value === 'task'){
        allDataTaskHistory.value = defaultDataTask.value
        filterData.value = allDataTaskHistory.value
        changePageTask(1)
        if(allDataTaskHistory.value != null){
          total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
          total_item_task.value = allDataTaskHistory.value.length
          paginate_task.value = 1
        }

      }
      else if(page.value === 'redeem'){
        allDataRedeemPoints.value = defaultDataRedeem.value
        filterData.value = allDataRedeemPoints.value
        changePagePoints(1)
        if(allDataRedeemPoints.value != null){
          total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
          total_item_points.value = allDataRedeemPoints.value.length
          paginate_points.value = 1
        }
        
      }
      else if(page.value === 'login'){
        allDataWebsiteLogin.value = defaultDataLogin.value
        filterData.value = allDataWebsiteLogin.value
        changePageLogin(1)
        if(allDataWebsiteLogin.value != null){
          total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
          total_item_login.value = allDataWebsiteLogin.value.length
          paginate_login.value = 1
        }
        
      }
      else if(page.value === 'data'){
        allDataUserData.value = defaultDataUser.value
        filterData.value = allDataUserData.value
        changePageData(1)
        if(allDataUserData.value != null){
          total_page_data.value = Math.ceil((allDataUserData.value.length / 10))
          total_item_data.value = allDataUserData.value.length
          paginate_data.value = 1
        }
        
      }
    }))

    const searchList=(input)=>{
      // datefilter.value = ''
      if(page.value === 'purchase'){
        if(filterData.value == null || filterData.value === ''){
          filterData.value = defaultDataPurchase.value
        }
        else if(inputSearch.value !== ''){
          allDataPurchaseHistory.value = filterData.value
          let filter = allDataPurchaseHistory.value.filter((val)=>{
            if(val.name.toUpperCase().includes(input.toUpperCase())){
              return val
            }
          })
          if(filter){
            allDataPurchaseHistory.value = filter
          }else{
            allDataPurchaseHistory.value = filterData.value
          }
          changePagePurchase(1)
        }else{
          allDataPurchaseHistory.value = filterData.value
        }
        // console.log(allDataPurchaseHistory)
        // console.log(defaultDataPurchase)
        total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
        total_item_purchase.value = allDataPurchaseHistory.value.length
        paginate_purchase.value = 1    
      }
      if(page.value === 'task'){
        if(filterData.value == null || filterData.value === ''){
          filterData.value = defaultDataTask.value
        }
        if(inputSearch.value !== ''){
          allDataTaskHistory.value = filterData.value
          let filter = allDataTaskHistory.value.filter((val)=>{
            if(val.name.toUpperCase().includes(input.toUpperCase())){
              return val
            }
          })
          if(filter){
            allDataTaskHistory.value = filter
          }else{
            allDataTaskHistory.value = filterData.value
          }
          changePageTask(1)
        }else{
          allDataTaskHistory.value = filterData.value
        }
        total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
        total_item_task.value = allDataTaskHistory.value.length
        paginate_task.value = 1  
      }
      if(page.value === 'redeem'){
        if(filterData.value == null || filterData.value === ''){
          filterData.value = defaultDataRedeem.value
        }
        if(inputSearch.value !== ''){
          allDataRedeemPoints.value = filterData.value
          let filter = allDataRedeemPoints.value.filter((val)=>{
            if(val.name.toUpperCase().includes(input.toUpperCase())){
              return val
            }
          })
          if(filter){
            allDataRedeemPoints.value = filter
          }else{
            allDataRedeemPoints.value = filterData.value
          }
          changePagePoints(1)
        }else{
          allDataRedeemPoints.value = filterData.value
        }
        total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
        total_item_points.value = allDataRedeemPoints.value.length
        paginate_points.value = 1
      }
      if(page.value === 'login'){
        if(filterData.value == null || filterData.value === ''){
          filterData.value = defaultDataLogin.value
        }
        if(inputSearch.value !== ''){
          allDataWebsiteLogin.value = filterData.value
          let filter = allDataWebsiteLogin.value.filter((val)=>{
            if(val.name.toUpperCase().includes(input.toUpperCase())){
              return val
            }
          })
          if(filter){
            allDataWebsiteLogin.value = filter
          }else{
            allDataWebsiteLogin.value = filterData.value
          }
          // findDataChartLogin()
          changePageLogin(1)
        }else{
          allDataWebsiteLogin.value = filterData.value
          // findDataChartLogin()
        }
        total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
        total_item_login.value = allDataWebsiteLogin.value.length
        paginate_login.value = 1
      }
      if(page.value === 'data'){
        if(inputSearch.value !== ''){
          allDataUserData.value = defaultDataUser.value
          let filter = allDataUserData.value.filter((val)=>{
            if(val.name.toUpperCase().includes(input.toUpperCase())){
              return val
            }
          })
          if(filter){
            allDataUserData.value = filter
          }else{
            allDataUserData.value = defaultDataUser.value
          }
          changePageData(1)
        }else{
          allDataUserData.value = defaultDataUser.value
        }
        total_page_data.value = Math.ceil((allDataUserData.value.length / 10))
        total_item_data.value = allDataUserData.value.length
        paginate_data.value = 1
      }
    }
    
    let startDateRange = ref('')
    let endDateRange = ref('')
    let filterData = ref(null)
    const dateFilterFunction=(params)=>{
      if(page.value == 'purchase'){
        if ((startDateRange.value != '' && endDateRange.value != '')){
          datefilter.value = ''
          allDataPurchaseHistory.value = defaultDataPurchase.value
          var formatStart =  startDateRange.value.split('-')
          var startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          var formatEnd =  endDateRange.value.split('-')
          var endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
          

          let filter = allDataPurchaseHistory.value.filter((val)=>{
            var formatDate = val.purchased_date.split(/\//)
            var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            return (date >= startDate && date <= endDate)
          })

          if(filter){
            allDataPurchaseHistory.value = filter
          }else{
            allDataPurchaseHistory.value = defaultDataPurchase.value
          }
          filterData.value = allDataPurchaseHistory.value
          changePagePurchase(1)
          total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
          total_item_purchase.value = allDataPurchaseHistory.value.length
          paginate_purchase.value = 1
          openDate()
        }
        else if(params == 'today'){
          if(datefilter.value == 'today'){
            datefilter.value = ''
            allDataPurchaseHistory.value = defaultDataPurchase.value
            filterData.value = allDataPurchaseHistory.value
            changePagePurchase(1)
            total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
            total_item_purchase.value = allDataPurchaseHistory.value.length
            paginate_purchase.value = 1 
          }else{
            datefilter.value = 'today'
            allDataPurchaseHistory.value = defaultDataPurchase.value
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            let filter = allDataPurchaseHistory.value.filter((val)=>{
              if(val.purchased_date.toUpperCase().includes(today.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataPurchaseHistory.value = filter
            }else{
              allDataPurchaseHistory.value = defaultDataPurchase.value
            }
            filterData.value = allDataPurchaseHistory.value
            changePagePurchase(1)
            total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
            total_item_purchase.value = allDataPurchaseHistory.value.length
            paginate_purchase.value = 1  
          }
          openDate()
        }
        else if(params == 'yesterday'){
          if(datefilter.value == 'yesterday'){
            datefilter.value = ''
            allDataPurchaseHistory.value = defaultDataPurchase.value
            filterData.value = allDataPurchaseHistory.value
            changePagePurchase(1)
            total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
            total_item_purchase.value = allDataPurchaseHistory.value.length
            paginate_purchase.value = 1 
          }else{
            datefilter.value = 'yesterday'
            allDataPurchaseHistory.value = defaultDataPurchase.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataPurchaseHistory.value.filter((val)=>{
              var formatDate = val.purchased_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataPurchaseHistory.value = filter
            }else{
              allDataPurchaseHistory.value = defaultDataPurchase.value
            }
            filterData.value = allDataPurchaseHistory.value
            changePagePurchase(1)
            total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
            total_item_purchase.value = allDataPurchaseHistory.value.length
            paginate_purchase.value = 1  
          }
          openDate()
        }
        else if(params == 'week'){
          if(datefilter.value == 'week'){
            datefilter.value = ''
            allDataPurchaseHistory.value = defaultDataPurchase.value
            filterData.value = allDataPurchaseHistory.value
            changePagePurchase(1)
            total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
            total_item_purchase.value = allDataPurchaseHistory.value.length
            paginate_purchase.value = 1 
          }else{
            datefilter.value = 'week'
            allDataPurchaseHistory.value = defaultDataPurchase.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataPurchaseHistory.value.filter((val)=>{
              var formatDate = val.purchased_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataPurchaseHistory.value = filter
            }else{
              allDataPurchaseHistory.value = defaultDataPurchase.value
            }
            filterData.value = allDataPurchaseHistory.value
            changePagePurchase(1)
            total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
            total_item_purchase.value = allDataPurchaseHistory.value.length
            paginate_purchase.value = 1  
          }
          openDate()
        }
        else if(params == 'month'){
          if(datefilter.value == 'month'){
            datefilter.value = ''
            allDataPurchaseHistory.value = defaultDataPurchase.value
            filterData.value = allDataPurchaseHistory.value
            changePagePurchase(1)
            total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
            total_item_purchase.value = allDataPurchaseHistory.value.length
            paginate_purchase.value = 1 
          }else{
            datefilter.value = 'month'
            allDataPurchaseHistory.value = defaultDataPurchase.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataPurchaseHistory.value.filter((val)=>{
              var formatDate = val.purchased_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataPurchaseHistory.value = filter
            }else{
              allDataPurchaseHistory.value = defaultDataPurchase.value
            }
            filterData.value = allDataPurchaseHistory.value
            changePagePurchase(1)
            total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
            total_item_purchase.value = allDataPurchaseHistory.value.length
            paginate_purchase.value = 1  
          }
          openDate()
        }
        else{
          allDataPurchaseHistory.value = defaultDataPurchase.value
          filterData.value = allDataPurchaseHistory.value
          changePagePurchase(1)
          total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
          total_item_purchase.value = allDataPurchaseHistory.value.length
          paginate_purchase.value = 1 
        }
      }
      if(page.value == 'task'){
        if((startDateRange.value != '' && endDateRange.value != '')){
          datefilter.value = ''
          allDataTaskHistory.value = defaultDataTask.value
          formatStart =  startDateRange.value.split('-')
          startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          formatEnd =  endDateRange.value.split('-')
          endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
        
          let filter = allDataTaskHistory.value.filter((val)=>{
            var formatDate = val.completed_at_date.split(/\//)
            var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            return (date >= startDate && date <= endDate)
          })

          if(filter){
            allDataTaskHistory.value = filter
          }else{
            allDataTaskHistory.value = defaultDataTask.value
          }
          filterData.value = allDataTaskHistory.value
          changePageTask(1)
          total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
          total_item_task.value = allDataTaskHistory.value.length
          paginate_task.value = 1 
          openDate()
        }
        else if(params == 'today'){
          if(datefilter.value == 'today'){
            datefilter.value = ''
            allDataTaskHistory.value = defaultDataTask.value
            filterData.value = allDataTaskHistory.value
            changePageTask(1)
            total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
            total_item_task.value = allDataTaskHistory.value.length
            paginate_task.value = 1 
          }else{
            datefilter.value = 'today'
            allDataTaskHistory.value = defaultDataTask.value
            today = new Date();
            dd = String(today.getDate()).padStart(2, '0');
            mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            let filter = allDataTaskHistory.value.filter((val)=>{
              if(val.completed_at_date.toUpperCase().includes(today.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataTaskHistory.value = filter
            }else{
              allDataTaskHistory.value = defaultDataTask.value
            }
            filterData.value = allDataTaskHistory.value
            changePageTask(1)
            total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
            total_item_task.value = allDataTaskHistory.value.length
            paginate_task.value = 1  
          }
          openDate()
        }
        else if(params == 'yesterday'){
          if(datefilter.value == 'yesterday'){
            datefilter.value = ''
            allDataTaskHistory.value = defaultDataTask.value
            filterData.value = allDataTaskHistory.value
            changePagePurchase(1)
            total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
            total_item_task.value = allDataTaskHistory.value.length
            paginate_task.value = 1 
          }else{
            datefilter.value = 'yesterday'
            allDataTaskHistory.value = defaultDataTask.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataTaskHistory.value.filter((val)=>{
              var formatDate = val.completed_at_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataTaskHistory.value = filter
            }else{
              allDataTaskHistory.value = defaultDataTask.value
            }
            filterData.value = allDataTaskHistory.value
            changePageTask(1)
            total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
            total_item_task.value = allDataTaskHistory.value.length
            paginate_task.value = 1 
          }
          openDate()
        }
        else if(params == 'week'){
          if(datefilter.value == 'week'){
            datefilter.value = ''
            allDataTaskHistory.value = defaultDataTask.value
            filterData.value = allDataTaskHistory.value
            changePageTask(1)
            total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
            total_item_task.value = allDataTaskHistory.value.length
            paginate_task.value = 1 
          }else{
            datefilter.value = 'week'
            allDataTaskHistory.value = defaultDataTask.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataTaskHistory.value.filter((val)=>{
              var formatDate = val.completed_at_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataTaskHistory.value = filter
            }else{
              allDataTaskHistory.value = defaultDataTask.value
            }
            filterData.value = allDataTaskHistory.value
            changePageTask(1)
            total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
            total_item_task.value = allDataTaskHistory.value.length
            paginate_task.value = 1 
          }
          openDate()
        }
        else if(params == 'month'){
          if(datefilter.value == 'month'){
            datefilter.value = ''
            allDataTaskHistory.value = defaultDataTask.value
            filterData.value = allDataTaskHistory.value
            changePageTask(1)
            total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
            total_item_task.value = allDataTaskHistory.value.length
            paginate_task.value = 1 
          }else{
            datefilter.value = 'month'
            allDataTaskHistory.value = defaultDataTask.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataTaskHistory.value.filter((val)=>{
              var formatDate = val.completed_at_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataTaskHistory.value = filter
            }else{
              allDataTaskHistory.value = defaultDataTask.value
            }
            filterData.value = allDataTaskHistory.value
            changePageTask(1)
            total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
            total_item_task.value = allDataTaskHistory.value.length
            paginate_task.value = 1
          }
          openDate()
        }
        else{
          allDataTaskHistory.value = defaultDataTask.value
          filterData.value = allDataTaskHistory.value
          changePageTask(1)
          total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
          total_item_task.value = allDataTaskHistory.value.length
          paginate_task.value = 1 
        }
      }
      if(page.value == 'redeem'){
        // redeem_date
        if((startDateRange.value != '' && endDateRange.value != '')){
          datefilter.value = ''
          allDataRedeemPoints.value = defaultDataRedeem.value
          formatStart =  startDateRange.value.split('-')
          startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          formatEnd =  endDateRange.value.split('-')
          endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
        
          let filter = allDataRedeemPoints.value.filter((val)=>{
            var formatDate = val.redeem_date.split(/\//)
            var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            return (date >= startDate && date <= endDate)
          })

          if(filter){
            allDataRedeemPoints.value = filter
          }else{
            allDataRedeemPoints.value = defaultDataRedeem.value
          }
          filterData.value = allDataRedeemPoints.value
          changePagePoints(1)
          total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
          total_item_points.value = allDataRedeemPoints.value.length
          paginate_points.value = 1
          openDate() 
        }
        else if(params == 'today'){
          if(datefilter.value == 'today'){
            datefilter.value = ''
            allDataRedeemPoints.value = defaultDataRedeem.value
            filterData.value = allDataRedeemPoints.value
            changePagePoints(1)
            total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
            total_item_points.value = allDataRedeemPoints.value.length
            paginate_points.value = 1 
          }else{
            datefilter.value = 'today'
            allDataRedeemPoints.value = defaultDataRedeem.value
            today = new Date();
            dd = String(today.getDate()).padStart(2, '0');
            mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;


            let filter = allDataRedeemPoints.value.filter((val)=>{
              if(val.redeem_date.toUpperCase().includes(today.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataRedeemPoints.value = filter
            }else{
              allDataRedeemPoints.value = defaultDataRedeem.value
            }
            filterData.value = defaultDataRedeem.value
            changePagePoints(1)
            total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
            total_item_points.value = allDataRedeemPoints.value.length
            paginate_points.value = 1
          }
          openDate()
        }
        else if(params == 'yesterday'){
          if(datefilter.value == 'yesterday'){
            datefilter.value = ''
            allDataRedeemPoints.value = defaultDataRedeem.value
            filterData.value = allDataRedeemPoints.value
            changePagePoints(1)
            total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
            total_item_points.value = allDataRedeemPoints.value.length
            paginate_points.value = 1
          }else{
            datefilter.value = 'yesterday'
            allDataRedeemPoints.value = defaultDataRedeem.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataRedeemPoints.value.filter((val)=>{
              var formatDate = val.redeem_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataRedeemPoints.value = filter
            }else{
              allDataRedeemPoints.value = defaultDataRedeem.value
            }
            filterData.value = allDataRedeemPoints.value
            changePagePoints(1)
            total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
            total_item_points.value = allDataRedeemPoints.value.length
            paginate_points.value = 1
          }
          openDate()
        }
        else if(params == 'week'){
          if(datefilter.value == 'week'){
            datefilter.value = ''
            allDataRedeemPoints.value = defaultDataRedeem.value
            filterData.value = allDataRedeemPoints.value
            changePagePoints(1)
            total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
            total_item_points.value = allDataRedeemPoints.value.length
            paginate_points.value = 1
          }else{
            datefilter.value = 'week'
            allDataRedeemPoints.value = defaultDataRedeem.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())


            let filter = allDataRedeemPoints.value.filter((val)=>{
              var formatDate = val.redeem_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataRedeemPoints.value = filter
            }else{
              allDataRedeemPoints.value = defaultDataRedeem.value
            }
            filterData.value = allDataRedeemPoints.value
            changePagePoints(1)
            total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
            total_item_points.value = allDataRedeemPoints.value.length
            paginate_points.value = 1
          }
          openDate()
        }
        else if(params == 'month'){
          if(datefilter.value == 'month'){
            datefilter.value = ''
            allDataRedeemPoints.value = defaultDataRedeem.value
            filterData.value = allDataRedeemPoints.value
            changePagePoints(1)
            total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
            total_item_points.value = allDataRedeemPoints.value.length
            paginate_points.value = 1
          }else{
            datefilter.value = 'month'
            allDataRedeemPoints.value = defaultDataRedeem.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())


            let filter = allDataRedeemPoints.value.filter((val)=>{
              var formatDate = val.redeem_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataRedeemPoints.value = filter
            }else{
              allDataRedeemPoints.value = defaultDataRedeem.value
            }
            filterData.value = allDataRedeemPoints.value
            changePagePoints(1)
            total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
            total_item_points.value = allDataRedeemPoints.value.length
            paginate_points.value = 1
          }
          openDate()
        }
        else{
          allDataRedeemPoints.value = defaultDataRedeem.value
          filterData.value = allDataRedeemPoints.value
          changePagePoints(1)
          total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
          total_item_points.value = allDataRedeemPoints.value.length
          paginate_points.value = 1
        }
      }
      if(page.value == 'login'){
        // last_login_date
        if((startDateRange.value != '' && endDateRange.value != '')){
          datefilter.value = ''
          allDataWebsiteLogin.value = defaultDataLogin.value
          formatStart =  startDateRange.value.split('-')
          startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          formatEnd =  endDateRange.value.split('-')
          endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
        
          let filter = allDataWebsiteLogin.value.filter((val)=>{
            var formatDate = val.last_login_date.split(/\//)
            var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            return (date >= startDate && date <= endDate)
          })

          if(filter){
            allDataWebsiteLogin.value = filter
            // findDataChartLogin()
          }else{
            allDataWebsiteLogin.value = defaultDataLogin.value
            // findDataChartLogin()
          }
          filterData.value = allDataWebsiteLogin.value
          changePageLogin(1)
          total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
          total_item_login.value = allDataWebsiteLogin.value.length
          paginate_login.value = 1 
          openDate()
        }
        else if(params == 'today'){
          if(datefilter.value == 'today'){
            datefilter.value = ''
            allDataWebsiteLogin.value = defaultDataLogin.value
            filterData.value = allDataWebsiteLogin.value
            changePageLogin(1)
            // findDataChartLogin()
            total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
            total_item_login.value = allDataWebsiteLogin.value.length
            paginate_login.value = 1 
          }else{
            datefilter.value = 'today'
            allDataWebsiteLogin.value = defaultDataLogin.value
            today = new Date();
            dd = String(today.getDate()).padStart(2, '0');
            mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;


            let filter = allDataWebsiteLogin.value.filter((val)=>{
              if(val.last_login_date.toUpperCase().includes(today.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataWebsiteLogin.value = filter
              // findDataChartLogin()
            }else{
              allDataWebsiteLogin.value = defaultDataLogin.value
              // findDataChartLogin()
            }
            filterData.value = allDataWebsiteLogin.value
            changePageLogin(1)
            total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
            total_item_login.value = allDataWebsiteLogin.value.length
            paginate_login.value = 1 
          }
          openDate()
        }
        else if(params == 'yesterday'){
          if(datefilter.value == 'yesterday'){
            datefilter.value = ''
            allDataWebsiteLogin.value = defaultDataLogin.value
            filterData.value = allDataWebsiteLogin.value
            changePageLogin(1)
            // findDataChartLogin()
            total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
            total_item_login.value = allDataWebsiteLogin.value.length
            paginate_login.value = 1 
          }else{
            datefilter.value = 'yesterday'
            allDataWebsiteLogin.value = defaultDataLogin.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataWebsiteLogin.value.filter((val)=>{
              var formatDate = val.last_login_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataWebsiteLogin.value = filter
            }else{
              allDataWebsiteLogin.value = defaultDataLogin.value
            }
            filterData.value = allDataWebsiteLogin.value
            changePageLogin(1)
            // findDataChartLogin()
            total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
            total_item_login.value = allDataWebsiteLogin.value.length
            paginate_login.value = 1 
          }
          openDate()
        }
        else if(params == 'week'){
          if(datefilter.value == 'week'){
            datefilter.value = ''
            allDataWebsiteLogin.value = defaultDataLogin.value
            filterData.value = allDataWebsiteLogin.value
            changePageLogin(1)
            // findDataChartLogin()
            total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
            total_item_login.value = allDataWebsiteLogin.value.length
            paginate_login.value = 1 
          }else{
            datefilter.value = 'week'
            allDataWebsiteLogin.value = defaultDataLogin.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataWebsiteLogin.value.filter((val)=>{
              var formatDate = val.last_login_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataWebsiteLogin.value = filter
            }else{
              allDataWebsiteLogin.value = defaultDataLogin.value
            }
            filterData.value = allDataWebsiteLogin.value
            changePageLogin(1)
            // findDataChartLogin()
            total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
            total_item_login.value = allDataWebsiteLogin.value.length
            paginate_login.value = 1 
          }
          openDate()
        }
        else if(params == 'month'){
          if(datefilter.value == 'month'){
            datefilter.value = ''
            allDataWebsiteLogin.value = defaultDataLogin.value
            filterData.value = allDataWebsiteLogin.value
            changePageLogin(1)
            // findDataChartLogin()
            total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
            total_item_login.value = allDataWebsiteLogin.value.length
            paginate_login.value = 1 
          }else{
            datefilter.value = 'month'
            allDataWebsiteLogin.value = defaultDataLogin.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataWebsiteLogin.value.filter((val)=>{
              var formatDate = val.last_login_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataWebsiteLogin.value = filter
            }else{
              allDataWebsiteLogin.value = defaultDataLogin.value
            }
            filterData.value = allDataWebsiteLogin.value
            changePageLogin(1)
            // findDataChartLogin()
            total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
            total_item_login.value = allDataWebsiteLogin.value.length
            paginate_login.value = 1 
          }
          openDate()
        }
        else{
          allDataWebsiteLogin.value = defaultDataLogin.value
          filterData.value = allDataWebsiteLogin.value
          changePageLogin(1)
          // findDataChartLogin()
          total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
          total_item_login.value = allDataWebsiteLogin.value.length
          paginate_login.value = 1 
        }
      }
    }

    const export_to_csv=()=>{
      if(page.value === 'purchase'){
        let arrayToPdf = []
        var rows = document.querySelectorAll('#purchaseTable tr')
        let row = []

        rows[0].querySelectorAll('td,th').forEach((val)=>{
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataPurchaseHistory.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.purchased_date},${val.expired_date},${val.name.replaceAll(',', '')},${val.email},${val.phone},${val.referral_code}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if(page.value === 'task'){
        let arrayToPdf = []
        rows = document.querySelectorAll('#taskTable tr')
        let row = []
        rows[0].querySelectorAll('td,th').forEach((val)=>{
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataTaskHistory.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.completed_at_date},${val.name.replaceAll(',', '')},${val.task},${val.point}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if(page.value === 'redeem'){
        let arrayToPdf = []
        rows = document.querySelectorAll('#redeemTable tr')
        let row = []
        rows[0].querySelectorAll('td,th').forEach((val)=>{
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataRedeemPoints.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.redeem_date},${val.name.replaceAll(',', '')},${val.voucher},${val.point}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if(page.value === 'login'){
        let arrayToPdf = []
        rows = document.querySelectorAll('#loginTable tr')
        let row = []
        rows[0].querySelectorAll('td,th').forEach((val)=>{
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataWebsiteLogin.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.last_login_date},${val.last_login_time},${val.name.replaceAll(',', '')},${val.email},${val.login_counts}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if(page.value === 'data'){
        let arrayToPdf = []
        rows = document.querySelectorAll('#userTable tr')
        let row = []
        rows[0].querySelectorAll('td,th').forEach((val)=>{
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataUserData.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.name.replaceAll(',', '')},${val.email},${val.loyalty_program},${val.points},${val.referral_code}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
    }

    function download_csv(csv, filename){
      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], {type: "text/csv"});
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }

    let seriesLogin = ref([])

    let optionsLogin=ref(
    {
        width:'50%',
        chart:{
        type:'bar',
        },plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '70%',
          endingShape: 'rounded'
        },
        },dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Date'
          }
        },
        yaxis: {
          title: {
            text: 'Login Counts'
          }
        },
        fill: {
          opacity: 1 ,
        }, 
        // tooltip: {
        //   y: {
        //     formatter: function (val) {
        //     return "" + val + " second"
        //     }
        //   }
        // }
      }
    )
    
    let idChartLogin = ref([])
    let dataChartLogin= ref()
    
    const isDateInArray=(needle, haystack)=>{
      for (var i = 0; i < haystack.length; i++) {
        if (needle === haystack[i]) {
          return true;
        }
      }
      return false;
    }
    const findDataChartLogin=async()=>{
      let dates = ref(null)
      let uid = ref(null)
      let uniqueDates = ref(null)
      uniqueDates.value = []
      dates.value = []
      uid.value = []
      const rawDates = []

      for(let i = 0; i < allDataWebsiteLogin.value.length; i++){
        uid.value.push(allDataWebsiteLogin.value[i].user_uid)
      }
      const promise = []

      let token = localStorage.getItem('idToken')
      for(let i = 0; i < uid.value.length; i++){
        promise.push(
          Axios.post('https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseWebsiteLogin',{
            "user_uid":uid.value[i]
          },{
        headers:{
          Authorization: 'Bearer ' + token,
        }}
        ).catch((err)=>{
        if(err.message === 'Request failed with status code 401'){
            localStorage.removeItem('idStorage')
            window.location.href="/login"
        }
      }))
      }

      const resolve = await Promise.all(promise)
      for(let i = 0; i < resolve.length; i++){
        for(let j = 0; j < resolve[i].data.details.length; j++){
          // dates.value.push(resolve[i].data.details[j].login_date)
          rawDates.push({
            login_date: resolve[i].data.details[j].login_date,
            login_datetime_unix: resolve[i].data.details[j].login_datetime_unix,
          })
        }
      }

      //sort
      // rawDates.sort((a,b)=>b.login_datetime_unix - a.login_datetime_unix)

      dates.value = rawDates.map((date)=>date.login_date)

      for (var j = 0; j < dates.value.length; j++) {
        if(dates.value[j] == null || dates.value[j] == ''){
          break
        }else if(!isDateInArray(dates.value[j], uniqueDates.value)){
          uniqueDates.value.push(dates.value[j]);
        }
      }

      idChartLogin.value = uniqueDates.value
      dataChartLogin.value = []

      var count = 0

      for (var k = 0; k < uniqueDates.value.length; k++){
        count = 0
        for(var l = 0; l < dates.value.length; l++){
          if(uniqueDates.value[k] === dates.value[l]){
            count = count + 1
            dataChartLogin.value[k] = count
          }
        }
      }
      pushChartDataUserLogin()
    }

    const pushChartDataUserLogin=()=>{
      let tempId = ref([])
      let tempData = ref([])
      let add = 0
      for(let i = start_item_chart_login.value; i < max_item_chart_login.value; i++){
        if(idChartLogin.value[i] == null || idChartLogin.value[i] == ''){
          break
        }else{
          tempId.value[add] = idChartLogin.value[i]
          tempData.value[add] = dataChartLogin.value[i]
          add++
        }
      }
      total_chart_page_login.value = Math.ceil((idChartLogin.value.length / 10))
      total_chart_item_login.value = idChartLogin.value.length

      seriesLogin.value = [{
        name: 'Date',
        data: tempData,
        color:'#004F98'
      }]
      optionsLogin.value = {
        xaxis: {
          labels: {
            format: 'dd/MM',
          },
          categories: tempId,
          title:{
            text: 'Date'
          }
        },
        yaxis: {
          title: {
            text: 'Activity Count'
          }
        }
      }
    }

    //Purchase State
    let start_page_purchase = ref(0)
    let end_page_purchase = ref(10)
    let total_item_purchase = ref (0)
    let total_page_purchase = ref(0)
    let paginate_purchase = ref(1)

    const changePagePurchase=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_purchase.value =  new_start
      end_page_purchase.value = new_end
    }

    //Task State
    let start_page_task = ref(0)
    let end_page_task = ref(10)
    let total_item_task = ref (0)
    let total_page_task = ref(0)
    let paginate_task = ref(1)

    const changePageTask=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_task.value =  new_start
      end_page_task.value = new_end
    }

    //Points State
    let start_page_points = ref(0)
    let end_page_points = ref(10)
    let total_item_points = ref (0)
    let total_page_points = ref(0)
    let paginate_points = ref(1)

    const changePagePoints=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_points.value =  new_start
      end_page_points.value = new_end
    }

    //Login State
    let start_page_login = ref(0)
    let end_page_login= ref(10)
    let total_item_login = ref (0)
    let total_page_login = ref(0)
    let paginate_login = ref(1)
    let max_item_per_page_login= ref(10)
    let start_item_login = ref(0)

    const changePageLogin=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_login.value =  new_start
      end_page_login.value = new_end
      max_item_per_page_login.value = pageNum * 10
      start_item_login.value = max_item_per_page_login.value - 10
    }

    let start_chart_login = ref(0)
    let end_chart_login = ref(10)
    let total_chart_item_login = ref(0)
    let total_chart_page_login = ref(0)
    let paginate_chart_login = ref(1)
    let max_item_chart_login= ref(10)
    let start_item_chart_login = ref(0)

    const changeChartLogin=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_chart_login.value =  new_start
      end_chart_login.value = new_end
      total_chart_page_login.value = Math.ceil((idChartLogin.value.length / 10))
      total_chart_item_login.value = idChartLogin.value.length
      max_item_chart_login.value = pageNum * 10
      start_item_chart_login.value = max_item_chart_login.value - 10
      pushChartDataUserLogin()
    }

    //Login Detail State
    let start_page_loginDetail = ref(0)
    let end_page_loginDetail= ref(10)
    let total_item_loginDetail = ref (0)
    let total_page_loginDetail = ref(0)
    let paginate_loginDetail = ref(1)

    const changePageLoginDetail=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_loginDetail.value =  new_start
      end_page_loginDetail.value = new_end
    }

    //Data State
    let start_page_data = ref(0)
    let end_page_data = ref(10)
    let total_item_data = ref (0)
    let total_page_data = ref(0)
    let paginate_data = ref(1)

    const changePageData=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_data.value =  new_start
      end_page_data.value = new_end
    }

    const fetchPurchaseHistory=async()=>{
      isLoadingPurchase.value = true
      await store.dispatch('getValuePurchaseHistory')
      let temp
      temp = await store.getters.getAllPurchaseHistory
      allDataPurchaseHistory.value = temp
      defaultDataPurchase.value = temp
      total_page_purchase.value = Math.ceil((allDataPurchaseHistory.value.length / 10))
      total_item_purchase.value = allDataPurchaseHistory.value.length
      isLoadingPurchase.value = false
    }
    const fetchTaskHistory=async()=>{
      isLoadingTask.value = true
      await store.dispatch('getValueTaskHistory')
      let temp = await store.getters.getAllTaskHistory
      allDataTaskHistory.value = temp
      defaultDataTask.value = temp
      total_page_task.value = Math.ceil((allDataTaskHistory.value.length / 10))
      total_item_task.value = allDataTaskHistory.value.length
      isLoadingTask.value = false
    }
    const fetchRedeemPoints=async()=>{
      isLoadingRedeem.value = true
      await store.dispatch('getValueRedeemPoints')
      let temp = await store.getters.getAllRedeemPoints
      allDataRedeemPoints.value = temp
      defaultDataRedeem.value = temp
      total_page_points.value = Math.ceil((allDataRedeemPoints.value.length / 10))
      total_item_points.value = allDataRedeemPoints.value.length
      isLoadingRedeem.value = false
    }
    const fetchWebsiteLogin=async()=>{
      isLoadingLogin.value = true
      await store.dispatch('getValueWebsiteLogin')
      let temp = await store.getters.getAllWebsiteLogin
      allDataWebsiteLogin.value = temp
      allDataWebsiteLoginDetail.value = temp
      defaultDataLogin.value = temp
      total_page_login.value = Math.ceil((allDataWebsiteLogin.value.length / 10))
      total_item_login.value = allDataWebsiteLogin.value.length
      isLoadingLogin.value = false
      // findDataChartLogin()
    }
    const fetchUserData=async()=>{
      isLoadingData.value = true
      await store.dispatch('getValueUserData')
      let temp = await store.getters.getAllUserData
      defaultDataUser.value = temp
      allDataUserData.value = temp
      total_page_data.value = Math.ceil((allDataUserData.value.length / 10))
      total_item_data.value = allDataUserData.value.length
      isLoadingData.value = false
    }

    const fetchWebsiteLoginDetail=(id)=>{
      isLoadingLoginDetail.value = true
      isDetail.value = true
      loginDetail.value = allDataWebsiteLoginDetail.value[id]
      let token = localStorage.getItem('idToken')
      let uid = loginDetail.value.user_uid
      Axios.post('https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseWebsiteLogin',{
        "user_uid":uid
      },{
      headers:{
            Authorization: 'Bearer ' + token,
      }}
      ).then((res)=>{
        allDataLoginDetail.value = res.data
        allDataLoginDetail.value = allDataLoginDetail.value.details

        total_page_loginDetail.value = Math.ceil((allDataLoginDetail.value.length / 10))
        total_item_loginDetail.value = allDataLoginDetail.value.length
      }).catch((err)=>{
        allDataLoginDetail.value = []
        if(err.message === 'Request failed with status code 401'){
            localStorage.removeItem('idStorage')
            window.location.href="/login"
        }
      })
      isLoadingLoginDetail.value = false
    }
    onBeforeMount(()=>{
      fetchPurchaseHistory()
      fetchTaskHistory()
      fetchRedeemPoints()
      fetchWebsiteLogin()
      fetchUserData()
    })
    
    return {
      path,
      subPath,
      subSubPath,
      page,
      datefilter,
      isDetail,
      isLoadingLoginDetail,
      isLoadingPurchase,
      isLoadingTask,
      isLoadingRedeem,
      isLoadingLogin,
      isLoadingData,
      allDataPurchaseHistory,
      defaultDataPurchase,
      allDataTaskHistory,
      defaultDataTask,
      allDataRedeemPoints,
      defaultDataRedeem,
      allDataWebsiteLogin,
      allDataWebsiteLoginDetail,
      defaultDataLogin,
      allDataUserData,
      defaultDataUser,
      allDataLoginDetail,
      loginDetail,
      openDate,
      inputSearch,
      searchList,
      startDateRange,
      endDateRange,
      filterData,
      dateFilterFunction,
      export_to_csv,
      download_csv,
      seriesLogin,
      optionsLogin,
      idChartLogin,
      dataChartLogin,
      isDateInArray,
      findDataChartLogin,
      start_page_purchase,
      end_page_purchase,
      total_item_purchase,
      total_page_purchase,
      paginate_purchase,
      changePagePurchase,
      start_page_task,
      end_page_task,
      total_item_task,
      total_page_task,
      paginate_task,
      changePageTask,
      start_page_points,
      end_page_points,
      total_item_points,
      total_page_points,
      paginate_points,
      changePagePoints,
      start_page_login,
      end_page_login,
      total_item_login,
      total_page_login,
      paginate_login,
      max_item_per_page_login,
      start_item_login,
      changePageLogin,
      start_chart_login,
      end_chart_login,
      total_chart_item_login,
      total_chart_page_login,
      paginate_chart_login,
      max_item_chart_login,
      start_item_chart_login,
      changeChartLogin,
      pushChartDataUserLogin,
      start_page_loginDetail,
      end_page_loginDetail,
      total_item_loginDetail,
      total_page_loginDetail,
      paginate_loginDetail,
      changePageLoginDetail,
      start_page_data,
      end_page_data,
      total_item_data,
      total_page_data,
      paginate_data,
      changePageData,
      fetchWebsiteLoginDetail,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/IndividualLicense/HistoryActivity.scss";
</style>
