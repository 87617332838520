<template>
  <!-- PopUp Submit Register User-->
  <div id="popup" class="box-popup">
    <div class="popup-content">
      <div class="popup-left">
        <img src="../../assets/succeed-popup.svg" alt="" />
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <div id="popupFailed" class="box-popup">
    <div class="popup-failed">
      <div class="popup-left">
        <!-- <img src="../../assets/succeed-popup.svg" alt=""> -->
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <!-- Modal Add Institution -->
  <b-modal id="modalAddInstitution" title="Add Institution">
    <div class="box-input-text">
      <p>New Institution Name</p>
      <input v-model="addInstitutionName" type="text" name="" id="" />
    </div>
    <div class="box-input-dropdown">
      <p>Type</p>
      <select v-model="addInstitutionStatus" name="" id="">
        <option value="Free">Free</option>
        <option value="Premium">Premium</option>
        <option value="Premium+">Premium+</option>
        <option value="Premium Unlimited">Premium Unlimited</option>
      </select>
    </div>
    <div
      class="box-input-dropdown"
      v-if="
        addInstitutionStatus === 'Premium' ||
        addInstitutionStatus === 'Premium+' ||
        addInstitutionStatus === 'Premium Unlimited'
      "
    >
      <p>License End Date</p>
      <input v-model="addInstitutionDate" type="date" id="dateValue" />
    </div>
    <div class="box-input-dropdown">
      <p>Max User</p>
      <input
        v-model="addInstitutionMax"
        type="number"
        min="-1"
        id="maxStudent"
      />
      <p class="desc">*setup value to -1 to make it unlimited</p>
    </div>
    <!-- <div class="box-input-logo">
      <p>New Institution Logo (.jpg/.jpeg/.png)</p>
      <div class="input-logo"></div>
      <input
        @change="addInstitutionPicture"
        id="input=pictureAdd"
        type="file"
        accept=".jpg,.jpeg,.png"
      />
      <label for="input=pictureAdd" class="add-logo">
        <img src="../../assets/logo-change.svg" alt="" />
        <p>Add Logo</p>
      </label>
      <p class="file-name">File name: {{ pictureName }}</p>
    </div> -->
    <div class="box-input-logo">
      <div class="title">
        <p>New Institution Logo (.jpg/.jpeg/.png)</p>
      </div>
      <!-- <div class="input-logo"></div> -->
      <input
        @change="addInstitutionPicture"
        id="input=pictureAdd"
        type="file"
        accept=".jpg,.jpeg,.png"
      />
      <label for="input=pictureAdd" class="add-logo">
        <img v-if="previewImg" :src="previewImg" />
        <div v-else class="else">
          <img src="../../assets/logo-change.svg" alt="" />
          <p>Add Logo</p>
        </div>
      </label>
      <p class="file-name">File name: {{ pictureName }}</p>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal" @click="clearData()">
        <p>CANCEL</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="addInstitution()"
      >
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>
  <!-- Modal Edit Institution -->
  <b-modal id="modalEditInstitution" title="Edit Institution">
    <div class="box-input-text">
      <p>Institution Name</p>
      <input v-model="newInstitutionName" type="text" name="" id="" />
    </div>
    <div class="box-input-dropdown">
      <p>Type</p>
      <select v-model="newInstitutionStatus" name="" id="">
        <option value="Free">Free</option>
        <option value="Premium">Premium</option>
        <option value="Premium+">Premium+</option>
        <option value="Premium Unlimited">Premium Unlimited</option>
      </select>
    </div>
    <div class="box-input-dropdown">
      <p>License End Date</p>
      <input v-model="newInstitutionDate" type="date" id="dateValue" />
    </div>
    <div class="box-input-dropdown">
      <p>Max User</p>
      <input
        v-model="newInstitutionMax"
        type="number"
        min="-1"
        id="maxStudent"
      />
      <p class="desc">*setup value to -1 to make it unlimited</p>
    </div>
    <!-- <div class="box-input-logo">
      <p>New Institution Logo (.jpg/.jpeg/.png)</p>
      <div class="input-logo"></div>
      <input
        @change="editInstitutionPicture"
        id="input=pictureEdit"
        type="file"
        accept=".jpg,.jpeg,.png"
      />
      <label for="input=pictureEdit" class="add-logo">
        <img src="../../assets/logo-change.svg" alt="" />
        <p>Add Logo</p>
      </label>
      <p class="file-name">File name: {{ pictureEditName }}</p>
    </div> -->

    <div class="box-input-logo">
      <div class="title">
        <p>New Institution Logo (.jpg/.jpeg/.png)</p>
      </div>
      <!-- <div class="input-logo"></div> -->
      <input
        @change="editInstitutionPicture"
        id="input=pictureEdit"
        type="file"
        accept=".jpg,.jpeg,.png"
      />
      <label for="input=pictureEdit" class="add-logo">
        <img v-if="previewImg" :src="previewImg" />
        <div v-else class="else">
          <img src="../../assets/logo-change.svg" alt="" />
          <p>Add Logo</p>
        </div>
      </label>
      <p class="file-name">File name: {{ pictureEditName }}</p>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal" @click="clearDataEdit()">
        <p>CANCEL</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="editInstitution()"
      >
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>
  <!-- Modal Delete Institution -->
  <b-modal id="modalDeleteInstitution" title="Delete Institution">
    <div class="box-delete">
      <p>Are you sure you want to delete</p>
      <p>{{ deleteInstitutionName }}</p>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal">
        <p>CANCEL</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="deleteInsitution()"
      >
        <p>DELETE</p>
      </div>
    </div>
  </b-modal>
  <!-- Modal Add User -->
  <b-modal id="modalAddUser" title="Add User">
    <div class="box-detail">
      <p class="title">Institution ID:</p>
      <p class="desc">{{ valueInstitutionUid }}</p>
    </div>
    <div class="box-detail">
      <p class="title">Institution Name:</p>
      <p class="desc">{{ valueInstitutionName }}</p>
    </div>
    <div class="box-input-text">
      <p>Please enter the email of the user you want to add</p>
      <input v-model="addUserEmail" type="text" name="" id="" />
    </div>
    <div class="new-footer">
      <div
        class="btn-cancel"
        data-bs-dismiss="modal"
        @click="addUserEmail = null"
      >
        <p>CANCEL</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="addNewUser()"
      >
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>
  <!-- Modal Change Status -->
  <b-modal id="modalChangeStatus" title="Change Status">
    <div class="box-detail">
      <p class="title">Institution ID:</p>
      <p class="desc">{{ changeInstitutionUid }}</p>
    </div>
    <div class="box-detail">
      <p class="title">Name:</p>
      <p class="desc">{{ changeUserName }}</p>
    </div>
    <div class="box-detail">
      <p class="title">Email:</p>
      <p class="desc">{{ changeUserEmail }}</p>
    </div>
    <div class="box-input-dropdown">
      <p>Status</p>
      <select v-model="changeStatus" name="" id="">
        <option value="student">Student</option>
        <option value="teacher">Teacher</option>
      </select>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal">
        <p>CANCEL</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="changeUserStatus()"
      >
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>
  <!-- Modal Delete User -->
  <b-modal id="modalDeleteUser" title="Delete User">
    <div class="box-delete">
      <p>Are you sure you want to delete</p>
      <p>{{ deleteUserName }}</p>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal">
        <p>CANCEL</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="deleteUser()"
      >
        <p>DELETE</p>
      </div>
    </div>
  </b-modal>
  <div class="container-registerUser">
    <SideHeader />
    <div class="container-right-registerUser">
      <TopHeader
        :pathPage="path"
        :pathSubPage="subPath"
        :pathSubSubPage="subSubPath"
      />
      <div class="main-registerUser">
        <div class="box-header-registerUser">
          <div class="box-navigation">
            <div class="navigation active">Register User</div>
          </div>
          <div class="box-header-right">
            <div
              v-if="isDetail == false"
              @click="export_to_csv()"
              class="btn-export"
            >
              <img src="../../assets/log-in.svg" alt="" />
              <p>Export</p>
            </div>
          </div>
        </div>
        <div class="box-main-registerUser">
          <div class="box-page" v-if="isDetail == false">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="box-dropdown">
                  <ul>
                    <li class="dropdown">
                      <div class="filter">
                        <img src="../../assets/filter.svg" alt="" />
                        <p>Filter</p>
                      </div>
                      <ul>
                        <li>
                          <div class="box-submenu">
                            <p>Type</p>
                            <img src="../../assets/arrowleft.svg" alt="" />
                          </div>
                          <ul>
                            <li @click="filterFunction('free')">
                              <p>Free</p>
                            </li>
                            <li @click="filterFunction('premium')">
                              <p>Premium</p>
                            </li>
                            <li @click="filterFunction('premium+')">
                              <p>Premium+</p>
                            </li>
                            <li @click="filterFunction('premium unlimited')">
                              <p>Premium Unlimited</p>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div class="box-submenu">
                            <p>Status</p>
                            <img src="../../assets/arrowleft.svg" alt="" />
                          </div>
                          <ul>
                            <li @click="filterFunction('active')">
                              <p>Active</p>
                            </li>
                            <li @click="filterFunction('expired')">
                              <p>Expired</p>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div
                            class="box-submenu"
                            @click="filterFunction('oldest')"
                          >
                            <p>Expired Date: Oldest</p>
                          </div>
                        </li>
                        <li>
                          <div
                            class="box-submenu"
                            @click="filterFunction('newest')"
                          >
                            <p>Expired Date: Newest</p>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <!-- <div class="card-date">
                                    <div class="date-title" @click="openDate()">
                                        <img src="../../assets/calendar.svg" alt="">
                                        <p>Date</p>
                                    </div>
                                    <div id="date" class="dropdown-date">
                                        <div class="card-start-end">
                                            <input type="date">
                                            <p>to</p>
                                            <input type="date">
                                        </div>
                                        <div class="separator"></div>
                                        <div class="box-date-filter">
                                            <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                                            <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                                            <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                                            <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                                        </div>
                                    </div>
                                </div> -->
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
              <div class="date-searching-right">
                <b-button
                  v-b-modal.modalAddInstitution
                  cancel-disabled="true"
                  class="btn-add"
                >
                  <p>+ Add Intitution</p>
                </b-button>
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingUser === false" id="institutionTable">
                <tr>
                  <th>No</th>
                  <th>Institution ID</th>
                  <th>Institution Name</th>
                  <th>Institution Code</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Expired Date</th>
                  <th>Max User</th>
                  <th>Action</th>
                </tr>
                <tr
                  v-for="(dataRegisterUser, id) in allDataRegisterUser"
                  :key="dataRegisterUser.id"
                  class="hover-row"
                >
                  <td
                    v-if="
                      id >= start_page_registerUser &&
                      id < end_page_registerUser
                    "
                    @click="fetchDetailUser(id)"
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_registerUser &&
                      id < end_page_registerUser
                    "
                    @click="fetchDetailUser(id)"
                  >
                    {{ dataRegisterUser.institution_uid ?? "" }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_registerUser &&
                      id < end_page_registerUser
                    "
                    @click="fetchDetailUser(id)"
                  >
                    {{ dataRegisterUser.name }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_registerUser &&
                      id < end_page_registerUser
                    "
                    @click="fetchDetailUser(id)"
                  >
                    {{ dataRegisterUser.code }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_registerUser &&
                      id < end_page_registerUser
                    "
                    @click="fetchDetailUser(id)"
                  >
                    {{ dataRegisterUser.license_type }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_registerUser &&
                      id < end_page_registerUser
                    "
                    @click="fetchDetailUser(id)"
                  >
                    <div
                      v-if="dataRegisterUser.license_status === 'Active'"
                      class="data-active"
                    >
                      <p>Active</p>
                    </div>
                    <div v-else class="expired">
                      <p>Expired</p>
                    </div>
                  </td>
                  <td
                    v-if="
                      id >= start_page_registerUser &&
                      id < end_page_registerUser
                    "
                    @click="fetchDetailUser(id)"
                  >
                    {{ dataRegisterUser.license_end ?? "-" }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_registerUser &&
                      id < end_page_registerUser &&
                      dataRegisterUser.registration_limit != -1
                    "
                    @click="fetchDetailUser(id)"
                  >
                    {{ dataRegisterUser.registration_limit }}
                  </td>
                  <td
                    v-else-if="
                      id >= start_page_registerUser &&
                      id < end_page_registerUser &&
                      dataRegisterUser.registration_limit == -1
                    "
                    @click="fetchDetailUser(id)"
                  >
                    unlimited
                  </td>
                  <td
                    v-if="
                      id >= start_page_registerUser &&
                      id < end_page_registerUser
                    "
                  >
                    <div class="box-action">
                      <b-button
                        v-b-modal.modalEditInstitution
                        cancel-disabled="true"
                        class="btn-change"
                        @click="getIdEditInstitution(id)"
                      >
                        <p>Edit Institution</p>
                      </b-button>
                      <b-button
                        v-b-modal.modalDeleteInstitution
                        cancel-disabled="true"
                        class="btn-delete"
                        @click="getIdDeleteInstitution(id)"
                      >
                        <p>Delete</p>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="empty-card" v-else-if="isLoadingUser === true">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_registerUser + 1 }} to
                {{ end_page_registerUser }} of
                {{ total_item_registerUser }} entries
              </p>
              <paginate
                v-model="paginate_registerUser"
                :page-count="total_page_registerUser"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageRegisterUser"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
          <div class="box-page" v-if="isDetail == true">
            <div
              class="box-back"
              @click="(isDetail = false), (inputSearch = '')"
            >
              <img src="../../assets/back.svg" alt="" />
              <p>Back to Register User</p>
            </div>
            <div class="box-details">
              <div class="detail-name">
                <p>{{ userDetail.name }}</p>
              </div>
              <div class="box-detail-desc">
                <div class="detail-data">
                  <div class="title">
                    <p>Institution ID:</p>
                  </div>
                  <div class="description">
                    <p>{{ userDetail.institution_uid }}</p>
                  </div>
                </div>
                <div class="detail-data">
                  <div class="title">
                    <p>Institution Code:</p>
                  </div>
                  <div class="description">
                    <p>{{ userDetail.code }}</p>
                  </div>
                </div>
                <div class="detail-data">
                  <div class="title">
                    <p>Type:</p>
                  </div>
                  <div class="description">
                    <p>{{ userDetail.license_type }}</p>
                  </div>
                </div>
                <div class="detail-data">
                  <div class="title">
                    <p>Max User:</p>
                  </div>
                  <div
                    v-if="userDetail.registration_limit != -1"
                    class="description"
                  >
                    <p>{{ userDetail.registration_limit }}</p>
                  </div>
                  <div v-else class="description">
                    <p>unlimited</p>
                  </div>
                </div>
                <div class="detail-data">
                  <div class="title">
                    <p>Status:</p>
                  </div>
                  <div class="description">
                    <div
                      v-if="userDetail.license_status === 'Active'"
                      class="data-active"
                    >
                      <p>Active</p>
                    </div>
                    <div v-else class="data-active expired">
                      <p>Expired</p>
                    </div>
                  </div>
                </div>
                <div class="detail-data">
                  <div class="title">
                    <p>Expired Date:</p>
                  </div>
                  <div class="description">
                    <p>{{ userDetail.license_end }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="separator"></div>
            <div class="box-title">
              <p>Users</p>
            </div>
            <div class="box-date-searching-left">
              <div class="date-searching-left">
                <div class="box-dropdown">
                  <ul>
                    <li class="dropdown">
                      <div class="filter">
                        <img src="../../assets/filter.svg" alt="" />
                        <p>Filter</p>
                      </div>
                      <ul>
                        <li>
                          <div class="box-submenu">
                            <p>Status</p>
                            <img src="../../assets/arrowleft.svg" alt="" />
                          </div>
                          <ul>
                            <li @click="filterFunction('teacher')">
                              <p>Teacher</p>
                            </li>
                            <li @click="filterFunction('student')">
                              <p>Student</p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
              <div class="date-searching-right">
                <b-button
                  v-b-modal.modalAddUser
                  cancel-disabled="true"
                  class="btn-add"
                >
                  <p>+ Add User</p>
                </b-button>
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingUserDetail === false">
                <tr>
                  <th>No</th>
                  <th>User ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                <tr
                  v-for="(dataUserDetail, id) in allDataUserDetail"
                  :key="dataUserDetail.id"
                >
                  <td
                    v-if="
                      id >= start_page_detailUser && id < end_page_detailUser
                    "
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_detailUser && id < end_page_detailUser
                    "
                  >
                    {{ dataUserDetail.id }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_detailUser && id < end_page_detailUser
                    "
                  >
                    {{ dataUserDetail.name }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_detailUser && id < end_page_detailUser
                    "
                  >
                    {{ dataUserDetail.email }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_detailUser && id < end_page_detailUser
                    "
                  >
                    {{ dataUserDetail.status }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_detailUser && id < end_page_detailUser
                    "
                  >
                    <div class="box-action">
                      <b-button
                        v-b-modal.modalChangeStatus
                        cancel-disabled="true"
                        class="btn-change"
                        @click="getUserId(id)"
                      >
                        <p>Change Status</p>
                      </b-button>
                      <b-button
                        v-b-modal.modalDeleteUser
                        cancel-disabled="true"
                        class="btn-delete"
                        @click="getDeleteUserId(id)"
                      >
                        <p>Delete</p>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="empty-card" v-else-if="isLoadingUserDetail === true">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_detailUser + 1 }} to
                {{ end_page_detailUser }} of {{ total_item_detailUser }} entries
              </p>
              <paginate
                v-model="paginate_detailUser"
                :page-count="total_page_detailUser"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageDetailUser"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue";
import TopHeader from "../../components/Header/TopHeader.vue";
import { onBeforeMount, ref, watch } from "vue";
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";
import Axios from "axios";

export default {
  name: "RegisterUser",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    const store = useStore();
    let path = ref("Tools");
    let subPath = ref("Register User");
    let subSubPath = ref();
    let isDetail = ref(false);
    let datefilter = ref("");
    let isLoadingUser = ref(true);
    let isLoadingUserDetail = ref(true);
    let previewImg = ref(null);

    let allDataRegisterUser = ref(null);
    let defaultDataRegistUser = ref(null);
    let allDataUserDetail = ref(null);
    let defaultDataUserDetail = ref(null);
    let userDetail = ref(null);

    let inputSearch = ref("");

    watch(
      async () => isDetail.value,
      () => {
        if (isDetail.value == false) {
          allDataRegisterUser.value = defaultDataRegistUser.value;
          filterData.value = allDataRegisterUser.value;
          changePageRegisterUser(1);
          if (allDataRegisterUser.value != null) {
            total_page_registerUser.value = Math.ceil(
              allDataRegisterUser.value.length / 10
            );
            total_item_registerUser.value = allDataRegisterUser.value.length;
            paginate_registerUser.value = 1;
          }
        } else {
          allDataUserDetail.value = defaultDataUserDetail.value;
          filterData.value = allDataUserDetail.value;
          changePageDetailUser(1);
          if (allDataUserDetail.value != null) {
            total_page_detailUser.value = Math.ceil(
              allDataUserDetail.value.length / 10
            );
            total_item_detailUser.value = allDataUserDetail.value.length;
            paginate_detailUser.value = 1;
          }
        }
      }
    );
    let filterData = ref(null);
    const searchList = () => {
      // console.log(inputSearch)
      if (isDetail.value == false) {
        if (inputSearch.value !== "") {
          allDataRegisterUser.value = defaultDataRegistUser.value;
          let filter = allDataRegisterUser.value.filter((val) => {
            if (
              val.name.toUpperCase().includes(inputSearch.value.toUpperCase())
            ) {
              return val;
            }
          });
          if (filter) {
            allDataRegisterUser.value = filter;
          } else {
            allDataRegisterUser.value = defaultDataRegistUser.value;
          }
          changePageRegisterUser(1);
        } else {
          allDataRegisterUser.value = defaultDataRegistUser.value;
        }
        filterData.value = allDataRegisterUser.value;
        total_page_registerUser.value = Math.ceil(
          allDataRegisterUser.value.length / 10
        );
        total_item_registerUser.value = allDataRegisterUser.value.length;
        paginate_registerUser.value = 1;
      } else if (isDetail.value == true) {
        if (inputSearch.value !== "") {
          allDataUserDetail.value = defaultDataUserDetail.value;
          let filter = allDataUserDetail.value.filter((val) => {
            if (
              val.name.toUpperCase().includes(inputSearch.value.toUpperCase())
            ) {
              return val;
            }
          });
          if (filter) {
            allDataUserDetail.value = filter;
          } else {
            allDataUserDetail.value = defaultDataUserDetail.value;
          }
          changePageDetailUser(1);
          // tesst
        } else {
          allDataUserDetail.value = defaultDataUserDetail.value;
        }
        filterData.value = allDataUserDetail.value;
        total_page_detailUser.value = Math.ceil(
          allDataUserDetail.value.length / 10
        );
        total_item_detailUser.value = allDataUserDetail.value.length;
        paginate_detailUser.value = 1;
      }
    };

    const filterFunction = (params) => {
      if (
        params === "free" ||
        params === "premium" ||
        params === "premium+" ||
        params === "premium unlimited"
      ) {
        if (filterData.value == null || filterData.value === "") {
          filterData.value = defaultDataRegistUser.value;
        }
        allDataRegisterUser.value = filterData.value;
        let filter = allDataRegisterUser.value.filter((val) => {
          if (val.license_type.toUpperCase() === params.toUpperCase()) {
            return val;
          }
        });
        if (filter) {
          allDataRegisterUser.value = filter;
        } else {
          allDataRegisterUser.value = defaultDataRegistUser.value;
        }
        changePageRegisterUser(1);
        total_page_registerUser.value = Math.ceil(
          allDataRegisterUser.value.length / 10
        );
        total_item_registerUser.value = allDataRegisterUser.value.length;
        paginate_registerUser.value = 1;
      } else if (params === "active" || params === "expired") {
        if (filterData.value == null || filterData.value === "") {
          filterData.value = defaultDataRegistUser.value;
        }
        allDataRegisterUser.value = filterData.value;
        let filter = allDataRegisterUser.value.filter((val) => {
          if (val.license_status.toUpperCase().includes(params.toUpperCase())) {
            return val;
          }
        });
        if (filter) {
          allDataRegisterUser.value = filter;
        } else {
          allDataRegisterUser.value = defaultDataRegistUser.value;
        }
        changePageRegisterUser(1);
        total_page_registerUser.value = Math.ceil(
          allDataRegisterUser.value.length / 10
        );
        total_item_registerUser.value = allDataRegisterUser.value.length;
        paginate_registerUser.value = 1;
      } else if (params === "oldest") {
        // allDataRegisterUser.value = defaultDataRegistUser.value
        allDataRegisterUser.value.sort(
          (a, b) => a.license_end_unix - b.license_end_unix
        );
      } else if (params === "newest") {
        // allDataRegisterUser.value = defaultDataRegistUser.value
        allDataRegisterUser.value.sort(
          (a, b) => b.license_end_unix - a.license_end_unix
        );
      } else if (params === "teacher" || params === "student") {
        if (filterData.value == null || filterData.value === "") {
          filterData.value = defaultDataUserDetail.value;
        }
        allDataUserDetail.value = filterData.value;
        let filter = allDataUserDetail.value.filter((val) => {
          if (val.status.toUpperCase().includes(params.toUpperCase())) {
            return val;
          }
        });
        if (filter) {
          allDataUserDetail.value = filter;
        } else {
          allDataUserDetail.value = defaultDataUserDetail.value;
        }
        changePageDetailUser(1);
        total_page_detailUser.value = Math.ceil(
          allDataUserDetail.value.length / 10
        );
        total_item_detailUser.value = allDataUserDetail.value.length;
        paginate_detailUser.value = 1;
      }
    };

    const export_to_csv = () => {
      let arrayToPdf = [];
      var rows = document.querySelectorAll("#institutionTable tr");
      let row = [];
      rows[0].querySelectorAll("td,th").forEach((val) => {
        row.push(val.innerText);
      });
      row.pop();
      arrayToPdf.push(row.join(","));
      allDataRegisterUser.value.forEach((val, index) => {
        arrayToPdf.push(
          `${index + 1},${val.institution_uid},${val.name},${val.code},${
            val.license_type
          },${val.license_status},${val.license_end}`
        );
      });
      download_csv(arrayToPdf.join("\n"), subPath.value);
    };
    function download_csv(csv, filename) {
      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], { type: "text/csv" });
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
    //add Institution State
    let addInstitutionName = ref(null);
    let pictureName = ref(null);
    let addInstitutionDate = ref(null);
    let addInstitutionStatus = ref(null);
    let addInstitutionMax = ref(null);
    const sendDataAddInstitution = new FormData();
    const addInstitutionPicture = (event) => {
      sendDataAddInstitution.delete("image");
      let selectedFile = event.target.files[0];
      sendDataAddInstitution.append("image", selectedFile);
      pictureName.value = selectedFile.name;
      const file = event.target.files[0];
      previewImg.value = URL.createObjectURL(file);
      // for(var pair of sendDataAddInstitution.entries()) {
      //     console.log(pair[0]+ ', '+ pair[1]);
      // }
    };
    const clearData = () => {
      document.getElementById("input=pictureAdd").value = "";
      pictureName.value = null;
      sendDataAddInstitution.delete("image");
      sendDataAddInstitution.delete("institution_name");
      sendDataAddInstitution.delete("institution_license_end");
      sendDataAddInstitution.delete("institution_license_type");
    };
    const addInstitution = () => {
      let token = localStorage.getItem("idToken");
      if (addInstitutionStatus.value === "Free") {
        sendDataAddInstitution.append(
          "institution_name",
          addInstitutionName.value
        );
        // sendDataAddInstitution.append("institution_license_end", addInstitutionDate.value)
        sendDataAddInstitution.append(
          "institution_license_type",
          addInstitutionStatus.value
        );
        if (addInstitutionMax.value < -1 || addInstitutionMax.value == 0) {
          sendDataAddInstitution.append("institution_registration_limit", -1);
        } else {
          sendDataAddInstitution.append(
            "institution_registration_limit",
            addInstitutionMax.value
          );
        }
        // console.log(addInstitutionMax.value);
      } else {
        sendDataAddInstitution.append(
          "institution_name",
          addInstitutionName.value
        );
        sendDataAddInstitution.append(
          "institution_license_end",
          addInstitutionDate.value
        );
        sendDataAddInstitution.append(
          "institution_license_type",
          addInstitutionStatus.value
        );
        if (addInstitutionMax.value < -1 || addInstitutionMax.value == 0) {
          sendDataAddInstitution.append("institution_registration_limit", -1);
        } else {
          sendDataAddInstitution.append(
            "institution_registration_limit",
            addInstitutionMax.value
          );
        }
        // console.log(addInstitutionDate.value);
      }
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/createInstitutionForAdmin",
        sendDataAddInstitution,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          clearData();
          fetchRegisterUser();
          popUp("SuccessAddInstitution");
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            popUp("FailedAddInstitution");
          }
          clearData();
        });
    };

    //Change status Institution state
    let oldInstitutionName = ref(null);
    let newInstitutionName = ref(null);
    let oldInstitutionUid = ref(null);
    let pictureEditName = ref(null);
    let newInstitutionStatus = ref(null);
    let newInstitutionDate = ref(null);
    let newInstitutionMax = ref(null);
    const sendDataEditInstitution = new FormData();

    const editInstitutionPicture = (event) => {
      sendDataEditInstitution.delete("institution_image");
      let selectedEditFile = event.target.files[0];
      sendDataEditInstitution.append("institution_image", selectedEditFile);
      pictureEditName.value = selectedEditFile.name;
      const file = event.target.files[0];
      previewImg.value = URL.createObjectURL(file);
    };

    const clearDataEdit = () => {
      document.getElementById("input=pictureEdit").value = "";
      oldInstitutionName.value = null;
      newInstitutionName.value = null;
      oldInstitutionUid.value = null;
      pictureEditName.value = null;
      newInstitutionStatus.value = null;
      newInstitutionDate.value = null;
      newInstitutionMax.value = null;
      sendDataAddInstitution.delete("image");
      sendDataEditInstitution.delete("institution_image");
      sendDataEditInstitution.delete("institution_registration_limit");
      sendDataEditInstitution.delete("institution_name");
      sendDataEditInstitution.delete("institution_uid");
      sendDataEditInstitution.delete("institution_license_type");
    };

    const getIdEditInstitution = (id) => {
      // console.log(allDataRegisterUser);
      oldInstitutionName.value = allDataRegisterUser.value[id].name;
      oldInstitutionUid.value = allDataRegisterUser.value[id].institution_uid;
      // license_type
      newInstitutionName.value = allDataRegisterUser.value[id].name;
      newInstitutionStatus.value = allDataRegisterUser.value[id].license_type;
      newInstitutionMax.value =
        allDataRegisterUser.value[id].registration_limit;
      var formatDate = allDataRegisterUser.value[id].license_end.split(/\//);
      var date = [formatDate[2], formatDate[1], formatDate[0]].join("-");
      newInstitutionDate.value = date;
      // console.log(newInstitutionMax);
    };

    const editInstitution = () => {
      let token = localStorage.getItem("idToken");
      sendDataEditInstitution.append(
        "institution_name",
        newInstitutionName.value
      );
      sendDataEditInstitution.append(
        "institution_uid",
        oldInstitutionUid.value
      );
      sendDataEditInstitution.append(
        "institution_license_type",
        newInstitutionStatus.value
      );
      sendDataEditInstitution.append(
        "institution_license_end",
        newInstitutionDate.value
      );
      if (newInstitutionMax.value < -1 || newInstitutionMax.value == 0) {
        // console.log("masuk if");
        // console.log(newInstitutionMax.value);
        sendDataEditInstitution.append("institution_registration_limit", -1);
      } else {
        // console.log("masuk else");
        // console.log(newInstitutionMax.value);
        sendDataEditInstitution.append(
          "institution_registration_limit",
          newInstitutionMax.value
        );
      }
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/editInstitutionForAdmin",
        sendDataEditInstitution,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          clearDataEdit();
          fetchRegisterUser();
          popUp("SuccessEditInstitution");
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            popUp("FailedEditInstitution");
          }
          clearDataEdit();
        });
    };

    // Delete Institution
    let deleteInstitutionUid = ref(null);
    let deleteInstitutionName = ref(null);

    const getIdDeleteInstitution = (id) => {
      deleteInstitutionName.value = allDataRegisterUser.value[id].name;
      deleteInstitutionUid.value =
        allDataRegisterUser.value[id].institution_uid;
    };

    const deleteInsitution = () => {
      let token = localStorage.getItem("idToken");
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/deleteInstitutionByIdForAdmin",
        {
          institution_uid: deleteInstitutionUid.value,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          deleteInstitutionName.value = null;
          deleteInstitutionUid.value = null;
          fetchRegisterUser();
          popUp("SuccessDeleteInstitution");
        })
        .catch((err) => {
          deleteInstitutionName.value = null;
          deleteInstitutionUid.value = null;
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            popUp("FailedDeleteInstitution");
          }
        });
    };

    //Add new user state
    let valueInstitutionUid = ref(null);
    let valueInstitutionName = ref(null);
    let addUserEmail = ref(null);

    const addNewUser = () => {
      // console.log(userDetail.value.registered_users.length);
      // console.log(userDetail.value.registration_limit);
      if (userDetail.value.registration_limit == -1) {
        let token = localStorage.getItem("idToken");
        Axios.post(
          "https://us-central1-millea-lab.cloudfunctions.net/web/api/registerUserToInstitutionForAdmin",
          {
            institution_uid: valueInstitutionUid.value,
            user_email: addUserEmail.value,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
          .then(() => {
            fetchDetailUser(saveId.value);
            popUp("SuccessAddUser");
          })
          .catch((err) => {
            console.log(err);
            addUserEmail.value = null;
            if (err.message === "Request failed with status code 401") {
              localStorage.removeItem("idStorage");
              window.location.href = "/login";
            } else {
              fetchDetailUser(saveId.value);
              popUp("FailedAddUser");
            }
          });
      } else if (
        userDetail.value.registered_users.length >=
        userDetail.value.registration_limit
      ) {
        popUp("limitStudentAchieved");
      } else {
        let token = localStorage.getItem("idToken");
        Axios.post(
          "https://us-central1-millea-lab.cloudfunctions.net/web/api/registerUserToInstitutionForAdmin",
          {
            institution_uid: valueInstitutionUid.value,
            user_email: addUserEmail.value,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
          .then(() => {
            fetchDetailUser(saveId.value);
            popUp("SuccessAddUser");
          })
          .catch((err) => {
            addUserEmail.value = null;
            if (err.message === "Request failed with status code 401") {
              localStorage.removeItem("idStorage");
              window.location.href = "/login";
            } else {
              fetchDetailUser(saveId.value);
              popUp("FailedAddUser");
            }
          });
      }
    };

    //change status state
    let changeInstitutionUid = ref(null);
    let changeUserName = ref(null);
    let changeUserEmail = ref(null);
    let changeStatus = ref(null);
    let changeUserUid = ref(null);

    const getUserId = (id) => {
      changeUserName.value = allDataUserDetail.value[id].name;
      changeUserEmail.value = allDataUserDetail.value[id].email;
      changeUserUid.value = allDataUserDetail.value[id].id;
    };

    const changeUserStatus = () => {
      let token = localStorage.getItem("idToken");
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/changeUserStatusForAdmin",
        {
          user_uid: changeUserUid.value,
          status: changeStatus.value,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          fetchDetailUser(saveId.value);
          changeStatus.value = null;
          popUp("SuccessEditUser");
        })
        .catch((err) => {
          changeStatus.value = null;
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            fetchDetailUser(saveId.value);
            popUp("FailedEditUser");
          }
        });
    };

    //delete user state
    let deleteUserInstitutionUid = ref(null);
    let deleteUserUid = ref(null);
    let deleteUserName = ref(null);

    const getDeleteUserId = (id) => {
      deleteUserUid.value = allDataUserDetail.value[id].id;
      deleteUserName.value = allDataUserDetail.value[id].name;
    };

    const deleteUser = () => {
      let token = localStorage.getItem("idToken");
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/deleteUserByIdForAdmin",
        {
          institution_uid: deleteUserInstitutionUid.value,
          user_uid: deleteUserUid.value,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          fetchDetailUser(saveId.value);
          popUp("SuccessDeleteUser");
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            fetchDetailUser(saveId.value);
            popUp("FailedDeleteUser");
          }
        });
    };
    let popUpText = ref("");
    const popUp = (status) => {
      if (status === "SuccessAddInstitution") {
        popUpText.value = "Add Institution Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedAddInstitution") {
        popUpText.value = "Add Institution Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessEditInstitution") {
        popUpText.value = "Edit Institution Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedEditInstitution") {
        popUpText.value = "Edit Institution Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessDeleteInstitution") {
        popUpText.value = "Delete Institution Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedDeleteInstitution") {
        popUpText.value = "Delete Institution Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "limitStudentAchieved") {
        popUpText.value = "Student Limit Achieved!";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessAddUser") {
        popUpText.value = "Add User Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedAddUser") {
        popUpText.value = "Add User Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessEditUser") {
        popUpText.value = "Edit User Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedEditUser") {
        popUpText.value = "Edit User Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessDeleteUser") {
        popUpText.value = "Delete User Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedDeleteUser") {
        popUpText.value = "Delete User Failed";
        document.getElementById("popupFailed").style.display = "flex";
      }
    };
    const closepopUp = () => {
      document.getElementById("popup").style.display = "none";
      document.getElementById("popupFailed").style.display = "none";
    };

    const openDate = () => {
      document.getElementById("date").classList.toggle("show");
    };

    //     if(params == 'today'){
    //         if(datefilter.value == 'today'){
    //         datefilter.value = ''
    //         }else{
    //         datefilter.value = 'today'
    //         }
    //     }
    //     if(params == 'yesterday'){
    //         if(datefilter.value == 'yesterday'){
    //         datefilter.value = ''
    //         }else{
    //         datefilter.value = 'yesterday'
    //         }
    //     }
    //     if(params == 'week'){
    //         if(datefilter.value == 'week'){
    //         datefilter.value = ''
    //         }else{
    //         datefilter.value = 'week'
    //         }
    //     }
    //     if(params == 'month'){
    //         if(datefilter.value == 'month'){
    //         datefilter.value = ''
    //         }else{
    //         datefilter.value = 'month'
    //         }
    //     }
    // }

    //Regist User State
    let start_page_registerUser = ref(0);
    let end_page_registerUser = ref(10);
    let total_item_registerUser = ref(0);
    let total_page_registerUser = ref(0);
    let paginate_registerUser = ref(1);

    const changePageRegisterUser = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_page_registerUser.value = new_start;
      end_page_registerUser.value = new_end;
    };

    //Regist User Detail State
    let start_page_detailUser = ref(0);
    let end_page_detailUser = ref(10);
    let total_item_detailUser = ref(0);
    let total_page_detailUser = ref(0);
    let paginate_detailUser = ref(1);

    const changePageDetailUser = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_page_detailUser.value = new_start;
      end_page_detailUser.value = new_end;
    };

    const fetchRegisterUser = async () => {
      isLoadingUser.value = true;
      await store.dispatch("getValueRegisterUser");
      let temp = await store.getters.getAllRegisterUser;
      allDataRegisterUser.value = temp;
      defaultDataRegistUser.value = temp;
      // console.log(allDataRegisterUser);
      total_page_registerUser.value = Math.ceil(
        allDataRegisterUser.value.length / 10
      );
      total_item_registerUser.value = allDataRegisterUser.value.length;
      isLoadingUser.value = false;
    };
    let saveId = ref(null);
    const fetchDetailUser = (id) => {
      isLoadingUserDetail.value = true;
      saveId.value = id;
      inputSearch.value = "";
      isDetail.value = true;
      userDetail.value = allDataRegisterUser.value[id];
      // console.log(userDetail);
      valueInstitutionUid.value = userDetail.value.institution_uid;
      valueInstitutionName.value = userDetail.value.name;
      changeInstitutionUid.value = userDetail.value.institution_uid;
      deleteUserInstitutionUid.value = userDetail.value.institution_uid;
      let token = localStorage.getItem("idToken");
      let uid = userDetail.value.institution_uid;
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/getInstitutionRegisteredUsersForAdmin",
        {
          institution_uid: uid,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => {
          allDataUserDetail.value = res.data;
          defaultDataUserDetail.value = res.data;
          total_page_detailUser.value = Math.ceil(
            allDataUserDetail.value.length / 10
          );
          total_item_detailUser.value = allDataUserDetail.value.length;
        })
        .catch((err) => {
          allDataUserDetail.value = [];
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          }
        });
      isLoadingUserDetail.value = false;
    };

    onBeforeMount(() => {
      fetchRegisterUser();
    });

    return {
      path,
      subPath,
      subSubPath,
      isDetail,
      datefilter,
      isLoadingUser,
      isLoadingUserDetail,
      previewImg,
      allDataRegisterUser,
      defaultDataRegistUser,
      allDataUserDetail,
      defaultDataUserDetail,
      userDetail,
      inputSearch,
      filterData,
      searchList,
      filterFunction,
      export_to_csv,
      download_csv,
      addInstitutionName,
      addInstitutionDate,
      addInstitutionStatus,
      addInstitutionMax,
      pictureName,
      sendDataAddInstitution,
      addInstitutionPicture,
      clearData,
      addInstitution,
      oldInstitutionName,
      newInstitutionName,
      oldInstitutionUid,
      newInstitutionStatus,
      newInstitutionDate,
      newInstitutionMax,
      sendDataEditInstitution,
      pictureEditName,
      editInstitutionPicture,
      clearDataEdit,
      getIdEditInstitution,
      editInstitution,
      deleteInstitutionUid,
      deleteInstitutionName,
      getIdDeleteInstitution,
      deleteInsitution,
      valueInstitutionUid,
      valueInstitutionName,
      addUserEmail,
      addNewUser,
      changeInstitutionUid,
      changeUserName,
      changeUserEmail,
      changeStatus,
      changeUserUid,
      getUserId,
      changeUserStatus,
      deleteUserInstitutionUid,
      deleteUserUid,
      deleteUserName,
      getDeleteUserId,
      deleteUser,
      popUpText,
      popUp,
      closepopUp,
      openDate,
      // dateFilterFunction,
      start_page_registerUser,
      end_page_registerUser,
      total_item_registerUser,
      total_page_registerUser,
      paginate_registerUser,
      changePageRegisterUser,
      start_page_detailUser,
      end_page_detailUser,
      total_item_detailUser,
      total_page_detailUser,
      paginate_detailUser,
      changePageDetailUser,
      saveId,
      fetchDetailUser,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/Tools/RegisterUser.scss";
</style>
