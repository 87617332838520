<template>
  <div class="container-trainingList">
    <SideHeader/>
    <div class="container-right-trainingList">
      <TopHeader
       :pathPage="path"
       :pathSubPage="subPath"
       :pathSubSubPage="subSubPath"
      />
      <div class="main-trainingList">
        <div class="box-header-training">
          <div class="box-navigation">
            <div class="navigation active">Training List</div>
          </div>
        </div>
        <div class="box-main-training">
          <div class="box-page" v-if="isDetail == false">
            <div class="box-date-searching">
              <div class="card-date">
                <p>Filter</p>
              </div>
              <div class="card-date">
                <img src="../../assets/calendar.svg" alt="">
                <p>Date</p>
              </div>
              <div class="card-searching">
                <input type="text" placeholder="Search">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div>
            <div class="box-table">
              <table>
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Current Points</th>
                </tr>
                <tr class="hover-row" @click="isDetail = true">
                  <td>1</td>
                  <td>name</td>
                  <td>voucher</td>
                  <td>reedemed</td>
                </tr>
                <tr class="hover-row">
                  <td>odd test</td>
                  <td>odd test</td>
                  <td>odd test</td>
                  <td>odd test</td>
                </tr>
              </table>
            </div>
            <div class="paginate-left">
              <p>Showing start to end of total entries</p>
                <paginate
                v-model="paginate_purchase"
                :page-count="10"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageStandpoint"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
          <div class="box-page" v-if="isDetail == true">
            <div class="box-back" @click="isDetail = false">
              <img src="" alt="">
              <p>Back to Points</p>
            </div>
            <div class="box-details">
              <div class="detail-name">
                <p>Mirai Philips</p>
              </div>
              <div class="box-detail-desc">
                <div class="detail-left">
                  <div class="detail-data">
                    <div class="title"><p>Training Title:</p></div>
                    <div class="description"><p>desc</p></div>
                  </div>
                  <div class="detail-data">
                    <div class="title"><p>Training Review:</p></div>
                    <div class="description"><p>desc</p></div>
                  </div>
                  <div class="detail-data">
                    <div class="title"><p>Description:</p></div>
                    <div class="description"><p>desc</p></div>
                  </div>
                </div>
                <div class="detail-right">
                  <div class="detail-data">
                    <div class="title"><p>Training Title:</p></div>
                    <div class="description"><p>desc</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue"
import TopHeader from "../../components/Header/TopHeader.vue"
import {ref} from 'vue'
import Paginate from 'vuejs-paginate-next';

export default {
  name: "TrainingList",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    let path = ref('Training')
    let subPath = ref('Training List')
    let subSubPath = ref(null)
    let isDetail = ref(false)
    return {
      path,
      subPath,
      subSubPath,
      isDetail
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/Training/TrainingList.scss";
</style>
