import Axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import moment from "moment";

const state = {
  isLoading: false,
  isLogin: false,
  allDataPurchaseHistory: undefined,
  allDataTaskHistory: undefined,
  allDataRedeemPoints: undefined,
  allDataUserData: undefined,
  uidCustomer: undefined,
};

const mutations = {
  setValueLoading(state, { name, value }) {
    state[name] = value;
  },
  setValueLogin(state, { name, value }) {
    state[name] = value;
  },
  setValueLogout(state, { name, value }) {
    state[name] = value;
  },
  setValuePurchaseHistory(state, { name, value }) {
    state[name] = value;
  },
  setValueTaskHistory(state, { name, value }) {
    state[name] = value;
  },
  setValueRedeemPoints(state, { name, value }) {
    state[name] = value;
  },
  setValueWebsiteLogin(state, { name, value }) {
    state[name] = value;
  },
  setValueUserData(state, { name, value }) {
    state[name] = value;
  },
  setValueReview(state, { name, value }) {
    state[name] = value;
  },
  setValueActiveData(state, { name, value }) {
    state[name] = value;
  },
  setValueActiveLicense(state, { name, value }) {
    state[name] = value;
  },
  setValueActiveVoucher(state, { name, value }) {
    state[name] = value;
  },
  setValueActivePoints(state, { name, value }) {
    state[name] = value;
  },
  setValueDeveloperLogin(state, { name, value }) {
    state[name] = value;
  },
  setValueDeveloperSave(state, { name, value }) {
    state[name] = value;
  },
  setValueDeveloperClass(state, { name, value }) {
    state[name] = value;
  },
  setValueDeveloperAssets(state, { name, value }) {
    state[name] = value;
  },
  setValueAnalyticActivity(state, { name, value }) {
    state[name] = value;
  },
  setValueAnalyticDownload(state, { name, value }) {
    state[name] = value;
  },
  setValueRegisterUser(state, { name, value }) {
    state[name] = value;
  },
  setValueCategoryManager(state, { name, value }) {
    state[name] = value;
  },
  setValueFeedback(state, { name, value }) {
    state[name] = value;
  },
  setValueEventList(state, { name, value }) {
    state[name] = value;
  },
  setValueLeaderboard(state, { name, value }) {
    state[name] = value;
  },
  setValueLeaderboardFilter(state, { name, value }) {
    state[name] = value;
  },

  setValueRegistrationApproval(state, { name, value }) {
    state[name] = value;
  },
  setValueBlog(state, { name, value }) {
    state[name] = value;
  },
  setValueTask(state, { name, value }) {
    state[name] = value;
  },
  setValueTaskDaily(state, { name, value }) {
    state[name] = value;
  },
  setValueContent(state, { name, value }) {
    state[name] = value;
  },
};

const actions = {
  getValueLoginWithGoogle: ({ commit }, valueParams) => {
    commit("setValueLogin", { name: "isLogin", value: true });
    commit("setValueLogin", { name: "user", value: valueParams });
  },
  getValueLogin: async ({ commit }) => {
    let idStorage = localStorage.getItem("idStorage");
    commit("setValueUid", { name: "uidCustomer", value: idStorage });
    if (idStorage !== null && idStorage !== undefined) {
      // berhasil login
      let collectionID = "users";
      let docRef = doc(db, collectionID, idStorage);
      let docSnapshot = await getDoc(docRef);
      let user = docSnapshot.data();
      if (user !== undefined) {
        if (user.userDetails !== undefined) {
          let sendToFirebase = {
            id: user.id,
            email: user.email,
          };
          commit("setValueLogin", { name: "user", value: sendToFirebase });
          commit("setValueLogin", { name: "isLogin", value: true });
        } else {
          let sendToFirebase = {
            id: user.id,
            email: user.email,
          };
          commit("setValueLogin", { name: "user", value: sendToFirebase });
          commit("setValueLogin", { name: "isLogin", value: true });
        }

        return true;
      } else {
        localStorage.removeItem("idStorage");
        return false;
      }
    } else {
      commit("setValueLogin", { name: "isLogin", value: false });
      return false;
    }
  },
  getValueLogout: ({ commit }) => {
    localStorage.removeItem("idStorage");
    commit("setValueLogout", { name: "isLogin", value: false });
  },
  getValueLoading: ({ commit }, valueParams) => {
    commit("setValueLoading", { name: "isLoading", value: valueParams.value });
    return true;
  },
  getValueIsLogin: async ({ commit }, valueParams) => {
    commit("setValueLogin", { name: "isLogin", value: valueParams.value });
  },
  getValuePurchaseHistory: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicensePurchaseHistory",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValuePurchaseHistory", {
          name: "allDataPurchaseHistory",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValuePurchaseHistory", {
          name: "allDataPurchaseHistory",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueTaskHistory: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseTaskHistory",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueTaskHistory", {
          name: "allDataTaskHistory",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueTaskHistory", {
          name: "allDataTaskHistory",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueRedeemPoints: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseRedeemPointsHistory",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueRedeemPoints", {
          name: "allDataRedeemPoints",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueRedeemPoints", {
          name: "allDataRedeemPoints",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueWebsiteLogin: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseWebsiteLogin",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueWebsiteLogin", {
          name: "allDataWebsiteLogin",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueWebsiteLogin", {
          name: "allDataWebsiteLogin",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueUserData: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseUserData",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueUserData", {
          name: "allDataUserData",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueUserData", { name: "allDataUserData", value: [] });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueReview: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseWebsiteReviews",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueReview", { name: "allDataReview", value: res.data });
      })
      .catch((err) => {
        commit("setValueReview", { name: "allDataReview", value: [] });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueActiveData: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseActiveUserListUserData",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueActiveData", {
          name: "allDataActiveData",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueActiveData", { name: "allDataActiveData", value: [] });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueActiveLicense: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseActiveUserListLicense",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueActiveLicense", {
          name: "allDataActiveLicense",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueActiveLicense", {
          name: "allDataActiveLicense",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueActiveVoucher: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseActiveUserListVoucher",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueActiveVoucher", {
          name: "allDataActiveVoucher",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueActiveVoucher", {
          name: "allDataActiveVoucher",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueActivePoints: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseActiveUserListPoints",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueActivePoints", {
          name: "allDataActivePoints",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueActivePoints", {
          name: "allDataActivePoints",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueDeveloperLogin: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getDeveloperLoginActivity",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueDeveloperLogin", {
          name: "allDataDeveloperLogin",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueDeveloperLogin", {
          name: "allDataDeveloperLogin",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueDeveloperSave: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getDeveloperSceneActivity",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueDeveloperSave", {
          name: "allDataDeveloperSave",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueDeveloperSave", {
          name: "allDataDeveloperSave",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueDeveloperClass: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getDeveloperClassroomActivity",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueDeveloperClass", {
          name: "allDataDeveloperClass",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueDeveloperClass", {
          name: "allDataDeveloperClass",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueDeveloperAssets: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getDeveloperAssetUsages",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueDeveloperAssets", {
          name: "allDataDeveloperAssets",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueDeveloperAssets", {
          name: "allDataDeveloperAssets",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueAnalyticActivity: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getUserOpenApplicationActivity",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueAnalyticActivity", {
          name: "allDataAnalyticActivity",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueAnalyticActivity", {
          name: "allDataAnalyticActivity",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueAnalyticDownload: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getClicksTrackingDetails",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueAnalyticDownload", {
          name: "allDataAnalyticDownload",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueAnalyticDownload", {
          name: "allDataAnalyticDownload",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueRegisterUser: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getInstitutionListForAdmin",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueRegisterUser", {
          name: "allDataRegisterUser",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueRegisterUser", {
          name: "allDataRegisterUser",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueCategoryManager: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getEducationCategories",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueCategoryManager", {
          name: "allDataCategoryManager",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueCategoryManagerr", {
          name: "allDataCategoryManager",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueFeedback: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.post(
      "https://us-central1-millea-lab.cloudfunctions.net/web/api/getApplicationFeedbacks",
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        commit("setValueFeedback", {
          name: "allDataFeedback",
          value: res.data,
        });
      })
      .catch((err) => {
        commit("setValueFeedback", { name: "allDataFeedback", value: [] });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueEventList: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.get(
      "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/events",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        console.log(res);
        commit("setValueEventList", {
          name: "allDataEventList",
          value: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        commit("setValueEventList", { name: "allDataEventList", value: [] });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueRegistrationApproval: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.get(
      "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/users/pendekar-vr-registration",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        // console.log(res);
        commit("setValueRegistrationApproval", {
          name: "allDataRegistrationApproval",
          value: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        commit("setValueRegistrationApproval", {
          name: "allDataRegistrationApproval",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueBlog: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.get(
      "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blogs/admin/all",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        // console.log(res);
        commit("setValueBlog", { name: "allDataBlog", value: res.data });
      })
      .catch((err) => {
        console.log(err);
        commit("setValueBlog", { name: "allDataBlog", value: [] });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueTask: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.get(
      "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/tasks",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        // console.log(res);
        commit("setValueTask", { name: "allDataTask", value: res.data });
      })
      .catch((err) => {
        console.log(err);
        commit("setValueTask", { name: "allDataTask", value: [] });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueTaskDaily: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.get(
      "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/tasks/daily-distributions",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        // console.log(res);
        commit("setValueTaskDaily", {
          name: "allDataTaskDaily",
          value: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        commit("setValueTaskDaily", { name: "allDataTaskDaily", value: [] });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueContent: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.get(
      "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/contents",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        // console.log(res);
        commit("setValueContent", { name: "allDataContent", value: res.data });
      })
      .catch((err) => {
        console.log(err);
        commit("setValueContent", { name: "allDataContent", value: [] });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueLeaderboard: async ({ commit }) => {
    let token = localStorage.getItem("idToken");
    // let uid = localStorage.getItem('idStorage')
    await Axios.get(
      "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/users/pendekar-vr-leaderboard",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        // console.log(res);
        commit("setValueLeaderboard", {
          name: "allDataLeaderboard",
          value: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        commit("setValueLeaderboard", {
          name: "allDataLeaderboard",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
  },
  getValueLeaderboardFilter: async (
    { commit },
    { page, limit, startDate, endDate }
  ) => {
    const token = localStorage.getItem("idToken");
    let params = {
      startDate: null,
      endDate: null,
    };
    if (startDate && endDate) {
      params.startDate = moment(startDate).format("YYYY-MM-DD");
      params.endDate = moment(endDate).format("YYYY-MM-DD");
    }
    await Axios.get(
      `https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/users/pendekar-vr-leaderboard-rework`,
      // `https://b9cb-125-160-8-190.ngrok-free.app/millea-lab-dev/us-central1/webPendekarVRDashboard/api/v1/users/pendekar-vr-leaderboard-rework`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate: params.startDate,
          endDate: params.endDate,
        },
      }
    )
      .then((res) => {
        commit("setValueLeaderboardFilter", {
          name: "allDataLeaderboardFilter",
          value: res.data,
        });
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        commit("setValueLeaderboard", {
          name: "allDataLeaderboard",
          value: [],
        });
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("idStorage");
          window.location.href = "/login";
        }
      });
    // console.log("Page:" + page);
    // console.log("Limit:" + limit);
    // console.log("Start Date:" + startDate);
    // console.log("End Date:" + endDate);
    // console.log("Params: " + JSON.stringify(params));
  },
};

const getters = {
  getIsLoading: (state) => {
    return state.isLoading;
  },
  getIsLogin: (state) => {
    return state.isLogin;
  },
  getValueUidCustomer: (state) => {
    return state.uidCustomer;
  },
  getAllPurchaseHistory: (state) => {
    return state.allDataPurchaseHistory;
  },
  getAllTaskHistory: (state) => {
    return state.allDataTaskHistory;
  },
  getAllRedeemPoints: (state) => {
    return state.allDataRedeemPoints;
  },
  getAllWebsiteLogin: (state) => {
    return state.allDataWebsiteLogin;
  },
  getAllUserData: (state) => {
    return state.allDataUserData;
  },
  getAllReview: (state) => {
    return state.allDataReview;
  },
  getAllActiveData: (state) => {
    return state.allDataActiveData;
  },
  getAllActiveLicense: (state) => {
    return state.allDataActiveLicense;
  },
  getAllActiveVoucher: (state) => {
    return state.allDataActiveVoucher;
  },
  getAllActivePoints: (state) => {
    return state.allDataActivePoints;
  },
  getAllDeveloperLogin: (state) => {
    return state.allDataDeveloperLogin;
  },
  getAllDeveloperSave: (state) => {
    return state.allDataDeveloperSave;
  },
  getAllDeveloperClass: (state) => {
    return state.allDataDeveloperClass;
  },
  getAllDeveloperAssets: (state) => {
    return state.allDataDeveloperAssets;
  },
  getAllAnalyticActivity: (state) => {
    return state.allDataAnalyticActivity;
  },
  getAllAnalyticDownload: (state) => {
    return state.allDataAnalyticDownload;
  },
  getAllRegisterUser: (state) => {
    return state.allDataRegisterUser;
  },
  getAllCategoryManager: (state) => {
    return state.allDataCategoryManager;
  },
  getAllFeedback: (state) => {
    return state.allDataFeedback;
  },
  getAllEventList: (state) => {
    return state.allDataEventList;
  },
  getAllLeaderboard: (state) => {
    return state.allDataLeaderboard;
  },
  getAllLeaderboardFilter: (state) => {
    return state.allDataLeaderboardFilter;
  },
  getAllRegistrationApproval: (state) => {
    return state.allDataRegistrationApproval;
  },
  getAllBlog: (state) => {
    return state.allDataBlog;
  },
  getAllTask: (state) => {
    return state.allDataTask;
  },
  getAllTaskDaily: (state) => {
    return state.allDataTaskDaily;
  },
  getAllContent: (state) => {
    return state.allDataContent;
  },
};

const namespaced = false;

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
