import { createStore } from "vuex";
import Auth from './auth'

export default createStore({
  modules:{
    Auth
  }
  // state: {},
  // getters: {},
  // mutations: {},
  // actions: {},
  // modules: {},
});
