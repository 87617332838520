<template>
  <!-- Popup -->
  <div id="popup" class="box-popup">
    <div class="popup-content">
      <div class="popup-left">
        <img src="../../assets/succeed-popup.svg" alt="" />
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <div id="popupFailed" class="box-popup">
    <div class="popup-failed">
      <div class="popup-left">
        <!-- <img src="../../assets/succeed-popup.svg" alt=""> -->
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>

  <!-- Modal Action Task Approval -->
  <b-modal id="modalPopUpTask" title="View Task">
    <div class="box-detail">
      <div class="title">Name:</div>
      <div class="description">{{ allDataTaskDetail.user_name }}</div>
    </div>
    <div class="box-detail">
      <div class="title">Task:</div>
      <div class="description">{{ allDataTaskDetail.task_category }}</div>
    </div>
    <div class="box-detail">
      <div class="title">Additional Information:</div>
      <div class="description">
        {{ allDataTaskDetail.task_additional_information }}
      </div>
    </div>
    <div class="box-download">
      <div class="title">Lampiran (.document/.video/.jpg/.jpeg/.png)</div>
      <div class="download">
        <a
          class="btn-download"
          :href="allDataTaskDetail.task_attachment_file_url"
          target="_blank"
          >Preview attachment</a
        >
      </div>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal" @click="rejectTask()">
        <p>REJECT</p>
      </div>
      <div class="btn-submit" data-bs-dismiss="modal" @click="approveTask()">
        <p>APPROVE</p>
      </div>
    </div>
  </b-modal>
  <div class="container-taskApproval">
    <SideHeader />
    <div class="container-right-taskApproval">
      <TopHeader
        :pathPage="path"
        :pathSubPage="subPath"
        :pathSubSubPage="subSubPath"
      />
      <div class="main-taskApproval">
        <div class="box-header-taskApproval">
          <div class="box-navigation">
            <div class="navigation active">Task Approval</div>
          </div>
          <div class="box-header-right">
            <div class="btn-export">
              <img src="../../assets/log-in.svg" alt="" />
              <p>Export</p>
            </div>
          </div>
        </div>
        <div class="box-main-taskApproval">
          <div class="box-page">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="card-date">
                  <div class="date-title" @click="openDate()">
                    <img src="../../assets/calendar.svg" alt="" />
                    <p>Date</p>
                  </div>
                  <div id="date" class="dropdown-date">
                    <div class="card-start-end">
                      <input
                        v-model="startDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                      <p>to</p>
                      <input
                        v-model="endDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                    </div>
                    <div class="separator"></div>
                    <div class="box-date-filter">
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'today' }]"
                        @click="dateFilterFunction('today')"
                      >
                        Today
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'yesterday' }]"
                        @click="dateFilterFunction('yesterday')"
                      >
                        Yesterday
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'week' }]"
                        @click="dateFilterFunction('week')"
                      >
                        Last Week
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'month' }]"
                        @click="dateFilterFunction('month')"
                      >
                        Last Month
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="box-line-chart">
              <div class="chart-title">
                <p>Statistik Hasil Task Harian - Pending</p>
              </div>
              <apexchart
                width="100%"
                height="330"
                type="line"
                :options="optionsPending"
                :series="seriesPending"
              ></apexchart>
              <div class="chart-total">
                <div class="box-total">
                  <p class="title">Total Pending</p>
                  <p>{{ dailyPending }}</p>
                </div>
                <div class="box-total">
                  <p class="title">Total Approved</p>
                  <p>{{ dailyApprove }}</p>
                </div>
                <div class="box-total">
                  <p class="title">Total Rejected</p>
                  <p>{{ dailyReject }}</p>
                </div>
                <div class="box-total">
                  <p class="title">Total Task</p>
                  <p>{{ totalTask }}</p>
                </div>
              </div>
            </div>
            <div class="box-table">
              <table
                v-if="isLoadingTaskApproval == false"
                id="registrationTable"
              >
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Task</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                <tr
                  v-for="(dataTask, id) in allDataTaskList"
                  :key="dataTask.id"
                >
                  <td v-if="id >= start_page_task && id < end_page_task">
                    {{ id + 1 }}
                  </td>
                  <td v-if="id >= start_page_task && id < end_page_task">
                    {{ dataTask.user_name }}
                  </td>
                  <td v-if="id >= start_page_task && id < end_page_task">
                    {{ dataTask.task_category }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_task &&
                      id < end_page_task &&
                      dataTask.task_submission_status === 'APPROVED'
                    "
                  >
                    <div class="approved">
                      <p>Approved</p>
                    </div>
                  </td>
                  <td
                    v-else-if="
                      id >= start_page_task &&
                      id < end_page_task &&
                      dataTask.task_submission_status === 'PENDING'
                    "
                  >
                    <div class="pending">
                      <p>Pending</p>
                    </div>
                  </td>
                  <td
                    v-else-if="
                      id >= start_page_task &&
                      id < end_page_task &&
                      dataTask.task_submission_status === 'REJECTED'
                    "
                  >
                    <div class="rejected">
                      <p>Rejected</p>
                    </div>
                  </td>
                  <td v-if="id >= start_page_task && id < end_page_task">
                    <b-button
                      v-b-modal.modalPopUpTask
                      cancel-disabled="true"
                      class="btn-view"
                      @click="getTaskDetail(id)"
                    >
                      <p>View</p>
                    </b-button>
                  </td>
                </tr>
              </table>
              <div class="empty-card" v-else>
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_task + 1 }} to {{ end_page_task }} of
                {{ total_item_task }} entries
              </p>
              <paginate
                v-model="paginate_task"
                :page-count="total_page_task"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageTask"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue";
import TopHeader from "../../components/Header/TopHeader.vue";
import { onBeforeMount, ref } from "vue";
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";
import Axios from "axios";

export default {
  name: "TaskApproval",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    const store = useStore();
    let path = ref("PendekarVR");
    let subPath = ref("Task Approval");
    let subSubPath = ref();
    let datefilter = ref("");

    let isLoadingTaskApproval = ref(true);

    let allDataTaskList = ref(null);
    let allDataTaskListDaily = ref(null);
    let defaultDataTaskList = ref(null);

    const openDate = () => {
      document.getElementById("date").classList.toggle("show");
    };

    let startDateRange = ref("");
    let endDateRange = ref("");
    const dateFilterFunction = (params) => {
      if (startDateRange.value != "" && endDateRange.value != "") {
        datefilter.value = "";
        openDate();
      } else if (params == "today") {
        if (datefilter.value == "today") {
          datefilter.value = "";
        } else {
          datefilter.value = "today";
        }
        openDate();
      } else if (params == "yesterday") {
        if (datefilter.value == "yesterday") {
          datefilter.value = "";
        } else {
          datefilter.value = "yesterday";
        }
        openDate();
      } else if (params == "week") {
        if (datefilter.value == "week") {
          datefilter.value = "";
        } else {
          datefilter.value = "week";
        }
        openDate();
      } else if (params == "month") {
        if (datefilter.value == "month") {
          datefilter.value = "";
        } else {
          datefilter.value = "month";
        }
        openDate();
      }
      // else{
      // }
    };

    let popUpText = ref("");
    const popUp = (status) => {
      if (status == "SuccessRejectTask") {
        popUpText.value = "Reject Task Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedRejectTask") {
        popUpText.value = "Reject Task Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessApproveTask") {
        popUpText.value = "Approve Task Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedApproveTask") {
        popUpText.value = "Approve Task Failed";
        document.getElementById("popupFailed").style.display = "flex";
      }
    };

    const closepopUp = () => {
      document.getElementById("popup").style.display = "none";
      document.getElementById("popupFailed").style.display = "none";
    };

    let inputSearch = ref("");

    const searchList = () => {
      if (inputSearch.value !== "") {
        allDataTaskList.value = defaultDataTaskList.value;
        // console.log(allDataTaskList)
        let filter = allDataTaskList.value.filter((val) => {
          if (
            val.user_name
              .toUpperCase()
              .includes(inputSearch.value.toUpperCase())
          ) {
            return val;
          }
        });
        if (filter) {
          allDataTaskList.value = filter;
        } else {
          allDataTaskList.value = defaultDataTaskList.value;
        }
        changePageTask(1);
      } else {
        allDataTaskList.value = defaultDataTaskList.value;
      }
      total_page_task.value = Math.ceil(allDataTaskList.value.length / 10);
      total_item_task.value = allDataTaskList.value.length;
      paginate_task.value = 1;
    };

    //Pending Chart
    let seriesDataPending = ref([]);
    let optionsDataPending = ref([]);
    let seriesDataApproved = ref([]);
    let optionsDataApproved = ref([]);
    let seriesDataRejected = ref([]);
    let optionsDataRejected = ref([]);
    let seriesPending = ref([]);
    let optionsPending = ref({
      colors: ["#F0AD4E", "#5CB85C", "#D9534F"],
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
        title: {
          text: "Tanggal",
        },
        style: {
          color: "#000000",
          fontSize: "12px",
          fontFamily: "Open Sans",
          fontWeight: "150%",
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        title: {
          text: "Total",
        },
        style: {
          color: "#000000",
          fontSize: "12px",
          fontFamily: "Open Sans",
          fontWeight: "150%",
        },
      },
    });

    const findDataChartPending = () => {
      seriesDataPending.value = [];
      optionsDataPending.value = [];
      for (
        let i = 0;
        i < allDataTaskListDaily.value.pending_tasks_daily_distributions.length;
        i++
      ) {
        seriesDataPending.value.push(
          allDataTaskListDaily.value.pending_tasks_daily_distributions[i].count
        );
        optionsDataPending.value.push(
          allDataTaskListDaily.value.pending_tasks_daily_distributions[i]
            .formatted_date
        );
      }
      seriesDataApproved.value = [];
      optionsDataApproved.value = [];
      for (
        let i = 0;
        i <
        allDataTaskListDaily.value.approved_tasks_daily_distributions.length;
        i++
      ) {
        seriesDataApproved.value.push(
          allDataTaskListDaily.value.approved_tasks_daily_distributions[i].count
        );
        optionsDataApproved.value.push(
          allDataTaskListDaily.value.approved_tasks_daily_distributions[i]
            .formatted_date
        );
      }
      seriesDataRejected.value = [];
      optionsDataRejected.value = [];
      for (
        let i = 0;
        i <
        allDataTaskListDaily.value.rejected_tasks_daily_distributions.length;
        i++
      ) {
        seriesDataRejected.value.push(
          allDataTaskListDaily.value.rejected_tasks_daily_distributions[i].count
        );
        optionsDataRejected.value.push(
          allDataTaskListDaily.value.rejected_tasks_daily_distributions[i]
            .formatted_date
        );
      }
      pushDataChartPending();
    };

    const pushDataChartPending = () => {
      (seriesPending.value = [
        {
          data: seriesDataPending,
          name: "Pending",
        },
        {
          data: seriesDataApproved,
          name: "Approved",
        },
        {
          data: seriesDataRejected,
          name: "Rejected",
        },
      ]),
        (optionsPending.value = {
          xaxis: {
            categories: optionsDataApproved,
          },
          // xaxis: {
          //   categories: optionsDataApproved,
          // },
          // xaxis: {
          //   categories: optionsDataRejected,
          // },
        });
    };

    //Task State
    let start_page_task = ref(0);
    let end_page_task = ref(10);
    let total_item_task = ref(0);
    let total_page_task = ref(0);
    let paginate_task = ref(1);

    const changePageTask = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_page_task.value = new_start;
      end_page_task.value = new_end;
    };

    const fetchTaskList = async () => {
      isLoadingTaskApproval.value = true;
      await store.dispatch("getValueTask");
      allDataTaskList.value = await store.getters.getAllTask;
      defaultDataTaskList.value = allDataTaskList.value.result;
      allDataTaskList.value = allDataTaskList.value.result;
      // console.log(allDataTaskList);

      total_page_task.value = Math.ceil(allDataTaskList.value.length / 10);
      total_item_task.value = allDataTaskList.value.length;
      fetchTaskListDaily();
      isLoadingTaskApproval.value = false;
    };

    let dailyApprove = ref(0);
    let dailyReject = ref(0);
    let dailyPending = ref(0);
    let totalTask = ref(0);
    const fetchTaskListDaily = async () => {
      await store.dispatch("getValueTaskDaily");
      allDataTaskListDaily.value = await store.getters.getAllTaskDaily;
      allDataTaskListDaily.value = allDataTaskListDaily.value.result;
      for (
        let i = 0;
        i <
        allDataTaskListDaily.value.approved_tasks_daily_distributions.length;
        i++
      ) {
        dailyApprove.value =
          dailyApprove.value +
          allDataTaskListDaily.value.approved_tasks_daily_distributions[i]
            .count;
      }
      for (
        let i = 0;
        i <
        allDataTaskListDaily.value.rejected_tasks_daily_distributions.length;
        i++
      ) {
        dailyReject.value =
          dailyReject.value +
          allDataTaskListDaily.value.rejected_tasks_daily_distributions[i]
            .count;
      }
      for (
        let i = 0;
        i < allDataTaskListDaily.value.pending_tasks_daily_distributions.length;
        i++
      ) {
        dailyPending.value =
          dailyPending.value +
          allDataTaskListDaily.value.pending_tasks_daily_distributions[i].count;
      }
      totalTask.value =
        dailyApprove.value + dailyPending.value + dailyReject.value;
      // console.log(allDataTaskListDaily);
      findDataChartPending();
      // findDataChartApproved();
      // findDataChartRejected();
    };

    let allDataTaskDetail = ref([]);
    let taskUID = ref();
    let userUID = ref();
    const getTaskDetail = (id) => {
      // console.log(id);
      userUID.value = allDataTaskList.value[id].user_uid;
      taskUID.value = allDataTaskList.value[id].id;
      allDataTaskDetail.value = allDataTaskList.value[id];
      // console.log(userUID, taskUID, allDataTaskDetail);
    };

    const rejectTask = () => {
      isLoadingTaskApproval.value = true;
      let token = localStorage.getItem("idToken");
      Axios.patch(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/tasks/" +
          taskUID.value,
        {
          user_uid: userUID.value,
          task_submission_status_id: "K9MICJwtlSWs1oneUfmr",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          console.log("success");
          popUp("SuccessRejectTask");
          fetchTaskList();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedRejectTask");
            fetchTaskList();
          }
        });
    };
    const approveTask = () => {
      isLoadingTaskApproval.value = true;
      let token = localStorage.getItem("idToken");
      Axios.patch(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/tasks/" +
          taskUID.value,
        {
          user_uid: userUID.value,
          task_submission_status_id: "Pfr1nXLSrxeX7aef3QjI",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          console.log("success");
          popUp("SuccessApproveTask");
          fetchTaskList();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedApproveTask");
            fetchTaskList();
          }
        });
    };
    onBeforeMount(() => {
      fetchTaskList();
    });

    return {
      path,
      subPath,
      subSubPath,
      datefilter,
      isLoadingTaskApproval,
      allDataTaskList,
      allDataTaskListDaily,
      defaultDataTaskList,
      openDate,
      startDateRange,
      endDateRange,
      dateFilterFunction,
      inputSearch,
      searchList,
      seriesDataPending,
      optionsDataPending,
      seriesPending,
      optionsPending,
      findDataChartPending,
      pushDataChartPending,
      seriesDataApproved,
      optionsDataApproved,
      // seriesApproved,
      // optionsApproved,
      // findDataChartApproved,
      // pushDataChartApproved,
      seriesDataRejected,
      optionsDataRejected,
      // seriesRejected,
      // optionsRejected,
      // findDataChartRejected,
      // pushDataChartRejected,
      start_page_task,
      end_page_task,
      total_item_task,
      total_page_task,
      paginate_task,
      changePageTask,
      fetchTaskList,
      allDataTaskDetail,
      taskUID,
      userUID,
      getTaskDetail,
      rejectTask,
      approveTask,
      dailyApprove,
      dailyPending,
      dailyReject,
      totalTask,
      popUpText,
      popUp,
      closepopUp,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/PendekarVR/TaskApproval.scss";
</style>
