<template>
  <!-- Popup -->
  <div id="popup" class="box-popup">
    <div class="popup-content">
      <div class="popup-left">
        <img src="../../assets/succeed-popup.svg" alt="" />
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <div id="popupFailed" class="box-popup">
    <div class="popup-failed">
      <div class="popup-left">
        <!-- <img src="../../assets/succeed-popup.svg" alt=""> -->
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <!-- Modal Add Category -->
  <b-modal id="modalAddCategory" title="Add Category">
    <div class="box-input-text">
      <p>New Category Name</p>
      <input v-model="newNameCategory" type="text" name="" id="" />
    </div>
    <div class="box-input-text">
      <p>New Category Indonesian Translation</p>
      <input v-model="newIndonesiaCategory" type="text" name="" id="" />
    </div>
    <div class="box-input-text">
      <p>New Category English Translation</p>
      <input v-model="newEnglishCategory" type="text" name="" id="" />
    </div>

    <div class="new-footer">
      <div
        class="btn-cancel"
        data-bs-dismiss="modal"
        @click="
          (newNameCategory = null),
            (newIndonesiaCategory = null),
            (newEnglishCategory = null)
        "
      >
        <p>CANCEL</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="addNewCategory()"
      >
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>
  <!-- Modal Edit Category -->
  <b-modal id="modalEditCategory" title="Edit Category">
    <div class="box-detail">
      <p class="title">Category name:</p>
      <p class="desc">{{ oldNameCategory }}</p>
    </div>
    <div class="box-input-text">
      <p>New category name:</p>
      <input v-model="editNameCategory" type="text" name="" id="" />
    </div>
    <div class="box-input-text">
      <p>New Category Indonesian Translation:</p>
      <input v-model="editIndonesiaCategory" type="text" name="" id="" />
    </div>
    <div class="box-input-text">
      <p>New Category English Translation:</p>
      <input v-model="editEnglishCategory" type="text" name="" id="" />
    </div>
    <div class="new-footer">
      <div
        class="btn-cancel"
        data-bs-dismiss="modal"
        @click="
          (editNameCategory = null),
            (editIndonesiaCategory = null),
            (editEnglishCategory = null)
        "
      >
        <p>CANCEL</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="editCategory()"
      >
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>
  <!-- Modal Delete Category -->
  <b-modal id="modalDeleteCategory" title="Delete Category">
    <div class="box-delete">
      <p>Are you sure you want to delete</p>
      <p>{{ deleteNameCategory }}</p>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal"><p>CANCEL</p></div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="deleteCategory()"
      >
        <p>DELETE</p>
      </div>
    </div>
  </b-modal>
  <!-- Modal Add Sub Category -->
  <b-modal id="modalAddSubCategory" title="Add Subcategory">
    <div class="box-input-text">
      <p>New subcategory name:</p>
      <input v-model="newNameSubCategory" type="text" name="" id="" />
    </div>
    <div class="box-input-text">
      <p>New Subcategory Indonesian Translation:</p>
      <input v-model="newIndonesiaSubCategory" type="text" name="" id="" />
    </div>
    <div class="box-input-text">
      <p>New Subcategory English Translation:</p>
      <input v-model="newEnglishSubCategory" type="text" name="" id="" />
    </div>
    <div class="new-footer">
      <div
        class="btn-cancel"
        data-bs-dismiss="modal"
        @click="
          (newNameSubCategory = null),
            (newIndonesiaSubCategory = null),
            (newEnglishSubCategory = null)
        "
      >
        <p>CANCEL</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="addNewSubCategory()"
      >
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>
  <!-- Modal Edit Sub Category -->
  <b-modal id="modalEditSubCategory" title="Edit Subcategory">
    <div class="box-detail">
      <p class="title">Subcategory name:</p>
      <p class="desc">{{ oldNameSubCategory }}</p>
    </div>
    <div class="box-input-text">
      <p>New subcategory name:</p>
      <input v-model="editNameSubCategory" type="text" name="" id="" />
    </div>
    <div class="box-input-text">
      <p>New Subcategory Indonesian Translation:</p>
      <input v-model="editIndonesiaSubCategory" type="text" name="" id="" />
    </div>
    <div class="box-input-text">
      <p>New Subcategory English Translation:</p>
      <input v-model="editEnglishSubCategory" type="text" name="" id="" />
    </div>
    <div class="new-footer">
      <div
        class="btn-cancel"
        data-bs-dismiss="modal"
        @click="
          (editNameCategory = null),
            (editIndonesiaSubCategory = null),
            (editEnglishSubCategory = null)
        "
      >
        <p>CANCEL</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="editSubCategory()"
      >
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>
  <!-- Modal Delete Sub Category -->
  <b-modal id="modalDeleteSubCategory" title="Delete Subcategory">
    <div class="box-delete">
      <p>Are you sure you want to delete</p>
      <p>{{ deleteNameSubCategory }}</p>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal"><p>CANCEL</p></div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="deleteSubCategory()"
      >
        <p>DELETE</p>
      </div>
    </div>
  </b-modal>
  <div class="container-categoryManager">
    <SideHeader />
    <div class="container-right-categoryManager">
      <TopHeader
        :pathPage="path"
        :pathSubPage="subPath"
        :pathSubSubPage="subSubPath"
      />
      <div class="main-categoryManager">
        <div class="box-header-categoryManager">
          <div class="box-navigation">
            <div class="navigation active">Category Manager</div>
          </div>
          <div class="box-header-right">
            <div
              v-if="isDetail == false"
              @click="export_to_csv()"
              class="btn-export"
            >
              <img src="../../assets/log-in.svg" alt="" />
              <p>Export</p>
            </div>
          </div>
        </div>
        <div class="box-main-categoryManager">
          <div class="box-page" v-if="isDetail == false">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
              <div class="date-searching-right">
                <b-button
                  v-b-modal.modalAddCategory
                  cancel-disabled="true"
                  class="btn-add"
                >
                  <p>+ Add Category</p>
                </b-button>
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingCategory === false" id="categoryTable">
                <tr>
                  <th>No</th>
                  <th>Category</th>
                  <th>Action</th>
                </tr>
                <tr
                  v-for="(dataCategoryManager, id) in allDataCategoryManager"
                  :key="dataCategoryManager.id"
                  class="hover-row"
                >
                  <td
                    v-if="
                      id >= start_page_categoryManager &&
                      id < end_page_categoryManager
                    "
                    @click="fetchCategoryDetail(id)"
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_categoryManager &&
                      id < end_page_categoryManager
                    "
                    @click="fetchCategoryDetail(id)"
                  >
                    {{ dataCategoryManager.name }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_categoryManager &&
                      id < end_page_categoryManager
                    "
                  >
                    <div class="box-action">
                      <b-button
                        v-b-modal.modalEditCategory
                        cancel-disabled="true"
                        class="btn-edit"
                        @click="getIdEdit(id)"
                      >
                        <p>Edit</p>
                      </b-button>
                      <b-button
                        v-b-modal.modalDeleteCategory
                        cancel-disabled="true"
                        class="btn-delete"
                        @click="getIdDelete(id)"
                      >
                        <p>Delete</p>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="empty-card" v-else-if="isLoadingCategory === true">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_categoryManager + 1 }} to
                {{ end_page_categoryManager }} of
                {{ total_item_categoryManager }} entries
              </p>
              <paginate
                v-model="paginate_categoryManager"
                :page-count="total_page_categoryManager"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageCategoryManager"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
          <div class="box-page" v-if="isDetail == true">
            <div
              class="box-back"
              @click="(isDetail = false), (inputSearch = ''), searchList()"
            >
              <img src="../../assets/back.svg" alt="" />
              <p>Back to Category Manager</p>
            </div>
            <div class="box-details">
              <div class="detail-name">
                <p>{{ categoryDetail.name }}</p>
              </div>
              <div class="box-detail-desc">
                <div class="detail-data">
                  <div class="title"><p>Category name:</p></div>
                  <div class="description">
                    <p>{{ categoryDetail.name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="separator"></div>
            <div class="box-title"><p>Subcategory</p></div>
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
              <div class="date-searching-right">
                <b-button
                  v-b-modal.modalAddSubCategory
                  cancel-disabled="true"
                  class="btn-add"
                >
                  <p>+ Add Subcategory</p>
                </b-button>
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingCategoryDetail === false">
                <tr>
                  <th>No</th>
                  <th>Sub Category</th>
                  <th>Action</th>
                </tr>
                <tr
                  v-for="(dataCategoryDetail, id) in allDataCategoryDetail"
                  :key="dataCategoryDetail.id"
                >
                  <td
                    v-if="
                      id >= start_page_categoryDetail &&
                      id < end_page_categoryDetail
                    "
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_categoryDetail &&
                      id < end_page_categoryDetail
                    "
                  >
                    {{ dataCategoryDetail.name }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_categoryDetail &&
                      id < end_page_categoryDetail
                    "
                  >
                    <div class="box-action">
                      <b-button
                        v-b-modal.modalEditSubCategory
                        cancel-disabled="true"
                        class="btn-edit"
                        @click="getIdSubCategory(id)"
                      >
                        <p>Edit</p>
                      </b-button>
                      <b-button
                        v-b-modal.modalDeleteSubCategory
                        cancel-disabled="true"
                        class="btn-delete"
                        @click="getIdDeleteSub(id)"
                      >
                        <p>Delete</p>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </table>
              <div
                class="empty-card"
                v-else-if="isLoadingCategoryDetail === true"
              >
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_categoryDetail + 1 }} to
                {{ end_page_categoryDetail }} of
                {{ total_item_categoryDetail }} entries
              </p>
              <paginate
                v-model="paginate_categoryDetail"
                :page-count="total_page_categoryDetail"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageCategoryDetail"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue";
import TopHeader from "../../components/Header/TopHeader.vue";
import { onBeforeMount, ref } from "vue";
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";
import Axios from "axios";

export default {
  name: "CategoryManager",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    const store = useStore();
    let path = ref("Tools");
    let subPath = ref("Category Manager");
    let subSubPath = ref();
    let isDetail = ref(false);
    let isLoadingCategory = ref(true);
    let isLoadingCategoryDetail = ref(true);

    let allDataCategoryManager = ref(null);
    let defaultDataCategory = ref(null);
    let allDataCategoryDetail = ref(null);
    let defaultDataCategoryDetail = ref(null);
    let categoryDetail = ref(null);

    let popUpText = ref("");
    const popUp = (status) => {
      if (status === "SuccessAddCategory") {
        popUpText.value = "Add Category Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedAddCategory") {
        popUpText.value = "Add Category Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessEditCategory") {
        popUpText.value = "Edit Category Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedEditCategory") {
        popUpText.value = "Edit Category Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessDeleteCategory") {
        popUpText.value = "Delete Category Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedDeleteCategory") {
        popUpText.value = "Delete Category Failed";
        document.getElementById("popupFailed").style.display = "flex";
      }
      // else if(status === 'FailedAddSub'){
      //     popUpText.value = 'Add Sub Category Failed'
      //     document.getElementById("popupFailed").style.display = "flex";
      // }else if(status === 'FailedEditSub'){
      //     popUpText.value = 'Edit Sub Category Failed'
      //     document.getElementById("popupFailed").style.display = "flex";
      // }else if(status === 'FailedDeleteSub'){
      //     popUpText.value = 'Delete Sub Category Failed'
      //     document.getElementById("popupFailed").style.display = "flex";
      // }
    };

    const closepopUp = () => {
      document.getElementById("popup").style.display = "none";
      document.getElementById("popupFailed").style.display = "none";
    };

    let inputSearch = ref("");

    const searchList = () => {
      if (isDetail.value == false) {
        if (inputSearch.value !== "") {
          allDataCategoryManager.value = defaultDataCategory.value;
          let filter = allDataCategoryManager.value.filter((val) => {
            if (
              val.name.toUpperCase().includes(inputSearch.value.toUpperCase())
            ) {
              return val;
            }
          });
          if (filter) {
            allDataCategoryManager.value = filter;
          } else {
            allDataCategoryManager.value = defaultDataCategory.value;
          }
          changePageCategoryManager(1);
        } else {
          allDataCategoryManager.value = defaultDataCategory.value;
        }
        total_page_categoryManager.value = Math.ceil(
          allDataCategoryManager.value.length / 10
        );
        total_item_categoryManager.value = allDataCategoryManager.value.length;
        paginate_categoryManager.value = 1;
      }
      if (isDetail.value == true) {
        if (inputSearch.value !== "") {
          allDataCategoryDetail.value = defaultDataCategoryDetail.value;
          let filter = allDataCategoryDetail.value.filter((val) => {
            if (
              val.name.toUpperCase().includes(inputSearch.value.toUpperCase())
            ) {
              return val;
            }
          });
          if (filter) {
            allDataCategoryDetail.value = filter;
          } else {
            allDataCategoryDetail.value = defaultDataCategoryDetail.value;
          }
          changePageCategoryDetail(1);
        } else {
          allDataCategoryDetail.value = defaultDataCategoryDetail.value;
        }
        total_page_categoryDetail.value = Math.ceil(
          allDataCategoryDetail.value.length / 10
        );
        total_item_categoryDetail.value = allDataCategoryDetail.value.length;
        paginate_categoryDetail.value = 1;
      }
    };

    const export_to_csv = () => {
      let arrayToPdf = [];
      var rows = document.querySelectorAll("#categoryTable tr");
      let row = [];
      rows[0].querySelectorAll("td,th").forEach((val) => {
        row.push(val.innerText);
      });
      row.pop();
      arrayToPdf.push(row.join(","));
      allDataCategoryManager.value.forEach((val, index) => {
        arrayToPdf.push(`${index + 1},${val.name}`);
      });
      download_csv(arrayToPdf.join("\n"), subPath.value);
    };
    function download_csv(csv, filename) {
      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], { type: "text/csv" });
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }

    //Add new category
    let newNameCategory = ref(null);
    let newIndonesiaCategory = ref(null);
    let newEnglishCategory = ref(null);
    const addNewCategory = () => {
      let token = localStorage.getItem("idToken");
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/createEducationCategory",
        {
          name: newNameCategory.value,
          indonesian_translation: newIndonesiaCategory.value,
          english_translation: newEnglishCategory.value,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          newNameCategory.value = null;
          newIndonesiaCategory.value = null;
          newEnglishCategory.value = null;
          fetchCategoryManager();
          popUp("SuccessAddCategory");
        })
        .catch((err) => {
          newNameCategory.value = null;
          newIndonesiaCategory.value = null;
          newEnglishCategory.value = null;
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            popUp("FailedAddCategory");
          }
        });
    };

    //Edit Category
    let oldNameCategory = ref(null);
    let editNameCategory = ref(null);
    let editIndonesiaCategory = ref(null);
    let editEnglishCategory = ref(null);
    let oldUidCategory = ref(null);
    const getIdEdit = (id) => {
      oldNameCategory.value = allDataCategoryManager.value[id].name;
      oldUidCategory.value = allDataCategoryManager.value[id].category_uid;
    };
    const editCategory = () => {
      let token = localStorage.getItem("idToken");
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/editEducationCategory",
        {
          name: editNameCategory.value,
          indonesian_translation: editIndonesiaCategory.value,
          english_translation: editEnglishCategory.value,
          id: oldUidCategory.value,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          editNameCategory.value = null;
          editIndonesiaCategory.value = null;
          editEnglishCategory.value = null;
          fetchCategoryManager();
          popUp("SuccessEditCategory");
        })
        .catch((err) => {
          editNameCategory.value = null;
          editIndonesiaCategory.value = null;
          editEnglishCategory.value = null;
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            popUp("FailedEditCategory");
          }
        });
    };

    //Delete Category
    let deleteNameCategory = ref(null);
    let deleteUidCategory = ref(null);
    const getIdDelete = (id) => {
      deleteNameCategory.value = allDataCategoryManager.value[id].name;
      deleteUidCategory.value = allDataCategoryManager.value[id].category_uid;
    };
    const deleteCategory = () => {
      let token = localStorage.getItem("idToken");
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/deleteEducationCategoryByIds",
        {
          ids: [deleteUidCategory.value],
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          deleteUidCategory.value = null;
          fetchCategoryManager();
          popUp("SuccessDeleteCategory");
        })
        .catch((err) => {
          deleteUidCategory.value = null;
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            popUp("FailedDeleteCategory");
          }
        });
    };

    //addSubCategory
    let newNameSubCategory = ref(null);
    let newIndonesiaSubCategory = ref(null);
    let newEnglishSubCategory = ref(null);
    let newNameUidCategory = ref(null);

    const addNewSubCategory = () => {
      let token = localStorage.getItem("idToken");
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/createEducatiobSubCategory",
        {
          name: newNameSubCategory.value,
          indonesian_translation: newIndonesiaSubCategory.value,
          english_translation: newEnglishSubCategory.value,
          category_id: newNameUidCategory.value,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          newNameSubCategory.value = null;
          newIndonesiaSubCategory.value = null;
          newEnglishSubCategory.value = null;
          newNameUidCategory.value = null;
          // popUp('FailedAddSub')
          location.reload();
        })
        .catch((err) => {
          newNameSubCategory.value = null;
          newIndonesiaSubCategory.value = null;
          newEnglishSubCategory.value = null;
          newNameUidCategory.value = null;
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            location.reload();
          }
        });
    };

    //Edit Sub Category
    let oldNameSubCategory = ref(null);
    let editNameSubCategory = ref(null);
    let editIndonesiaSubCategory = ref(null);
    let editEnglishSubCategory = ref(null);
    let uidSubCategory = ref(null);
    let uidCategory = ref(null);

    const getIdSubCategory = (id) => {
      oldNameSubCategory.value = allDataCategoryDetail.value[id].name;
      uidSubCategory.value = allDataCategoryDetail.value[id].subcategory_uid;
    };

    const editSubCategory = () => {
      let token = localStorage.getItem("idToken");
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/editEducationSubCategory",
        {
          name: editNameSubCategory.value,
          indonesian_translation: editIndonesiaSubCategory.value,
          english_translation: editEnglishSubCategory.value,
          category_id: uidCategory.value,
          sub_category_id: uidSubCategory.value,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          editNameSubCategory.value = null;
          editIndonesiaSubCategory.value = null;
          editEnglishSubCategory.value = null;
          uidCategory.value = null;
          uidSubCategory.value = null;
          location.reload();
          // popUp()
        })
        .catch((err) => {
          editNameSubCategory.value = null;
          editIndonesiaSubCategory.value = null;
          editEnglishSubCategory.value = null;
          uidCategory.value = null;
          uidSubCategory.value = null;
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            location.reload();
          }
        });
    };

    //Delete Sub Category
    let deleteNameSubCategory = ref(null);
    let deleteUidCategoryForSub = ref(null);
    let deleteUidSubCategory = ref(null);

    const getIdDeleteSub = (id) => {
      deleteNameSubCategory.value = allDataCategoryDetail.value[id].name;
      deleteUidSubCategory.value =
        allDataCategoryDetail.value[id].subcategory_uid;
    };

    const deleteSubCategory = () => {
      let token = localStorage.getItem("idToken");
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/deleteEducationSubCategoryByIds",
        {
          category_id: deleteUidCategoryForSub.value,
          sub_category_ids: [deleteUidSubCategory.value],
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          deleteNameSubCategory.value = null;
          deleteUidCategoryForSub.value = null;
          deleteUidSubCategory.value = null;
          location.reload();
          // popUp()
        })
        .catch((err) => {
          deleteNameSubCategory.value = null;
          deleteUidCategoryForSub.value = null;
          deleteUidSubCategory.value = null;
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            location.reload();
          }
        });
    };

    //Category Manager State
    let start_page_categoryManager = ref(0);
    let end_page_categoryManager = ref(10);
    let total_item_categoryManager = ref(0);
    let total_page_categoryManager = ref(0);
    let paginate_categoryManager = ref(1);

    const changePageCategoryManager = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_page_categoryManager.value = new_start;
      end_page_categoryManager.value = new_end;
    };

    //Category Detail State
    let start_page_categoryDetail = ref(0);
    let end_page_categoryDetail = ref(10);
    let total_item_categoryDetail = ref(0);
    let total_page_categoryDetail = ref(0);
    let paginate_categoryDetail = ref(1);

    const changePageCategoryDetail = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_page_categoryDetail.value = new_start;
      end_page_categoryDetail.value = new_end;
    };

    const fetchCategoryManager = async () => {
      isLoadingCategory.value = true;
      await store.dispatch("getValueCategoryManager");
      let temp = await store.getters.getAllCategoryManager;
      allDataCategoryManager.value = temp;
      defaultDataCategory.value = temp;
      total_page_categoryManager.value = Math.ceil(
        allDataCategoryManager.value.length / 10
      );
      total_item_categoryManager.value = allDataCategoryManager.value.length;
      isLoadingCategory.value = false;
    };

    // let saveId = ref(null)
    const fetchCategoryDetail = (id) => {
      isLoadingCategoryDetail.value = true;
      isDetail.value = true;
      // saveId.value = id
      categoryDetail.value = allDataCategoryManager.value[id];
      newNameUidCategory.value = allDataCategoryManager.value[id].category_uid;
      uidCategory.value = allDataCategoryManager.value[id].category_uid;
      deleteUidCategoryForSub.value =
        allDataCategoryManager.value[id].category_uid;
      allDataCategoryDetail.value =
        allDataCategoryManager.value[id].subcategories;
      defaultDataCategoryDetail.value =
        allDataCategoryManager.value[id].subcategories;
      total_page_categoryDetail.value = Math.ceil(
        allDataCategoryDetail.value.length / 10
      );
      total_item_categoryDetail.value = allDataCategoryDetail.value.length;
      isLoadingCategoryDetail.value = false;
    };

    onBeforeMount(() => {
      fetchCategoryManager();
    });

    return {
      path,
      subPath,
      subSubPath,
      isDetail,
      isLoadingCategory,
      inputSearch,
      searchList,
      export_to_csv,
      download_csv,
      isLoadingCategoryDetail,
      allDataCategoryManager,
      defaultDataCategory,
      allDataCategoryDetail,
      defaultDataCategoryDetail,
      categoryDetail,
      popUpText,
      popUp,
      closepopUp,
      newNameCategory,
      newIndonesiaCategory,
      newEnglishCategory,
      addNewCategory,
      oldNameCategory,
      editNameCategory,
      editIndonesiaCategory,
      editEnglishCategory,
      oldUidCategory,
      getIdEdit,
      editCategory,
      deleteNameCategory,
      deleteUidCategory,
      getIdDelete,
      deleteCategory,
      newNameSubCategory,
      newIndonesiaSubCategory,
      newEnglishSubCategory,
      newNameUidCategory,
      addNewSubCategory,
      oldNameSubCategory,
      editNameSubCategory,
      editIndonesiaSubCategory,
      editEnglishSubCategory,
      uidSubCategory,
      uidCategory,
      getIdSubCategory,
      editSubCategory,
      deleteNameSubCategory,
      deleteUidCategoryForSub,
      deleteUidSubCategory,
      getIdDeleteSub,
      deleteSubCategory,
      start_page_categoryManager,
      end_page_categoryManager,
      total_item_categoryManager,
      total_page_categoryManager,
      paginate_categoryManager,
      changePageCategoryManager,
      start_page_categoryDetail,
      end_page_categoryDetail,
      total_item_categoryDetail,
      total_page_categoryDetail,
      paginate_categoryDetail,
      changePageCategoryDetail,
      // saveId,
      fetchCategoryDetail,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/Tools/CategoryManager.scss";
</style>