<template>
  <Suspense>
    <template #default>
      <div v-if="isLoading">
        <LoaderPage />
      </div>
      <router-view v-else />
    </template>
  </Suspense>
</template>
<script>
import { ref } from "vue";
import LoaderPage from "@/components/Loading/Loading.vue";

export default {
  name: "app",
  components: {
    LoaderPage,
  },
  setup() {
    let isLoading = ref(false);

    return {
      isLoading,
      LoaderPage,
    };
  },
};
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
</style>
