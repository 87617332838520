<template>
    <div class="container-translateObject">
        <SideHeader/>
        <div class="container-right-translateObject">
            <TopHeader
            :pathPage="path"
            :pathSubPage="subPath"
            :pathSubSubPage="subSubPath"
            />
            <div class="main-translateObject">
                <div class="box-header-translateObject">
                    <div class="box-navigation">
                        <div class="navigation active">Translate Object</div>
                    </div>
                    <div class="box-header-right">
                        <div class="btn-export">
                            <img src="../../assets/log-in.svg" alt="">
                            <p>Export</p>
                        </div>
                    </div>
                </div>
                <div class="box-main-translateObject">
                    <div class="box-page">
                        <div class="box-inputs">
                            <div class="input-left">
                                <!-- <div class="box-input-text">
                                    <p>Object Name (Bahasa Indonesia)</p>
                                    <input type="text" name="" id="">
                                </div> -->
                                <div class="box-input-logo">
                                    <p>Please Input Your Translation Object</p>
                                    <div class="input-logo"></div>
                                    <input @change="addTranslateFile" id="input=file"  type="file" accept=".xlsx, .xls, .csv"/>
                                    <label for="input=file" class="add-logo">
                                        <img src="../../assets/logo-change.svg" alt="">
                                        <p>Add File</p>
                                    </label>
                                    <div class="file-name"><p>File name: {{fileName}}</p></div>
                                </div>
                                <div @click="sendTranslate()" class="btn-submit">
                                    <p>Submit</p>
                                </div>
                            </div>
                            <div class="input-right">
                                <!-- <div class="box-input-text">
                                    <p>Object Name (English)</p>
                                    <input type="text" name="" id="">
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue"
import TopHeader from "../../components/Header/TopHeader.vue"
import {ref} from 'vue'
import readXlsxFile from 'read-excel-file'
import Axios from 'axios'


export default{
    name: "TranslateObject",
    components:{
    SideHeader,
    TopHeader,
},
    setup() {
        let path = ref('Tools')
        let subPath = ref('Translate Object')
        let subSubPath = ref()
        let file = ref(null)
        let fileName = ref(null)
        let dataToSend = ref([])

        const addTranslateFile=(event)=>{
            file.value = null
            fileName.value = null
            dataToSend.value = []
            file = event.target.files ? event.target.files[0] : null
            fileName.value = file.value.name
            readXlsxFile(file).then((rows) => {
                for(let i = 1; i < (rows.length); i++){
                    dataToSend.value.push({
                        original: rows[i][0],
                        indonesian: rows[i][1],
                        english: rows[i][2],
                        category: rows[i][3]
                    })
                }
            })
        }

        const sendTranslate=()=>{
            let token = localStorage.getItem('idToken')
            Axios.post('https://us-central1-millea-lab.cloudfunctions.net/web/api/translateObjectsFile',{
                objects: dataToSend.value
            },{
            headers:{
                    Authorization: 'Bearer ' + token,
            }}
            ).then(()=>{
                file.value = null
                fileName.value = null
                dataToSend.value = null
            }).catch((err)=>{
                if(err.message === 'Request failed with status code 401'){
                    localStorage.removeItem('idStorage')
                    window.location.href="/login"
                }
            })
        }
        return{
            path,
            subPath,
            subSubPath,
            file,
            fileName,
            dataToSend,
            addTranslateFile,
            sendTranslate,
        };
    },
};
</script>

<style lang="scss">
@import "../../scss/Tools/TranslateObject.scss";
</style>