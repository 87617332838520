<template>
    <div class="bg-loader">
        
        <!-- <img src="@/assets/otissenang.png" alt=""> -->
          <!-- <div class="loading">
                <div class="loading__letter">L</div>
                <div class="loading__letter">o</div>
                <div class="loading__letter">a</div>
                <div class="loading__letter">d</div>
                <div class="loading__letter">i</div>
                <div class="loading__letter">n</div>
                <div class="loading__letter">g</div>
                <div class="loading__letter">.</div>
                <div class="loading__letter">.</div>
                <div class="loading__letter">.</div>
            </div> -->
         <!-- <svg id="preloader" width="240px" height="120px" viewBox="0 0 240 120" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  
            <path id="loop-normal" class="st1" d="M120.5,60.5L146.48,87.02c14.64,14.64,38.39,14.65,53.03,0s14.64-38.39,0-53.03s-38.39-14.65-53.03,0L120.5,60.5
            L94.52,87.02c-14.64,14.64-38.39,14.64-53.03,0c-14.64-14.64-14.64-38.39,0-53.03c14.65-14.64,38.39-14.65,53.03,0z">
                <animate attributeName="stroke-dasharray" from="500, 50" to="450 50" begin="0s" dur="2s" repeatCount="indefinite" />
                <animate attributeName="stroke-dashoffset" from="-40" to="-540" begin="0s" dur="2s" repeatCount="indefinite" />
            </path>

            <path id="loop-offset" d="M146.48,87.02c14.64,14.64,38.39,14.65,53.03,0s14.64-38.39,0-53.03s-38.39-14.65-53.03,0L120.5,60.5L94.52,87.02c-14.64,14.64-38.39,14.64-53.03,0c-14.64-14.64-14.64-38.39,0-53.03c14.65-14.64,38.39-14.65,53.03,0L120.5,60.5L146.48,87.02z"></path>

            <path id="socket" d="M7.5,0c0,8.28-6.72,15-15,15l0-30C0.78-15,7.5-8.28,7.5,0z">
                <animateMotion
                dur="2s"
                repeatCount="indefinite"
                rotate="auto"
                keyTimes="0;1"
                keySplines="0.42, 0.0, 0.58, 1.0"
                >
                <mpath xlink:href="#loop-offset"/>
                </animateMotion>
            </path>
            
            <path id="plug" d="M0,9l15,0l0-5H0v-8.5l15,0l0-5H0V-15c-8.29,0-15,6.71-15,15c0,8.28,6.71,15,15,15V9z">
            <animateMotion
                dur="2s"
                rotate="auto"
                repeatCount="indefinite"
                keyTimes="0;1"    
                keySplines="0.42, 0, 0.58, 1"
            >
                <mpath xlink:href="#loop-normal"/>
            </animateMotion>
            </path>   

        </svg>  -->
        <!-- <video autoplay muted loop id="load_video"  >
            <source src="../../assets/OtisFerrisWheel.mp4" type="video/mp4">
        </video> -->
        <img src="../../assets/otisferrisWheelTransparent.gif" alt="">
    </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'

export default {
    name:'LoaderPage',
    setup(){

        return{
            
        }
    }
}
</script>

<style lang="scss">

.bg-loader{
      background: #4b9ce6;
    width: 100%;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    #load_video{
        // position: fixed;
        // right: 0;
        // bottom: 0;
        // min-width: 50%;
        // min-height: 50%;
        object-fit: cover;
        width: 200px;
        height: 250px;;
        position: fixed;

    }
    
    .loading {
    display: flex;
    flex-direction: row;
    position:relative;
    // top:10%;
    .loading__letter {
        font-size: 70px;
        font-weight: normal;
        letter-spacing: 4px;
        text-transform: uppercase;
        font-family: "Audiowide";
        color: #FFCF78;
        animation-name: bounce;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        }

        .loading__letter:nth-child(2) {
        animation-delay: .1s;	
        }
        .loading__letter:nth-child(3) {
        animation-delay: .2s;
        }
        .loading__letter:nth-child(4) {
        animation-delay: .3s;	
        }
        .loading__letter:nth-child(5) {
        animation-delay: .4s;
        }
        .loading__letter:nth-child(6) {
        animation-delay: .5s;	
        }
        .loading__letter:nth-child(7) {
        animation-delay: .6s;
        }
        .loading__letter:nth-child(8) {
        animation-delay: .8s;
        }
        .loading__letter:nth-child(9) {
        animation-delay: 1s;
        }
        .loading__letter:nth-child(10) {
        animation-delay: 1.2s;
        }
    }
    @keyframes bounce {
    0% {
        transform: translateY(0px)
    }
    40% {
        transform: translateY(-40px);
    }
    80%,
    100% {
        transform: translateY(0px);
    }
    }


    @media (max-width: 700px) {
    .loading__letter {
        font-size: 50px;
    }
    }

    @media (max-width: 340px) {
    .loading__letter {
        font-size: 40px;
    }
    }
    img{
        // width:30%;
        // height:60%;
        width:250px;
        height:250px;
        position: relative;
        // top:10%;
    }

    svg {
    // position: absolute;
    width: 240px;
    // height: 120px;
    height:120px;
    top: 0; right: 0;
    bottom: 0; left: 0; 
    margin: auto;
    }

    svg #plug,
    svg #socket {
    fill:#0794db;
    }

    svg #loop-normal {
    fill: none;
    stroke: #0794db;
    stroke-width: 12;
    }

    svg #loop-offset {
    display: none;
    }
}
</style>
