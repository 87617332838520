<template>
  <!-- Popup -->
  <div id="popup" class="box-popup">
    <div class="popup-content">
      <div class="popup-left">
        <img src="../../assets/succeed-popup.svg" alt="" />
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <div id="popupFailed" class="box-popup">
    <div class="popup-failed">
      <div class="popup-left">
        <!-- <img src="../../assets/succeed-popup.svg" alt=""> -->
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>

  <!-- Modal Create Event -->
  <b-modal id="modalCreateEvent" title="Create Event">
    <div class="box-input-text">
      <p>Title</p>
      <input v-model="addEventTitle" type="text" name="" id="" />
    </div>
    <div class="box-input-textarea">
      <p>Description</p>
      <textarea v-model="addEventDescription" name="" id=""></textarea>
    </div>
    <div class="box-input-text">
      <p>Max Attendant</p>
      <input v-model="addEventMaxAttendant" type="number" name="" id="" />
    </div>
    <div class="box-input-text50">
      <div class="input-text50">
        <p>Date Start</p>
        <input v-model="addEventDate" type="date" name="" id="" />
      </div>
      <div class="input-text50">
        <p>Time Start</p>
        <input v-model="addEventTime" type="time" name="" id="" />
      </div>
    </div>
    <div class="box-input-dropdown">
      <p>Timezone</p>
      <select v-model="addEventTimeZone" name="" id="">
        <option value="Asia/Jayapura"><p>WIT</p></option>
        <option value="Asia/Makassar"><p>WITA</p></option>
        <option value="Asia/Jakarta"><p>WIB</p></option>
      </select>
    </div>
    <div class="box-input-button">
      <p>Location</p>
      <div class="input-bottom">
        <button
          class="btn-input"
          :class="[{ active: activeType === 'online' }]"
          @click="changeEventType('online')"
        >
          <p>Online</p>
        </button>
        <button
          class="btn-input"
          :class="[{ active: activeType === 'offline' }]"
          @click="changeEventType('offline')"
        >
          <p>Offline</p>
        </button>
      </div>
    </div>
    <div class="box-input-text" v-if="activeType === 'online'">
      <p>Link</p>
      <input v-model="addEventLink" type="text" name="" id="" />
    </div>
    <div class="box-input-text" v-else>
      <p>Address</p>
      <input v-model="addEventLink" type="text" name="" id="" />
    </div>
    <div class="box-input-logo">
      <div class="title">
        <p>Banner Image (.jpg/.jpeg/.png)</p>
      </div>
      <!-- <div class="input-logo"></div> -->
      <input
        @change="addEventPicture"
        id="input=pictureEdit"
        type="file"
        accept=".jpg,.jpeg,.png"
      />
      <label for="input=pictureEdit" class="add-logo">
        <img v-if="previewImg" :src="previewImg" />
        <div v-else class="else">
          <img src="../../assets/logo-change.svg" alt="" />
          <p>Add Banner</p>
        </div>
      </label>
      <p class="file-name">File name: {{ pictureName }}</p>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal"><p>CANCEL</p></div>
      <div class="btn-submit" data-bs-dismiss="modal" @click="addNewEvent()">
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>

  <!-- Modal Edit Event -->
  <b-modal id="modalEditEvent" title="Edit Event">
    <div class="box-input-text">
      <p>Title</p>
      <input v-model="editEventTitle" type="text" name="" id="" />
    </div>
    <div class="box-input-textarea">
      <p>Description</p>
      <textarea v-model="editEventDescription" name="" id=""></textarea>
    </div>
    <div class="box-input-text">
      <p>Max Attendant</p>
      <input v-model="editEventMaxAttendant" type="number" name="" id="" />
    </div>
    <div class="box-input-text50">
      <div class="input-text50">
        <p>Date Start</p>
        <input v-model="editEventDate" type="date" name="" id="" />
      </div>
      <div class="input-text50">
        <p>Time Start</p>
        <input v-model="editEventTime" type="time" name="" id="" />
      </div>
    </div>
    <div class="box-input-dropdown">
      <p>Timezone</p>
      <select v-model="editEventTimeZone" name="" id="">
        <option value="Asia/Jayapura"><p>WIT</p></option>
        <option value="Asia/Makassar"><p>WITA</p></option>
        <option value="Asia/Jakarta"><p>WIB</p></option>
      </select>
    </div>
    <div class="box-input-button">
      <p>Location</p>
      <div class="input-bottom">
        <button
          class="btn-input"
          :class="[{ active: activeType === 'online' }]"
          @click="changeEventType('online')"
        >
          <p>Online</p>
        </button>
        <button
          class="btn-input"
          :class="[{ active: activeType === 'offline' }]"
          @click="changeEventType('offline')"
        >
          <p>Offline</p>
        </button>
      </div>
    </div>
    <div class="box-input-text" v-if="activeType === 'online'">
      <p>Link</p>
      <input v-model="editEventLink" type="text" name="" id="" />
    </div>
    <div class="box-input-text" v-else>
      <p>Address</p>
      <input v-model="editEventLink" type="text" name="" id="" />
    </div>
    <div class="box-input-logo">
      <div class="title">
        <p>Banner Image (.jpg/.jpeg/.png)</p>
      </div>
      <!-- <div class="input-logo"></div> -->
      <input
        @change="editEventPicture"
        id="input=pictureAdd"
        type="file"
        accept=".jpg,.jpeg,.png"
      />
      <label for="input=pictureAdd" class="add-logo">
        <img v-if="previewImg" :src="previewImg" />
        <div v-else class="else">
          <img src="../../assets/logo-change.svg" alt="" />
          <p>Add Banner</p>
        </div>
      </label>
      <p class="file-name">File name: {{ pictureName }}</p>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal"><p>CANCEL</p></div>
      <div class="btn-submit" data-bs-dismiss="modal" @click="editEvent()">
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>

  <!-- Modal Delete Event -->
  <b-modal id="modalDeleteEvent" title="Delete Event">
    <div class="box-delete">
      <p>Are you sure you want to delete</p>
      <p>"{{ deleteEventName }}"</p>
      <p>Event ?</p>
    </div>
    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal"><p>CANCEL</p></div>
      <div class="btn-submit" data-bs-dismiss="modal" @click="deleteEvent()">
        <p>DELETE</p>
      </div>
    </div>
  </b-modal>

  <div class="container-event">
    <SideHeader />
    <div class="container-right-event">
      <TopHeader
        :pathPage="path"
        :pathSubPage="subPath"
        :pathSubSubPage="subSubPath"
      />
      <div class="main-event">
        <div class="box-header-event">
          <div class="box-navigation">
            <div class="navigation active">Event List</div>
          </div>
          <div class="box-header-right">
            <div class="btn-export">
              <img src="../../assets/log-in.svg" alt="" />
              <p>Export</p>
            </div>
          </div>
        </div>
        <div class="box-main-event">
          <div v-if="isDetail == false" class="box-page">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="card-date">
                  <div class="date-title" @click="openDate()">
                    <img src="../../assets/calendar.svg" alt="" />
                    <p>Date</p>
                  </div>
                  <div id="date" class="dropdown-date">
                    <div class="card-start-end">
                      <input
                        v-model="startDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                      <p>to</p>
                      <input
                        v-model="endDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                    </div>
                    <div class="separator"></div>
                    <div class="box-date-filter">
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'today' }]"
                        @click="dateFilterFunction('today')"
                      >
                        Today
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'yesterday' }]"
                        @click="dateFilterFunction('yesterday')"
                      >
                        Yesterday
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'week' }]"
                        @click="dateFilterFunction('week')"
                      >
                        Last Week
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'month' }]"
                        @click="dateFilterFunction('month')"
                      >
                        Last Month
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
              <div class="date-searching-right">
                <b-button
                  v-b-modal.modalCreateEvent
                  cancel-disabled="true"
                  class="btn-add"
                  @click="changeEventType('online')"
                >
                  <p>+ Add Event</p>
                </b-button>
              </div>
            </div>
            <div class="box-table">
              <table id="categoryTable" v-if="isLoadingEvent == false">
                <tr>
                  <th>No</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Date Start</th>
                  <th>Time Start</th>
                  <th>Location</th>
                  <th>Action</th>
                </tr>
                <tr
                  class="hover-row"
                  v-for="(dataEventList, id) in alldataEventList"
                  :key="dataEventList.id"
                >
                  <td
                    @click="fetchDetailEvent(id)"
                    v-if="id >= start_page_events && id < end_page_events"
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    @click="fetchDetailEvent(id)"
                    v-if="id >= start_page_events && id < end_page_events"
                  >
                    {{ dataEventList.event_title }}
                  </td>
                  <td
                    @click="fetchDetailEvent(id)"
                    v-if="id >= start_page_events && id < end_page_events"
                  >
                    {{ dataEventList.event_description }}
                  </td>
                  <td
                    @click="fetchDetailEvent(id)"
                    v-if="id >= start_page_events && id < end_page_events"
                  >
                    {{
                      dataEventList.event_date
                        .substring(0, 10)
                        .replaceAll("-", "/")
                    }}
                  </td>
                  <td
                    @click="fetchDetailEvent(id)"
                    v-if="id >= start_page_events && id < end_page_events"
                  >
                    {{ dataEventList.event_date.substring(11, 16) }}
                  </td>
                  <td
                    @click="fetchDetailEvent(id)"
                    v-if="id >= start_page_events && id < end_page_events"
                  >
                    {{ dataEventList.event_type }}
                  </td>
                  <td v-if="id >= start_page_events && id < end_page_events">
                    <div class="box-action">
                      <b-button
                        v-b-modal.modalEditEvent
                        cancel-disabled="true"
                        class="btn-edit"
                        @click="getIdEditEvents(id)"
                      >
                        <p>Edit</p>
                      </b-button>
                      <b-button
                        v-b-modal.modalDeleteEvent
                        cancel-disabled="true"
                        class="btn-delete"
                        @click="getIdDeleteEvents(id)"
                      >
                        <p>Delete</p>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="empty-card" v-else>
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_events + 1 }} to {{ end_page_events }} of
                {{ total_item_events }} entries
              </p>
              <paginate
                v-model="paginate_events"
                :page-count="total_page_events"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageEvents"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
          <div v-else class="box-page">
            <div class="box-back" @click="isDetail = false">
              <img src="../../assets/back.svg" alt="" />
              <p>Back to Event List</p>
            </div>
            <div class="box-details">
              <div class="detail-name">
                <p>{{ alldataEventDetail.event_title }}</p>
              </div>
              <div class="box-detail-desc">
                <div class="detail-data">
                  <div class="title">
                    <p>Description:</p>
                  </div>
                  <div class="description">
                    <p>{{ alldataEventDetail.event_description }}</p>
                  </div>
                </div>
                <div class="detail-data">
                  <div class="title">
                    <p>Date Start:</p>
                  </div>
                  <div class="description">
                    <p>
                      {{
                        alldataEventDetail.event_date
                          .substring(0, 10)
                          .replaceAll("-", "/")
                      }}
                    </p>
                  </div>
                </div>
                <div class="detail-data">
                  <div class="title">
                    <p>Time Start:</p>
                  </div>
                  <div class="description">
                    <p>{{ alldataEventDetail.event_date.substring(11, 16) }}</p>
                  </div>
                </div>
                <div class="detail-data">
                  <div class="title">
                    <p>Location:</p>
                  </div>
                  <div class="description">
                    <p>{{ alldataEventDetail.event_type }}</p>
                  </div>
                </div>
                <div class="detail-data">
                  <div class="title">
                    <p>Address:</p>
                  </div>
                  <div class="description">
                    <p>{{ alldataEventDetail.event_date_timezone }}</p>
                  </div>
                </div>
                <div class="detail-data">
                  <div class="title">
                    <p>Max Attendant:</p>
                  </div>
                  <div class="description">
                    <p>{{ alldataEventDetail.event_max_attendant }}</p>
                  </div>
                </div>
                <div class="detail-data">
                  <div class="title">
                    <p>Banner Image:</p>
                  </div>
                  <div class="description">
                    <p
                      class="toggle"
                      @click="toggleImage = true"
                      v-if="toggleImage == false"
                    >
                      View Image
                    </p>
                    <p
                      class="toggle"
                      @click="toggleImage = false"
                      v-if="toggleImage == true"
                    >
                      Hide Image
                    </p>
                    <!-- <img :src="alldataEventDetail.event_banner_file_url" alt=""> -->
                  </div>
                </div>
                <div class="detail-data" v-if="toggleImage == true">
                  <img :src="alldataEventDetail.event_banner_file_url" alt="" />
                </div>
              </div>
            </div>
            <div class="separator"></div>
            <div class="box-date-searching-left">
              <div class="date-searching-left">
                <div class="card-date">
                  <div class="date-title" @click="openDate()">
                    <img src="../../assets/calendar.svg" alt="" />
                    <p>Date</p>
                  </div>
                  <div id="date" class="dropdown-date">
                    <div class="card-start-end">
                      <input
                        v-model="startDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                      <p>to</p>
                      <input
                        v-model="endDateRange"
                        @change="dateFilterFunction()"
                        type="date"
                      />
                    </div>
                    <div class="separator"></div>
                    <div class="box-date-filter">
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'today' }]"
                        @click="dateFilterFunction('today')"
                      >
                        Today
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'yesterday' }]"
                        @click="dateFilterFunction('yesterday')"
                      >
                        Yesterday
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'week' }]"
                        @click="dateFilterFunction('week')"
                      >
                        Last Week
                      </div>
                      <div
                        class="date-filter"
                        :class="[{ active: datefilter === 'month' }]"
                        @click="dateFilterFunction('month')"
                      >
                        Last Month
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-searching">
                  <input
                    v-model="inputSearch"
                    type="text"
                    placeholder="Search"
                    @change="searchList()"
                  />
                  <img src="../../assets/searching.svg" alt="" />
                </div>
              </div>
              <!-- <div class="date-searching-right">
                <b-button
                  v-b-modal.modalCreateEvent
                  cancel-disabled="true"
                  class="btn-add"
                >
                  <p>+ Add User</p>
                </b-button>
              </div> -->
            </div>
            <div class="box-table">
              <table id="categoryTable">
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>UID</th>
                </tr>
                <tr
                  v-for="(
                    dataAttendants, id
                  ) in alldataEventDetail.joined_attendants"
                  :key="dataAttendants.id"
                >
                  <td>{{ id + 1 }}</td>
                  <td>
                    <img :src="dataAttendants.picture" alt="" />
                    {{ dataAttendants.name }}
                  </td>
                  <td>
                    {{ dataAttendants.email }}
                  </td>
                  <td>
                    {{ dataAttendants.user_uid }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue";
import TopHeader from "../../components/Header/TopHeader.vue";
import { onBeforeMount, ref } from "vue";
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";
import Axios from "axios";

export default {
  name: "PendekarEvents",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    const store = useStore();
    let path = ref("PendekarVR");
    let subPath = ref("Events");
    let subSubPath = ref();
    let datefilter = ref("");
    let isDetail = ref(false);
    let toggleImage = ref(false);
    let previewImg = ref(null);

    let alldataEventList = ref(null);
    let defaultDataEventList = ref(null);
    let alldataEventDetail = ref(null);

    let isLoadingEvent = ref(true);

    const openDate = () => {
      document.getElementById("date").classList.toggle("show");
    };

    let startDateRange = ref("");
    let endDateRange = ref("");
    const dateFilterFunction = (params) => {
      if (startDateRange.value != "" && endDateRange.value != "") {
        datefilter.value = "";
        openDate();
      } else if (params == "today") {
        if (datefilter.value == "today") {
          datefilter.value = "";
        } else {
          datefilter.value = "today";
        }
        openDate();
      } else if (params == "yesterday") {
        if (datefilter.value == "yesterday") {
          datefilter.value = "";
        } else {
          datefilter.value = "yesterday";
        }
        openDate();
      } else if (params == "week") {
        if (datefilter.value == "week") {
          datefilter.value = "";
        } else {
          datefilter.value = "week";
        }
        openDate();
      } else if (params == "month") {
        if (datefilter.value == "month") {
          datefilter.value = "";
        } else {
          datefilter.value = "month";
        }
        openDate();
      }
      // else{
      // }
    };

    let popUpText = ref("");
    const popUp = (status) => {
      if (status == "SuccessAddEvent") {
        popUpText.value = "Add Event Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedAddEvent") {
        popUpText.value = "Add Event Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessEditEvent") {
        popUpText.value = "Edit Event Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedEditEvent") {
        popUpText.value = "Edit Event Failed";
        document.getElementById("popupFailed").style.display = "flex";
      } else if (status === "SuccessDeleteEvent") {
        popUpText.value = "Delete Event Success";
        document.getElementById("popup").style.display = "flex";
      } else if (status === "FailedDeleteEvent") {
        popUpText.value = "Delete Event Failed";
        document.getElementById("popupFailed").style.display = "flex";
      }
    };

    const closepopUp = () => {
      document.getElementById("popup").style.display = "none";
      document.getElementById("popupFailed").style.display = "none";
    };

    let inputSearch = ref("");

    const searchList = () => {
      if (inputSearch.value !== "") {
        alldataEventList.value = defaultDataEventList.value;
        // console.log(alldataEventList);
        let filter = alldataEventList.value.filter((val) => {
          if (
            val.event_title
              .toUpperCase()
              .includes(inputSearch.value.toUpperCase())
          ) {
            return val;
          }
        });
        if (filter) {
          alldataEventList.value = filter;
        } else {
          alldataEventList.value = defaultDataEventList.value;
        }
        changePageEvents(1);
      } else {
        alldataEventList.value = defaultDataEventList.value;
      }
      total_page_events.value = Math.ceil(alldataEventList.value.length / 10);
      total_item_events.value = alldataEventList.value.length;
      paginate_events.value = 1;
    };

    //add new Event
    let addEventDescription = ref(null);
    let addEventDate = ref(null);
    let addEventTime = ref(null);
    let addEventLink = ref(null);
    let addEventMaxAttendant = ref(null);
    let addEventTitle = ref(null);
    let addEventType = ref(null);
    let addEventTimeZone = ref(null);
    const sendDataAddEvent = new FormData();
    //notes online Location - Link
    //notes offline Location - address
    //event link = address

    let pictureName = ref(null);
    const addEventPicture = (event) => {
      sendDataAddEvent.delete("event_banner_file");
      let selectedFile = event.target.files[0];
      sendDataAddEvent.append("event_banner_file", selectedFile);
      pictureName.value = selectedFile.name;
      const file = event.target.files[0];
      previewImg.value = URL.createObjectURL(file);
    };

    let activeType = ref("online");
    const changeEventType = (type) => {
      activeType.value = type;
      if (type == "online") {
        addEventType.value = "2DFCcljPNicZtjIFZUDL";
      } else {
        addEventType.value = "tyBN4rjEJUTWsKN2Gjfk";
      }
      sendDataAddEvent.append("event_type_id", addEventType.value);
    };

    const addNewEvent = () => {
      isLoadingEvent.value = true;
      pictureName.value = "";
      previewImg.value = null;
      let token = localStorage.getItem("idToken");
      sendDataAddEvent.append("event_description", addEventDescription.value);
      sendDataAddEvent.append("event_link", addEventLink.value);
      sendDataAddEvent.append(
        "event_max_attendant",
        addEventMaxAttendant.value
      );
      sendDataAddEvent.append("event_title", addEventTitle.value);
      sendDataAddEvent.append("event_date_timezone", addEventTimeZone.value);

      const isoDateStr =
        addEventDate.value + "T" + addEventTime.value + ":00.000Z";
      // console.log(isoDateStr);
      sendDataAddEvent.append("event_date", isoDateStr);

      // for (var pair of sendDataAddEvent.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/events",
        sendDataAddEvent,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          console.log("success");
          popUp("SuccessAddEvent");
          fetchEventList();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedAddEvent");
          }
        });
    };

    //Edit Events List
    let editEventId = ref(null);
    let editEventDescription = ref(null);
    let editEventDate = ref(null);
    let editEventTime = ref(null);
    let editEventLink = ref(null);
    let editEventMaxAttendant = ref(null);
    let editEventTitle = ref(null);
    let editEventType = ref(null);
    let editEventTimeZone = ref(null);
    let editPicture = ref(null);
    const sendDataEditEvent = new FormData();

    let editPictureName = ref(null);
    const editEventPicture = (event) => {
      // console.log('masuk edit pict')
      sendDataEditEvent.delete("event_banner_file");
      let selectedFile = event.target.files[0];
      sendDataEditEvent.append("event_banner_file", selectedFile);
      pictureName.value = selectedFile.name;
      const file = event.target.files[0];
      previewImg.value = URL.createObjectURL(file);
    };

    const getIdEditEvents = (id) => {
      editEventId.value = alldataEventList.value[id].id;
      editEventDescription.value = alldataEventList.value[id].event_description;
      editEventDate.value = alldataEventList.value[id].event_date.substring(
        0,
        10
      );
      editEventTime.value = alldataEventList.value[id].event_date.substring(
        11,
        16
      );
      editEventLink.value = alldataEventList.value[id].event_link;
      editEventMaxAttendant.value =
        alldataEventList.value[id].event_max_attendant;
      editEventTitle.value = alldataEventList.value[id].event_title;
      if (alldataEventList.value[id].event_type == "ONLINE") {
        activeType.value = "online";
        editEventType.value = "2DFCcljPNicZtjIFZUDL";
      } else {
        activeType.value = "offline";
        editEventType.value = "tyBN4rjEJUTWsKN2Gjfk";
      }
      sendDataEditEvent.append("event_type_id", editEventType.value);
      editEventTimeZone.value = alldataEventList.value[id].event_date_timezone;
    };

    const editEvent = () => {
      isLoadingEvent.value = true;
      pictureName.value = "";
      previewImg.value = null;
      let token = localStorage.getItem("idToken");
      sendDataEditEvent.append("event_description", editEventDescription.value);
      sendDataEditEvent.append("event_link", editEventLink.value);
      sendDataEditEvent.append(
        "event_max_attendant",
        editEventMaxAttendant.value
      );
      sendDataEditEvent.append("event_title", editEventTitle.value);
      sendDataEditEvent.append("event_date_timezone", editEventTimeZone.value);

      const isoDateStr =
        editEventDate.value + "T" + editEventTime.value + ":00.000Z";
      // console.log(isoDateStr);
      sendDataEditEvent.append("event_date", isoDateStr);

      // for (var pair of sendDataEditEvent.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      Axios.put(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/events/" +
          editEventId.value,
        sendDataEditEvent,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          console.log("success");
          popUp("SuccessEditEvent");
          fetchEventList();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedEditEvent");
          }
        });
    };
    let deleteEventName = ref(null);
    let deleteEventId = ref(null);
    const getIdDeleteEvents = (id) => {
      // console.log(id);
      deleteEventId.value = alldataEventList.value[id].id;
      deleteEventName.value = alldataEventList.value[id].event_title;
    };
    const deleteEvent = () => {
      isLoadingEvent.value = true;
      let token = localStorage.getItem("idToken");
      Axios.delete(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/events/" +
          deleteEventId.value,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          console.log("success");
          popUp("SuccessDeleteEvent");
          fetchEventList();
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          } else {
            console.log(err);
            popUp("FailedDeleteEvent");
          }
        });
    };

    //Event State
    let start_page_events = ref(0);
    let end_page_events = ref(10);
    let total_item_events = ref(0);
    let total_page_events = ref(0);
    let paginate_events = ref(1);

    const changePageEvents = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_page_events.value = new_start;
      end_page_events.value = new_end;
    };

    const fetchEventList = async () => {
      isLoadingEvent.value = true;
      await store.dispatch("getValueEventList");
      alldataEventList.value = await store.getters.getAllEventList;
      defaultDataEventList.value = alldataEventList.value.result;
      alldataEventList.value = alldataEventList.value.result;
      // console.log(alldataEventList);

      total_page_events.value = Math.ceil(alldataEventList.value.length / 10);
      total_item_events.value = alldataEventList.value.length;

      isLoadingEvent.value = false;
    };

    const fetchDetailEvent = (id) => {
      // console.log(id);
      isDetail.value = true;
      toggleImage.value = false;
      alldataEventDetail.value = alldataEventList.value[id];
      // console.log(alldataEventDetail);
    };

    onBeforeMount(() => {
      fetchEventList();
    });

    return {
      path,
      subPath,
      subSubPath,
      datefilter,
      isDetail,
      toggleImage,
      previewImg,
      alldataEventList,
      defaultDataEventList,
      alldataEventDetail,
      isLoadingEvent,
      openDate,
      startDateRange,
      endDateRange,
      dateFilterFunction,
      searchList,
      inputSearch,
      addEventDescription,
      addEventDate,
      addEventTime,
      addEventLink,
      addEventMaxAttendant,
      addEventTitle,
      addEventType,
      addEventTimeZone,
      pictureName,
      sendDataAddEvent,
      addEventPicture,
      activeType,
      changeEventType,
      addNewEvent,
      editEventId,
      deleteEventName,
      deleteEventId,
      getIdDeleteEvents,
      deleteEvent,
      editEventDescription,
      editEventDate,
      editEventTime,
      editEventLink,
      editEventMaxAttendant,
      editEventTitle,
      editEventType,
      editEventTimeZone,
      editPicture,
      editPictureName,
      editEventPicture,
      getIdEditEvents,
      editEvent,
      start_page_events,
      end_page_events,
      total_item_events,
      total_page_events,
      paginate_events,
      changePageEvents,
      fetchEventList,
      fetchDetailEvent,
      popUpText,
      popUp,
      closepopUp,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/PendekarVR/Event.scss";
</style>
