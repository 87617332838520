<template>
  <div class="container-sideHeader-resize">
    <div class="box-logo">
      <img src="../../assets/Header/millealogoside.svg" alt="" />
    </div>
    <div class="box-dropdown" v-if="isExtendIndividual === true">
      <div class="box-menu" @click="onIndividualClick">
        <div class="box-separate-icon">
          <img src="../../assets/Header/individual.svg" alt="" />
          <p>Individual License</p>
        </div>
        <img src="../../assets/Header/arrowup.svg" alt="" class="icon-arrow" />
      </div>
      <div class="box-sub-menu">
        <div class="box-separate-sub">
          <router-link to="/">
            <p>History Activity</p>
          </router-link>
        </div>
        <div class="box-separate-sub">
          <router-link to="/review-request">
            <p>Review Request</p>
          </router-link>
        </div>
        <div class="box-separate-sub">
          <router-link to="/active-user-list">
            <p>Active User List</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="box-dropdown" v-else>
      <div class="box-menu" @click="onIndividualClick">
        <div class="box-separate-icon">
          <img src="../../assets/Header/individual.svg" alt="" />
          <p>Individual License</p>
        </div>
        <img
          src="../../assets/Header/arrowdown.svg"
          alt=""
          class="icon-arrow"
        />
      </div>
    </div>
    <!-- end of individual dropdown -->
    <div class="box-dropdown" @click="onUserClick" v-if="isExtendUser === true">
      <div class="box-menu">
        <div class="box-separate-icon">
          <img src="../../assets/Header/analytic.svg" alt="" />
          <p>Analytic</p>
        </div>
        <img src="../../assets/Header/arrowup.svg" alt="" class="icon-arrow" />
      </div>
      <div class="box-sub-menu">
        <div class="box-separate-sub">
          <router-link to="/developer">
            <p>Developer</p>
          </router-link>
        </div>
        <div class="box-separate-sub">
          <router-link to="/user">
            <p>User</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="box-dropdown" @click="onUserClick" v-else>
      <div class="box-menu">
        <div class="box-separate-icon">
          <img src="../../assets/Header/analytic.svg" alt="" />
          <p>Analytic</p>
        </div>
        <img
          src="../../assets/Header/arrowdown.svg"
          alt=""
          class="icon-arrow"
        />
      </div>
    </div>
    <!-- end of analytic dropdown -->
    <!-- <div class="box-dropdown" @click="onTrainingClick" v-if="isExtendTraining === true">
    <div class="box-menu">
      <div class="box-separate-icon">
        <img src="../../assets/Header/training.svg" alt="">
        <p>Training</p>
      </div>
      <img src="../../assets/Header/arrowup.svg" alt="" class="icon-arrow">
    </div>
    <div class="box-sub-menu">
      <div class="box-separate-sub">
        <router-link to="/training-list">
          <p>Training List</p>
        </router-link>
      </div>
      <div class="box-separate-sub">
        <router-link to="/trainer-list">
          <p>Trainer List</p>
        </router-link>
      </div>
      <div class="box-separate-sub">
        <router-link to="/user-list">
          <p>User List</p>
        </router-link>
      </div>
      <div class="box-separate-sub">
        <router-link to="/purchase-history">
          <p>Purchase History</p>
        </router-link>
      </div>
    </div>
  </div>
  <div class="box-dropdown"  @click="onTrainingClick" v-else>
    <div class="box-menu">
      <div class="box-separate-icon">
        <img src="../../assets/Header/training.svg" alt="">
        <p>Training</p>
      </div>
      <img src="../../assets/Header/arrowdown.svg" alt="" class="icon-arrow">
    </div>
  </div> -->
    <!-- end of training dropdown -->
    <div
      class="box-dropdown"
      @click="onToolsClick"
      v-if="isExtendTools === true"
    >
      <div class="box-menu">
        <div class="box-separate-icon">
          <img src="../../assets/Header/analytic.svg" alt="" />
          <p>Tools</p>
        </div>
        <img src="../../assets/Header/arrowup.svg" alt="" class="icon-arrow" />
      </div>
      <div class="box-sub-menu">
        <div class="box-separate-sub">
          <router-link to="/register-user">
            <p>Register User</p>
          </router-link>
        </div>
        <div class="box-separate-sub">
          <router-link to="/category-manager">
            <p>Category Manager</p>
          </router-link>
        </div>
        <div class="box-separate-sub">
          <router-link to="/translate-object">
            <p>Translate Object</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="box-dropdown" @click="onToolsClick" v-else>
      <div class="box-menu">
        <div class="box-separate-icon">
          <img src="../../assets/Header/analytic.svg" alt="" />
          <p>Tools</p>
        </div>
        <img
          src="../../assets/Header/arrowdown.svg"
          alt=""
          class="icon-arrow"
        />
      </div>
    </div>
    <!-- end of Tools dropdown -->
    <div class="box-dropdown" v-if="isExtendPendekarVR === true">
      <div class="box-menu" @click="onPendekarVRClick">
        <div class="box-separate-icon">
          <img src="../../assets/Header/pendekarvr-fade.svg" alt="" />
          <p>PendekarVR</p>
        </div>
        <img src="../../assets/Header/arrowup.svg" alt="" class="icon-arrow" />
      </div>
      <div class="box-sub-menu">
        <div class="box-separate-sub">
          <router-link to="/registration-approval">
            <p>Registration Approval</p>
          </router-link>
        </div>
        <div class="box-separate-sub">
          <router-link to="/task-approval">
            <p>Task Approval</p>
          </router-link>
        </div>
        <div class="box-separate-sub">
          <router-link to="/leaderboard">
            <p>Leaderboard</p>
          </router-link>
        </div>
        <div class="box-separate-sub">
          <router-link to="/leaderboard-filter">
            <p>Leaderboard Filter</p>
          </router-link>
        </div>
        <div class="box-separate-sub">
          <router-link to="/blog">
            <p>Blog</p>
          </router-link>
        </div>
        <div class="box-separate-sub">
          <router-link to="/events">
            <p>Events</p>
          </router-link>
        </div>
        <div class="box-separate-sub">
          <router-link to="/content">
            <p>Content</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="box-dropdown" v-else>
      <div class="box-menu" @click="onPendekarVRClick">
        <div class="box-separate-icon">
          <img src="../../assets/Header/pendekarvr-fade.svg" alt="" />
          <p>PendekarVR</p>
        </div>
        <img
          src="../../assets/Header/arrowdown.svg"
          alt=""
          class="icon-arrow"
        />
      </div>
    </div>
    <div class="box-dropdown">
      <div class="box-menu" @click="onLogout">
        <div class="box-separate-icon">
          <!-- <img src="../../assets/Header/analytic.svg" alt="" > -->
          <p>Log Out</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase-config";

export default {
  name: "sideHeader",
  components: {},
  setup() {
    const store = useStore();
    let isLoginGlobal = ref(false);
    let isExtendIndividual = ref(false);
    let isExtendTraining = ref(false);
    let isExtendUser = ref(false);
    let isExtendTools = ref(false);
    let isExtendPendekarVR = ref(false);

    const onIndividualClick = () => {
      isExtendIndividual.value = !isExtendIndividual.value;
      // isExtendTraining.value = false
      // isExtendUser.value = false
    };
    const onTrainingClick = () => {
      isExtendTraining.value = !isExtendTraining.value;
      // isExtendIndividual.value = false
      // isExtendUser.value = false
    };
    const onUserClick = () => {
      isExtendUser.value = !isExtendUser.value;
      // isExtendIndividual.value = false
      // isExtendTraining.value = false
    };
    const onToolsClick = () => {
      isExtendTools.value = !isExtendTools.value;
      // isExtendIndividual.value = false
      // isExtendTraining.value = false
    };
    const onPendekarVRClick = () => {
      isExtendPendekarVR.value = !isExtendPendekarVR.value;
      // isExtendIndividual.value = false
      // isExtendTraining.value = false
    };
    const onLogout = async () => {
      store.dispatch("getValueLogout", false);
      // eslint-disable-next-line no-unused-vars
      let result = await signOut(auth);
      signOut(auth).then(() => {
        // console.log(result)
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      });
    };
    const fetch = () => {
      isLoginGlobal.value = store.getters.getIsLogin;
    };
    onBeforeMount(() => {
      fetch();
    });
    return {
      isExtendIndividual,
      isExtendTraining,
      isExtendUser,
      isExtendTools,
      isExtendPendekarVR,
      onIndividualClick,
      onTrainingClick,
      onUserClick,
      onToolsClick,
      onPendekarVRClick,
      onLogout,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/SideHeader.scss";
</style>
