<template>
  <div class="container-analyticUser">
    <SideHeader />
    <div class="container-right-analyticUser">
      <TopHeader
        :pathPage="path"
        :pathSubPage="subPath"
        :pathSubSubPage="subSubPath"
      />
      <div class="main-analyticUser">
        <div class="box-header-analyticUser">
          <div class="box-navigation">
            <div
              class="navigation"
              :class="[{ active: page === 'activityCreator' }]"
              @click="
                fetchAnalyticActivityCreator(),
                  (page = 'activityCreator'),
                  (subSubPath = 'User Activity Creator'),
                  (datefilter = ''),
                  (startDateRange = ''),
                  (endDateRange = '')
              "
            >
              User Activity Creator
            </div>
            <div
              class="navigation"
              :class="[{ active: page === 'activityViewer' }]"
              @click="
                fetchAnalyticActivityViewer(),
                  (page = 'activityViewer'),
                  (subSubPath = 'User Activity Viewer'),
                  (datefilter = ''),
                  (startDateRange = ''),
                  (endDateRange = '')
              "
            >
              User Activity Viewer
            </div>
            <div
              class="navigation"
              :class="[{ active: page === 'download' }]"
              @click="
                fetchAnalyticDownload(),
                  (page = 'download'),
                  (subSubPath = 'User Download Creator'),
                  (datefilter = ''),
                  (startDateRange = ''),
                  (endDateRange = '')
              "
            >
              User Download Creator
            </div>
            <div
              class="navigation"
              :class="[{ active: page === 'feedback' }]"
              @click="
                fetchFeedback(),
                  (page = 'feedback'),
                  (subSubPath = 'Feedback'),
                  (datefilter = ''),
                  (startDateRange = ''),
                  (endDateRange = '')
              "
            >
              Feedback
            </div>
          </div>
          <div class="box-header-right" v-if="page != 'feedback'">
            <!-- <div class="update-timer">
              <p>Last updated was {{timer}} seconds ago</p>
            </div> -->
            <div class="btn-refresh" @click="refresh(), (filterTitle = '')">
              <img src="../../assets/refresh-cw.svg" alt="" />
            </div>
            <div @click="export_to_csv()" class="btn-export">
              <img src="../../assets/log-in.svg" alt="" />
              <p>Export</p>
            </div>
          </div>
        </div>
        <div class="box-main-analyticUser">
          <div class="box-page" v-if="page === 'activityCreator'">
            <div class="box-chart">
              <div class="box-chart-header">
                <p>User Activity Creator</p>
                <div class="chart-header-right">
                  <div class="box-dropdown">
                    <ul>
                      <li class="dropdown">
                        <div class="filter">
                          <img src="../../assets/filter.svg" alt="" />
                          <p>Filter</p>
                        </div>
                        <ul>
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('oldest')"
                            >
                              <p>Date: Oldest</p>
                            </div>
                          </li>
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('newest')"
                            >
                              <p>Date: Newest</p>
                            </div>
                          </li>
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('hightolow')"
                            >
                              <p>Activity: High to Low</p>
                            </div>
                          </li>
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('lowtohigh')"
                            >
                              <p>Activity: Low to High</p>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="card-date">
                    <div class="date-title" @click="openDate()">
                      <img src="../../assets/calendar.svg" alt="" />
                      <p>Date</p>
                    </div>
                    <div id="date" class="dropdown-date">
                      <div class="card-start-end">
                        <input
                          v-model="startDateRange"
                          @change="dateFilterFunction()"
                          type="date"
                        />
                        <p>to</p>
                        <input
                          v-model="endDateRange"
                          @change="dateFilterFunction()"
                          type="date"
                        />
                      </div>
                      <div class="separator"></div>
                      <div class="box-date-filter">
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'today' }]"
                          @click="dateFilterFunction('today')"
                        >
                          Today
                        </div>
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'yesterday' }]"
                          @click="dateFilterFunction('yesterday')"
                        >
                          Yesterday
                        </div>
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'week' }]"
                          @click="dateFilterFunction('week')"
                        >
                          Last Week
                        </div>
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'month' }]"
                          @click="dateFilterFunction('month')"
                        >
                          Last Month
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chart-detail">
                <apexchart
                  width="95%"
                  height="330"
                  type="bar"
                  :options="optionsActivityCreator"
                  :series="seriesActivityCreator"
                ></apexchart>
              </div>
              <div class="paginate-left">
                <p>
                  Showing {{ start_chart_uActivityCreator + 1 }} to
                  {{ end_chart_uActivityCreator }} of
                  {{ total_chart_item_uActivityCreator }} entries
                </p>
                <paginate
                  v-model="paginate_uActivityCreator"
                  :page-count="total_chart_page_uActivityCreator"
                  :page-range="3"
                  :margin-pages="1"
                  :click-handler="changeChartUserActivityCreator"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                  >>
                </paginate>
              </div>
            </div>
            <!-- <div class="box-search">
              <div class="card-searching">
                <input type="text" placeholder="Search">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div> -->
            <div class="box-table">
              <table
                v-if="isLoadingActivityCreator === false"
                id="activityTable"
              >
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Activity</th>
                </tr>
                <tr
                  v-for="(
                    dataAnalyticActivity, id
                  ) in allDataAnalyticActivityCreator"
                  :key="dataAnalyticActivity.id"
                >
                  <td
                    v-if="
                      id >= start_page_analyticActivityCreator &&
                      id < end_page_analyticActivityCreator
                    "
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_analyticActivityCreator &&
                      id < end_page_analyticActivityCreator
                    "
                  >
                    {{ dataAnalyticActivity.timestamp }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_analyticActivityCreator &&
                      id < end_page_analyticActivityCreator
                    "
                  >
                    {{ dataAnalyticActivity.count }}
                  </td>
                </tr>
              </table>
              <div
                class="empty-card"
                v-else-if="isLoadingActivityCreator === true"
              >
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_analyticActivityCreator + 1 }} to
                {{ end_page_analyticActivityCreator }} of
                {{ total_item_analyticActivityCreator }} entries
              </p>
              <paginate
                v-model="paginate_analyticActivityCreator"
                :page-count="total_page_analyticActivityCreator"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageAnalyticActivityCreator"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page === 'activityViewer'">
            <div class="box-chart">
              <div class="box-chart-header">
                <p>User Activity Viewer</p>
                <div class="chart-header-right">
                  <div class="box-dropdown">
                    <ul>
                      <li class="dropdown">
                        <div class="filter">
                          <img src="../../assets/filter.svg" alt="" />
                          <p>Filter</p>
                        </div>
                        <ul>
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('oldest')"
                            >
                              <p>Date: Oldest</p>
                            </div>
                          </li>
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('newest')"
                            >
                              <p>Date: Newest</p>
                            </div>
                          </li>
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('hightolow')"
                            >
                              <p>Activity: High to Low</p>
                            </div>
                          </li>
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('lowtohigh')"
                            >
                              <p>Activity: Low to High</p>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="card-date">
                    <div class="date-title" @click="openDate()">
                      <img src="../../assets/calendar.svg" alt="" />
                      <p>Date</p>
                    </div>
                    <div id="date" class="dropdown-date">
                      <div class="card-start-end">
                        <input
                          v-model="startDateRange"
                          @change="dateFilterFunction()"
                          type="date"
                        />
                        <p>to</p>
                        <input
                          v-model="endDateRange"
                          @change="dateFilterFunction()"
                          type="date"
                        />
                      </div>
                      <div class="separator"></div>
                      <div class="box-date-filter">
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'today' }]"
                          @click="dateFilterFunction('today')"
                        >
                          Today
                        </div>
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'yesterday' }]"
                          @click="dateFilterFunction('yesterday')"
                        >
                          Yesterday
                        </div>
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'week' }]"
                          @click="dateFilterFunction('week')"
                        >
                          Last Week
                        </div>
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'month' }]"
                          @click="dateFilterFunction('month')"
                        >
                          Last Month
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chart-detail">
                <apexchart
                  width="95%"
                  height="330"
                  type="bar"
                  :options="optionsActivityViewer"
                  :series="seriesActivityViewer"
                ></apexchart>
              </div>
              <div class="paginate-left">
                <p>
                  Showing {{ start_chart_uActivityViewer + 1 }} to
                  {{ end_chart_uActivityViewer }} of
                  {{ total_chart_item_uActivityViewer }} entries
                </p>
                <paginate
                  v-model="paginate_uActivityViewer"
                  :page-count="total_chart_page_uActivityViewer"
                  :page-range="3"
                  :margin-pages="1"
                  :click-handler="changeChartUserActivityViewer"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                  >>
                </paginate>
              </div>
            </div>
            <!-- <div class="box-search">
              <div class="card-searching">
                <input type="text" placeholder="Search">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div> -->
            <div class="box-table">
              <table
                v-if="isLoadingActivityViewer === false"
                id="activityViewerTable"
              >
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Activity</th>
                </tr>
                <tr
                  v-for="(
                    dataAnalyticActivity, id
                  ) in allDataAnalyticActivityViewer"
                  :key="dataAnalyticActivity.id"
                >
                  <td
                    v-if="
                      id >= start_page_analyticActivityViewer &&
                      id < end_page_analyticActivityViewer
                    "
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_analyticActivityViewer &&
                      id < end_page_analyticActivityViewer
                    "
                  >
                    {{ dataAnalyticActivity.timestamp }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_analyticActivityViewer &&
                      id < end_page_analyticActivityViewer
                    "
                  >
                    {{ dataAnalyticActivity.count }}
                  </td>
                </tr>
              </table>
              <div
                class="empty-card"
                v-else-if="isLoadingActivityViewer === true"
              >
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_analyticActivityViewer + 1 }} to
                {{ end_page_analyticActivityViewer }} of
                {{ total_item_analyticActivityViewer }} entries
              </p>
              <paginate
                v-model="paginate_analyticActivityViewer"
                :page-count="total_page_analyticActivityViewer"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageAnalyticActivityViewer"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page === 'download'">
            <div class="box-chart">
              <div class="box-chart-header">
                <p>User Download Creator</p>
                <div class="chart-header-right">
                  <div class="box-dropdown">
                    <ul>
                      <li class="dropdown">
                        <div class="filter">
                          <img src="../../assets/filter.svg" alt="" />
                          <p>Filter</p>
                        </div>
                        <ul>
                          <!-- <li>
                            <div class="box-submenu" ><p>By Creator</p></div>
                          </li>
                          <li>
                            <div class="box-submenu"><p>By Viewer</p></div>
                          </li> -->
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('oldest')"
                            >
                              <p>Date: Oldest</p>
                            </div>
                          </li>
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('newest')"
                            >
                              <p>Date: Newest</p>
                            </div>
                          </li>
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('hightolow')"
                            >
                              <p>Activity: High to Low</p>
                            </div>
                          </li>
                          <li>
                            <div
                              class="box-submenu"
                              @click="filterFunction('lowtohigh')"
                            >
                              <p>Activity: Low to High</p>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="card-date">
                    <div class="date-title" @click="openDate()">
                      <img src="../../assets/calendar.svg" alt="" />
                      <p>Date</p>
                    </div>
                    <div id="date" class="dropdown-date">
                      <div class="card-start-end">
                        <input
                          v-model="startDateRange"
                          @change="dateFilterFunction()"
                          type="date"
                        />
                        <p>to</p>
                        <input
                          v-model="endDateRange"
                          @change="dateFilterFunction()"
                          type="date"
                        />
                      </div>
                      <div class="separator"></div>
                      <div class="box-date-filter">
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'today' }]"
                          @click="dateFilterFunction('today')"
                        >
                          Today
                        </div>
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'yesterday' }]"
                          @click="dateFilterFunction('yesterday')"
                        >
                          Yesterday
                        </div>
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'week' }]"
                          @click="dateFilterFunction('week')"
                        >
                          Last Week
                        </div>
                        <div
                          class="date-filter"
                          :class="[{ active: datefilter === 'month' }]"
                          @click="dateFilterFunction('month')"
                        >
                          Last Month
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chart-detail">
                <apexchart
                  width="95%"
                  height="330"
                  type="bar"
                  :options="optionsDownload"
                  :series="seriesDownload"
                ></apexchart>
              </div>
              <div class="paginate-left">
                <p>
                  Showing {{ start_chart_uDownload + 1 }} to
                  {{ end_chart_uDownload }} of
                  {{ total_chart_item_uDownload }} entries
                </p>
                <paginate
                  v-model="paginate_uDownload"
                  :page-count="total_chart_page_uDownload"
                  :page-range="3"
                  :margin-pages="1"
                  :click-handler="changeChartUserDownload"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                  >>
                </paginate>
              </div>
            </div>
            <!-- <div class="box-search">
              <div class="card-searching">
                <input type="text" placeholder="Search">
                <img src="../../assets/searching.svg" alt="">
              </div>
            </div> -->
            <div class="box-table">
              <table v-if="isLoadingDownload === false" id="downloadTable">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Download</th>
                </tr>
                <tr
                  v-for="(dataAnalyticDownload, id) in allDataAnalyticDownload"
                  :key="dataAnalyticDownload.id"
                >
                  <td
                    v-if="
                      id >= start_page_analyticDownload &&
                      id < end_page_analyticDownload
                    "
                  >
                    {{ id + 1 }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_analyticDownload &&
                      id < end_page_analyticDownload
                    "
                  >
                    {{ dataAnalyticDownload.date }}
                  </td>
                  <td
                    v-if="
                      id >= start_page_analyticDownload &&
                      id < end_page_analyticDownload
                    "
                  >
                    {{ dataAnalyticDownload.click_count }}
                  </td>
                </tr>
              </table>
              <div class="empty-card" v-else-if="isLoadingDownload === true">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_analyticDownload + 1 }} to
                {{ end_page_analyticDownload }} of
                {{ total_item_analyticDownload }} entries
              </p>
              <paginate
                v-model="paginate_analyticDownload"
                :page-count="total_page_analyticDownload"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageAnalyticDownload"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page === 'feedback'">
            <div class="box-total-chart">
              <div class="total-rating">
                <h1>Review</h1>
                <p class="rating">{{ averageRating }}</p>
                <star-rating
                  v-model:rating="averageRating"
                  :read-only="true"
                  :increment="0.01"
                  :star-size="20"
                ></star-rating>
                <p>Based on {{ totalRating }} reviews</p>
              </div>
              <div class="separator-vertical"></div>
              <div class="rating-chart">
                <apexchart
                  width="1100"
                  height="180"
                  type="bar"
                  :options="optionsFeedback"
                  :series="seriesFeedback"
                ></apexchart>
              </div>
            </div>
            <div class="separator"></div>
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="box-dropdown">
                  <ul>
                    <li class="dropdown">
                      <div class="filter">
                        <img src="../../assets/filter.svg" alt="" />
                        <p>Filter</p>
                      </div>
                      <ul>
                        <li>
                          <div class="box-submenu" @click="filterFunction('5')">
                            <p>5 Star</p>
                          </div>
                        </li>
                        <li>
                          <div class="box-submenu" @click="filterFunction('4')">
                            <p>4 Star</p>
                          </div>
                        </li>
                        <li>
                          <div class="box-submenu" @click="filterFunction('3')">
                            <p>3 Star</p>
                          </div>
                        </li>
                        <li>
                          <div class="box-submenu" @click="filterFunction('2')">
                            <p>2 Star</p>
                          </div>
                        </li>
                        <li>
                          <div class="box-submenu" @click="filterFunction('1')">
                            <p>1 Star</p>
                          </div>
                        </li>
                        <!-- <li>
                            <div class="box-submenu"><p>Date: Oldest</p></div>
                        </li>
                        <li>
                            <div class="box-submenu"><p>Date: Newest</p></div>
                        </li> -->
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="box-feedback" v-if="isLoadingFeedback === false">
              <div
                class="container-feedback"
                v-for="(dataFeedback, id) in feedbackDetails"
                :key="dataFeedback.id"
              >
                <div
                  class="feedback-detail"
                  v-if="id >= start_page_feedback && id < end_page_feedback"
                >
                  <div class="email-date-box">
                    <div class="email-date-left">
                      <!-- <div class="profile">P</div> -->
                      <img
                        class="profile-picture"
                        :src="profilePicture[id]"
                        referrerpolicy="no-referrer"
                        alt=""
                      />
                      <p>{{ dataFeedback.email }}</p>
                    </div>
                  </div>
                  <div class="rating-uid-box">
                    <star-rating
                      v-model:rating="dataFeedback.star"
                      :read-only="true"
                      :increment="0.1"
                      :star-size="15"
                    ></star-rating>
                    <div class="separator"></div>
                    <p>UID {{ dataFeedback.feedback_uid }}</p>
                    <!-- feedback_uid -->
                    <!-- user_uid -->
                  </div>
                  <div class="review-box">
                    <p>{{ dataFeedback.feedback }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="empty-card" v-else-if="isLoadingFeedback === true">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="paginate-left">
              <p>
                Showing {{ start_page_feedback + 1 }} to
                {{ end_page_feedback }} of {{ total_item_feedback }} entries
              </p>
              <paginate
                v-model="paginate_feedback"
                :page-count="total_page_feedback"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageFeedback"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                >>
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue";
import TopHeader from "../../components/Header/TopHeader.vue";
import { onBeforeMount, ref, watch } from "vue";
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";
import Axios from "axios";
import StarRating from "vue-star-rating";

export default {
  name: "analyticUser",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
    StarRating,
  },
  setup() {
    const store = useStore();
    let path = ref("Analytics");
    let subPath = ref("User");
    let subSubPath = ref("User Activity");
    let page = ref("activityCreator");
    var timer = ref(0);
    let datefilter = ref("");
    let isLoadingActivityCreator = ref(true);
    let isLoadingActivityViewer = ref(true);
    let isLoadingDownload = ref(true);
    let isLoadingFeedback = ref(true);

    let allDataAnalyticActivityCreator = ref(null);
    let defaultDataActivityCreator = ref(null);
    let allDataAnalyticActivityViewer = ref(null);
    let defaultDataActivityViewer = ref(null);
    let allDataAnalyticDownload = ref(null);
    let defaultDataDownload = ref(null);
    let allDataFeedback = ref(null);
    let defaultDataFeedback = ref(null);

    const openDate = () => {
      document.getElementById("date").classList.toggle("show");
    };

    const refresh = () => {
      if (page.value === "activityCreator") {
        fetchAnalyticActivityCreator();
      } else if (page.value === "activityViewer") {
        defaultDataActivityViewer();
      } else if (page.value === "download") {
        fetchAnalyticDownload();
      } else {
        fetchFeedback();
      }
    };

    watch(
      async () => page.value,
      () => {
        if (page.value === "activityCreator") {
          allDataAnalyticActivityCreator.value =
            defaultDataActivityCreator.value;
        } else if (page.value === "feedback") {
          feedbackDetails.value = defaultDataFeedback.value;
          profilePicture.value = defaultProfilePicture.value;
        } else {
          allDataAnalyticDownload.value = defaultDataDownload.value;
        }
      }
    );
    watch(
      async () => allDataAnalyticActivityCreator.value,
      () => {
        findDataChartActivityCreator();
      }
    );
    watch(
      async () => allDataAnalyticActivityViewer.value,
      () => {
        findDataChartActivityViewer();
      }
    );
    watch(
      async () => allDataAnalyticDownload.value,
      () => {
        findDataChartDownload();
      }
    );

    let startDateRange = ref("");
    let endDateRange = ref("");
    const dateFilterFunction = (params) => {
      if (page.value == "activityCreator") {
        // timestamp
        // console.log(allDataAnalyticActivityCreator)
        if (startDateRange.value != "" && endDateRange.value != "") {
          datefilter.value = "";
          // var formatStart =  startDateRange.value.split('-')
          // var startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          // var formatEnd =  endDateRange.value.split('-')
          // var endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
          getActivity(
            startDateRange.value,
            endDateRange.value,
            "millealab-creator"
          );
          // console.log(startDateRange,endDateRange)

          // let filter = allDataAnalyticActivityCreator.value.filter((val)=>{
          //   var formatDate = val.timestamp.split(/\//)
          //   var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
          //   return (date >= startDate && date <= endDate)
          // })

          // if(filter){
          //   allDataAnalyticActivityCreator.value = filter
          // }else{
          //   allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
          // }
          changePageAnalyticActivityCreator(1);
          total_page_analyticActivityCreator.value = Math.ceil(
            allDataAnalyticActivityCreator.value.length / 10
          );
          total_item_analyticActivityCreator.value =
            allDataAnalyticActivityCreator.value.length;
          paginate_analyticActivityCreator.value = 1;
          openDate();
        } else if (params == "today") {
          if (datefilter.value == "today") {
            datefilter.value = "";
            allDataAnalyticActivityCreator.value =
              defaultDataActivityCreator.value;
            changePageAnalyticActivityCreator(1);
            total_page_analyticActivityCreator.value = Math.ceil(
              allDataAnalyticActivityCreator.value.length / 10
            );
            total_item_analyticActivityCreator.value =
              allDataAnalyticActivityCreator.value.length;
            paginate_analyticActivityCreator.value = 1;
          } else {
            datefilter.value = "today";
            allDataAnalyticActivityCreator.value =
              defaultDataActivityCreator.value;
            // var today = new Date();
            // var dd = String(today.getDate()).padStart(2, '0');
            // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            // var yyyy = today.getFullYear();

            var today = new Date();
            var startDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );
            var endDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            startDate = startDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            endDate = endDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            getActivity(startDate, endDate, "millealab-creator");
            // today = dd + '/' + mm + '/' + yyyy;

            // let filter = allDataAnalyticActivityCreator.value.filter((val)=>{
            //   if(val.timestamp.toUpperCase().includes(today.toUpperCase())){
            //     return val
            //   }
            // })
            // if(filter){
            //   allDataAnalyticActivityCreator.value = filter
            // }else{
            //   allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
            // }
            changePageAnalyticActivityCreator(1);
            total_page_analyticActivityCreator.value = Math.ceil(
              allDataAnalyticActivityCreator.value.length / 10
            );
            total_item_analyticActivityCreator.value =
              allDataAnalyticActivityCreator.value.length;
            paginate_analyticActivityCreator.value = 1;
          }
          openDate();
        } else if (params == "yesterday") {
          if (datefilter.value == "yesterday") {
            datefilter.value = "";
            allDataAnalyticActivityCreator.value =
              defaultDataActivityCreator.value;
            changePageAnalyticActivityCreator(1);
            total_page_analyticActivityCreator.value = Math.ceil(
              allDataAnalyticActivityCreator.value.length / 10
            );
            total_item_analyticActivityCreator.value =
              allDataAnalyticActivityCreator.value.length;
            paginate_analyticActivityCreator.value = 1;
          } else {
            datefilter.value = "yesterday";
            allDataAnalyticActivityCreator.value =
              defaultDataActivityCreator.value;

            today = new Date();
            startDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 1
            );
            endDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            startDate = startDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            endDate = endDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            getActivity(startDate, endDate, "millealab-creator");

            // today = new Date();
            // startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            // endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            // let filter = allDataAnalyticActivityCreator.value.filter((val)=>{
            //   var formatDate = val.timestamp.split(/\//)
            //   var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            //   return (date >= startDate && date <= endDate)
            // })

            // if(filter){
            //   allDataAnalyticActivityCreator.value = filter
            // }else{
            //   allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
            // }
            changePageAnalyticActivityCreator(1);
            total_page_analyticActivityCreator.value = Math.ceil(
              allDataAnalyticActivityCreator.value.length / 10
            );
            total_item_analyticActivityCreator.value =
              allDataAnalyticActivityCreator.value.length;
            paginate_analyticActivityCreator.value = 1;
          }
          openDate();
        } else if (params == "week") {
          if (datefilter.value == "week") {
            datefilter.value = "";
            allDataAnalyticActivityCreator.value =
              defaultDataActivityCreator.value;
            changePageAnalyticActivityCreator(1);
            total_page_analyticActivityCreator.value = Math.ceil(
              allDataAnalyticActivityCreator.value.length / 10
            );
            total_item_analyticActivityCreator.value =
              allDataAnalyticActivityCreator.value.length;
            paginate_analyticActivityCreator.value = 1;
          } else {
            datefilter.value = "week";
            allDataAnalyticActivityCreator.value =
              defaultDataActivityCreator.value;

            today = new Date();
            startDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 7
            );
            endDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            startDate = startDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            endDate = endDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            getActivity(startDate, endDate, "millealab-creator");

            // today = new Date();
            // startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            // endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            // let filter = allDataAnalyticActivityCreator.value.filter((val)=>{
            //   var formatDate = val.timestamp.split(/\//)
            //   var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            //   return (date >= startDate && date <= endDate)
            // })

            // if(filter){
            //   allDataAnalyticActivityCreator.value = filter
            // }else{
            //   allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
            // }
            changePageAnalyticActivityCreator(1);
            total_page_analyticActivityCreator.value = Math.ceil(
              allDataAnalyticActivityCreator.value.length / 10
            );
            total_item_analyticActivityCreator.value =
              allDataAnalyticActivityCreator.value.length;
            paginate_analyticActivityCreator.value = 1;
          }
          openDate();
        } else if (params == "month") {
          if (datefilter.value == "month") {
            datefilter.value = "";
            allDataAnalyticActivityCreator.value =
              defaultDataActivityCreator.value;
            changePageAnalyticActivityCreator(1);
            total_page_analyticActivityCreator.value = Math.ceil(
              allDataAnalyticActivityCreator.value.length / 10
            );
            total_item_analyticActivityCreator.value =
              allDataAnalyticActivityCreator.value.length;
            paginate_analyticActivityCreator.value = 1;
          } else {
            datefilter.value = "month";
            allDataAnalyticActivityCreator.value =
              defaultDataActivityCreator.value;

            today = new Date();
            startDate = new Date(
              today.getFullYear(),
              today.getMonth() - 1,
              today.getDate()
            );
            endDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            startDate = startDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            endDate = endDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            getActivity(startDate, endDate, "millealab-creator");

            // today = new Date();
            // startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            // endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            // let filter = allDataAnalyticActivityCreator.value.filter((val)=>{
            //   var formatDate = val.timestamp.split(/\//)
            //   var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            //   return (date >= startDate && date <= endDate)
            // })

            // if(filter){
            //   allDataAnalyticActivityCreator.value = filter
            // }else{
            //   allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
            // }
            changePageAnalyticActivityCreator(1);
            total_page_analyticActivityCreator.value = Math.ceil(
              allDataAnalyticActivityCreator.value.length / 10
            );
            total_item_analyticActivityCreator.value =
              allDataAnalyticActivityCreator.value.length;
            paginate_analyticActivityCreator.value = 1;
          }
          openDate();
        } else {
          allDataAnalyticActivityCreator.value =
            defaultDataActivityCreator.value;
          changePageAnalyticActivityCreator(1);
          total_page_analyticActivityCreator.value = Math.ceil(
            allDataAnalyticActivityCreator.value.length / 10
          );
          total_item_analyticActivityCreator.value =
            allDataAnalyticActivityCreator.value.length;
          paginate_analyticActivityCreator.value = 1;
        }
      }
      if (page.value == "activityViewer") {
        // timestamp
        // console.log(allDataAnalyticActivityCreator)
        if (startDateRange.value != "" && endDateRange.value != "") {
          datefilter.value = "";
          // var formatStart =  startDateRange.value.split('-')
          // var startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          // var formatEnd =  endDateRange.value.split('-')
          // var endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
          getActivity(
            startDateRange.value,
            endDateRange.value,
            "millealab-viewer"
          );
          // console.log(startDateRange,endDateRange)

          // let filter = allDataAnalyticActivityCreator.value.filter((val)=>{
          //   var formatDate = val.timestamp.split(/\//)
          //   var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
          //   return (date >= startDate && date <= endDate)
          // })

          // if(filter){
          //   allDataAnalyticActivityCreator.value = filter
          // }else{
          //   allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
          // }
          changePageAnalyticActivityViewer(1);
          total_page_analyticActivityViewer.value = Math.ceil(
            allDataAnalyticActivityViewer.value.length / 10
          );
          total_item_analyticActivityViewer.value =
            allDataAnalyticActivityViewer.value.length;
          paginate_analyticActivityViewer.value = 1;
          openDate();
        } else if (params == "today") {
          if (datefilter.value == "today") {
            datefilter.value = "";
            allDataAnalyticActivityViewer.value =
              defaultDataActivityViewer.value;
            changePageAnalyticActivityViewer(1);
            total_page_analyticActivityViewer.value = Math.ceil(
              allDataAnalyticActivityViewer.value.length / 10
            );
            total_item_analyticActivityViewer.value =
              allDataAnalyticActivityViewer.value.length;
            paginate_analyticActivityViewer.value = 1;
          } else {
            datefilter.value = "today";
            allDataAnalyticActivityViewer.value =
              defaultDataActivityViewer.value;
            // var today = new Date();
            // var dd = String(today.getDate()).padStart(2, '0');
            // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            // var yyyy = today.getFullYear();

            today = new Date();
            startDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );
            endDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            startDate = startDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            endDate = endDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            getActivity(startDate, endDate, "millealab-viewer");
            // today = dd + '/' + mm + '/' + yyyy;

            // let filter = allDataAnalyticActivityCreator.value.filter((val)=>{
            //   if(val.timestamp.toUpperCase().includes(today.toUpperCase())){
            //     return val
            //   }
            // })
            // if(filter){
            //   allDataAnalyticActivityCreator.value = filter
            // }else{
            //   allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
            // }
            changePageAnalyticActivityViewer(1);
            total_page_analyticActivityViewer.value = Math.ceil(
              allDataAnalyticActivityViewer.value.length / 10
            );
            total_item_analyticActivityViewer.value =
              allDataAnalyticActivityViewer.value.length;
            paginate_analyticActivityViewer.value = 1;
          }
          openDate();
        } else if (params == "yesterday") {
          if (datefilter.value == "yesterday") {
            datefilter.value = "";
            allDataAnalyticActivityViewer.value =
              defaultDataActivityViewer.value;
            changePageAnalyticActivityViewer(1);
            total_page_analyticActivityViewer.value = Math.ceil(
              allDataAnalyticActivityViewer.value.length / 10
            );
            total_item_analyticActivityViewer.value =
              allDataAnalyticActivityViewer.value.length;
            paginate_analyticActivityViewer.value = 1;
          } else {
            datefilter.value = "yesterday";
            allDataAnalyticActivityViewer.value =
              defaultDataActivityViewer.value;

            today = new Date();
            startDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 1
            );
            endDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            startDate = startDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            endDate = endDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            getActivity(startDate, endDate, "millealab-viewer");

            // today = new Date();
            // startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            // endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            // let filter = allDataAnalyticActivityCreator.value.filter((val)=>{
            //   var formatDate = val.timestamp.split(/\//)
            //   var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            //   return (date >= startDate && date <= endDate)
            // })

            // if(filter){
            //   allDataAnalyticActivityCreator.value = filter
            // }else{
            //   allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
            // }
            changePageAnalyticActivityViewer(1);
            total_page_analyticActivityViewer.value = Math.ceil(
              allDataAnalyticActivityViewer.value.length / 10
            );
            total_item_analyticActivityViewer.value =
              allDataAnalyticActivityViewer.value.length;
            paginate_analyticActivityViewer.value = 1;
          }
          openDate();
        } else if (params == "week") {
          if (datefilter.value == "week") {
            datefilter.value = "";
            allDataAnalyticActivityViewer.value =
              defaultDataActivityViewer.value;
            changePageAnalyticActivityViewer(1);
            total_page_analyticActivityViewer.value = Math.ceil(
              allDataAnalyticActivityViewer.value.length / 10
            );
            total_item_analyticActivityViewer.value =
              allDataAnalyticActivityViewer.value.length;
            paginate_analyticActivityViewer.value = 1;
          } else {
            datefilter.value = "week";
            allDataAnalyticActivityViewer.value =
              defaultDataActivityViewer.value;

            today = new Date();
            startDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 7
            );
            endDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            startDate = startDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            endDate = endDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            getActivity(startDate, endDate, "millealab-viewer");

            // today = new Date();
            // startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            // endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            // let filter = allDataAnalyticActivityCreator.value.filter((val)=>{
            //   var formatDate = val.timestamp.split(/\//)
            //   var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            //   return (date >= startDate && date <= endDate)
            // })

            // if(filter){
            //   allDataAnalyticActivityCreator.value = filter
            // }else{
            //   allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
            // }
            changePageAnalyticActivityViewer(1);
            total_page_analyticActivityViewer.value = Math.ceil(
              allDataAnalyticActivityViewer.value.length / 10
            );
            total_item_analyticActivityViewer.value =
              allDataAnalyticActivityViewer.value.length;
            paginate_analyticActivityViewer.value = 1;
          }
          openDate();
        } else if (params == "month") {
          if (datefilter.value == "month") {
            datefilter.value = "";
            allDataAnalyticActivityViewer.value =
              defaultDataActivityViewer.value;
            changePageAnalyticActivityViewer(1);
            total_page_analyticActivityViewer.value = Math.ceil(
              allDataAnalyticActivityViewer.value.length / 10
            );
            total_item_analyticActivityViewer.value =
              allDataAnalyticActivityViewer.value.length;
            paginate_analyticActivityViewer.value = 1;
          } else {
            datefilter.value = "month";
            allDataAnalyticActivityViewer.value =
              defaultDataActivityViewer.value;

            today = new Date();
            startDate = new Date(
              today.getFullYear(),
              today.getMonth() - 1,
              today.getDate()
            );
            endDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            startDate = startDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            endDate = endDate
              .toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
            getActivity(startDate, endDate, "millealab-viewer");

            // today = new Date();
            // startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            // endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            // let filter = allDataAnalyticActivityCreator.value.filter((val)=>{
            //   var formatDate = val.timestamp.split(/\//)
            //   var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            //   return (date >= startDate && date <= endDate)
            // })

            // if(filter){
            //   allDataAnalyticActivityCreator.value = filter
            // }else{
            //   allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
            // }
            changePageAnalyticActivityViewer(1);
            total_page_analyticActivityViewer.value = Math.ceil(
              allDataAnalyticActivityViewer.value.length / 10
            );
            total_item_analyticActivityViewer.value =
              allDataAnalyticActivityViewer.value.length;
            paginate_analyticActivityViewer.value = 1;
          }
          openDate();
        } else {
          allDataAnalyticActivityViewer.value = defaultDataActivityViewer.value;
          changePageAnalyticActivityViewer(1);
          total_page_analyticActivityViewer.value = Math.ceil(
            allDataAnalyticActivityViewer.value.length / 10
          );
          total_item_analyticActivityViewer.value =
            allDataAnalyticActivityViewer.value.length;
          paginate_analyticActivityViewer.value = 1;
        }
      }
      if (page.value == "download") {
        // date
        // console.log(allDataAnalyticDownload)
        if (startDateRange.value != "" && endDateRange.value != "") {
          allDataAnalyticDownload.value = defaultDataDownload.value;
          var formatStart = startDateRange.value.split("-");
          startDate = new Date(
            [formatStart[1], formatStart[2], formatStart[0]].join("/")
          );
          var formatEnd = endDateRange.value.split("-");
          endDate = new Date(
            [formatEnd[1], formatEnd[2], formatEnd[0]].join("/")
          );

          let filter = allDataAnalyticDownload.value.filter((val) => {
            var formatDate = val.date.split(/\//);
            var date = new Date(
              [formatDate[1], formatDate[0], formatDate[2]].join("/")
            );
            return date >= startDate && date <= endDate;
          });

          if (filter) {
            allDataAnalyticDownload.value = filter;
          } else {
            allDataAnalyticDownload.value = defaultDataDownload.value;
          }
          changePageAnalyticDownload(1);
          total_page_analyticDownload.value = Math.ceil(
            allDataAnalyticDownload.value.length / 10
          );
          total_item_analyticDownload.value =
            allDataAnalyticDownload.value.length;
          paginate_uDownload.value = 1;
          openDate();
        } else if (params == "today") {
          if (datefilter.value == "today") {
            datefilter.value = "";
            allDataAnalyticDownload.value = defaultDataDownload.value;
            changePageAnalyticDownload(1);
            total_page_analyticDownload.value = Math.ceil(
              allDataAnalyticDownload.value.length / 10
            );
            total_item_analyticDownload.value =
              allDataAnalyticDownload.value.length;
            paginate_uDownload.value = 1;
          } else {
            datefilter.value = "today";
            allDataAnalyticDownload.value = defaultDataDownload.value;
            today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + "/" + mm + "/" + yyyy;

            let filter = allDataAnalyticDownload.value.filter((val) => {
              if (val.date.toUpperCase().includes(today.toUpperCase())) {
                return val;
              }
            });
            if (filter) {
              allDataAnalyticDownload.value = filter;
            } else {
              allDataAnalyticDownload.value = defaultDataDownload.value;
            }
            changePageAnalyticDownload(1);
            total_page_analyticDownload.value = Math.ceil(
              allDataAnalyticDownload.value.length / 10
            );
            total_item_analyticDownload.value =
              allDataAnalyticDownload.value.length;
            paginate_uDownload.value = 1;
          }
          openDate();
        } else if (params == "yesterday") {
          if (datefilter.value == "yesterday") {
            datefilter.value = "";
            allDataAnalyticDownload.value = defaultDataDownload.value;
            changePageAnalyticDownload(1);
            total_page_analyticDownload.value = Math.ceil(
              allDataAnalyticDownload.value.length / 10
            );
            total_item_analyticDownload.value =
              allDataAnalyticDownload.value.length;
            paginate_uDownload.value = 1;
          } else {
            datefilter.value = "yesterday";
            allDataAnalyticDownload.value = defaultDataDownload.value;

            today = new Date();
            startDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 1
            );
            endDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            let filter = allDataAnalyticDownload.value.filter((val) => {
              var formatDate = val.date.split(/\//);
              var date = new Date(
                [formatDate[1], formatDate[0], formatDate[2]].join("/")
              );
              return date >= startDate && date <= endDate;
            });

            if (filter) {
              allDataAnalyticDownload.value = filter;
            } else {
              allDataAnalyticDownload.value = defaultDataDownload.value;
            }
            changePageAnalyticDownload(1);
            total_page_analyticDownload.value = Math.ceil(
              allDataAnalyticDownload.value.length / 10
            );
            total_item_analyticDownload.value =
              allDataAnalyticDownload.value.length;
            paginate_uDownload.value = 1;
          }
          openDate();
        } else if (params == "week") {
          if (datefilter.value == "week") {
            datefilter.value = "";
            allDataAnalyticDownload.value = defaultDataDownload.value;
            changePageAnalyticDownload(1);
            total_page_analyticDownload.value = Math.ceil(
              allDataAnalyticDownload.value.length / 10
            );
            total_item_analyticDownload.value =
              allDataAnalyticDownload.value.length;
            paginate_uDownload.value = 1;
          } else {
            datefilter.value = "week";
            allDataAnalyticDownload.value = defaultDataDownload.value;

            today = new Date();
            startDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 7
            );
            endDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            let filter = allDataAnalyticDownload.value.filter((val) => {
              var formatDate = val.date.split(/\//);
              var date = new Date(
                [formatDate[1], formatDate[0], formatDate[2]].join("/")
              );
              return date >= startDate && date <= endDate;
            });

            if (filter) {
              allDataAnalyticDownload.value = filter;
            } else {
              allDataAnalyticDownload.value = defaultDataDownload.value;
            }
            changePageAnalyticDownload(1);
            total_page_analyticDownload.value = Math.ceil(
              allDataAnalyticDownload.value.length / 10
            );
            total_item_analyticDownload.value =
              allDataAnalyticDownload.value.length;
            paginate_uDownload.value = 1;
          }
          openDate();
        } else if (params == "month") {
          if (datefilter.value == "month") {
            datefilter.value = "";
            allDataAnalyticDownload.value = defaultDataDownload.value;
            changePageAnalyticDownload(1);
            total_page_analyticDownload.value = Math.ceil(
              allDataAnalyticDownload.value.length / 10
            );
            total_item_analyticDownload.value =
              allDataAnalyticDownload.value.length;
            paginate_uDownload.value = 1;
          } else {
            datefilter.value = "month";
            allDataAnalyticDownload.value = defaultDataDownload.value;

            today = new Date();
            startDate = new Date(
              today.getFullYear(),
              today.getMonth() - 1,
              today.getDate()
            );
            endDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            let filter = allDataAnalyticDownload.value.filter((val) => {
              var formatDate = val.date.split(/\//);
              var date = new Date(
                [formatDate[1], formatDate[0], formatDate[2]].join("/")
              );
              return date >= startDate && date <= endDate;
            });

            if (filter) {
              allDataAnalyticDownload.value = filter;
            } else {
              allDataAnalyticDownload.value = defaultDataDownload.value;
            }
            changePageAnalyticDownload(1);
            total_page_analyticDownload.value = Math.ceil(
              allDataAnalyticDownload.value.length / 10
            );
            total_item_analyticDownload.value =
              allDataAnalyticDownload.value.length;
            paginate_uDownload.value = 1;
          }
          openDate();
        } else {
          allDataAnalyticDownload.value = defaultDataDownload.value;
          changePageAnalyticDownload(1);
          total_page_analyticDownload.value = Math.ceil(
            allDataAnalyticDownload.value.length / 10
          );
          total_item_analyticDownload.value =
            allDataAnalyticDownload.value.length;
          paginate_uDownload.value = 1;
        }
      }
    };
    let filterTitle = ref(null);
    const filterFunction = async (params) => {
      if (page.value === "activityCreator") {
        // allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
        if (params === "oldest") {
          allDataAnalyticActivityCreator.value.sort(
            (a, b) => a.timestamp_unix - b.timestamp_unix
          );
          findDataChartActivityCreator();
        } else if (params === "newest") {
          allDataAnalyticActivityCreator.value.sort(
            (a, b) => b.timestamp_unix - a.timestamp_unix
          );
          findDataChartActivityCreator();
        } else if (params === "hightolow") {
          allDataAnalyticActivityCreator.value.sort(
            (a, b) => b.count - a.count
          );
          findDataChartActivityCreator();
        } else if (params === "lowtohigh") {
          allDataAnalyticActivityCreator.value.sort(
            (a, b) => a.count - b.count
          );
          findDataChartActivityCreator();
        }
      }
      if (page.value === "activityViewer") {
        // allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value
        if (params === "oldest") {
          allDataAnalyticActivityViewer.value.sort(
            (a, b) => a.timestamp_unix - b.timestamp_unix
          );
          findDataChartActivityViewer();
        } else if (params === "newest") {
          allDataAnalyticActivityViewer.value.sort(
            (a, b) => b.timestamp_unix - a.timestamp_unix
          );
          findDataChartActivityViewer();
        } else if (params === "hightolow") {
          allDataAnalyticActivityViewer.value.sort((a, b) => b.count - a.count);
          findDataChartActivityViewer();
        } else if (params === "lowtohigh") {
          allDataAnalyticActivityViewer.value.sort((a, b) => a.count - b.count);
          findDataChartActivityViewer();
        }
      }
      if (page.value === "download") {
        // console.log(allDataAnalyticDownload)
        // allDataAnalyticDownload.value = defaultDataDownload.value
        if (params === "oldest") {
          allDataAnalyticDownload.value.sort(
            (a, b) => a.date_unix - b.date_unix
          );
          findDataChartDownload();
        } else if (params === "newest") {
          allDataAnalyticDownload.value.sort(
            (a, b) => b.date_unix - a.date_unix
          );
          findDataChartDownload();
        } else if (params === "hightolow") {
          allDataAnalyticDownload.value.sort(
            (a, b) => b.click_count - a.click_count
          );
          findDataChartDownload();
        } else if (params === "lowtohigh") {
          allDataAnalyticDownload.value.sort(
            (a, b) => a.click_count - b.click_count
          );
          findDataChartDownload();
        }
      }
      if (page.value === "feedback") {
        feedbackDetails.value = defaultDataFeedback.value;
        profilePicture.value = defaultProfilePicture.value;
        let tempProfile = ref([]);
        let i = -1;
        let filter = feedbackDetails.value.filter((val) => {
          i++;
          if (val.star.toUpperCase().includes(params.toUpperCase())) {
            tempProfile.value.push(profilePicture.value[i]);
            // console.log(i)
            return val;
          }
        });
        // console.log(tempProfile)
        profilePicture.value = tempProfile.value;
        if (filter) {
          feedbackDetails.value = filter;
        } else {
          feedbackDetails.value = defaultDataFeedback.value;
        }
        changePageFeedback(1);
        total_page_feedback.value = Math.ceil(feedbackDetails.value.length / 6);
        total_item_feedback.value = feedbackDetails.value.length;
        paginate_feedback.value = 1;
      }
    };

    const export_to_csv = () => {
      if (page.value === "activityCreator") {
        let arrayToPdf = [];
        var rows = document.querySelectorAll("#activityTable tr");
        let row = [];
        rows[0].querySelectorAll("td,th").forEach((val) => {
          // console.log(val.innerText)
          row.push(val.innerText);
        });
        arrayToPdf.push(row.join(","));
        allDataAnalyticActivityCreator.value.forEach((val, index) => {
          arrayToPdf.push(`${index + 1},${val.timestamp},${val.count}`);
        });
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if (page.value === "activityViewer") {
        let arrayToPdf = [];
        rows = document.querySelectorAll("#activityViewerTable tr");
        let row = [];
        rows[0].querySelectorAll("td,th").forEach((val) => {
          // console.log(val.innerText)
          row.push(val.innerText);
        });
        arrayToPdf.push(row.join(","));
        allDataAnalyticActivityViewer.value.forEach((val, index) => {
          arrayToPdf.push(`${index + 1},${val.timestamp},${val.count}`);
        });
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if (page.value === "download") {
        let arrayToPdf = [];
        rows = document.querySelectorAll("#downloadTable tr");
        let row = [];
        rows[0].querySelectorAll("td,th").forEach((val) => {
          // console.log(val.innerText)
          row.push(val.innerText);
        });
        arrayToPdf.push(row.join(","));
        allDataAnalyticDownload.value.forEach((val, index) => {
          arrayToPdf.push(`${index + 1},${val.date},${val.click_count}`);
        });
        download_csv(arrayToPdf.join("\n"), page.value);
      }
    };

    function download_csv(csv, filename) {
      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], { type: "text/csv" });
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }

    let seriesDownload = ref([]);
    let optionsDownload = ref({
      width: "50%",
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        title: {
          text: "Date",
        },
        labels: {
          format: "dd/MM",
        },
      },
      yaxis: {
        title: {
          text: "Login Counts",
        },
      },
      fill: {
        opacity: 1,
      },
      // tooltip: {
      //     y: {
      //         formatter: function (val) {
      //         return "" + val + " second"
      //         }
      //     }
      // }
    });

    let seriesActivityCreator = ref([]);
    let optionsActivityCreator = ref({
      width: "50%",
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        labels: {
          // format: 'dd/MM',
        },
        categories: [],
        title: {
          text: "Date",
        },
      },
      yaxis: {
        title: {
          text: "Activity Counts",
        },
      },
      fill: {
        opacity: 1,
      },
      // tooltip: {
      //     y: {
      //         formatter: function (val) {
      //         return "" + val + " second"
      //         }
      //     }
      // }
    });

    let seriesActivityViewer = ref([]);
    let optionsActivityViewer = ref({
      width: "50%",
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        labels: {
          // format: 'dd/MM',
        },
        categories: [],
        title: {
          text: "Date",
        },
      },
      yaxis: {
        title: {
          text: "Activity Counts",
        },
      },
      fill: {
        opacity: 1,
      },
      // tooltip: {
      //     y: {
      //         formatter: function (val) {
      //         return "" + val + " second"
      //         }
      //     }
      // }
    });

    let seriesFeedback = ref([]);
    let optionsFeedback = ref({
      chart: {
        type: "bar",
        height: 180,
      },
      plotOptions: {
        bar: {
          // borderRadius: 4,
          horizontal: true,
        },
      },
      xaxis: {
        categories: ["5", "4", "3", "2", "1"],
      },
    });

    let idChartActivity = ref([]);
    let dataChartActivity = ref();

    const findDataChartActivityCreator = () => {
      idChartActivity.value = [];
      dataChartActivity.value = [];
      let i = 0;
      for (i = 0; i < allDataAnalyticActivityCreator.value.length; i++) {
        idChartActivity.value.push(
          allDataAnalyticActivityCreator.value[i].timestamp
        );
        dataChartActivity.value.push(
          allDataAnalyticActivityCreator.value[i].count
        );
      }
      pushChartDataActivityCreator();
    };
    const pushChartDataActivityCreator = () => {
      let tempId = ref([]);
      let tempData = ref([]);
      let add = 0;
      for (
        let i = start_item_chart_uActivityCreator.value;
        i < max_item_chart_uActivityCreator.value;
        i++
      ) {
        if (
          idChartActivity.value[i] == null ||
          idChartActivity.value[i] == ""
        ) {
          break;
        } else {
          tempId.value[add] = idChartActivity.value[i];
          tempData.value[add] = dataChartActivity.value[i];
          add++;
        }
      }
      total_chart_page_uActivityCreator.value = Math.ceil(
        idChartActivity.value.length / 10
      );
      total_chart_item_uActivityCreator.value = idChartActivity.value.length;

      seriesActivityCreator.value = [
        {
          name: "Date",
          data: tempData,
          color: "#004F98",
        },
      ];
      optionsActivityCreator.value = {
        xaxis: {
          labels: {
            format: "dd/MM",
          },
          categories: tempId,
          title: {
            text: "Date",
          },
        },
        yaxis: {
          title: {
            text: "Activity Count",
          },
        },
      };
    };

    let idChartActivityViewer = ref([]);
    let dataChartActivityViewer = ref();

    const findDataChartActivityViewer = () => {
      idChartActivityViewer.value = [];
      dataChartActivityViewer.value = [];
      let i = 0;
      for (i = 0; i < allDataAnalyticActivityViewer.value.length; i++) {
        idChartActivityViewer.value.push(
          allDataAnalyticActivityViewer.value[i].timestamp
        );
        dataChartActivityViewer.value.push(
          allDataAnalyticActivityViewer.value[i].count
        );
      }
      pushChartDataActivityViewer();
    };
    const pushChartDataActivityViewer = () => {
      let tempId = ref([]);
      let tempData = ref([]);
      let add = 0;
      for (
        let i = start_item_chart_uActivityViewer.value;
        i < max_item_chart_uActivityViewer.value;
        i++
      ) {
        if (
          idChartActivityViewer.value[i] == null ||
          idChartActivityViewer.value[i] == ""
        ) {
          break;
        } else {
          tempId.value[add] = idChartActivityViewer.value[i];
          tempData.value[add] = dataChartActivityViewer.value[i];
          add++;
        }
      }
      total_chart_page_uActivityViewer.value = Math.ceil(
        idChartActivityViewer.value.length / 10
      );
      total_chart_item_uActivityViewer.value =
        idChartActivityViewer.value.length;

      seriesActivityViewer.value = [
        {
          name: "Date",
          data: tempData,
          color: "#004F98",
        },
      ];
      optionsActivityViewer.value = {
        xaxis: {
          labels: {
            format: "dd/MM",
          },
          categories: tempId,
          title: {
            text: "Date",
          },
        },
        yaxis: {
          title: {
            text: "Activity Count",
          },
        },
      };
    };

    let idChartDownload = ref([]);
    let dataChartDownload = ref();

    const findDataChartDownload = () => {
      idChartDownload.value = [];
      dataChartDownload.value = [];
      let i = 0;
      for (i = 0; i < allDataAnalyticDownload.value.length; i++) {
        idChartDownload.value.push(allDataAnalyticDownload.value[i].date);
        dataChartDownload.value.push(
          allDataAnalyticDownload.value[i].click_count
        );
      }

      pushChartDataDownload();
    };
    const pushChartDataDownload = () => {
      let tempId = ref([]);
      let tempData = ref([]);
      let add = 0;
      for (
        let i = start_item_chart_uDownload.value;
        i < max_item_chart_uDownload.value;
        i++
      ) {
        if (
          idChartDownload.value[i] == null ||
          idChartDownload.value[i] == ""
        ) {
          break;
        } else {
          tempId.value[add] = idChartDownload.value[i];
          tempData.value[add] = dataChartDownload.value[i];
          add++;
        }
      }
      total_chart_page_uDownload.value = Math.ceil(
        idChartDownload.value.length / 10
      );
      total_chart_item_uDownload.value = idChartDownload.value.length;

      seriesDownload.value = [
        {
          name: "Date",
          data: tempData,
          color: "#004F98",
        },
      ];
      optionsDownload.value = {
        xaxis: {
          labels: {
            format: "dd/MM",
          },
          categories: tempId,
          title: {
            text: "Date",
          },
        },
        yaxis: {
          title: {
            text: "Activity Count",
          },
        },
      };
    };

    //Developer Analytic Activity Creator
    let start_page_analyticActivityCreator = ref(0);
    let end_page_analyticActivityCreator = ref(10);
    let total_item_analyticActivityCreator = ref(0);
    let total_page_analyticActivityCreator = ref(0);
    let paginate_analyticActivityCreator = ref(1);
    let max_item_per_page_activityCreator = ref(10);
    let start_item_activityCreator = ref(0);

    const changePageAnalyticActivityCreator = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;
      start_page_analyticActivityCreator.value = new_start;
      end_page_analyticActivityCreator.value = new_end;

      max_item_per_page_activityCreator.value = pageNum * 10;
      start_item_activityCreator.value =
        max_item_per_page_activityCreator.value - 10;
    };

    let start_chart_uActivityCreator = ref(0);
    let end_chart_uActivityCreator = ref(10);
    let total_chart_item_uActivityCreator = ref(0);
    let total_chart_page_uActivityCreator = ref(0);
    let paginate_uActivityCreator = ref(1);
    let max_item_chart_uActivityCreator = ref(10);
    let start_item_chart_uActivityCreator = ref(0);

    const changeChartUserActivityCreator = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_chart_uActivityCreator.value = new_start;
      end_chart_uActivityCreator.value = new_end;
      total_chart_page_uActivityCreator.value = Math.ceil(
        idChartActivity.value.length / 10
      );
      total_chart_item_uActivityCreator.value = idChartActivity.value.length;
      max_item_chart_uActivityCreator.value = pageNum * 10;
      start_item_chart_uActivityCreator.value =
        max_item_chart_uActivityCreator.value - 10;
      pushChartDataActivityCreator();
    };

    //Developer Analytic Activity Viewer
    let start_page_analyticActivityViewer = ref(0);
    let end_page_analyticActivityViewer = ref(10);
    let total_item_analyticActivityViewer = ref(0);
    let total_page_analyticActivityViewer = ref(0);
    let paginate_analyticActivityViewer = ref(1);
    let max_item_per_page_activityViewer = ref(10);
    let start_item_activityViewer = ref(0);

    const changePageAnalyticActivityViewer = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;
      start_page_analyticActivityViewer.value = new_start;
      end_page_analyticActivityViewer.value = new_end;

      max_item_per_page_activityViewer.value = pageNum * 10;
      start_item_activityViewer.value =
        max_item_per_page_activityViewer.value - 10;
    };

    let start_chart_uActivityViewer = ref(0);
    let end_chart_uActivityViewer = ref(10);
    let total_chart_item_uActivityViewer = ref(0);
    let total_chart_page_uActivityViewer = ref(0);
    let paginate_uActivityViewer = ref(1);
    let max_item_chart_uActivityViewer = ref(10);
    let start_item_chart_uActivityViewer = ref(0);

    const changeChartUserActivityViewer = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_chart_uActivityViewer.value = new_start;
      end_chart_uActivityViewer.value = new_end;
      total_chart_page_uActivityViewer.value = Math.ceil(
        idChartActivityViewer.value.length / 10
      );
      total_chart_item_uActivityViewer.value =
        idChartActivityViewer.value.length;
      max_item_chart_uActivityViewer.value = pageNum * 10;
      start_item_chart_uActivityViewer.value =
        max_item_chart_uActivityViewer.value - 10;
      pushChartDataActivityViewer();
    };

    //Developer Analytic Download
    let start_page_analyticDownload = ref(0);
    let end_page_analyticDownload = ref(10);
    let total_item_analyticDownload = ref(0);
    let total_page_analyticDownload = ref(0);
    let paginate_analyticDownload = ref(1);
    let max_item_per_page_download = ref(10);
    let start_item_download = ref(0);

    const changePageAnalyticDownload = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;
      start_page_analyticDownload.value = new_start;
      end_page_analyticDownload.value = new_end;
      max_item_per_page_download.value = pageNum * 10;
      start_item_download.value = max_item_per_page_download.value - 10;
      // findDataChartDownload()
    };

    let start_chart_uDownload = ref(0);
    let end_chart_uDownload = ref(10);
    let total_chart_item_uDownload = ref(0);
    let total_chart_page_uDownload = ref(0);
    let paginate_uDownload = ref(1);
    let max_item_chart_uDownload = ref(10);
    let start_item_chart_uDownload = ref(0);

    const changeChartUserDownload = (pageNum) => {
      let new_end = pageNum * 10;
      let new_start = new_end - 10;

      start_chart_uDownload.value = new_start;
      end_chart_uDownload.value = new_end;
      total_chart_page_uDownload.value = Math.ceil(
        idChartDownload.value.length / 10
      );
      total_chart_item_uDownload.value = idChartDownload.value.length;
      max_item_chart_uDownload.value = pageNum * 10;
      start_item_chart_uDownload.value = max_item_chart_uDownload.value - 10;
      pushChartDataDownload();
    };

    //User Feedback
    let start_page_feedback = ref(0);
    let end_page_feedback = ref(6);
    let total_item_feedback = ref(0);
    let total_page_feedback = ref(0);
    let paginate_feedback = ref(1);
    let max_item_per_feedback = ref(6);
    let start_item_feedback = ref(0);

    const changePageFeedback = (pageNum) => {
      let new_end = pageNum * 6;
      let new_start = new_end - 6;
      start_page_feedback.value = new_start;
      end_page_feedback.value = new_end;
      max_item_per_feedback.value = pageNum * 6;
      start_item_feedback.value = max_item_per_feedback.value - 6;
      // findDataChartDownload()
    };

    const fetchAnalyticActivityCreator = async () => {
      isLoadingActivityCreator.value = true;
      allDataAnalyticActivityCreator.value = defaultDataActivityCreator.value;

      var today = new Date();
      var startDate = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      );
      var endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );

      startDate = startDate
        .toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
      endDate = endDate
        .toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
      // endDate = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
      // console.log(startDate, endDate)
      await getActivity(startDate, endDate, "millealab-creator");
      isLoadingActivityCreator.value = false;
    };

    const fetchAnalyticActivityViewer = async () => {
      isLoadingActivityViewer.value = true;
      allDataAnalyticActivityViewer.value = defaultDataActivityViewer.value;

      var today = new Date();
      var startDate = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      );
      var endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );

      startDate = startDate
        .toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
      endDate = endDate
        .toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
      // endDate = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
      // console.log(startDate, endDate)
      await getActivity(startDate, endDate, "millealab-viewer");
      isLoadingActivityViewer.value = false;
    };

    const getActivity = async (startDate, endDate, type) => {
      isLoadingActivityCreator.value = true;
      isLoadingActivityViewer.value = true;
      let token = localStorage.getItem("idToken");
      await Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/web/api/getUserOpenApplicationActivity",
        {
          type: type,
          start_date: startDate,
          end_date: endDate,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => {
          if (page.value == "activityCreator") {
            allDataAnalyticActivityCreator.value = res.data;
            defaultDataActivityCreator.value = res.data;

            total_page_analyticActivityCreator.value = Math.ceil(
              allDataAnalyticActivityCreator.value.length / 10
            );
            total_item_analyticActivityCreator.value =
              allDataAnalyticActivityCreator.value.length;
          }
          if (page.value == "activityViewer") {
            allDataAnalyticActivityViewer.value = res.data;
            defaultDataActivityViewer.value = res.data;

            total_page_analyticActivityViewer.value = Math.ceil(
              allDataAnalyticActivityViewer.value.length / 10
            );
            total_item_analyticActivityViewer.value =
              allDataAnalyticActivityViewer.value.length;
          }
        })
        .catch((err) => {
          allDataAnalyticActivityCreator.value = [];
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("idStorage");
            window.location.href = "/login";
          }
        });
      isLoadingActivityCreator.value = false;
      isLoadingActivityViewer.value = false;
    };

    const fetchAnalyticDownload = async () => {
      isLoadingDownload.value = true;
      await store.dispatch("getValueAnalyticDownload");
      let temp = await store.getters.getAllAnalyticDownload.result[0].details;
      allDataAnalyticDownload.value = temp;
      defaultDataDownload.value = temp;
      total_page_analyticDownload.value = Math.ceil(
        allDataAnalyticDownload.value.length / 10
      );
      total_item_analyticDownload.value = allDataAnalyticDownload.value.length;
      // findDataChartDownload()
      isLoadingDownload.value = false;
    };

    let averageRating = ref(0);
    let starCount = ref([]);
    let feedbackDetails = ref(null);
    let totalRating = ref(0);
    const fetchFeedback = async () => {
      isLoadingFeedback.value = true;
      await store.dispatch("getValueFeedback");
      allDataFeedback.value = await store.getters.getAllFeedback;
      let temp = allDataFeedback.value.result.feedbacks;
      // console.log(allDataFeedback.value.result)
      averageRating.value = allDataFeedback.value.result.average_feedback_star;
      averageRating.value = Number(averageRating.value);
      starCount.value = allDataFeedback.value.result.star_count;
      seriesFeedback.value = [
        {
          name: "Star Count",
          data: [
            starCount.value[5],
            starCount.value[4],
            starCount.value[3],
            starCount.value[2],
            starCount.value[1],
          ],
          color: "#004F98",
        },
      ];
      feedbackDetails.value = temp;
      defaultDataFeedback.value = temp;
      // console.log(feedbackDetails)
      total_page_feedback.value = Math.ceil(
        allDataFeedback.value.result.feedback_count / 6
      );
      total_item_feedback.value = allDataFeedback.value.result.feedback_count;
      totalRating.value = total_item_feedback.value;
      getProfilePicture();
      isLoadingFeedback.value = false;
    };

    let profilePicture = ref([]);
    let defaultProfilePicture = ref([]);
    const getProfilePicture = async () => {
      let uidArray = ref(null);
      uidArray.value = [];
      const promise = [];
      const rawProfile = [];

      for (let i = 0; i < feedbackDetails.value.length; i++) {
        uidArray.value.push(feedbackDetails.value[i].user_uid);
      }

      // console.log(uidArray.value[0])

      for (let i = 0; i < uidArray.value.length; i++) {
        promise.push(
          Axios.post(
            `https://us-central1-millea-lab.cloudfunctions.net/web/api/getUserData`,
            {
              uid: uidArray.value[i],
            }
          ).catch((err) => {
            if (err.message === "Request failed with status code 401") {
              localStorage.removeItem("idStorage");
              window.location.href = "/login";
            }
          })
        );
      }
      const resolve = await Promise.all(promise);
      // console.log(resolve)
      for (let i = 0; i < resolve.length; i++) {
        if (resolve[i] != undefined) {
          rawProfile.push({
            profile: resolve[i].data[0].profile_picture,
          });
        } else {
          rawProfile.push({
            profile: "",
          });
        }
      }

      profilePicture.value = rawProfile.map((data) => data.profile);
      defaultProfilePicture.value = rawProfile.map((data) => data.profile);
      // console.log(profilePicture)
      // for(let i = 0; i < resolve.length; i++){
      //   for(let j = 0; j < resolve[i].data.details.length; j++){
      //     // dates.value.push(resolve[i].data.details[j].login_date)
      //     rawDates.push({
      //       login_date: resolve[i].data.details[j].login_date,
      //       login_datetime_unix: resolve[i].data.details[j].login_datetime_unix,
      //     })
      //   }
      // }
    };

    onBeforeMount(() => {
      fetchAnalyticActivityCreator();
      // fetchAnalyticActivityViewer()
      // fetchAnalyticDownload()
      // fetchFeedback()
    });

    return {
      path,
      subPath,
      subSubPath,
      page,
      timer,
      datefilter,
      isLoadingActivityCreator,
      isLoadingActivityViewer,
      isLoadingDownload,
      isLoadingFeedback,
      allDataAnalyticActivityCreator,
      allDataAnalyticActivityViewer,
      defaultDataActivityCreator,
      defaultDataActivityViewer,
      allDataAnalyticDownload,
      defaultDataDownload,
      allDataFeedback,
      defaultDataFeedback,
      openDate,
      refresh,
      // inputSearch,
      // searchList,
      startDateRange,
      endDateRange,
      dateFilterFunction,
      filterFunction,
      export_to_csv,
      download_csv,
      seriesDownload,
      optionsDownload,
      seriesActivityCreator,
      optionsActivityCreator,
      seriesActivityViewer,
      optionsActivityViewer,
      seriesFeedback,
      optionsFeedback,
      idChartActivity,
      dataChartActivity,
      idChartActivityViewer,
      dataChartActivityViewer,
      idChartDownload,
      dataChartDownload,
      findDataChartActivityCreator,
      pushChartDataActivityCreator,
      start_chart_uActivityCreator,
      end_chart_uActivityCreator,
      total_chart_item_uActivityCreator,
      total_chart_page_uActivityCreator,
      paginate_uActivityCreator,
      max_item_chart_uActivityCreator,
      start_item_chart_uActivityCreator,
      changeChartUserActivityCreator,
      start_page_analyticActivityCreator,
      end_page_analyticActivityCreator,
      total_item_analyticActivityCreator,
      total_page_analyticActivityCreator,
      paginate_analyticActivityCreator,
      max_item_per_page_activityCreator,
      start_item_activityCreator,
      changePageAnalyticActivityCreator,
      findDataChartActivityViewer,
      pushChartDataActivityViewer,
      start_chart_uActivityViewer,
      end_chart_uActivityViewer,
      total_chart_item_uActivityViewer,
      total_chart_page_uActivityViewer,
      paginate_uActivityViewer,
      max_item_chart_uActivityViewer,
      start_item_chart_uActivityViewer,
      changeChartUserActivityViewer,
      start_page_analyticActivityViewer,
      end_page_analyticActivityViewer,
      total_item_analyticActivityViewer,
      total_page_analyticActivityViewer,
      paginate_analyticActivityViewer,
      max_item_per_page_activityViewer,
      start_item_activityViewer,
      changePageAnalyticActivityViewer,
      start_page_analyticDownload,
      end_page_analyticDownload,
      total_item_analyticDownload,
      total_page_analyticDownload,
      paginate_analyticDownload,
      max_item_per_page_download,
      start_item_download,
      changePageAnalyticDownload,
      findDataChartDownload,
      pushChartDataDownload,
      start_chart_uDownload,
      end_chart_uDownload,
      total_chart_item_uDownload,
      total_chart_page_uDownload,
      paginate_uDownload,
      max_item_chart_uDownload,
      start_item_chart_uDownload,
      changeChartUserDownload,
      start_page_feedback,
      end_page_feedback,
      total_item_feedback,
      total_page_feedback,
      paginate_feedback,
      max_item_per_feedback,
      start_item_feedback,
      changePageFeedback,
      filterTitle,
      averageRating,
      starCount,
      feedbackDetails,
      totalRating,
      profilePicture,
      defaultProfilePicture,
      getProfilePicture,
      fetchAnalyticActivityCreator,
      fetchAnalyticActivityViewer,
      fetchAnalyticDownload,
      fetchFeedback,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/Analytic/User.scss";
</style>
