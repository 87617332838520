<template>
  <div class="container-activeUserList">
    <SideHeader/>
    <div class="container-right-activeUserList">
      <TopHeader
       :pathPage="path"
       :pathSubPage="subPath"
       :pathSubSubPage="subSubPath"
      />
      <div class="main-activeUserList">
        <div class="box-header-userList">
          <div class="box-navigation">
            <div class="navigation" :class="[{ active: page ==='data' }]" @click="page = 'data', subSubPath = 'User Data', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">User Data</div>
            <div class="navigation" :class="[{ active: page ==='license' }]" @click="page = 'license' , subSubPath = 'License', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">License</div>
            <div class="navigation" :class="[{ active: page ==='vouchers' }]" @click="page = 'vouchers', subSubPath = 'Vouchers', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">Vouchers</div>
            <div class="navigation" :class="[{ active: page ==='points' }]" @click="page = 'points', subSubPath = 'Points', datefilter = '', inputSearch = '', searchList(inputSearch), startDateRange = '', endDateRange = ''">Points</div>
          </div>
          <div class="box-header-right">
            <div v-if="isDetail == false" @click="export_to_csv()" class="btn-export">
              <img src="../../assets/log-in.svg" alt="">
              <p>Export</p>
            </div>
          </div>
          
        </div>
        <div class="box-main-userList">
          <div class="box-page" v-if="page ==='data'">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="card-searching">
                  <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                  <img src="../../assets/searching.svg" alt="">
                </div>
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingData === false" id="dataTable">
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>ZIP Code</th>
                  <th>Company</th>
                </tr>
                <tr v-for="(dataActiveData,id) in allDataActiveData" :key="dataActiveData.id">
                  <td v-if="id >= start_page_activeData && id < end_page_activeData">{{id + 1}}</td>
                  <td v-if="id >= start_page_activeData && id < end_page_activeData">{{dataActiveData.name}}</td>
                  <td v-if="id >= start_page_activeData && id < end_page_activeData">{{dataActiveData.email}}</td>
                  <td v-if="id >= start_page_activeData && id < end_page_activeData && dataActiveData.phone !== ''">{{dataActiveData.phone}}</td>
                  <td v-else-if="id >= start_page_activeData && id < end_page_activeData">-</td>
                  <td v-if="id >= start_page_activeData && id < end_page_activeData && dataActiveData.address !== ''">{{dataActiveData.address}}</td>
                  <td v-else-if="id >= start_page_activeData && id < end_page_activeData">-</td>
                  <td v-if="id >= start_page_activeData && id < end_page_activeData && dataActiveData.zip_code !== ''">{{dataActiveData.zip_code}}</td>
                  <td v-else-if="id >= start_page_activeData && id < end_page_activeData">-</td>
                  <td v-if="id >= start_page_activeData && id < end_page_activeData && dataActiveData.company !== ''">{{dataActiveData.company}}</td>
                  <td v-else-if="id >= start_page_activeData && id < end_page_activeData">-</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingData === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_activeData + 1}} to {{end_page_activeData}} of {{total_item_activeData}} entries</p>
                <paginate
                v-model="paginate_activeData"
                :page-count="total_page_activeData"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageActiveData"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page ==='license'">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="card-date">
                  <div class="date-title" @click="openDate()">
                    <img src="../../assets/calendar.svg" alt="">
                    <p>Date</p>
                  </div>
                  <div id="date" class="dropdown-date">
                    <div class="card-start-end">
                      <input v-model="startDateRange" @change="dateFilterFunction()" type="date">
                      <p>to</p>
                      <input v-model="endDateRange" @change="dateFilterFunction()" type="date">
                    </div>
                    <div class="separator"></div>
                    <div class="box-date-filter">
                      <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                      <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                      <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                      <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                    </div>
                  </div>
                </div>
                <div class="card-searching">
                  <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                  <img src="../../assets/searching.svg" alt="">
                </div>
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingLicense === false" id="licenseTable">
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Purchase Date</th>
                  <th>Expired Date</th>
                </tr>
                <tr v-for="(dataActiveLicense, id) in allDataActiveLicense" :key="dataActiveLicense.id">
                  <td v-if="id >= start_page_activeLicense && id < end_page_activeLicense">{{id + 1}}</td>
                  <td v-if="id >= start_page_activeLicense && id < end_page_activeLicense">{{dataActiveLicense.name}}</td>
                  <td v-if="id >= start_page_activeLicense && id < end_page_activeLicense">{{dataActiveLicense.quantity}}</td>
                  <td v-if="id >= start_page_activeLicense && id < end_page_activeLicense">{{dataActiveLicense.purchased_date}}</td>
                  <td v-if="id >= start_page_activeLicense && id < end_page_activeLicense">{{dataActiveLicense.expired_date}}</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingLicense === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_activeLicense + 1}} to {{end_page_activeLicense}} of {{total_item_activeLicense}} entries</p>
                <paginate
                v-model="paginate_activeLicense"
                :page-count="total_page_activeLicense"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageActiveLicense"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page ==='vouchers'">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="box-dropdown">
                  <ul>
                    <li class="dropdown">
                      <div class="filter">
                        <img src="../../assets/filter.svg" alt="">
                        <p>Filter</p>
                      </div>
                      <ul>
                        <li>
                            <div class="box-submenu">
                                <p>Voucher</p>
                                <img src="../../assets/arrowleft.svg" alt="">
                            </div>
                            <ul>
                                <li @click="filterFunction('bronze')"><p>Bronze</p></li>
                                <li @click="filterFunction('silver')"><p>Silver</p></li>
                                <li @click="filterFunction('gold')"><p>Gold</p></li>
                                <li @click="filterFunction('platinum')"><p>Platinum</p></li>
                            </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="card-date">
                  <div class="date-title" @click="openDate()">
                    <img src="../../assets/calendar.svg" alt="">
                    <p>Date</p>
                  </div>
                  <div id="date" class="dropdown-date">
                    <div class="card-start-end">
                      <input v-model="startDateRange" @change="dateFilterFunction()" type="date">
                      <p>to</p>
                      <input v-model="endDateRange" @change="dateFilterFunction()" type="date">
                    </div>
                    <div class="separator"></div>
                    <div class="box-date-filter">
                      <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                      <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                      <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                      <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                    </div>
                  </div>
                </div>
                <div class="card-searching">
                  <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                  <img src="../../assets/searching.svg" alt="">
                </div>
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingVouchers === false" id="vouchersTable">
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Voucher</th>
                  <th>Redeemed Date</th>
                </tr>
                <tr v-for="(dataActiveVoucher,id) in allDataActiveVoucher" :key="dataActiveVoucher.id">
                  <td v-if="id >= start_page_activeVoucher && id < end_page_activeVoucher">{{id + 1}}</td>
                  <td v-if="id >= start_page_activeVoucher && id < end_page_activeVoucher">{{dataActiveVoucher.name}}</td>
                  <td v-if="id >= start_page_activeVoucher && id < end_page_activeVoucher">{{dataActiveVoucher.voucher}}</td>
                  <td v-if="id >= start_page_activeVoucher && id < end_page_activeVoucher">{{dataActiveVoucher.redeem_date}}</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingVouchers === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_activeVoucher+ 1}} to {{end_page_activeVoucher}} of {{total_item_activeVoucher}} entries</p>
                <paginate
                v-model="paginate_activeVoucher"
                :page-count="total_page_activeVoucher"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageActiveVoucher"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page ==='points' && isDetail == false">
            <div class="box-date-searching">
              <div class="date-searching-left">
                <div class="box-dropdown">
                  <ul>
                    <li class="dropdown">
                      <div class="filter">
                        <img src="../../assets/filter.svg" alt="">
                        <p>Filter</p>
                      </div>
                      <ul>
                        <li>
                          <div class="box-submenu" @click="filterFunction('hightolow')">
                            <p>Current Points: High to Low</p>
                          </div>
                        </li>
                        <li>
                          <div class="box-submenu" @click="filterFunction('lowtohigh')">
                            <p>Current Points: Low to High</p>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="card-date">
                  <div class="date-title" @click="openDate()">
                    <img src="../../assets/calendar.svg" alt="">
                    <p>Date</p>
                  </div>
                  <div id="date" class="dropdown-date">
                    <div class="card-start-end">
                      <input v-model="startDateRange" @change="dateFilterFunction()" type="date">
                      <p>to</p>
                      <input v-model="endDateRange" @change="dateFilterFunction()" type="date">
                    </div>
                    <div class="separator"></div>
                    <div class="box-date-filter">
                      <div class="date-filter" :class="[{ active: datefilter ==='today' }]" @click="dateFilterFunction('today')">Today</div>
                      <div class="date-filter" :class="[{ active: datefilter ==='yesterday' }]" @click="dateFilterFunction('yesterday')">Yesterday</div>
                      <div class="date-filter" :class="[{ active: datefilter ==='week' }]" @click="dateFilterFunction('week')">Last Week</div>
                      <div class="date-filter" :class="[{ active: datefilter ==='month' }]" @click="dateFilterFunction('month')">Last Month</div>
                    </div>
                  </div>
                </div>
                <div class="card-searching">
                  <input v-model="inputSearch" type="text" placeholder="Search" @change="(e)=>searchList(e.target.value)">
                  <img src="../../assets/searching.svg" alt="">
                </div>
              </div>
            </div>
            <div class="box-table">
              <table v-if="isLoadingPoints === false" id="pointsTable">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Current Points</th>
                </tr>
                <tr class="hover-row" v-for="(dataActivePoints,id) in allDataActivePoints" :key="dataActivePoints.id" @click="fetchDetailPoint(id)">
                  <td v-if="id >= start_page_activePoints && id < end_page_activePoints">{{id + 1}}</td>
                  <td v-if="id >= start_page_activePoints && id < end_page_activePoints">{{dataActivePoints.date}}</td>
                  <td v-if="id >= start_page_activePoints && id < end_page_activePoints">{{dataActivePoints.name}}</td>
                  <td v-if="id >= start_page_activePoints && id < end_page_activePoints">{{dataActivePoints.point}}</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingPoints === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left">
              <p>Showing {{start_page_activePoints + 1}} to {{end_page_activePoints}} of {{total_item_activePoints}} entries</p>
                <paginate
                v-model="paginate_activePoints"
                :page-count="total_page_activePoints"
                :page-range="3"
                :margin-pages="1"
                :click-handler="changePageActivePoints"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
          <div class="box-page" v-if="page === 'points' && isDetail == true">
            <div class="box-back" @click="isDetail = false">
              <img src="../../assets/back.svg" alt="">
              <p>Back to Points</p>
            </div>
            <div class="box-details">
              <div class="detail-name">
                <p>{{pointDetail.name}}</p>
              </div>
              <div class="detail-email-count">
                <div class="email">
                  <p>Email : {{pointDetail.email}}</p>
                </div>
                <div class="count">
                  <p>Current points: {{pointDetail.point}}</p>
                </div>
              </div>
            </div>
            <div class="box-table-padding">
              <table v-if="isLoadingDetailPoint === false">
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Activity</th>
                  <th>Voucher</th>
                  <th>Point Earned</th>
                  <th>Point Used</th>
                </tr>
                <tr v-for="(dataPointDetail,id) in allDataPointDetail" :key="dataPointDetail.id">
                  <td v-if="id >= start_page_pointDetail&& id < end_page_pointDetail">{{id + 1}}</td>
                  <td v-if="id >= start_page_pointDetail&& id < end_page_pointDetail">{{dataPointDetail.data.completed_at_date}}</td>
                  <td v-if="id >= start_page_pointDetail&& id < end_page_pointDetail">{{dataPointDetail.data.task}}</td>
                  <td v-if="id >= start_page_pointDetail&& id < end_page_pointDetail">{{dataPointDetail.vouchers[0]}}</td>
                  <td  v-if="dataPointDetail.data.point > 0 && id >= start_page_pointDetail&& id < end_page_pointDetail">{{dataPointDetail.data.point}}</td>
                  <td v-else-if="id >= start_page_pointDetail&& id < end_page_pointDetail">-</td>
                  <td v-if="dataPointDetail.data.point < 0 && id >= start_page_pointDetail&& id < end_page_pointDetail">{{dataPointDetail.data.point}}</td>
                  <td v-else-if="id >= start_page_pointDetail&& id < end_page_pointDetail">-</td>
                </tr>
              </table>
              <div class="empty-card"  v-else-if="isLoadingDetailPoint === true">
                  <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
            </div>
            <div class="paginate-left-padding">
              <p>Showing {{start_page_pointDetail + 1}} to {{end_page_pointDetail}} of {{total_item_pointDetail}} entries</p>
                <paginate
                  v-model="paginate_pointDetail"
                  :page-count="total_page_pointDetail"
                  :page-range="3"
                  :margin-pages="1"
                  :click-handler="changePagePointDetail"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'pagination'"
                  :page-class="'page-item'">>
                </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "../../components/Header/SideHeader.vue"
import TopHeader from "../../components/Header/TopHeader.vue"
import {onBeforeMount, ref, watch} from 'vue'
import Paginate from 'vuejs-paginate-next';
import { useStore } from "vuex"
import Axios from 'axios'

export default {
  name: "ActiveUserList",
  components: {
    SideHeader,
    TopHeader,
    Paginate,
  },
  setup() {
    const store = useStore()
    let path = ref('Individual License')
    let subPath = ref('Active User List')
    let subSubPath = ref('User Data')
    let page = ref('data')
    let datefilter = ref('')
    let isDetail = ref(false)
    let isLoadingDetailPoint = ref(true)
    let isLoadingData = ref(true)
    let isLoadingLicense = ref(true)
    let isLoadingVouchers = ref(true)
    let isLoadingPoints = ref(true)
    
    let allDataActiveData = ref(null)
    let defaultDataActiveData = ref(null)
    let allDataActiveLicense= ref(null)
    let defaultDataActiveLicense = ref(null)
    let allDataActiveVoucher= ref(null)
    let defaultDataActiveVoucher = ref(null)
    let allDataActivePoints= ref(null)
    let defaultDataActivePoints = ref(null)
    let allDataPointDetail = ref(null)
    let pointVoucher = ref(null)
    let pointDetail = ref(null)

    const openDate=()=>{
      document.getElementById("date").classList.toggle("show");
    }

    let inputSearch = ref('')

    watch(async()=>page.value,(()=>{
      if(page.value === 'data'){
        allDataActiveData.value = defaultDataActiveData.value
        changePageActiveData(1)
        if(allDataActiveData.value != null){
          total_page_activeData.value = Math.ceil((allDataActiveData.value.length / 10))
          total_item_activeData.value = allDataActiveData.value.length
          paginate_activeData.value = 1
        }
      }
      else if(page.value === 'license'){
        allDataActiveLicense.value = defaultDataActiveLicense.value
        filterData.value = allDataActiveLicense.value
        changePageActiveLicense(1)
        if(allDataActiveLicense.value != null){
          total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
          total_item_activeLicense.value = allDataActiveLicense.value.length
          paginate_activeLicense.value = 1
        }
      }
      else if(page.value === 'vouchers'){
        allDataActiveVoucher.value = defaultDataActiveVoucher.value
        filterData.value = allDataActiveVoucher.value
        changePageActiveVoucher(1)
        if(allDataActiveVoucher.value != null){
          total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
          total_item_activeVoucher.value = allDataActiveVoucher.value.length
          paginate_activeVoucher.value = 1  
        }
        
      }
      else if(page.value === 'points'){
        allDataActivePoints.value = defaultDataActivePoints.value
        filterData.value = allDataActivePoints.value
        changePageActivePoints(1)
        if(allDataActivePoints.value != null){
          total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
          total_item_activePoints.value = allDataActivePoints.value.length
          paginate_activePoints.value = 1 
        }
        
      }
    }))

    const searchList=(input)=>{
      if(page.value === 'data'){
        if(inputSearch.value !== ''){
          allDataActiveData.value = defaultDataActiveData.value
          let filter = allDataActiveData.value.filter((val)=>{
            if(val.name.toUpperCase().includes(input.toUpperCase())){
              return val
            }
          })
          if(filter){
            allDataActiveData.value = filter
          }else{
            allDataActiveData.value = defaultDataActiveData.value
          }
          changePageActiveData(1)
        }else{
          allDataActiveData.value = defaultDataActiveData.value
        }
        total_page_activeData.value = Math.ceil((allDataActiveData.value.length / 10))
        total_item_activeData.value = allDataActiveData.value.length
        paginate_activeData.value = 1    
      }
      if(page.value === 'license'){
        if(filterData.value == null || filterData.value === ''){
          filterData.value = defaultDataActiveLicense.value
        }
        if(inputSearch.value !== ''){
          allDataActiveLicense.value = filterData.value
          let filter = allDataActiveLicense.value.filter((val)=>{
            if(val.name.toUpperCase().includes(input.toUpperCase())){
              return val
            }
          })
          if(filter){
            allDataActiveLicense.value = filter
          }else{
            allDataActiveLicense.value = filterData.value
          }
          changePageActiveLicense(1)
        }else{
          allDataActiveLicense.value = filterData.value
        }
        total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
        total_item_activeLicense.value = allDataActiveLicense.value.length
        paginate_activeLicense.value = 1    
      }
      if(page.value === 'vouchers'){
        if(filterData.value == null || filterData.value === ''){
          filterData.value = defaultDataActiveVoucher.value
        }
        if(inputSearch.value !== ''){
          allDataActiveVoucher.value = defaultDataActiveVoucher.value
          let filter = allDataActiveVoucher.value.filter((val)=>{
            if(val.name.toUpperCase().includes(input.toUpperCase())){
              return val
            }
          })
          if(filter){
            allDataActiveVoucher.value = filter
          }else{
            allDataActiveVoucher.value = defaultDataActiveVoucher.value
          }
          changePageActiveVoucher(1)
        }else{
          allDataActiveVoucher.value = defaultDataActiveVoucher.value
        }
        total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
        total_item_activeVoucher.value = allDataActiveVoucher.value.length
        paginate_activeVoucher.value = 1    
      }
      if(page.value === 'points'){
        if(filterData.value == null || filterData.value === ''){
          filterData.value = defaultDataActivePoints.value
        }
        if(inputSearch.value !== ''){
          allDataActivePoints.value = filterData.value
          let filter = allDataActivePoints.value.filter((val)=>{
            if(val.name.toUpperCase().includes(input.toUpperCase())){
              return val
            }
          })
          if(filter){
            allDataActivePoints.value = filter
          }else{
            allDataActivePoints.value = filterData.value
          }
          changePageActivePoints(1)
        }else{
          allDataActivePoints.value = filterData.value
        }
        total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
        total_item_activePoints.value = allDataActivePoints.value.length
        paginate_activePoints.value = 1    
      }
    }

    let startDateRange = ref('')
    let endDateRange = ref('')
    let filterData = ref(null)
    const dateFilterFunction=(params)=>{
      if(page.value == 'license'){
        if((startDateRange.value != '' && endDateRange.value != '')){
          datefilter.value = ''
          allDataActiveLicense.value = defaultDataActiveLicense.value
          var formatStart =  startDateRange.value.split('-')
          var startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          var formatEnd =  endDateRange.value.split('-')
          var endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
        
          let filter = allDataActiveLicense.value.filter((val)=>{
            var formatDate = val.purchased_date.split(/\//)
            var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            return (date >= startDate && date <= endDate)
          })

          if(filter){
            allDataActiveLicense.value = filter
          }else{
            allDataActiveLicense.value = defaultDataActiveLicense.value
          }
          filterData.value = allDataActiveLicense.value
          changePageActiveLicense(1)
          total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
          total_item_activeLicense.value = allDataActiveLicense.value.length
          paginate_activeLicense.value = 1 
          openDate()
        }
        else if(params == 'today'){
          if(datefilter.value == 'today'){
            datefilter.value = ''
            allDataActiveLicense.value = defaultDataActiveLicense.value
            filterData.value = allDataActiveLicense.value
            changePageActiveLicense(1)
            total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
            total_item_activeLicense.value = allDataActiveLicense.value.length
            paginate_activeLicense.value = 1 
          }else{
            datefilter.value = 'today'
            allDataActiveLicense.value = defaultDataActiveLicense.value
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            let filter = allDataActiveLicense.value.filter((val)=>{
              if(val.purchased_date.toUpperCase().includes(today.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataActiveLicense.value = filter
            }else{
              allDataActiveLicense.value = defaultDataActiveLicense.value
            }
            filterData.value = allDataActiveLicense.value
            changePageActiveLicense(1)
            total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
            total_item_activeLicense.value = allDataActiveLicense.value.length
            paginate_activeLicense.value = 1
          }
          openDate()
        }
        else if(params == 'yesterday'){
          if(datefilter.value == 'yesterday'){
            datefilter.value = ''
            allDataActiveLicense.value = defaultDataActiveLicense.value
            filterData.value = allDataActiveLicense.value
            changePageActiveLicense(1)
            total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
            total_item_activeLicense.value = allDataActiveLicense.value.length
            paginate_activeLicense.value = 1 
          }else{
            datefilter.value = 'yesterday'
            allDataActiveLicense.value = defaultDataActiveLicense.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataActiveLicense.value.filter((val)=>{
              var formatDate = val.purchased_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataActiveLicense.value = filter
            }else{
              allDataActiveLicense.value = defaultDataActiveLicense.value
            }
            filterData.value = allDataActiveLicense.value
            changePageActiveLicense(1)
            total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
            total_item_activeLicense.value = allDataActiveLicense.value.length
            paginate_activeLicense.value = 1 
          }
          openDate()
        }
        else if(params == 'week'){
          if(datefilter.value == 'week'){
            datefilter.value = ''
            allDataActiveLicense.value = defaultDataActiveLicense.value
            filterData.value = allDataActiveLicense.value
            changePageActiveLicense(1)
            total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
            total_item_activeLicense.value = allDataActiveLicense.value.length
            paginate_activeLicense.value = 1 
          }else{
            datefilter.value = 'week'
            allDataActiveLicense.value = defaultDataActiveLicense.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataActiveLicense.value.filter((val)=>{
              var formatDate = val.purchased_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataActiveLicense.value = filter
            }else{
              allDataActiveLicense.value = defaultDataActiveLicense.value
            }
            filterData.value = allDataActiveLicense.value
            changePageActiveLicense(1)
            total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
            total_item_activeLicense.value = allDataActiveLicense.value.length
            paginate_activeLicense.value = 1 
          }
          openDate()
        }
        else if(params == 'month'){
          if(datefilter.value == 'month'){
            datefilter.value = ''
            allDataActiveLicense.value = defaultDataActiveLicense.value
            filterData.value = allDataActiveLicense.value
            changePageActiveLicense(1)
            total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
            total_item_activeLicense.value = allDataActiveLicense.value.length
            paginate_activeLicense.value = 1 
          }else{
            datefilter.value = 'month'
            allDataActiveLicense.value = defaultDataActiveLicense.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataActiveLicense.value.filter((val)=>{
              var formatDate = val.purchased_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataActiveLicense.value = filter
            }else{
              allDataActiveLicense.value = defaultDataActiveLicense.value
            }
            filterData.value = allDataActiveLicense.value
            changePageActiveLicense(1)
            total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
            total_item_activeLicense.value = allDataActiveLicense.value.length
            paginate_activeLicense.value = 1 
          }
          openDate()
        }
        else{
          allDataActiveLicense.value = defaultDataActiveLicense.value
          filterData.value = allDataActiveLicense.value
          changePageActiveLicense(1)
          total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
          total_item_activeLicense.value = allDataActiveLicense.value.length
          paginate_activeLicense.value = 1 
        }
      }
      if(page.value == 'vouchers'){
        // redeem_date
        // console.log(allDataActiveVoucher)
        if((startDateRange.value != '' && endDateRange.value != '')){
          formatStart =  startDateRange.value.split('-')
          startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          formatEnd =  endDateRange.value.split('-')
          endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
        
          let filter = allDataActiveVoucher.value.filter((val)=>{
            var formatDate = val.redeem_date.split(/\//)
            var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            return (date >= startDate && date <= endDate)
          })

          if(filter){
            allDataActiveVoucher.value = filter
          }else{
            allDataActiveVoucher.value = defaultDataActiveVoucher.value
          }
          filterData.value = allDataActiveVoucher.value
          changePageActiveVoucher(1)
          total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
          total_item_activeVoucher.value = allDataActiveVoucher.value.length
          paginate_activeVoucher.value = 1 
          openDate()
        }
        else if(params == 'today'){
          if(datefilter.value == 'today'){
            datefilter.value = ''
            allDataActiveVoucher.value = defaultDataActiveVoucher.value
            filterData.value = allDataActiveVoucher.value
            changePageActiveVoucher(1)
            total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
            total_item_activeVoucher.value = allDataActiveVoucher.value.length
            paginate_activeVoucher.value = 1 
          }else{
            datefilter.value = 'today'
            allDataActiveVoucher.value = defaultDataActiveVoucher.value
            today = new Date();
            dd = String(today.getDate()).padStart(2, '0');
            mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            let filter = allDataActiveVoucher.value.filter((val)=>{
              if(val.redeem_date.toUpperCase().includes(today.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataActiveVoucher.value = filter
            }else{
              allDataActiveVoucher.value = defaultDataActiveVoucher.value
            }
            filterData.value = allDataActiveVoucher.value
            changePageActiveVoucher(1)
            total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
            total_item_activeVoucher.value = allDataActiveVoucher.value.length
            paginate_activeVoucher.value = 1  
          }
          openDate()
        }
        else if(params == 'yesterday'){
          if(datefilter.value == 'yesterday'){
            datefilter.value = ''
            allDataActiveVoucher.value = defaultDataActiveVoucher.value
            filterData.value = allDataActiveVoucher.value
            changePageActiveVoucher(1)
            total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
            total_item_activeVoucher.value = allDataActiveVoucher.value.length
            paginate_activeVoucher.value = 1 
          }else{
            datefilter.value = 'yesterday'
            allDataActiveVoucher.value = defaultDataActiveVoucher.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataActiveVoucher.value.filter((val)=>{
              var formatDate = val.redeem_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataActiveVoucher.value = filter
            }else{
              allDataActiveVoucher.value = defaultDataActiveVoucher.value
            }
            filterData.value = allDataActiveVoucher.value
            changePageActiveVoucher(1)
            total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
            total_item_activeVoucher.value = allDataActiveVoucher.value.length
            paginate_activeVoucher.value = 1 
          }
          openDate()
        }
        else if(params == 'week'){
          if(datefilter.value == 'week'){
            datefilter.value = ''
            allDataActiveVoucher.value = defaultDataActiveVoucher.value
            filterData.value = allDataActiveVoucher.value
            changePageActiveVoucher(1)
            total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
            total_item_activeVoucher.value = allDataActiveVoucher.value.length
            paginate_activeVoucher.value = 1 
          }else{
            datefilter.value = 'week'
            allDataActiveVoucher.value = defaultDataActiveVoucher.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataActiveVoucher.value.filter((val)=>{
              var formatDate = val.redeem_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataActiveVoucher.value = filter
            }else{
              allDataActiveVoucher.value = defaultDataActiveVoucher.value
            }
            filterData.value = allDataActiveVoucher.value
            changePageActiveVoucher(1)
            total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
            total_item_activeVoucher.value = allDataActiveVoucher.value.length
            paginate_activeVoucher.value = 1 
          }
          openDate()
        }
        else if(params == 'month'){
          if(datefilter.value == 'month'){
            datefilter.value = ''
            allDataActiveVoucher.value = defaultDataActiveVoucher.value
            filterData.value = allDataActiveVoucher.value
            changePageActiveVoucher(1)
            total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
            total_item_activeVoucher.value = allDataActiveVoucher.value.length
            paginate_activeVoucher.value = 1 
          }else{
            datefilter.value = 'month'
            allDataActiveVoucher.value = defaultDataActiveVoucher.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataActiveVoucher.value.filter((val)=>{
              var formatDate = val.redeem_date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataActiveVoucher.value = filter
            }else{
              allDataActiveVoucher.value = defaultDataActiveVoucher.value
            }
            filterData.value = allDataActiveVoucher.value
            changePageActiveVoucher(1)
            total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
            total_item_activeVoucher.value = allDataActiveVoucher.value.length
            paginate_activeVoucher.value = 1 
          }
          openDate()
        }
        else{
          allDataActiveVoucher.value = defaultDataActiveVoucher.value
          filterData.value = allDataActiveVoucher.value
          changePageActiveVoucher(1)
          total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
          total_item_activeVoucher.value = allDataActiveVoucher.value.length
          paginate_activeVoucher.value = 1
        }
      }
      if(page.value == 'points'){
        // date
        // console.log(allDataActivePoints)
        if((startDateRange.value != '' && endDateRange.value != '')){
          formatStart =  startDateRange.value.split('-')
          startDate = new Date([ formatStart[1], formatStart[2], formatStart[0] ].join('/'))
          formatEnd =  endDateRange.value.split('-')
          endDate = new Date([ formatEnd[1], formatEnd[2], formatEnd[0] ].join('/'))
        
          let filter = allDataActivePoints.value.filter((val)=>{
            var formatDate = val.date.split(/\//)
            var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
            return (date >= startDate && date <= endDate)
          })

          if(filter){
            allDataActivePoints.value = filter
          }else{
            allDataActivePoints.value = defaultDataActivePoints.value
          }
          filterData.value = allDataActivePoints.value
          changePageActivePoints(1)
          total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
          total_item_activePoints.value = allDataActivePoints.value.length
          paginate_activePoints.value = 1 
          openDate()
        }
        else if(params == 'today'){
          if(datefilter.value == 'today'){
            datefilter.value = ''
            allDataActivePoints.value = defaultDataActivePoints.value
            filterData.value = allDataActivePoints.value
            changePageActivePoints(1)
            total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
            total_item_activePoints.value = allDataActivePoints.value.length
            paginate_activePoints.value = 1 
          }else{
            datefilter.value = 'today'
            allDataActivePoints.value = defaultDataActivePoints.value
            today = new Date();
            dd = String(today.getDate()).padStart(2, '0');
            mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            let filter = allDataActivePoints.value.filter((val)=>{
              if(val.date.toUpperCase().includes(today.toUpperCase())){
                return val
              }
            })
            if(filter){
              allDataActivePoints.value = filter
            }else{
              allDataActivePoints.value = defaultDataActivePoints.value
            }
            filterData.value = allDataActivePoints.value
            changePageActivePoints(1)
            total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
            total_item_activePoints.value = allDataActivePoints.value.length
            paginate_activePoints.value = 1  
          }
          openDate()
        }
        else if(params == 'yesterday'){
          if(datefilter.value == 'yesterday'){
            datefilter.value = ''
            allDataActivePoints.value = defaultDataActivePoints.value
            filterData.value = allDataActivePoints.value
            changePageActivePoints(1)
            total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
            total_item_activePoints.value = allDataActivePoints.value.length
            paginate_activePoints.value = 1 
          }else{
            datefilter.value = 'yesterday'
            allDataActivePoints.value = defaultDataActivePoints.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataActivePoints.value.filter((val)=>{
              var formatDate = val.date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataActivePoints.value = filter
            }else{
              allDataActivePoints.value = defaultDataActivePoints.value
            }
            filterData.value = allDataActivePoints.value
            changePageActivePoints(1)
            total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
            total_item_activePoints.value = allDataActivePoints.value.length
            paginate_activePoints.value = 1 
          }
          openDate()
        }
        else if(params == 'week'){
          if(datefilter.value == 'week'){
            datefilter.value = ''
            allDataActivePoints.value = defaultDataActivePoints.value
            filterData.value = allDataActivePoints.value
            changePageActivePoints(1)
            total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
            total_item_activePoints.value = allDataActivePoints.value.length
            paginate_activePoints.value = 1 
          }else{
            datefilter.value = 'week'
            allDataActivePoints.value = defaultDataActivePoints.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataActivePoints.value.filter((val)=>{
              var formatDate = val.date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataActivePoints.value = filter
            }else{
              allDataActivePoints.value = defaultDataActivePoints.value
            }
            filterData.value = allDataActivePoints.value
            changePageActivePoints(1)
            total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
            total_item_activePoints.value = allDataActivePoints.value.length
            paginate_activePoints.value = 1 
          }
          openDate()
        }
        else if(params == 'month'){
          if(datefilter.value == 'month'){
            datefilter.value = ''
            allDataActivePoints.value = defaultDataActivePoints.value
            filterData.value = allDataActivePoints.value
            changePageActivePoints(1)
            total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
            total_item_activePoints.value = allDataActivePoints.value.length
            paginate_activePoints.value = 1 
          }else{
            datefilter.value = 'month'
            allDataActivePoints.value = defaultDataActivePoints.value

            today = new Date();
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
            endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            let filter = allDataActivePoints.value.filter((val)=>{
              var formatDate = val.date.split(/\//)
              var date = new Date([ formatDate[1], formatDate[0], formatDate[2] ].join('/'))
              return (date >= startDate && date <= endDate)
            })

            if(filter){
              allDataActivePoints.value = filter
            }else{
              allDataActivePoints.value = defaultDataActivePoints.value
            }
            filterData.value = allDataActivePoints.value
            changePageActivePoints(1)
            total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
            total_item_activePoints.value = allDataActivePoints.value.length
            paginate_activePoints.value = 1 
          }
          openDate()
        }
        else{
          allDataActivePoints.value = defaultDataActivePoints.value
          filterData.value = allDataActivePoints.value
          changePageActivePoints(1)
          total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
          total_item_activePoints.value = allDataActivePoints.value.length
          paginate_activePoints.value = 1 
        }
      }
    }

    const filterFunction=(params)=>{
      if(page.value === 'vouchers'){
        allDataActiveVoucher.value = filterData.value
        let filter = allDataActiveVoucher.value.filter((val)=>{
          if(val.voucher.toUpperCase().includes(params.toUpperCase())){
            return val
          }
        })
        if(filter){
          allDataActiveVoucher.value = filter
        }else{
          allDataActiveVoucher.value = defaultDataActiveVoucher.value
        }
        changePageActiveVoucher(1)
        total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
        total_item_activeVoucher.value = allDataActiveVoucher.value.length
        paginate_activeVoucher.value = 1  
      }
      if(page.value === 'points'){
        // allDataActivePoints.value = defaultDataActivePoints.value
        if(params === 'hightolow'){
          allDataActivePoints.value.sort((a,b)=>b.point - a.point)
        }else if(params === 'lowtohigh'){
          allDataActivePoints.value.sort((a,b)=>a.point - b.point)
        }
      }
    }

    const export_to_csv=()=>{
      if(page.value === 'data'){
        let arrayToPdf = []
        var rows = document.querySelectorAll('#dataTable tr')
        let row = []

        rows[0].querySelectorAll('td,th').forEach((val)=>{
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataActiveData.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.name.replaceAll(',', '')},${val.email},${val.phone},${val.address.replaceAll(',', '')},${val.zip_code},${val.company.replaceAll(',', '')}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if(page.value === 'license'){
        let arrayToPdf = []
        rows = document.querySelectorAll('#licenseTable tr')
        let row = []
        rows[0].querySelectorAll('td,th').forEach((val)=>{
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataActiveLicense.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.name.replaceAll(',', '')},${val.quantity},${val.purchased_date},${val.expired_date}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if(page.value === 'vouchers'){
        let arrayToPdf = []
        rows = document.querySelectorAll('#vouchersTable tr')
        let row = []
        rows[0].querySelectorAll('td,th').forEach((val)=>{
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataActiveVoucher.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.name.replaceAll(',', '')},${val.voucher},${val.redeem_date}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
      if(page.value === 'points'){
        let arrayToPdf = []
        rows = document.querySelectorAll('#pointsTable tr')
        let row = []
        rows[0].querySelectorAll('td,th').forEach((val)=>{
          row.push(val.innerText)
        })
        arrayToPdf.push(row.join(','))
        allDataActivePoints.value.forEach((val,index)=>{
          arrayToPdf.push(`${index+1},${val.date},${val.name.replaceAll(',', '')},${val.point}`)
        })
        download_csv(arrayToPdf.join("\n"), page.value);
      }
    }

    function download_csv(csv, filename){
      var csvFile;
      var downloadLink;

      csvFile = new Blob([csv], {type: "text/csv"});
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }

    //Active Data State
    let start_page_activeData = ref(0)
    let end_page_activeData = ref(10)
    let total_item_activeData = ref (0)
    let total_page_activeData = ref(0)
    let paginate_activeData = ref(1)

    const changePageActiveData=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_activeData.value =  new_start
      end_page_activeData.value = new_end
    }

    //Active License State
    let start_page_activeLicense = ref(0)
    let end_page_activeLicense = ref(10)
    let total_item_activeLicense = ref (0)
    let total_page_activeLicense = ref(0)
    let paginate_activeLicense = ref(1)

    const changePageActiveLicense=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_activeLicense.value =  new_start
      end_page_activeLicense.value = new_end
    }

    //Active Voucher State
    let start_page_activeVoucher = ref(0)
    let end_page_activeVoucher = ref(10)
    let total_item_activeVoucher = ref (0)
    let total_page_activeVoucher = ref(0)
    let paginate_activeVoucher = ref(1)

    const changePageActiveVoucher=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_activeVoucher.value =  new_start
      end_page_activeVoucher.value = new_end
    }

    //Active Points State
    let start_page_activePoints = ref(0)
    let end_page_activePoints = ref(10)
    let total_item_activePoints = ref (0)
    let total_page_activePoints = ref(0)
    let paginate_activePoints = ref(1)

    const changePageActivePoints=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_activePoints.value =  new_start
      end_page_activePoints.value = new_end
    }

    //Active Points Detail
    let start_page_pointDetail = ref(0)
    let end_page_pointDetail= ref(10)
    let total_item_pointDetail = ref (0)
    let total_page_pointDetail = ref(0)
    let paginate_pointDetail = ref(1)

    const changePagePointDetail=(pageNum)=>{
      let new_end = pageNum * 10
      let new_start = new_end - 10
    
      start_page_pointDetail.value =  new_start
      end_page_pointDetail.value = new_end
    }

    const fetchActiveData=async()=>{
      isLoadingData.value = true
      await store.dispatch('getValueActiveData')
      let temp = await store.getters.getAllActiveData
      allDataActiveData.value = temp
      defaultDataActiveData.value = temp
      total_page_activeData.value = Math.ceil((allDataActiveData.value.length / 10))
      total_item_activeData.value = allDataActiveData.value.length
      isLoadingData.value = false
    }
    const fetchActiveLicense=async()=>{
      isLoadingLicense.value = true
      await store.dispatch('getValueActiveLicense')
      let temp = await store.getters.getAllActiveLicense
      allDataActiveLicense.value = temp
      defaultDataActiveLicense.value = temp
      total_page_activeLicense.value = Math.ceil((allDataActiveLicense.value.length / 10))
      total_item_activeLicense.value = allDataActiveLicense.value.length
      isLoadingLicense.value = false
    }
    const fetchActiveVoucher=async()=>{
      isLoadingVouchers.value = true
      await store.dispatch('getValueActiveVoucher')
      let temp = await store.getters.getAllActiveVoucher
      allDataActiveVoucher.value = temp
      defaultDataActiveVoucher.value = temp
      total_page_activeVoucher.value = Math.ceil((allDataActiveVoucher.value.length / 10))
      total_item_activeVoucher.value = allDataActiveVoucher.value.length
      // console.log(allDataActiveVoucher)
      isLoadingVouchers.value = false
    }
    const fetchActivePoints=async()=>{
      isLoadingPoints.value = true
      await store.dispatch('getValueActivePoints')
      let temp = await store.getters.getAllActivePoints
      allDataActivePoints.value = temp
      defaultDataActivePoints.value = temp
      total_page_activePoints.value = Math.ceil((allDataActivePoints.value.length / 10))
      total_item_activePoints.value = allDataActivePoints.value.length
      // console.log(allDataActivePoints)
      isLoadingPoints.value = false
    }
    const fetchDetailPoint=(id)=>{
      isLoadingDetailPoint.value = true
      isDetail.value = true
      pointDetail.value = allDataActivePoints.value[id]
      let token = localStorage.getItem('idToken')
      // let uid = localStorage.getItem('idStorage')
      let uid = pointDetail.value.uid
      Axios.post('https://us-central1-millea-lab.cloudfunctions.net/web/api/getIndividualLicenseTaskHistory',{
        "user_uid":uid
      },{
      headers:{
            Authorization: 'Bearer ' + token,
      }}
      ).then((res)=>{
        allDataPointDetail.value = []
        // console.log(res.data[0].point)
        pointVoucher.value = []
        for (let i = 0; i < res.data.length; i++){
          if(res.data[i].point < 0){
            // console.log(res.data[i].task.split(" ").splice(-1))
            allDataPointDetail.value.push({
              data: res.data[i],
              vouchers: res.data[i].task.split(" ").splice(-1)
            })
            // pointVoucher.value[i] = res.data[i].task.split(" ").splice(-1)
          }else{
            allDataPointDetail.value.push({
              data: res.data[i],
              vouchers: "-"
            })
          }
        }
        // allDataPointDetail.value = pointVoucher.map((voucher)=>voucher.value)
        // console.log(allDataPointDetail)

        total_page_pointDetail.value = Math.ceil((allDataPointDetail.value.length / 10))
        total_item_pointDetail.value = allDataPointDetail.value.length
      }).catch((err)=>{
        allDataPointDetail.value = []
        if(err.message === 'Request failed with status code 401'){
            localStorage.removeItem('idStorage')
            window.location.href="/login"
        }
      })
      isLoadingDetailPoint.value = false
    }
    onBeforeMount(()=>{
      fetchActiveData()
      fetchActiveLicense()
      fetchActiveVoucher()
      fetchActivePoints()
    })

    return {
      path,
      subPath,
      subSubPath,
      page,
      datefilter,
      isDetail,
      isLoadingDetailPoint,
      isLoadingData,
      isLoadingLicense,
      isLoadingVouchers,
      isLoadingPoints,
      allDataActiveData,
      defaultDataActiveData,
      allDataActiveLicense,
      defaultDataActiveLicense,
      allDataActiveVoucher,
      defaultDataActiveVoucher,
      allDataActivePoints,
      defaultDataActivePoints,
      allDataPointDetail,
      pointDetail,
      pointVoucher,
      openDate,
      inputSearch,
      searchList,
      startDateRange,
      endDateRange,
      filterData,
      dateFilterFunction,
      filterFunction,
      export_to_csv,
      download_csv,
      start_page_activeData,
      end_page_activeData,
      total_item_activeData,
      total_page_activeData,
      paginate_activeData,
      changePageActiveData,
      start_page_activeLicense,
      end_page_activeLicense,
      total_item_activeLicense,
      total_page_activeLicense,
      paginate_activeLicense,
      changePageActiveLicense,
      start_page_activeVoucher,
      end_page_activeVoucher,
      total_item_activeVoucher,
      total_page_activeVoucher,
      paginate_activeVoucher,
      changePageActiveVoucher,
      start_page_activePoints,
      end_page_activePoints,
      total_item_activePoints,
      total_page_activePoints,
      paginate_activePoints,
      changePageActivePoints,
      start_page_pointDetail,
      end_page_pointDetail,
      total_item_pointDetail,
      total_page_pointDetail,
      paginate_pointDetail,
      changePagePointDetail,
      fetchDetailPoint,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/IndividualLicense/ActiveUserList.scss";
</style>

