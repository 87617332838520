// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
import {getAuth,GoogleAuthProvider} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCy02khlvZbXq9rleicnnjW4kdyRnT14tw",
  authDomain: "millea-lab.firebaseapp.com",
  databaseURL: "https://millea-lab.firebaseio.com",
  projectId: "millea-lab",
  storageBucket: "millea-lab-cdn",
  messagingSenderId: "1092223455901",
  appId: "1:1092223455901:web:4c772066f5f8a257697a74",
  measurementId: "G-K61SBKPZLL"
  };


firebase.initializeApp(firebaseConfig)
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)

export const provider = new GoogleAuthProvider()

export const db = getFirestore(app)
  

  